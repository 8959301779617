/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from "react"

const initialValues: {
  checkedTreeBranchInput: number
  treeBranchExtraProps: any
} = {
  checkedTreeBranchInput: 0,
  treeBranchExtraProps: {},
}

const CompositionFormTreeContext = createContext(initialValues)

export default CompositionFormTreeContext
