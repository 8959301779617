import React, { useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import Checkbox from "components/Checkbox/Checkbox"

import { handleToast } from "utils/messages"
import { getCountryFlagClassName } from "utils/strings"

import { userRequestPlantsAccess } from "../../../users/service/api"
import { sortByCompanyName, sortByCountry } from "../../service/sort"
import { Company } from "../../types"
import CompanyDetailsModal from "./CompanyDetailsModal"
import CompanyRoleLabel from "./CompanyRoleLabel"

interface CompanyRequestTableProps {
  companies: Company[]
  onSubmitted?: Function
}

const RequestCompanyAccess = (
  props: React.PropsWithChildren<CompanyRequestTableProps>,
) => {
  const { companies, onSubmitted } = props
  const { t } = useTranslation()

  const [nameSortDirection, setNameSortDirection] = useState("ascending")
  const [countrySortDirection, setCountrySortDirection] = useState("ascending")
  const [selectedPlants, setSelectedPlants] = useState<number[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0)
  const [isSelectedCompanyPlant, setIsSelectedCompanyPlant] =
    useState<boolean>(false)
  const [sortedCompanies, setSortedCompanies] = useState<Company[]>([])
  const [isCompanyDetailsOpened, setIsCompanyDetailsOpened] =
    useState<boolean>(false)

  const showCompanyDetails = false

  const showDetailsModal = (company: Company, isPlant = false) => {
    setIsSelectedCompanyPlant(isPlant)
    setSelectedCompanyId(company.id || 0)
    setIsCompanyDetailsOpened(true)
  }

  const onCloseCompanyDetailsModal = () => {
    setIsCompanyDetailsOpened(false)
  }

  const onRequestAccessClicked = useCallback(() => {
    if (selectedPlants.length) {
      userRequestPlantsAccess({ plants: selectedPlants }).then(
        ({ data, errors }) => {
          if (errors) {
            handleToast(errors, "error")
          }

          if (data) {
            handleToast([
              "Request access to selected plants sent and will be soon reviewed",
            ])
            if (onSubmitted) {
              onSubmitted(selectedPlants)
            }

            setSelectedPlants([])
          }
        },
      )
    } else {
      handleToast(["You have to select at least one plant"], "error")
    }
  }, [onSubmitted, selectedPlants])

  const toggleRequestPlant = (requested: boolean, companyId?: number) => {
    if (companyId) {
      if (requested) {
        setSelectedPlants(selectedPlants.filter((id) => id !== companyId))
      } else {
        setSelectedPlants([...selectedPlants, companyId])
      }
    }
  }

  const plantRow = (company: Company) => {
    const plantRequested = company.id
      ? selectedPlants.includes(company.id)
      : false

    if (company.archived === false) {
      return (
        <tr key={company.id} className={plantRequested ? "row-selected" : ""}>
          <td className="align-middle text-center">
            <Checkbox
              name={`plant-request-checkbox-${company.id}`}
              value={plantRequested}
              handleOnChange={() =>
                toggleRequestPlant(plantRequested, company.id)
              }
              labelClassName="mx-auto"
              checkboxWrapperClassName="mr-0"
            />
          </td>
          <td className="align-middle">
            {showCompanyDetails ? (
              <button
                type="button"
                className="btn btn-link"
                onClick={() =>
                  company.parent && showDetailsModal(company.parent)
                }
              >
                {company.parent?.name}
              </button>
            ) : (
              company.parent?.name
            )}
          </td>
          <td className="align-middle">
            {showCompanyDetails ? (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => showDetailsModal(company, true)}
              >
                {company.name}
              </button>
            ) : (
              company.name
            )}
          </td>
          <td className="align-middle">
            <CompanyRoleLabel role={company.roleId} />
          </td>
          <td className="align-middle text-center">
            <span className={getCountryFlagClassName(company.country?.code)} />
          </td>
        </tr>
      )
    }
  }

  useEffect(() => {
    setSortedCompanies(sortByCompanyName(companies, nameSortDirection))
  }, [companies, nameSortDirection])

  return (
    <>
    <div className="modal-body">
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="text-center">{t("Request")}</th>
            <th
              className="cursor-pointer"
              onClick={() =>
                setNameSortDirection(
                  nameSortDirection === "ascending"
                    ? "descending"
                    : "ascending",
                )
              }
            >
              {t("Company name")}
              <span
                className={`pl-2 fa text-primary ${
                  nameSortDirection === "ascending"
                    ? "fa-angle-up"
                    : "fa-angle-down"
                }`}
              />
            </th>
            <th>{t("Plant name")}</th>
            <th>{t("Activity / role")}</th>
            <th
              className="text-center cursor-pointer"
              onClick={() => {
                setCountrySortDirection(
                  countrySortDirection === "ascending"
                    ? "descending"
                    : "ascending",
                )
                setSortedCompanies(
                  sortByCountry(companies, countrySortDirection),
                )
              }}
            >
              {t("Country")}
              <span
                className={`pl-2 fa text-primary ${
                  countrySortDirection === "ascending"
                    ? "fa-angle-up"
                    : "fa-angle-down"
                }`}
              />
            </th>
          </tr>
          {sortedCompanies.length ? (
            sortedCompanies.map((company) => plantRow(company))
          ) : (
            <tr>
              <td className="align-middle text-center" colSpan={5}>
                {t("There is no data to display")}
              </td>
            </tr>
          )}
        </thead>
      </table>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end mt-4">
          <button
            type="button"
            className={
              selectedPlants.length
                ? "btn btn-primary"
                : "btn btn-outline-primary"
            }
            onClick={onRequestAccessClicked}
          >
            {t("Request access to selected plants")}
          </button>
        </div>
      </div>

      <CompanyDetailsModal
        {...{
          companyId: selectedCompanyId,
          isPlant: isSelectedCompanyPlant,
          closeModal: onCloseCompanyDetailsModal,
          isCompanyDetailsOpened,
        }}
      />
    </>
  )
}

export default RequestCompanyAccess
