import React, { useState, useEffect } from "react"
import { useRouteMatch } from "react-router-dom"

import CompositionForm from "../components/CompositionForm/CompositionForm"
import { getComposition } from "../service/api"
import { CompositionQueryResponse } from "../types"

const CompositionDetail = () => {
  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const [currentComposition, setCurrentComposition] =
    useState<CompositionQueryResponse>({
      id: 0,
      name: "",
      productName: "",
      additivesPercentage: 0,
      otherPercentage: 0,
      contaminationPercentage: 0,
      companyId: 0,
      type: 0,
      userId: 0,
      isArchived: false,
    })

  useEffect(() => {
    if (id) {
      getComposition({ id: parseInt(id, 0) }).then(({ data }) => {
        if (data) {
          setCurrentComposition(data)
        }
      })
    }
  }, [id])

  return (
    <div>
      <h1>{currentComposition.productName}</h1>
      <CompositionForm initialComposition={currentComposition} disabled />
    </div>
  )
}

export default CompositionDetail
