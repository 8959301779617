import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"

import application, { ApplicationState } from "../modules/applications/reducer"
import auth, { AuthState } from "../modules/auth/reducer"
import company, { CompanyState } from "../modules/companies/reducer"
import composition, { CompositionState } from "../modules/compositions/reducer"
import country, { CountryState } from "../modules/countries/reducer"
import genericComposition, {
  GenericCompositionState,
} from "../modules/genericCompositions/reducer"
import material, { MaterialState } from "../modules/materials/reducer"
import product, { ProductState } from "../modules/products/reducer"
import submission, { SubmissionState } from "../modules/submissions/reducer"
import user, { UserState } from "../modules/users/reducer"

const rootReducer = combineReducers({
  auth,
  application,
  product,
  material,
  composition,
  genericComposition,
  country,
  company,
  submission,
  user,
})

export interface State {
  auth: AuthState
  application: ApplicationState
  product: ProductState
  material: MaterialState
  composition: CompositionState
  genericComposition: GenericCompositionState
  country: CountryState
  company: CompanyState
  submission: SubmissionState
  user: UserState
}

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk)),
)

export default store
