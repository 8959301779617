import React, { useState, useEffect } from "react"
import { useRouteMatch } from "react-router-dom"

import ContractComponent from "./components/ContractComponent/index"
import { getContract } from "./service/api"
import { Contract } from "./types"

export default () => {
  const [contractInfo, setContractInfo] = useState<Contract>()

  const {
    params: { id: companyId },
  }: { params: { id: string } } = useRouteMatch()

  useEffect(() => {
    const fetchContract = async () => {
      const { data: contract } = await getContract({
        companyId: Number(companyId),
      })
      if (contract) {
        setContractInfo(contract)
      }
    }
    fetchContract()
  }, [companyId])

  return <ContractComponent {...{ contractInfo }} />
}
