import bulkActionsHintArrow from "assets/images/auditicons/bulkActions-HintArrow.svg"
import { activityTypes, wasteTypes } from "modules/NewAudit/constants"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import ConfirmButton from "views/partials/ConfirmButton"

import Checkbox from "components/Checkbox/Checkbox"

import { dateFormat, isDaysAfter } from "utils/dates"
import { handleToast } from "utils/messages"
import { history } from "utils/routes"
import { getCountryFlagClassName } from "utils/strings"

import useUserFromToken from "hooks/useUserFromToken"

import { EuCountriesArray } from "../../../../constants"
import {
  CAN_EDIT_SUBMISSION_TO_DAYS,
  COLLECTION_DATE_FORMAT,
} from "../../constants"
import {
  approveSubmission,
  declineSubmission,
  submissionStatusChange,
} from "../../service/api"
import { Submission, SubmissionStatus } from "../../types"

interface Props {
  inputData: string
  submissions: Submission[]
  toggledSubbmisions: Submission[]
  setToggleSubbmision: React.Dispatch<React.SetStateAction<Submission[]>>
  onSortChanged(sortBy: string, sortDirection: string): void
  getSubmissions(): void
  isMain: boolean
  isDisabled: boolean
  toggleAllSubmissions: { inputAllToggle: boolean; outputAllToggle: boolean }
  setToggleAllSubmissions: React.Dispatch<
    React.SetStateAction<{
      inputAllToggle: boolean
      outputAllToggle: boolean
    }>
  >
  filterActivityType?: string | null
  filterWasteType?: string | null
  filterStatus?: string | null
}

type SubmissionSortFieldsType =
  | "type"
  | "yearlyNumber"
  | "collectionDate"
  | "composition"
  | "wasteType"
  | "contamination"
  | "volumeNet"
  | "volumeGross"
  | "estimatedShareExportedOutsideEu"
  | "status"
  | "administrationNumber"

const SubmissionListTableBody: React.FC<Props> = ({
  submissions,
  toggledSubbmisions,
  setToggleSubbmision,
  getSubmissions,
  inputData,
  isMain = false,
  isDisabled = false,
  toggleAllSubmissions,
  setToggleAllSubmissions,
  filterActivityType = null,
  filterWasteType = null,
  filterStatus = null,
}) => {
  const { t } = useTranslation()
  const { isAdmin, isRrr } = useUserFromToken()

  const [sortKey, setSortKey] = React.useState<SubmissionSortFieldsType>("type")
  const [sortDesc, setSortDesc] = React.useState<{
    type: boolean
    yearlyNumber: boolean
    collectionDate: boolean
    composition: boolean
    wasteType: boolean
    contamination: boolean
    volumeNet: boolean
    volumeGross: boolean
    estimatedShareExportedOutsideEu: boolean
    status: boolean
    administrationNumber: boolean
  }>({
    type: true,
    yearlyNumber: true,
    collectionDate: true,
    composition: true,
    wasteType: true,
    contamination: true,
    volumeNet: true,
    volumeGross: true,
    estimatedShareExportedOutsideEu: true,
    status: true,
    administrationNumber: true,
  })

  const requestSort = (key: SubmissionSortFieldsType) => {
    if (key !== sortKey) {
      setSortKey(key)
    }

    if (key === sortKey) {
      setSortDesc((prevValue) => ({ ...prevValue, [key]: !prevValue[key] }))
    }
  }

  const filteredSubmissions = useMemo(() => {
    return submissions
      .filter((submission) =>
        filterActivityType && filterActivityType !== activityTypes[0].value
          ? filterActivityType === (submission.type ? "convert" : "recycle")
          : true,
      )
      .filter((submission) =>
        filterWasteType && filterWasteType !== wasteTypes[0].value
          ? filterWasteType === submission?.wasteType?.toLowerCase()
          : true,
      )
      .filter(
        (submission) =>
          (submission.isInput && inputData === "input") ||
          (!submission.isInput && inputData === "output"),
      )
      .filter((submission) => ( !filterStatus || filterStatus === 'all' ? true : submission.status === filterStatus))
  }, [submissions, filterActivityType, filterWasteType, filterStatus])

  const sortedSubmissions = useMemo(() => {
    const sortableItems = [...filteredSubmissions]

    sortableItems.sort((a: any, b: any) => {
      let itemA: any = a[sortKey]
      let itemB: any = b[sortKey]

      switch (sortKey) {
        case "yearlyNumber":
          const itemAMonth = a.yearlyNumber.split("/")[0]
          const itemBMonth = b.yearlyNumber.split("/")[0]

          itemA =
            itemAMonth.length === 1 ? `${0}${a.yearlyNumber}` : a.yearlyNumber
          itemB =
            itemBMonth.length === 1 ? `${0}${b.yearlyNumber}` : b.yearlyNumber

          break
        case "wasteType":
          itemA = a?.wasteType || "not-set"
          itemB = b?.wasteType || "not-set"

          break
        case "composition":
          itemA = a?.composition
            ? a?.composition?.productName
                .replace(" - Post-Consumer", "")
                .replace(" - Pre-Consumer", "")
            : ""
          itemB = b?.composition
            ? b?.composition?.productName
                .replace(" - Post-Consumer", "")
                .replace(" - Pre-Consumer", "")
            : ""

          break
        case "volumeNet":
          if (inputData === "output") {
            itemA = (
              a.volumeNet -
              (a.estimatedShareExportedOutsideEu / 100) * a.volumeNet
            ).toFixed(2)
            itemB = (
              b.volumeNet -
              (b.estimatedShareExportedOutsideEu / 100) * b.volumeNet
            ).toFixed(2)
          }

          break
        case "estimatedShareExportedOutsideEu":
          if (inputData === "output") {
            itemA = (
              (a.estimatedShareExportedOutsideEu / 100) *
              a.volumeNet
            ).toFixed(2)
            itemB = (
              (b.estimatedShareExportedOutsideEu / 100) *
              b.volumeNet
            ).toFixed(2)
          }

          break
        case "contamination" as any:
          if (inputData === "input") {
            itemA = Math.round((1 - a.volumeNet / a.volumeGross) * 100)
            itemB = Math.round((1 - b.volumeNet / b.volumeGross) * 100)
          }

          break
        default:
          break
      }

      const sortOrder = sortDesc[sortKey]

      if (itemA < itemB) {
        return sortOrder ? 1 : -1
      }
      if (itemA > itemB) {
        return sortOrder ? -1 : 1
      }

      return 0
    })

    return sortableItems
  }, [filteredSubmissions, sortDesc, sortKey])

  const toggleSubmission = (submission: Submission) => {
    const isSubmissionAdded = !!toggledSubbmisions.find(
      (sub) => sub.id === submission.id,
    )

    if (isSubmissionAdded) {
      const reduced = toggledSubbmisions.filter(
        (sub) => sub.id !== submission.id,
      )
      setToggleSubbmision(reduced)
    } else {
      setToggleSubbmision((prev) => [...prev, submission])
    }
  }

  const toggleAllSubmisions = () => {
    const difference = sortedSubmissions.filter(
      (x) => !toggledSubbmisions.includes(x),
    )
    const differenceByType = difference.filter(
      (x) => x.isInput === (inputData === "input"),
    )

    setToggleSubbmision((prev) => [...prev, ...differenceByType])

    if (inputData === "input") {
      if (!toggleAllSubmissions.inputAllToggle) {
        setToggleAllSubmissions((prev) => ({
          ...prev,
          inputAllToggle: true,
        }))
      } else {
        setToggleAllSubmissions((prev) => ({
          ...prev,
          inputAllToggle: false,
        }))

        const popCorrect = toggledSubbmisions.filter((p) => !p.isInput)
        setToggleSubbmision(popCorrect)
      }
    } else if (inputData === "output") {
      if (!toggleAllSubmissions.outputAllToggle) {
        setToggleAllSubmissions((prev) => ({
          ...prev,
          outputAllToggle: true,
        }))
      } else {
        setToggleAllSubmissions((prev) => ({
          ...prev,
          outputAllToggle: false,
        }))

        const popCorrect = toggledSubbmisions.filter((p) => p.isInput)
        setToggleSubbmision(popCorrect)
      }
    }
  }

  const isAllToggled = (isInput: boolean): boolean => {
    if (Object.entries(toggledSubbmisions).length === 0) return false

    const predicate = (submission: { isInput: boolean }) =>
      (isInput && submission.isInput) || (!isInput && !submission.isInput)

    return (
      toggledSubbmisions.filter(predicate).length ===
      sortedSubmissions.filter(predicate).length
    )
  }

  const canEditSubmission = (createdAt: Date | undefined) => {
    // Nick asked to save this logic for future
    // if (isAdmin) {
    //   return true
    // }
    // if (createdAt && isDaysAfter(CAN_EDIT_SUBMISSION_TO_DAYS, createdAt)) {
    //   return false
    // }

    return true
  }

  const handleOnClickEdit = async (
    id: number | undefined,
    createdAt: Date | undefined,
    isInput: boolean,
  ) => {
    if (canEditSubmission(createdAt)) {
      if (id) {
        history.push(
          `/submissions/edit/${id}/${isInput ? "input" : "output"}`,
          {
            title: t("Edit Submission"),
            id,
          },
        )
      }
    } else {
      handleToast(
        [
          `Edit action is disabled because this submission was added more than ${CAN_EDIT_SUBMISSION_TO_DAYS} days ago.`,
        ],
        "warning",
      )
    }
  }

  const handlePendingSubmission = async (id?: number, single?: boolean) => {
    const { errors } = await submissionStatusChange({
      id,
      status: SubmissionStatus.SENT,
    })
    if (errors) {
      handleToast(errors, "error")
    } else {
      if (single === true) {
        handleToast(["Submission status set back to pending"], "info")
      }
      getSubmissions()
    }
  }

  const handleAcceptSubmission = async (id?: number, single?: boolean) => {
    if (id) {
      const { errors } = await approveSubmission({ id })
      if (errors) {
        handleToast(errors, "error")
      } else {
        if (single === true) {
          handleToast(["Submission accepted"])
        }

        getSubmissions()
      }
    }
  }

  const handleDeclineSubmission = async (id?: number, single?: boolean) => {
    if (id) {
      const { errors } = await declineSubmission({ id })
      if (errors) {
        handleToast(errors, "error")
      } else {
        if (single === true) {
          handleToast(["Submission declined"], "error")
        }
        getSubmissions()
      }
    }
  }

  const handleAcceptAllSubmission = async () => {
    toggledSubbmisions.map((submission: Submission) => {
      handleAcceptSubmission(submission.id, false)
    })
    handleToast(["Submissions accepted"])
  }

  const handleDeclineAllSubmission = async () => {
    toggledSubbmisions.map((submission: Submission) => {
      handleDeclineSubmission(submission.id, false)
    })
    handleToast(["Submissions declined"], "error")
  }

  const handlePendingAllSubmission = async () => {
    toggledSubbmisions.map((submission: Submission) => {
      handlePendingSubmission(submission.id)
    })
    handleToast(["Submissions status set back to pending"], "info")
  }

  const showConfirmButton = (submission: Submission) => {
    return submission.status === SubmissionStatus.CREATED && (isRrr || isAdmin)
  }

  useEffect(() => {
    if (inputData === "input") {
      setToggleAllSubmissions((prev) => ({
        ...prev,
        inputAllToggle: isAllToggled(true),
      }))
    } else {
      setToggleAllSubmissions((prev) => ({
        ...prev,
        outputAllToggle: isAllToggled(false),
      }))
    }
  }, [inputData, setToggleAllSubmissions])

  const inputTooltip = (submission: Submission) => {
    const countryFromEU =
      submission.country && submission.country.code
        ? EuCountriesArray.includes(submission.country.code)
        : ""

    const productName = submission.composition
      ? submission.composition.productName
          .replace(" - Post-Consumer", "")
          .replace(" - Pre-Consumer", "")
      : ""

    return (
      <table className="table table-hover text-left">
        <tbody>
          <tr>
            <th>
              <strong>{t("General")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Submission Date")}:</th>
            <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
          </tr>
          <tr>
            <th>{t("Submission by")}:</th>
            <td>{submission.company.name}</td>
          </tr>
          <tr>
            <th>
              <strong>{t("Waste Information")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Month Received")}:</th>
            <td>
              {dateFormat(submission.collectionDate, COLLECTION_DATE_FORMAT)}
            </td>
          </tr>
          <tr>
            <th>{t("Waste origin")}:</th>
            <td>{productName}</td>
          </tr>
          <tr>
            <th>{t("Waste received from")}:</th>
            <td>
              {(() => {
                if (countryFromEU) {
                  return (
                    <span
                      className={getCountryFlagClassName(
                        submission.country?.code,
                      )}
                    />
                  )
                }
                return <span>Non-EU</span>
              })()}
            </td>
          </tr>
          <tr>
            <th>{t("Total Incoming Waste (kg)")}:</th>
            <td>{submission.volumeGross}</td>
          </tr>
          <tr>
            <th>{t("Contamination")}:</th>
            <td>
              {Math.round(
                (1 - submission.volumeNet / submission.volumeGross) * 100,
              )}
            </td>
          </tr>
          <tr>
            <th>{t("Input into the final recycling operation (kg)")}:</th>
            <td>{submission.volumeNet}</td>
          </tr>
          <tr>
            <th>{t("Administration Number")}:</th>
            <td>{submission.administrationNumber}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  const outputTooltip = (submission: Submission) => {
    return (
      <table className="table table-hover text-left">
        <tbody>
          <tr>
            <th>
              <strong>{t("General")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Submission Date")}:</th>
            <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
          </tr>
          <tr>
            <th>{t("Submission by")}:</th>
            <td>{submission.company.name}</td>
          </tr>
          <tr>
            <th>
              <strong>{t("Waste Information")}</strong>
            </th>
          </tr>
          <tr>
            <th>
              {t(
                "Month sold (recycler) / Month used in production (converter)",
              )}
              :
            </th>
            <td>
              {dateFormat(submission.collectionDate, COLLECTION_DATE_FORMAT)}
            </td>
          </tr>
          <tr>
            <th>{t("Total Net Input PVC (kg)")}:</th>
            <td>{submission.volumeNet}</td>
          </tr>
          <tr>
            <th>{t("Net Output EU")}:</th>
            {/* @ts-ignore */}
            <td>
              {(
                submission.volumeNet -
                ((submission.estimatedShareExportedOutsideEu / 100) *
                  submission.volumeNet +
                  (submission.estimatedShareExportedInsideEu / 100) *
                    submission.volumeNet)
              ).toFixed(2)}
            </td>
          </tr>
          <tr>
            <th>{t("Net Output non EU")}:</th>
            {/* @ts-ignore */}
            <td>
              {(
                (submission.estimatedShareExportedOutsideEu / 100) *
                  submission.volumeNet +
                (submission.estimatedShareExportedInsideEu / 100) *
                  submission.volumeNet
              ).toFixed(2)}
            </td>
          </tr>
          <tr>
            <th>{t("Administration Number")}:</th>
            <td>{submission.administrationNumber}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <>
      <table className="table-auto table-light table-bordered text-center">
        <thead>
          <tr>
            {!isMain ? (
              <th className="align-middle text-center px-2">
                <Checkbox
                  name="plant-request-checkbox"
                  value={
                    inputData === "input"
                      ? toggleAllSubmissions.inputAllToggle
                      : toggleAllSubmissions.outputAllToggle
                  }
                  handleOnChange={() => toggleAllSubmisions()}
                  labelClassName="mx-auto"
                  checkboxWrapperClassName="mr-2"
                  isDisabled={isDisabled}
                />
              </th>
            ) : (
              ""
            )}

            {filterActivityType === null ||
            filterActivityType === activityTypes[0].value ? (
              <th
                className="cursor-pointer align-middle px-2"
                onClick={() => requestSort("type")}
              >
                {t("Type")}
                <span
                  className={`pl-2 fa text-primary ${
                    sortDesc.type ? "fa-angle-down" : "fa-angle-up"
                  }`}
                />
              </th>
            ) : (
              <th className="align-middle px-2">{t("Type")}</th>
            )}

            <th
              className="cursor-pointer align-middle px-2"
              onClick={() => requestSort("yearlyNumber")}
            >
              {t("Submission number")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc.yearlyNumber ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th
              className="cursor-pointer align-middle px-2"
              onClick={() => requestSort("collectionDate")}
            >
              {t("Submission date")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc.collectionDate ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            {inputData === "input" ? (
              <>
                <th
                  className="cursor-pointer align-middle px-2"
                  onClick={() => requestSort("composition")}
                >
                  {t("Waste origin")}
                  <span
                    className={`pl-2 fa text-primary ${
                      sortDesc.composition ? "fa-angle-down" : "fa-angle-up"
                    }`}
                  />
                </th>

                {filterWasteType === null ||
                filterWasteType === wasteTypes[0].value ? (
                  <th
                    className="cursor-pointer align-middle px-2"
                    onClick={() => requestSort("wasteType")}
                  >
                    {t("Waste type")}
                    <span
                      className={`pl-2 fa text-primary ${
                        sortDesc.wasteType ? "fa-angle-down" : "fa-angle-up"
                      }`}
                    />
                  </th>
                ) : (
                  <th className="align-middle px-2">{t("Waste type")}</th>
                )}

                <th className="align-middle px-2">
                  {t("Waste received from")}
                </th>
                <th className="align-middle px-2">{t("Plant Country")}</th>
                <th
                  className="cursor-pointer align-middle"
                  onClick={() => requestSort("volumeNet")}
                >
                  {t("Net input")}
                  <span
                    className={`pl-2 fa text-primary ${
                      sortDesc.volumeNet ? "fa-angle-down" : "fa-angle-up"
                    }`}
                  />
                </th>
                <th
                  className="cursor-pointer align-middle px-2"
                  onClick={() => requestSort("volumeGross")}
                >
                  {t("Total Incoming Waste (kg)")}
                  <span
                    className={`pl-2 fa text-primary ${
                      sortDesc.volumeGross ? "fa-angle-down" : "fa-angle-up"
                    }`}
                  />
                </th>
                <th
                  className="cursor-pointer align-middle px-2"
                  onClick={() => requestSort("contamination")}
                >
                  {t("Contamination")}
                  <span
                    className={`pl-2 fa text-primary ${
                      sortDesc.contamination ? "fa-angle-down" : "fa-angle-up"
                    }`}
                  />
                </th>
              </>
            ) : (
              <>
                <th className="align-middle px-2">{t("Plant Country")}</th>
                <th
                  className="cursor-pointer align-middle px-2"
                  onClick={() => requestSort("volumeNet")}
                >
                  {t("Net Output EU + UK")}
                  <span
                    className={`pl-2 fa text-primary ${
                      sortDesc.volumeNet ? "fa-angle-down" : "fa-angle-up"
                    }`}
                  />
                </th>
                <th
                  className="cursor-pointer align-middle px-2"
                  onClick={() => requestSort("estimatedShareExportedOutsideEu")}
                >
                  {t("Net Output non-EU")}
                  <span
                    className={`pl-2 fa text-primary ${
                      sortDesc.estimatedShareExportedOutsideEu
                        ? "fa-angle-down"
                        : "fa-angle-up"
                    }`}
                  />
                </th>
              </>
            )}
            <th
              className="cursor-pointer align-middle px-2"
              onClick={() => requestSort("status")}
            >
              {t("Status")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc.status ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th
              className="cursor-pointer align-middle px-2"
              onClick={() => requestSort("administrationNumber")}
            >
              {t("Admin number")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc.administrationNumber
                    ? "fa-angle-down"
                    : "fa-angle-up"
                }`}
              />
            </th>
            <th className="align-middle px-2">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {console.log(sortedSubmissions)}
          {sortedSubmissions.length ? (
            sortedSubmissions.map((submission) => {
              const submissionRequested = submission.id
                ? !!toggledSubbmisions.find((sub) => sub.id === submission.id)
                : false
              const submissionCountryFromEU = submission.country
                ? EuCountriesArray.includes(submission.country.code)
                : false
              const companyCountryFromEU = submission.company.country
                ? EuCountriesArray.includes(submission.company.country.code)
                : false
              if (submission.isInput === (inputData === "input")) {
                return (
                  <tr key={submission.id}>
                    {!isMain ? (
                      <td className="align-middle text-center px-2">
                        <Checkbox
                          name={`plant-request-checkbox-${submission.id}`}
                          value={submissionRequested}
                          handleOnChange={() => toggleSubmission(submission)}
                          labelClassName="mx-auto"
                          checkboxWrapperClassName="mr-2"
                          isDisabled={isDisabled}
                        />
                      </td>
                    ) : (
                      ""
                    )}
                    <td className="align-middle px-2">
                      {submission.type ? "Converting" : "Recycling"}
                    </td>
                    <td className="align-middle px-2">
                      {submission.yearlyNumber}
                    </td>
                    <td className="align-middle px-2">
                      {dateFormat(
                        submission.collectionDate,
                        COLLECTION_DATE_FORMAT,
                      )}
                    </td>
                    {inputData === "input" ? (
                      <>
                        <td className="align-middle px-2">
                          {submission.composition
                            ? submission.composition.productName
                                .replace(" - Post-Consumer", "")
                                .replace(" - Pre-Consumer", "")
                            : ""}
                        </td>
                        <td className="align-middle px-2">
                          {submission?.wasteType || "not-set"}
                        </td>
                        <td className="align-middle px-2">
                          {(() => {
                            if (submissionCountryFromEU) {
                              return (
                                <span
                                  className={getCountryFlagClassName(
                                    submission.country?.code,
                                  )}
                                />
                              )
                            }
                            return <span>Non-EU</span>
                          })()}
                        </td>
                        <td className="align-middle px-2">
                          {(() => {
                            if (companyCountryFromEU) {
                              return (
                                <span
                                  className={getCountryFlagClassName(
                                    submission.company?.country?.code,
                                  )}
                                />
                              )
                            }
                            return <span>Non-EU</span>
                          })()}
                        </td>
                        <td className="align-middle px-2">
                          {submission.volumeNet}
                        </td>
                        <td className="align-middle px-2">
                          {submission.volumeGross}
                        </td>
                        <td className="align-middle px-2">
                          {Math.round(
                            (1 -
                              submission.volumeNet / submission.volumeGross) *
                              100,
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="align-middle px-2">
                          {(() => {
                            if (companyCountryFromEU) {
                              return (
                                <span
                                  className={getCountryFlagClassName(
                                    submission.company?.country?.code,
                                  )}
                                />
                              )
                            }
                            return <span>Non-EU</span>
                          })()}
                        </td>
                        <td className="align-middle px-2">
                          {/* @ts-ignore */}
                          {(submission.type
                            ? submission.volumeNet -
                              ((submission.estimatedShareExportedOutsideEu /
                                100) *
                                submission.volumeNet +
                                (submission.estimatedShareExportedInsideEu /
                                  100) *
                                  submission.volumeNet)
                            : submission.netOutputEU
                          ).toFixed(2)}
                        </td>
                        <td className="align-middle px-2">
                          {/* @ts-ignore */}
                          {(submission.type
                            ? (submission.estimatedShareExportedOutsideEu /
                                100) *
                                submission.volumeNet +
                              (submission.estimatedShareExportedInsideEu /
                                100) *
                                submission.volumeNet
                            : submission.netOutputNonEU
                          ).toFixed(2)}
                        </td>
                      </>
                    )}
                    <td className="align-middle px-2">
                      {submission.status === t("SENT") ||
                      submission.status === t("AUDIT_IN_PROGRESS")
                        ? t("PENDING")
                        : t(submission.status)}
                    </td>
                    <td className="align-middle px-2">
                      {submission.administrationNumber
                        ? submission.administrationNumber
                        : "None"}
                    </td>
                    <td className="align-middle p-2">
                      <div className="text-primary">
                        <div className="row">
                          <div className="col-12">
                            <ReactTooltip
                              id={`tooltip_${submission.id}`}
                              backgroundColor="white"
                              textColor="black"
                            >
                              {inputData === "input"
                                ? inputTooltip(submission)
                                : outputTooltip(submission)}
                            </ReactTooltip>
                            <Link
                              to={{
                                pathname: `/submissions/show/${submission.id}/${
                                  submission.isInput ? "input" : "output"
                                }`,
                                state: {
                                  title: t("Submission Detail"),
                                },
                              }}
                              style={{ width: "100%" }}
                              key={submission.id}
                              data-tip=""
                              data-for={`tooltip_${submission.id}`}
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-sm text-left"
                                style={{ width: "100%" }}
                              >
                                <i className="fas fa-eye btn-icon mr-1" />{" "}
                                {t("View submission")}
                              </button>
                            </Link>
                          </div>
                        </div>
                        {!isMain &&
                        !isDisabled &&
                        submission.status != "ACCEPTED" ? (
                          <div className="row mt-1">
                            <div className="col-12">
                              <button
                                type="button"
                                className="btn btn-outline-success btn-sm text-left"
                                style={{ width: "100%" }}
                                onClick={() =>
                                  handleAcceptSubmission(submission.id, true)
                                }
                              >
                                <i className="fas fa-check btn-icon mr-1" />{" "}
                                {t("Approve")}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {!isMain && !isDisabled ? (
                          <div className="row mt-1">
                            <div className="col-12">
                              <button
                                type="button"
                                className="btn btn-outline-warning btn-sm text-left"
                                style={{ width: "100%" }}
                                onClick={() =>
                                  handleOnClickEdit(
                                    submission.id,
                                    submission.createdAt,
                                    submission.isInput,
                                  )
                                }
                              >
                                <i className="fas fa-pen btn-icon mr-1" />{" "}
                                {t("Edit")}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {!isMain && !isDisabled ? (
                          <div className="row mt-1">
                            <div className="col-12">
                              {showConfirmButton(submission) && (
                                <button
                                  type="button"
                                  className="btn btn-outline-success btn-sm text-left"
                                  style={{ width: "100%" }}
                                  onClick={() =>
                                    handleAcceptSubmission(submission.id, true)
                                  }
                                >
                                  <i className="fas fa-check btn-icon mr-1" />{" "}
                                  {t("Send submission")}
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {!isMain &&
                        !isDisabled &&
                        submission.status != "DECLINED" ? (
                          <div className="row mt-1">
                            <div className="col-12">
                              <ConfirmButton
                                title="Decline"
                                text={t(
                                  "Are you sure you wish to decline this submission?",
                                )}
                                confirmText={t("Decline")}
                                icon="trash"
                                className="btn-sm text-left w-100 btn-outline-danger"
                                confirmClassName="btn-outline-danger"
                                danger
                                onConfirm={() =>
                                  handleDeclineSubmission(submission.id, true)
                                }
                              >
                                <i className="fas fa-ban btn-icon mr-1" />{" "}
                                {t("Decline")}
                              </ConfirmButton>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {!isMain &&
                        !isDisabled &&
                        submission.status != "SENT" ? (
                          <div className="row mt-1">
                            <div className="col-12">
                              <ConfirmButton
                                title="Back to pending"
                                text={t(
                                  "Are you sure you wish to back to pending this submission?",
                                )}
                                confirmText={t("Back to pending")}
                                icon="reply"
                                className="btn-sm text-left w-100 btn-outline-warning"
                                confirmClassName="btn-outline-warning"
                                onConfirm={() =>
                                  handlePendingSubmission(submission.id, true)
                                }
                              >
                                <i className="fas fa-reply btn-icon mr-1" />{" "}
                                {t("Back to pending")}
                              </ConfirmButton>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                )
              }
            })
          ) : (
            <tr>
              <td colSpan={inputData === "input" ? 9 : 7}>
                There is no submitted data to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {toggledSubbmisions.length > 0 && !isDisabled && (
        <div className="bottom-audit-table col-12">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <span className="arrow-line">
                    <img src={bulkActionsHintArrow} />
                  </span>
                  {t("Bulk action on ")}
                  {toggledSubbmisions.length} {t(" selected")}{" "}
                  {toggledSubbmisions.length === 1 ? "row" : "rows"}:
                </div>
                <div className="col-8">
                  <button
                    type="button"
                    className="btn btn-sm btn-success text-left col-3"
                    style={{ width: "100%" }}
                    onClick={() => handleAcceptAllSubmission()}
                  >
                    <i className="fas fa-plus btn-icon mr-1 " /> {t("Approve")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger text-left col-3 ml-1 mr-1"
                    style={{ width: "100%" }}
                    onClick={() => handleDeclineAllSubmission()}
                  >
                    <i className="fas fa-ban btn-icon mr-1" /> {t("Decline")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-warning text-left col-3"
                    style={{ width: "100%" }}
                    onClick={() => handlePendingAllSubmission()}
                  >
                    <i className="fas fa-arrow-left  btn-icon mr-1" />{" "}
                    {t("Back to pending")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SubmissionListTableBody
