import { restHelper } from "../../@common/service/api"
import { ID, GETALL } from "../../@common/types"
import { Company, CompanyVatCheck, Mosa } from "../types"

export const queries = {
  companiesToAttach: `
  query companiesToAttach($id: Int) {
    companiesToAttach(id: $id) { 
        id
        name
      }
    }
  `,
  companies: `
  query companies($name: String, $address: String, $vatNumber: String, $country: String, $polymers: [Int]) {
    companies(name: $name, address: $address, vatNumber: $vatNumber, country: $country, polymers: $polymers) { 
        id
        name
        address
        vatNumber
        country {
          id
          name
          code
        }
        ceoName
        ceoEmail
        parent { id, name }
        branches { name }
        approved
        archived
        roleId
        polymers { id, name }
      }
    }
  `,
  parentCompanies: `
  query parentCompanies {
    parentCompanies {
      id
      name
      country {
        id
        name
        code
      }
      address
      vatNumber
      ceoName
      ceoEmail
      capacities
      roleId
    }
  }
  `,
  allPlantsList: `
  query allPlantsList($all: Boolean) {
    allPlantsList(all: $all) {
      id
      name
      parent {
        id
        name
      }
      country {
        id
        name
        code
      }
    archived
    roleId
    }
  }
  `,
  company: `
    query company($id: Int) {
      company(id: $id) {
        id
        name
        country {
          id
          name
          code
        }
        parent {
          id
          name
          country {
            id
            name
            code
          }
        }
        branches {
          id
          name
          country {
            id
            name
            code
          }
        }
        archived
        address
        vatNumber
        ceoName
        ceoEmail
        capacities
        website
        allowDisplayOnWebsite
        roleId
        polymers {
          id
          name
        }
        mosa {
          id
          name
        }
        sharedDataWithMosa
        sharedUfmeWithEppa
        sharedSnepWithEppa
      }
    },
  `,
  plants: `
    query plants($all: Boolean) {
      plants(all: $all) {
        id
        name
        country {
          id
          name
          code
        }
        parent {
          id
          name
        }
        address
        vatNumber
        ceoName
        ceoEmail
        capacities
        approved
        archived
        roleId
        polymers {
          id
          name
        }
      }
    }
  `,
  companyPlants: `
    query companyPlants {
      companyPlants {
        id
        name
        country {
          id
          name
          code
        }
        address
        vatNumber
        ceoName
        ceoEmail
        capacities
        approved
      }
    }
  `,
  plant: `
  query plant($id: Int) {
    plant(id: $id) {
        id
        name
        country {
          id
          name
          code
        }
        address
        vatNumber
        ceoName
        ceoEmail
        capacities
      }
    }
  `,
  acceptedSubmissionsCount: `
    query acceptedSubmissionsCount {
      acceptedSubmissionsCount
    }
  `,
  checkCompanyVatNumber: `
    query checkCompanyVatNumber(
      $countryCode: String
      $vatNumber: String
    ) {
      checkCompanyVatNumber(
        countryCode: $countryCode
        vatNumber: $vatNumber
      ) {
        valid
        countryCode
        vatNumber
        name
        address
      }
    }
  `,
  mosaList: `
  query {
    mosaList {
      id,
      name
    }
  }
`,
}

export const mutations = {
  companyCreate: `
    mutation companyCreate(
      $name: String
      $address: String
      $country: Int
      $vatNumber: String
      $ceoName: String
      $ceoEmail: String
      $capacities: Int
      $website: String
      $allowDisplayOnWebsite: Boolean
      $mosa: [Int]
      $polymers: [Int]
      $roleId: Int
      $sharedDataWithMosa: Boolean
      $sharedUfmeWithEppa: Boolean
      $sharedSnepWithEppa: Boolean
    ) {
      companyCreate(
        name: $name
        address: $address
        country: $country
        vatNumber: $vatNumber
        ceoName: $ceoName
        ceoEmail: $ceoEmail
        capacities: $capacities
        website: $website
        allowDisplayOnWebsite: $allowDisplayOnWebsite
        mosa: $mosa
        polymers: $polymers
        roleId: $roleId
        sharedDataWithMosa: $sharedDataWithMosa
        sharedUfmeWithEppa: $sharedUfmeWithEppa
        sharedSnepWithEppa: $sharedSnepWithEppa
      ) { id }
    }
  `,
  companyUpdate: `
    mutation companyUpdate(
      $id: Int!
      $name: String
      $address: String
      $country: Int
      $vatNumber: String
      $ceoName: String
      $ceoEmail: String
      $capacities: Int
      $website: String
      $allowDisplayOnWebsite: Boolean
      $roleId: Int
      $polymers: [Int]
      $mosa: [Int]
      $sharedDataWithMosa: Boolean      
      $sharedUfmeWithEppa: Boolean
      $sharedSnepWithEppa: Boolean
    ) {
      companyUpdate(
        id: $id
        name: $name
        address: $address
        country: $country
        vatNumber: $vatNumber
        ceoName: $ceoName
        ceoEmail: $ceoEmail
        capacities: $capacities
        website: $website
        allowDisplayOnWebsite: $allowDisplayOnWebsite
        roleId: $roleId
        polymers: $polymers
        mosa: $mosa
        sharedDataWithMosa: $sharedDataWithMosa
        sharedUfmeWithEppa: $sharedUfmeWithEppa
        sharedSnepWithEppa: $sharedSnepWithEppa
      ) { id }
    }
  `,
  plantAdd: `
    mutation plantCompanyAdd(
      $name: String
      $address: String
      $parent: Int
      $country: Int
      $vatNumber: String
      $capacities: Int
      $ceoName: String
      $ceoEmail: String
      $polymers: [Int]
      $roleId: Int
    ) {
      plantCompanyAdd(
        name: $name
        address: $address
        parent: $parent
        country: $country
        vatNumber: $vatNumber
        capacities: $capacities
        ceoName: $ceoName
        ceoEmail: $ceoEmail
        polymers: $polymers
        roleId: $roleId
      )
    }
  `,
  approvePlant: `
    mutation approvePlant ($id: Int!) {
      approvePlant(
        id: $id
      ) {
        id
        approved
      }
    }
  `,
  rejectPlant: `
    mutation rejectPlant ($id: Int!) {
      rejectPlant(
        id: $id
      ) {
        id
        approved
      }
    }
  `,
  plantChangeIsInput: `
    mutation plantChangeIsInput (
      $id: Int!
      $value: Boolean!
    ) {
      plantChangeIsInput(
        id: $id
        value: $value
      )
    }
  `,
  archiveCompany: `
    mutation archiveCompany (
      $id: Int!
    ) {
      archiveCompany(
        id: $id
      )
    }
  `,
  unarchiveCompany: `
    mutation unarchiveCompany (
      $id: Int!
    ) {
      unarchiveCompany(
        id: $id
      )
    }
  `,
}

export const getCompanies = restHelper<Company[], object>(queries, "companies")

export const getParentCompanies = restHelper<Company[], object>(
  queries,
  "parentCompanies",
)
export const getAllPlantsList = restHelper<Company[], object>(
  queries,
  "allPlantsList",
)

export const getPlants = restHelper<Company[], GETALL>(queries, "plants")

export const getCompanyPlants = restHelper<Company[], object>(
  queries,
  "companyPlants",
)

export const getPlant = restHelper<Company, ID>(queries, "plant")

export const plantChangeIsInput = restHelper<
  boolean,
  { id: number; value: boolean }
>(mutations, "plantChangeIsInput")

export const setArchiveCompany = restHelper<boolean, { id: number }>(
  mutations,
  "archiveCompany",
)

export const setUnarchiveCompany = restHelper<boolean, { id: number }>(
  mutations,
  "unarchiveCompany",
)

export const companiesToAttach = restHelper<Company[], ID>(
  queries,
  "companiesToAttach",
)
export const getCompany = restHelper<Company, ID>(queries, "company")

export const postCompany = restHelper<Company, Company>(
  mutations,
  "companyCreate",
)

export const putCompany = restHelper<Company, Company>(
  mutations,
  "companyUpdate",
)

export const postPlant = restHelper<Company, Company>(mutations, "plantAdd")

export const approvePlant = restHelper<Company, ID>(mutations, "approvePlant")

export const rejectPlant = restHelper<Company, ID>(mutations, "rejectPlant")

export const getAcceptedSubmissionsCount = restHelper<number, object>(
  queries,
  "acceptedSubmissionsCount",
)

export const checkCompanyVatNumber = restHelper<object, CompanyVatCheck>(
  queries,
  "checkCompanyVatNumber",
)

export const getMosaList = restHelper<Mosa[], object>(
  queries,
  "mosaList",
)