export const AUDIT_DATE_FORMAT = "MMMM yyyy"

export const auditTypes = [
  { label: "Recovinyl Verification", value: "recovinyl-verification" },
  { label: "Recovinyl Audit", value: "recovinyl-audit" },
  { label: "EuCertPlast", value: "eucertplaes" },
  { label: "Recyclass", value: "recyclass" },
  { label: "Other", value: "other" },
]

export const auditStatuses = [
  {},
  { color: "#EB7926", name: "Created" },
  { color: "#EB7926", name: "Active" },
  { color: "#EB7926", name: "In progress" },
  { color: "#74BD18", name: "Completed" },
]
