import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { actionHandler } from "../@common/actions"
import {
  UpdateTreeDragAndDropInput,
  ID,
  PaginationInput,
} from "../@common/types"
import {
  FETCH_APPLICATIONS,
  FETCH_APPLICATION_LINKS,
  FETCH_ASSIGN_END_CONFIG,
  FETCH_SECTOR_APPLICATIONS,
} from "./constants"
import {
  getApplications,
  postApplication,
  putApplicationTree,
  deleteApplication,
  getLinks,
  getApplicationLinks,
  postApplicationLink,
  getSectorApplications,
  getAssignEndConfig,
} from "./service/api"
import {
  Application,
  Link,
  ApplicationLink,
  GetSectorApplicationInput,
  CreateApplicationInput,
  CreateApplicationLinkInput,
  SectorApplication,
  AssignEndConfig,
} from "./types"

export interface SetApplications {
  type: string
  payload: { applications: Application[] }
}

export const setApplications = (payload: {
  applications: Application[]
}): SetApplications => ({
  type: FETCH_APPLICATIONS,
  payload,
})

export interface SetLinks {
  type: string
  payload: { links: Link[]; linksQty: number }
}

export const setLinks = (payload: {
  links: Link[]
  linksQty: number
}): SetLinks => ({
  type: FETCH_APPLICATION_LINKS,
  payload,
})

export interface SetApplicationLinks {
  type: string
  payload: { applicationLinks: ApplicationLink[] }
}

export const setApplicationLinks = (payload: {
  applicationLinks: ApplicationLink[]
}): SetApplicationLinks => ({
  type: FETCH_APPLICATION_LINKS,
  payload,
})

export const fetchApplications = () => async (dispatch: Dispatch) => {
  actionHandler(getApplications(), (applications) => {
    dispatch(setApplications({ applications }))
  })
}

export const createApplication =
  (credentials: CreateApplicationInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postApplication(credentials), () => {
      dispatch(fetchApplications())
    })
  }

export const updateApplicationTree =
  (credentials: UpdateTreeDragAndDropInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(putApplicationTree(credentials), () => {
      dispatch(fetchApplications())
    })
  }

export const removeApplication =
  (credentials: ID) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(deleteApplication(credentials), () => {
      dispatch(fetchApplications())
    })
  }

export const fetchLinks =
  (credentials: PaginationInput = {}) =>
  async (dispatch: Dispatch) => {
    actionHandler(getLinks(credentials), ({ links, total }) => {
      dispatch(setLinks({ links, linksQty: total }))
    })
  }

export const fetchApplicationLinks = () => async (dispatch: Dispatch) => {
  actionHandler(getApplicationLinks(), (applicationLinks) => {
    dispatch(setApplicationLinks({ applicationLinks }))
  })
}

export const createApplicationLink =
  (credentials: CreateApplicationLinkInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postApplicationLink(credentials), () => {
      dispatch(fetchApplicationLinks())
    })
  }

/**
 * SECTOR_APPLICATIONS
 */

export interface SetSectorApplications {
  type: string
  payload: { sectorApplications: SectorApplication[] }
}

export const setSectorApplications = (payload: {
  sectorApplications: SectorApplication[]
}): SetSectorApplications => ({
  type: FETCH_SECTOR_APPLICATIONS,
  payload,
})

export const fetchSectorApplications =
  (credentials: GetSectorApplicationInput = {}) =>
  async (dispatch: Dispatch) => {
    actionHandler(getSectorApplications(credentials), (sectorApplications) => {
      dispatch(setSectorApplications({ sectorApplications }))
    })
  }

export interface SetAssignEndConfig {
  type: string
  payload: { assignEndConf: AssignEndConfig }
}

export const setAssignEndConfig = (payload: {
  assignEndConf: AssignEndConfig
}): SetAssignEndConfig => ({
  type: FETCH_ASSIGN_END_CONFIG,
  payload,
})

export const fetchAssignEndConfig = () => async (dispatch: Dispatch) => {
  actionHandler(getAssignEndConfig(), (assignEndConf) => {
    // const assignEndConf = JSON.parse(assignEndConfRow.content)
    dispatch(setAssignEndConfig({ assignEndConf }))
  })
}

export type ApplicationActions = SetApplications
