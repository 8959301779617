import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useRouteMatch } from "react-router-dom"
import { LoadingIndicator } from "react-select/src/components/indicators"

import SubmissionForm from "../components/SubmissionForm/SubmissionForm"
import useSubmission from "../hooks/useSubmission"
import { getSubmission } from "../service/api"

const SubmissionDetail = () => {
  const [submission, setSubmission] = useState<any>()
  const {
    params,
  }: { params: { id: string | undefined; type: string | undefined } } =
    useRouteMatch()
  const { id, type } = params

  useEffect(() => {
    getSubmission({ id: parseInt(id as any) }).then(({ data }) => {
      setSubmission(data)
    })
  }, [])

  if (type === "convertingInput" || type === "convertingOutput") {
    return <div />
  }

  return <SubmissionForm initialSubmission={submission} disabled />
}

export default SubmissionDetail
