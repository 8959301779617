import { useFormik } from "formik"
import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import Input from "components/Input/Input"
import Select, {
  SelectValue,
  BaseSelectedValue,
  mapToOptions,
} from "components/Select/Select"
import Tree, { TreeNode, BranchActionHandler } from "components/Tree/Tree"

import { generateCustomString } from "utils/strings"
import total from "utils/total"

import useMaxNumberState from "hooks/useMaxNumberState"
import useStateGetter from "hooks/useStateGetter"

import { fetchCompanies } from "../../../companies/actions"
import { getPlants } from "../../../companies/service/api"
import { Company } from "../../../companies/types"
import "../../../genericCompositions/components/GenericCompositionForm/GenericCompositionForm.scss"
import CompositionFormValidationShema from "../../../genericCompositions/components/GenericCompositionForm/GenericCompositionFormValidationShema"
import { traverseToFindPath } from "../../../genericCompositions/utils"
import { getMaterials, Material } from "../../../materials/service/api"
import { fetchCompositions, createComposition } from "../../actions"
import {
  CompositionFormTreeBranchExtraProps,
  SubCompositions,
  CompositionQueryResponse,
} from "../../types"
import CompositionFormTreeBranch from "./CompositionFormTreeBranch"
import CompositionFormTreeContext from "./CompositionFormTreeContext"

const TREE_PREFIX = "tree"
const SUB_COMPONENT_PREFIX = "subComponent"

const createPrefixedId = (prefix: string, id: number | string) =>
  `${prefix}_${id}`

interface TreeValue {
  checked: boolean
  overall: number
  consume: number
  percentage: number
  composition: SubCompositions
}

interface TreeValues {
  [key: string]: TreeValue
}

interface Props {
  initialComposition?: CompositionQueryResponse
  disabled?: boolean
}

const CompositionForm: React.FC<Props> = ({ initialComposition }) => {
  const [checkedTreeBranchInput, setCheckedTreeBranchInput] = useState(0)
  const [treeValues, setTreeValues] = useState<TreeValues>({})
  const [virginAndAdditives, setVirginAndAdditives] = useMaxNumberState()
  const [otherMaterials, setOtherMaterials] = useMaxNumberState()
  const [subCompositions, setSubCompositions] = useState<SubCompositions>({})
  const [treeBranchExtraProps, setTreeBranchExtraProps] = useState<
    null | object
  >(null)
  const [isPlantDisable, setIsSetPlantDisable] = useState<boolean>(false)

  const dispatch = useDispatch()
  const [companies, setCompanies] = useState<Company[]>()
  const [materials, setMaterials] = useState<TreeNode[]>([])

  const history = useHistory()

  const isInitialComposition = !!initialComposition
  // (isUpdateMode) if we want extend this component to edit feature
  // const isUpdateMode = isInitialComposition && disabled === false;
  const companiesOptions = React.useMemo(
    () => mapToOptions(companies ?? []),
    [companies],
  )
  const isTotal = () => total.getTotalStateValue() === 100

  // Update all state from initial composition data
  if (isInitialComposition && initialComposition) {
    const { additivesPercentage, otherPercentage, compositionMaterials } =
      initialComposition
    if (additivesPercentage !== virginAndAdditives) {
      setVirginAndAdditives(additivesPercentage)
      total.state = { additivesPercentage }
    }
    if (otherPercentage !== otherMaterials) {
      setOtherMaterials(otherPercentage)
      total.state = { otherPercentage }
    }

    if (
      compositionMaterials &&
      Object.keys(treeBranchExtraProps || {}).length === 0
    ) {
      const subCompositionObj: SubCompositions = {}
      const materialsObj: CompositionFormTreeBranchExtraProps = {}
      compositionMaterials.forEach((compositionMaterial) => {
        const {
          percentage,
          material,
          postConsumer,
          preConsumer,
          subComposition,
        } = compositionMaterial
        if (material?.id && percentage) {
          materialsObj[material.id] = { percentage, postConsumer, preConsumer }
          total.state = {
            [createPrefixedId(TREE_PREFIX, material.id)]: percentage,
          }
        }
        if (subComposition?.id) {
          subCompositionObj[subComposition.id] = {
            checked: true,
            composition: {
              label: subComposition.name,
              value: subComposition.id,
            },
            percentage,
            compositionsOptions: [],
          }
          total.state = {
            [createPrefixedId(SUB_COMPONENT_PREFIX, subComposition.id)]:
              percentage,
          }
        }
      })
      setTreeBranchExtraProps(materialsObj)
      setSubCompositions(subCompositionObj)
    }
  }

  const handleOnSubmit = async ({
    selectedCompany,
    productName,
    productId,
  }: {
    selectedCompany: Nullable<BaseSelectedValue<number>>
    productName: string
    productId: string
  }) => {
    const inputMaterials = Object.keys(treeValues).map((key) => {
      const { consume, overall } = treeValues[key]
      return {
        id: parseInt(key, 0),
        preConsumer: consume,
        postConsumer: 100 - consume,
        percentage: overall,
      }
    })

    const subCompositionMaterials = Object.keys(subCompositions).map((key) => {
      const { composition, percentage } = subCompositions[key]
      return {
        subCompositionId: composition?.value,
        percentage,
        preConsumer: 0,
        postConsumer: 0,
      }
    })

    if (isTotal() && selectedCompany?.value) {
      await dispatch(
        createComposition({
          name: productId,
          productName,
          additivesPercentage: virginAndAdditives,
          otherPercentage: otherMaterials,
          companyId: selectedCompany?.value,
          type: 0,
          materials: [...inputMaterials, ...subCompositionMaterials],
        }),
      )

      total.reset()

      if (history.length > 2) {
        history.goBack()
      } else {
        history.replace("/waste-origin")
      }
    }
  }

  const selectedCompany = useStateGetter<any>(["submission", "selectedCompany"])

  const fetchSelectedCompany = useCallback(() => {
    if (selectedCompany) {
      return {
        label: selectedCompany.name,
        value: selectedCompany.id,
      }
    }

    return null
  }, [])

  useEffect(() => {
    if (fetchSelectedCompany()) {
      setIsSetPlantDisable(true)
    }
  }, [fetchSelectedCompany])

  const formik = useFormik({
    initialValues: {
      selectedCompany: fetchSelectedCompany(),
      productName: "",
      productId: generateCustomString(),
    },
    validationSchema: CompositionFormValidationShema,
    onSubmit: handleOnSubmit,
  })

  const { values, errors, setFieldValue, touched, handleSubmit } = formik

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCompany", selectedOption)
  }

  const handleCompositionFormTreeBranchAction: BranchActionHandler = (
    { id, wasteType },
    actionName,
    value,
  ) => {
    if (actionName === "onRemoveCheck") {
      total.reset()
      setFieldValue("productName", ``)
    }

    if (actionName === "onMaterialCheck") {
      total.reset()
      setFieldValue("productName", ``)
      setCheckedTreeBranchInput(id)
      return 0
    }

    if (actionName === "onMaterialConsumerTypeCheck") {
      total.reset()
      const { checkedType } = value as { checkedType: string }
      const result = traverseToFindPath<Material>(materials, id)

      if (wasteType) {
        setFieldValue("productName", result.title)
      } else {
        setFieldValue(
          "productName",
          `${result.title} - ${checkedType}-Consumer`,
        )
      }

      total.state = { [id]: 100 }
      setTreeValues({
        ...treeValues,
        ...{
          [id]: {
            overall: 100,
            consume: checkedType === "Pre" ? 100 : 0,
          },
        },
      })
      return 0
    }

    const handlerValue = value as { overall: number; consume: number }
    const { overall, consume } = handlerValue
    const treeKey = createPrefixedId(TREE_PREFIX, id)
    total.state = { [treeKey]: overall }

    const acceptedQty = total.getKeyValue(treeKey)
    setTreeValues({
      ...treeValues,
      ...{
        [id]: {
          overall: acceptedQty,
          consume,
        },
      },
    })
    return acceptedQty
  }

  useEffect(() => {
    getPlants({}).then(({ data, errors }) => {
      if (data) {
        setCompanies(data)

        if (data.length === 1) {
          setFieldValue("selectedCompany", {
            label: data[0].name,
            value: data[0].id,
          })
        }
      }
    })
  }, [setFieldValue])

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchCompositions())
    getMaterials({ onlyVisible: true }).then(({ data }) => {
      if (!data) {
        return
      }

      if (selectedCompany?.polymers?.length > 0) {
        const allowedPolymers = selectedCompany.polymers.map(
          ({ name }: any) => name.toLowerCase(),
        )
        data = data.filter((material) =>
          allowedPolymers.includes(material.title.toLowerCase()),
        )
      }

      setMaterials(data)
    })
  }, [dispatch, selectedCompany.polymers])

  useEffect(() => {
    if (initialComposition?.id) {
      const { company, productName, name } = initialComposition
      if (company) {
        setFieldValue("selectedCompany", {
          label: company.name,
          value: company.id,
        })
      }

      setFieldValue("productName", productName)
      setFieldValue("productId", name)
    }
  }, [initialComposition, setFieldValue])

  useEffect(() => {
    total.reset()
  }, [])

  const { t } = useTranslation()

  return (
    <div className="srs-composition-form">
      <form className="mb-5" onSubmit={handleSubmit}>
        <Select
          name="selectedCompany"
          label={t("Plant")}
          options={companiesOptions}
          value={values.selectedCompany}
          handleOnChange={onSelectChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.selectedCompany && errors.selectedCompany}
          isDisabled={isPlantDisable}
        />
        <Input
          label={t("Waste name")}
          name="productName"
          value={values.productName}
          handleOnChange={formik.handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.productName && errors.productName}
          isDisabled
        />
        <hr />
        <h4>
          <strong>{t("Source of recycled waste")}</strong>
        </h4>
        <div className="srs-tree-line-container mb-5">
          <CompositionFormTreeContext.Provider
            value={{ checkedTreeBranchInput, treeBranchExtraProps }}
          >
            <Tree
              nodes={materials}
              Branch={CompositionFormTreeBranch}
              branchActionHandler={handleCompositionFormTreeBranchAction}
            />
          </CompositionFormTreeContext.Provider>
        </div>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-primary rounded-bottom-left"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left mr-2" /> {t("Back")}
          </button>
          {!isInitialComposition && (
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right"
              disabled={isTotal() === false}
            >
              {t("Submit")}
              <i className="fas fa-arrow-right ml-3" />
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default CompositionForm
