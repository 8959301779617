import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { actionHandler } from "../@common/actions"
import { UpdateTreeDragAndDropInput, ID } from "../@common/types"
import { FETCH_PRODUCTS } from "./constants"
import {
  getProducts,
  postProduct,
  putProductTree,
  deleteProduct,
} from "./service/api"
import { Product, CreateProductInput } from "./types"

export interface SetProducts {
  type: string
  payload: { products: Product[] }
}

export const setProducts = (payload: { products: Product[] }): SetProducts => ({
  type: FETCH_PRODUCTS,
  payload,
})

export const fetchProducts =
  (onlyVisible = false) =>
  async (dispatch: Dispatch) => {
    actionHandler(getProducts({ onlyVisible }), (products) => {
      dispatch(setProducts({ products }))
    })
  }

export const createProduct =
  (payload: CreateProductInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postProduct(payload), () => {
      dispatch(fetchProducts())
    })
  }

export const updateProductTree =
  (payload: UpdateTreeDragAndDropInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(putProductTree(payload), () => {
      dispatch(fetchProducts())
    })
  }

export const removeProduct =
  (productId: ID) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(deleteProduct(productId), () => {
      dispatch(fetchProducts())
    })
  }

export type ProductActions = SetProducts
