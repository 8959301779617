import { useEffect } from "react"
import useStateGetter from "./useStateGetter";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssignEndConfig } from "modules/applications/actions";
import { AssignEndConfig } from "modules/applications/types";

const useApplicationConfig = () => {
  const configApplicationEnd = useStateGetter<AssignEndConfig>(["application", "assignEndConf"])
  
  const dispatch = useDispatch();
  useEffect(() => {
    if (configApplicationEnd === null) {
      dispatch(fetchAssignEndConfig());
    }
  }, [configApplicationEnd, dispatch]);

  return configApplicationEnd
}

export default useApplicationConfig