/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from "react"

const initialValues: {
  checkedTreeBranchInput: number
} = {
  checkedTreeBranchInput: 0,
}

const GenericCompositionFormTreeContext = createContext(initialValues)

export default GenericCompositionFormTreeContext
