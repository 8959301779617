import React, { Dispatch, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import { dateFormat } from "utils/dates"
import { handleToast } from "utils/messages"
import { getCountryFlagClassName } from "utils/strings"

import { EuCountriesArray } from "../../../../constants"
import { COLLECTION_DATE_FORMAT } from "../../constants"
import { approveSubmission, submissionStatusChange } from "../../service/api"
import { Submission, SubmissionStatus } from "../../types"

interface Props {
  isInputData: boolean
  submissions: Submission[]
  onSortChanged(sortBy: string, sortDirection: string): void
  getSubmissions(): void
}
const SubmissionListTableBody: React.FC<Props> = ({
  submissions,
  getSubmissions,
  onSortChanged,
  isInputData = false,
}) => {
  const { t } = useTranslation()

  const [submissionOrder, setsSubmissionOrder] = useState("DESC")
  const [dateOrder, setDateOrder] = useState("DESC")
  const [wasteType, setWasteType] = useState("DESC")

  const onSortClicked = (
    sortBy: string,
    lastSortDirection: string,
    stateHandler: Dispatch<string>,
  ) => {
    let sortDirection = "DESC"
    if (lastSortDirection === sortDirection) {
      sortDirection = "ASC"
    }
    stateHandler(sortDirection)
    onSortChanged(sortBy, sortDirection)
  }

  const handleAcceptSubmission = async (id?: number) => {
    if (id) {
      const { errors } = await approveSubmission({ id })
      if (errors) {
        handleToast(errors, "error")
      } else {
        handleToast(["Submission accepted"])
        getSubmissions()
      }
    }
  }

  const handlePendingSubmission = async (id?: number) => {
    const { errors } = await submissionStatusChange({
      id,
      status: SubmissionStatus.SENT,
    })

    if (errors) {
      handleToast(errors, "error")
    } else {
      handleToast(["Submission sent"])
      getSubmissions()
    }
  }

  const showApproveButton = (submission: Submission) =>
    submission.status === SubmissionStatus.SENT

  const showPendingButton = (submission: Submission) =>
    [SubmissionStatus.ACCEPTED, SubmissionStatus.DECLINED].includes(
      submission.status as SubmissionStatus,
    )

  const inputTooltip = (submission: Submission) => {
    const countryFromEU =
      submission.country && submission.country.code
        ? EuCountriesArray.includes(submission.country.code)
        : ""

    const productName = submission.composition
      ? submission.composition.productName
          .replace(" - Post-Consumer", "")
          .replace(" - Pre-Consumer", "")
      : ""

    return (
      <table className="table table-hover text-left">
        <tbody>
          <tr>
            <th>
              <strong>{t("General")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Submission Date")}:</th>
            <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
          </tr>
          <tr>
            <th>{t("Submission by")}:</th>
            <td>{submission.company.name}</td>
          </tr>
          <tr>
            <th>
              <strong>{t("Waste Information")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Month Received")}:</th>
            <td>
              {dateFormat(submission.collectionDate, COLLECTION_DATE_FORMAT)}
            </td>
          </tr>
          <tr>
            <th>{t("Waste origin")}:</th>
            <td>{productName}</td>
          </tr>
          <tr>
            <th>{t("Waste received from")}:</th>
            <td>
              {(() => {
                if (countryFromEU) {
                  return (
                    <span
                      className={getCountryFlagClassName(
                        submission.country?.code,
                      )}
                    />
                  )
                }
                return <span>Non-EU</span>
              })()}
            </td>
          </tr>
          <tr>
            <th>{t("Total Incoming Waste (kg)")}:</th>
            <td>{submission.volumeGross}</td>
          </tr>
          <tr>
            <th>{t("Contamination")}:</th>
            <td>
              {Math.round(
                (1 - submission.volumeNet / submission.volumeGross) * 100,
              )}
            </td>
          </tr>
          <tr>
            <th>{t("Input into the final recycling operation (kg)")}:</th>
            <td>{submission.volumeNet}</td>
          </tr>
          <tr>
            <th>{t("Administration Number")}:</th>
            <td>{submission.administrationNumber}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  const outputTooltip = (submission: Submission) => {
    return (
      <table className="table table-hover text-left">
        <tbody>
          <tr>
            <th>
              <strong>{t("General")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Submission Date")}:</th>
            <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
          </tr>
          <tr>
            <th>{t("Submission by")}:</th>
            <td>{submission.company.name}</td>
          </tr>
          <tr>
            <th>
              <strong>{t("Waste Information")}</strong>
            </th>
          </tr>
          <tr>
            <th>
              {t(
                "Month sold (recycler) / Month used in production (converter)",
              )}
              :
            </th>
            <td>
              {dateFormat(submission.collectionDate, COLLECTION_DATE_FORMAT)}
            </td>
          </tr>
          <tr>
            <th>{t("Total Net Input PVC (kg)")}:</th>
            <td>{submission.volumeGross}</td>
          </tr>
          <tr>
            <th>{t("Net Output EU")}:</th>
            {/* @ts-ignore */}
            <td>
              {(
                (submission?.estimatedShareExportedInsideEu / 100) *
                submission.volumeNet
              ).toFixed(2)}
            </td>
          </tr>
          <tr>
            <th>{t("Net Output non EU")}:</th>
            {/* @ts-ignore */}
            <td>
              {(
                (submission?.estimatedShareExportedOutsideEu / 100) *
                submission.volumeNet
              ).toFixed(2)}
            </td>
          </tr>
          <tr>
            <th>{t("Administration Number")}:</th>
            <td>{submission.administrationNumber}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <>
      <table className="table table-light table-bordered text-center">
        <thead>
          <tr>
            <th
              onClick={() =>
                onSortClicked(
                  "s.createdAt",
                  submissionOrder,
                  setsSubmissionOrder,
                )
              }
            >
              {t("Submission number")}
              <span
                className={`pl-2 fa text-primary cursor-pointer ${
                  submissionOrder === "ASC" ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th
              onClick={() =>
                onSortClicked("s.collectionDate", dateOrder, setDateOrder)
              }
            >
              {t("Submission date")}
              <span
                className={`pl-2 fa text-primary cursor-pointer ${
                  dateOrder === "ASC" ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            {isInputData ? (
              <>
                <th>{t("Waste origin")}</th>
                <th
                  onClick={() =>
                    onSortClicked("wasteType", wasteType, setWasteType)
                  }
                >
                  {t("Waste type")}
                  <span
                    className={`pl-2 fa text-primary cursor-pointer ${
                      wasteType === "ASC" ? "fa-angle-down" : "fa-angle-up"
                    }`}
                  />
                </th>
                <th>{t("Waste received from")}</th>
                <th>{t("Plant Country")}</th>
                <th>{t("Net input")}</th>
              </>
            ) : (
              <>
                <th>{t("Plant Country")}</th>
                <th>{t("Net Output EU + UK")} </th>
                <th>{t("Net Output non-EU")} </th>
              </>
            )}
            <th>{t("Status")} </th>
            <th>{t("Administration Number")} </th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {submissions.length ? (
            submissions.map((submission) => {
              const submissionCountryFromEU = submission.country
                ? EuCountriesArray.includes(submission.country.code)
                : false
              const companyCountryFromEU = submission.company.country
                ? EuCountriesArray.includes(submission.company.country.code)
                : false
              return (
                <tr key={submission.id}>
                  <td className="align-middle">{submission.yearlyNumber}</td>
                  <td className="align-middle">
                    {dateFormat(
                      submission.collectionDate,
                      COLLECTION_DATE_FORMAT,
                    )}
                  </td>
                  {isInputData ? (
                    <>
                      <td className="align-middle">
                        {submission.composition
                          ? submission.composition.productName
                              .replace(" - Post-Consumer", "")
                              .replace(" - Pre-Consumer", "")
                          : ""}
                      </td>
                      <td className="align-middle">{submission.wasteType}</td>
                      <td className="align-middle">
                        {(() => {
                          if (submissionCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">
                        {(() => {
                          if (companyCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.company?.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">{submission.volumeNet}</td>
                    </>
                  ) : (
                    <>
                      <td className="align-middle">
                        {(() => {
                          if (companyCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.company?.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">{submission.netOutputEU}</td>
                      <td className="align-middle">
                        {submission.netOutputNonEU}
                      </td>
                    </>
                  )}
                  <td className="align-middle">
                    {submission.status === "SENT" ||
                    submission.status === "AUDIT_IN_PROGRESS"
                      ? "PENDING"
                      : submission.status}
                  </td>
                  <td className="align-middle">
                    {submission.administrationNumber}
                  </td>
                  <td className="align-middle">
                    <div className="text-primary">
                      <ReactTooltip
                        id={`tooltip_${submission.id}`}
                        backgroundColor="white"
                        textColor="black"
                      >
                        {isInputData
                          ? inputTooltip(submission)
                          : outputTooltip(submission)}
                      </ReactTooltip>
                      <Link
                        to={`/submissions/show/${submission.id}/${
                          submission.isInput ? "input" : "output"
                        }`}
                        style={{ width: "100%" }}
                        key={submission.id}
                        data-tip=""
                        data-for={`tooltip_${submission.id}`}
                      >
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm text-left my-1"
                          style={{ width: "100%" }}
                        >
                          <i className="fas fa-eye btn-icon mr-1" />
                          {t("View submission")}
                        </button>
                      </Link>
                      {showApproveButton(submission) && (
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm text-left my-1"
                          style={{ width: "100%" }}
                          onClick={() => handleAcceptSubmission(submission.id)}
                        >
                          <i className="fas fa-check btn-icon mr-1" />
                          {t("Approve")}
                        </button>
                      )}
                      {showPendingButton(submission) && (
                        <button
                          type="button"
                          className="btn btn-outline-warning btn-sm text-left my-1"
                          style={{ width: "100%" }}
                          onClick={() => handlePendingSubmission(submission.id)}
                        >
                          <i className="fas fa-pen btn-icon mr-1" />
                          {t("Pending")}
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={isInputData ? 9 : 7}>
                There is no submitted data to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default SubmissionListTableBody
