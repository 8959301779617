import { CompanyRole } from "./types"

export const COMPANIES_PREFIX = "@COMPANIES/"
export const PLANTS_PREFIX = "@PLANTS/"
export const FETCH_COMPANIES = `${COMPANIES_PREFIX}FETCH_COMPANIES`
export const SET_ACTIVE_COMPANIES = `${COMPANIES_PREFIX}SET_ACTIVE_COMPANIES`
export const FETCH_PLANTS = `${PLANTS_PREFIX}FETCH_PLANTS`

export const ACTIVITY_LIST = [
  {
    value: CompanyRole.RECYCLER,
    label: "Recycling",
  },
  {
    value: CompanyRole.CONVERTER,
    label: "Converting",
  },
  {
    value: CompanyRole.RECYCLER_AND_CONVERTER,
    label: "Recycling and Converting",
  },
]
