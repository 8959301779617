// import { Company } from '../companies/types';
import { UserActions } from "./actions"
import { FETCH_USERS, FETCH_USER, FETCH_USER_COMPANIES } from "./constants"
import { User, UserCompanies } from "./types"

export interface UserState {
  users: User[]
  user: User | null
  userCompanies: UserCompanies
}

const initialState: UserState = {
  users: [],
  user: null,
  userCompanies: {},
}

const user = (state: UserState = initialState, action: UserActions) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_USER:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_USER_COMPANIES:
      return {
        ...state,
        ...{
          userCompanies: {
            ...state.userCompanies,
            ...action.payload,
          },
        },
      }
    default:
      return state
  }
}

export default user
