import { restHelper } from "../../@common/service/api"
import { ID } from "../../@common/types"
import {
  Composition,
  CompositionPost,
  CompositionQueryResponse,
} from "../types"

export const queries = {
  compositions: `
    query compositions($companyId: Int) {
      compositions(companyId: $companyId) {
        id
        name
        type
        productName
      }
    }
  `,
  composition: `
    query composition($id: Int!) {
      composition(id: $id) {
        id
        name
        productName
        additivesPercentage
        otherPercentage
        company {
          id
          name
        }
        compositionMaterials {
          id
          percentage
          preConsumer
          postConsumer
          material {
            id
          }
          subComposition {
            id
            name
          }
        }
      } 
    }
  `,
  compositionGetByCompanyId: `query compositionGetByCompanyId(
    $companyId: Int!
  ) {
    compositionGetByCompanyId(
      companyId: $companyId 
    ) {
      id
      name
      type
      productName
      isArchived
      contaminationPercentage
    }
  }`,
}

export const mutations = {
  compositionCreate: `
    mutation compositionCreate(
      $name: String!
      $productName: String!
      $additivesPercentage: Int!
      $otherPercentage: Int!
      $companyId: Int!
      $type: Int!
      $materials: [CompositionMaterialInput!]
    ) {
      compositionCreate(
        name: $name
        productName: $productName
        additivesPercentage: $additivesPercentage
        otherPercentage: $otherPercentage
        companyId: $companyId
        type: $type
        materials: $materials
      ) { id }
    }
  `,
  compositionDelete: `
    mutation compositionDelete(
      $id: Int!
    ) {
      compositionDelete(
        id: $id
      )
    }
  `,
  compositionArchivization: `
    mutation compositionArchivization(
      $id: Int!
    ) {
      compositionArchivization(
        id: $id
      )
    }
  `,
}

export const getCompositions = restHelper<Composition[], ID>(
  queries,
  "compositions",
)

export const getComposition = restHelper<CompositionQueryResponse, ID>(
  queries,
  "composition",
)

export const compositionGetByCompanyId = restHelper<Composition[], ID>(
  queries,
  "compositionGetByCompanyId",
)

export const postComposition = restHelper<Composition, CompositionPost>(
  mutations,
  "compositionCreate",
)

export const deleteComposition = restHelper<boolean, ID>(
  mutations,
  "compositionDelete",
)

export const archiveComposition = restHelper<boolean, ID>(
  mutations,
  "compositionArchivization",
)
