import { useFormik } from "formik"
import { Country } from "modules/countries/types"
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useRouteMatch } from "react-router-dom"
import * as Yup from "yup"

import FormError from "components/FormError/FormError"
import Input from "components/Input/Input"
import Label from "components/Label/Label"
import Select, {
  mapToOptions,
  BaseSelectedValue,
} from "components/Select/Select"

import { isDaysAfter } from "utils/dates"
import { handleToast } from "utils/messages"
import { history } from "utils/routes"
import { windowStorage } from "utils/windowStorage"

import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { ACTIVE_COMPANY_KEY } from "../../../../constants"
import { getPlants } from "../../../companies/service/api"
import { Company, CompanyRole } from "../../../companies/types"
import { fetchCountries } from "../../../countries/actions"
import { fetchGenericCompositions } from "../../../genericCompositions/actions"
import { CompositionType } from "../../../genericCompositions/enums"
import { GenericComposition } from "../../../genericCompositions/types"
import { filterCompanyCompositions } from "../../../genericCompositions/utils"
import { fetchProducts } from "../../../products/actions"
import {
  createConverterSubmissionsOutput,
  setSelectedCompany,
} from "../../actions"
import {
  CAN_EDIT_SUBMISSION_TO_DAYS,
  SELECTED_COMPANY_KEY,
  SUBMISSION_DATE_FORMAT,
} from "../../constants"
import useSubmission from "../../hooks/useSubmission"
import { putSubmission } from "../../service/api"
import SubmissionButtons from "../SubmissionForm/SubmissionButtons"
import SubmissionConverterOutputValidationShema from "./SubmissionConverterOutputValidationShema";

/* Set date to -1 month in form */
const actualDate = new Date()
actualDate.setMonth(actualDate.getMonth() - 1)

const INITIAL_FORM_VALUES = {
  company: null,
  product: null,
  date: actualDate,
  materialGroup: null,
  total: 0,
  totalPet: 0,
  totalUnits: 0,
  totalPercentageOfRecycledPlasticsInFrance: 0,
  estimatedShareExportedOutsideEu: 0,
  estimatedShareExportedInsideEu: 0,
}

interface IProps {
  isEdit?: boolean
  isDisabled?: boolean
}

const SubmissionConverterFormOutput = ({ isEdit, isDisabled }: IProps) => {
  const dispatch = useDispatch()

  const redirectConfigs = {
    route: isEdit ? "/submissions" : "/submissions/new-converting",
    title: isEdit ? "Select Submission type:" : "Converting Activity",
  }

  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const submission = useSubmission(id ? parseInt(id, 0) : 0)

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<Company[]>([])
  const [isExitOnSubmit, setIsExitOnSubmit] = useState(false)
  const [plants, setPlants] = useState<Company[]>()
  const [currentPlant, setCurrentPlant] = useState<Company>()
  const countries = useStateGetter<Country[]>(["country", "countries"]) ?? []

  const compositions =
    useStateGetter<GenericComposition[]>([
      "genericComposition",
      CompositionType.USED_IN,
      "list",
    ]) || []

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchProducts())
    getPlants().then((d) => setPlants(d.data))
  }, [dispatch])

  const { isAdmin } = useUserFromToken()

  useEffect(() => {
    // Check if the variable was specified
    if (typeof isAdmin === "boolean") {
      getCompaniesAsync()
    }
  }, [isAdmin])

  const getCompaniesAsync = async () => {
    const { data, errors } = await getPlants()
    if (data) {
      setCompanies(data)
    }
    if (errors) {
      handleToast(errors, "error")
    }
  }

  const canRemoveSubmission = (createdAt: Date | undefined) => {
    // Nick asked to save this logic for future
    // if (isAdmin) {
    //   return true
    // }
    // if (createdAt && isDaysAfter(CAN_EDIT_SUBMISSION_TO_DAYS, createdAt)) {
    //   return false
    // }

    return true
  }

  const handleOnSubmit = async (
    {
      company,
      product,
      date,
      materialGroup,
      total,
      totalPercentageOfRecycledPlasticsInFrance,
      estimatedShareExportedOutsideEu,
      estimatedShareExportedInsideEu,
      totalPet,
      totalUnits,
    }: {
      company: BaseSelectedValue<number> | null
      product: any
      materialGroup: BaseSelectedValue<number> | null
      date: Date
      total: number
      totalPercentageOfRecycledPlasticsInFrance: number
      estimatedShareExportedOutsideEu: number
      estimatedShareExportedInsideEu: number
      totalPet?: number
      totalUnits?: number
    },
    { resetForm }: { resetForm: () => void },
  ) => {
    if (!loading) {
      setLoading(true)
      const companyId = company?.value
      const materialsGroupId = materialGroup?.value

      const isEstimatedShareWithingRange =
        estimatedShareExportedOutsideEu + estimatedShareExportedInsideEu <= 100

      if (!isEstimatedShareWithingRange) {
        setLoading(false)
        dispatch(
          handleToast(
            [
              "The total value of recycled plastic used in plastic exported to the UK, Norway, Switzerland and outside the EU can only be lower than or equal to 100%.",
            ],
            "error",
          ),
        )
      }

      if (companyId && isEstimatedShareWithingRange && !isEdit) {
        const credentails = {
          companyId,
          materialsGroupId,
          collectionDate: date,
          isInput: false,
          type: 1,
          volumeNet: total,
          totalPercentageOfRecycledPlasticsInFrance:
            totalPercentageOfRecycledPlasticsInFrance,
          estimatedShareExportedOutsideEu: estimatedShareExportedOutsideEu,
          estimatedShareExportedInsideEu: estimatedShareExportedInsideEu,
          productId: product === null ? 0 : product.value,
          totalPet,
          totalUnits,
          successCallback: () => {
            setLoading(false)
            if (isExitOnSubmit === false) {
              resetForm()
            } else {
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: "convertingOutput",
              })
            }
          },
        }

        await dispatch(createConverterSubmissionsOutput(credentails))
      }

      if (companyId && isEstimatedShareWithingRange && isEdit && submission) {
        const { createdAt } = submission
        const credentails = {
          companyId,
          materialsGroupId,
          collectionDate: date,
          isInput: false,
          type: 1,
          volumeGross: 0,
          volumeNet: total,
          totalPercentageOfRecycledPlasticsInFrance,
          estimatedShareExportedOutsideEu,
          estimatedShareExportedInsideEu,
          productId: product === null ? 0 : product.value,
          successCallback: () => {
            setLoading(false)
            if (isExitOnSubmit === false) {
              resetForm()
            } else {
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: "convertingOutput",
              })
            }
          },
        }

        if (canRemoveSubmission(createdAt)) {
          try {
            const { errors } = await putSubmission({
              ...credentails,
              ...{ id: submission.id },
            })
            if (errors) {
              handleToast(errors, "error")
            } else {
              handleToast(["Submission updated"])
              if (!isExitOnSubmit) {
                history.push(redirectConfigs.route)
              } else {
                history.push(redirectConfigs.route, {
                  title: t(redirectConfigs.title),
                  tab: "convertingOutput",
                })
              }
            }
          } catch (error) {
            // @ts-ignore
            handleToast([error.message], "error")
          }
        } else {
          handleToast(
            [
              `Delete action is disabled because this submission was added more than ${CAN_EDIT_SUBMISSION_TO_DAYS} days ago.`,
            ],
            "warning",
          )
        }
      }
    }
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: SubmissionConverterOutputValidationShema,
    onSubmit: handleOnSubmit,
  })

  const { values, errors, setFieldValue, touched, handleSubmit } = formik

  const companyCompositions = useMemo(() => {
    return filterCompanyCompositions<GenericComposition>(compositions, currentPlant, true)
  }, [currentPlant, compositions])

  const configurePlants = async () => {
    if (!plants) await getPlants().then((d) => setPlants(d.data))
    // @ts-ignore
    const plant = plants?.find((a) => a.id == values?.company?.value)

    if (plant) setCurrentPlant(plant)
  }

  useEffect(() => {
    configurePlants()
  }, [values.company, plants])

  useEffect(() => {
    dispatch(
      // @ts-ignore
      fetchGenericCompositions(CompositionType.USED_IN, values?.company?.value),
    )
  }, [])

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  useEffect(() => {
    if (submission) {
      const {
        company,
        country,
        collectionDate,
        volumeNet,
        totalPercentageOfRecycledPlasticsInFrance,
        estimatedShareExportedInsideEu,
        estimatedShareExportedOutsideEu,
        materialsGroup,
        totalPet,
        totalUnits,
      } = submission

      setFieldValue("company", { value: company.id, label: company.name })
      setFieldValue("date", new Date(collectionDate))
      setFieldValue("total", volumeNet)
      setFieldValue(
        "estimatedShareExportedInsideEu",
        estimatedShareExportedInsideEu,
      )
      setFieldValue(
        "totalPercentageOfRecycledPlasticsInFrance",
        totalPercentageOfRecycledPlasticsInFrance,
      )
      setFieldValue(
        "estimatedShareExportedOutsideEu",
        estimatedShareExportedOutsideEu,
      )
      setFieldValue("totalPet", totalPet)
      setFieldValue("totalUnits", totalUnits)

      if (country) {
        setFieldValue("country", { value: country.id, label: country.name })
      }

      if (materialsGroup) {
        setFieldValue("materialGroup", {
          value: materialsGroup.id,
          label: materialsGroup.name,
        })
      }
    }
  }, [submission, compositions, setFieldValue])

  useEffect(() => {
    if (
      compositions.length > 0 &&
      submission &&
      submission.type === 1 &&
      !submission.isInput
    ) {
      const selectedProduct = companyCompositions.find(
        (composition) => composition.productId === submission.productId,
      )
      selectedProduct &&
        setFieldValue("product", {
          value: selectedProduct.productId,
          label: selectedProduct.productName,
        })
    }

    if (localStorage.getItem("goto") !== null && localStorage.getItem("goto")) {
      localStorage.removeItem("goto")
    }
  }, [companyCompositions])

  const onTotalChange = (e: any) => {
    setFieldValue("total", e.target.valueAsNumber)
  }

  const onPetTotalChange = (e: any) => {
    setFieldValue("totalPet", e.target.valueAsNumber)
  }

  const onTotalUnitsChange = (e: any) => {
    setFieldValue("totalUnits", e.target.valueAsNumber)
  }

  const handleTotalPercentageOfRecycledPlasticsInFranceChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue("totalPercentageOfRecycledPlasticsInFrance", value)
    } else {
      setFieldValue("totalPercentageOfRecycledPlasticsInFrance", 0)
    }
  }

  const handleEstimatedShareExportedOutsideEuChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue("estimatedShareExportedOutsideEu", value)
    } else {
      setFieldValue("estimatedShareExportedOutsideEu", 0)
    }
  }

  const handleEstimatedShareExportedInsideEuChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue("estimatedShareExportedInsideEu", value)
    } else {
      setFieldValue("estimatedShareExportedInsideEu", 0)
    }
  }

  const totalPercentageOfRecycledPlasticsUsedInProductsInFranceLabel = t(
    "Total percentage of recycled plastics used in products for the French market (%)",
  )
  const estimatedShareExportedInsideEuLabel = t(
    "Estimated share recycled plastic used in products exported to Norway and Switzerland (%)",
  )
  const estimatedShareExportedOutsideEuLabel = t(
    "Estimated share of recycled plastic used in products exported to outside of EU, UK, Norway and Switzerland (%)",
  )

  useEffect(() => {
    if (values.company) {
      const selectedCompany = values.company as unknown as { value: number }
      if (selectedCompany) {
        const fullCompany = companies.find(
          (c) => c.id === selectedCompany.value,
        ) as Company

        if (fullCompany) {
          windowStorage.remove(ACTIVE_COMPANY_KEY, false)
          windowStorage.set(SELECTED_COMPANY_KEY, fullCompany, false)
          dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
        }
      }
    }
  }, [values.company, companies, dispatch])

  /**
   * @todo figured out why we have here plants and companies as this data is taken from the same table
   * move filtering on backend side
   * use bitmap for company roles
   */
  const filteredCompanies = companies.filter((company) =>
    [CompanyRole.CONVERTER, CompanyRole.RECYCLER_AND_CONVERTER].includes(
      company.roleId,
    ),
  )

  return (
    <div>
      {!isEdit && (
        <div className="mb-5">
          <h2>{t("Output from converting activity")}</h2>
        </div>
      )}
      <form onSubmit={onFormSubmit}>
        {filteredCompanies && (
          <Select
            name="company"
            label={t("Plant name (*)")}
            options={mapToOptions(filteredCompanies)}
            value={values.company}
            handleOnChange={(selectedOption) => {
              configurePlants()
              setFieldValue("company", selectedOption)
            }}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.company && errors.company}
            isDisabled={isDisabled}
          />
        )}
        <div className="form-group row align-items-center ">
          <Label label={t("Date (*)")} name="." labelClassName="col-sm-4" />
          <div className="col-sm-8">
            <DatePicker
              className="form-control"
              selected={values.date}
              dateFormat={SUBMISSION_DATE_FORMAT}
              showMonthYearPicker
              showPopperArrow={false}
              onChange={(date: Date) => setFieldValue("date", date)}
              disabled={isDisabled}
            />
            <FormError error={touched.date && (errors.date as string)} />
          </div>
        </div>
        <Select
          name="materialGroup"
          label={`${t("Polymer")} (*)`}
          options={mapToOptions(currentPlant?.polymers || [])}
          value={values.materialGroup}
          handleOnChange={(selectedOption) =>
            setFieldValue("materialGroup", selectedOption)
          }
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.materialGroup && errors.materialGroup}
          isDisabled={isDisabled}
        />

        <div className="srs-select form-group row">
          {t("Used In") && (
            <Label
              label={t("Used In" + " (*)")}
              name="product"
              labelClassName="col-sm-4"
            />
          )}
          {!isDisabled && (
            <div className="col-sm-4">
              <Link
                className={
                  values.company
                    ? "btn btn-outline-primary w-100"
                    : "disabled-link btn btn-outline-primary w-100"
                }
                to="/used-in"
              >
                {t("Add new product")}
              </Link>
            </div>
          )}
          <div className={isDisabled ? "col-sm-8" : "col-sm-4"}>
            <Select
              name="product"
              options={mapToOptions(
                companyCompositions,
                "productName",
                "productId",
              )}
              value={values.product}
              handleOnChange={(selectedOption: any) =>
                setFieldValue("product", selectedOption)
              }
              isDisabled={isDisabled}
              wrapperClassName="srs-select-sm"
            />
            <FormError error={touched.product && errors.product} />
          </div>
        </div>
        <Input
          label={
            <span>
              {t(`Total weight of`)}
              <strong>{t(" recycled ")}</strong>
              {t(`plastic used (kg)`)}
            </span>
          }
          name="payment"
          type="number"
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          value={values.total || 0}
          handleOnChange={onTotalChange}
          placeholder={t("Enter total")}
          min="0"
          isDisabled={isDisabled}
          error={touched.total && errors.total}
        />
        {/* @ts-ignore */}
        {values?.materialGroup?.label == "PET" ? (
          <>
            <Input
              label={t("Total of PET sold (including recycled PET) (kg)")}
              name="totalPet"
              type="number"
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              value={values.totalPet || 0}
              handleOnChange={onPetTotalChange}
              placeholder={t("Enter total")}
              min="0"
              isDisabled={isDisabled}
              error={touched.totalPet && errors.totalPet}
            />
            <Input
              label={t("Total units sold (quantity)")}
              name="totalUnits"
              type="number"
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              value={values.totalUnits || 0}
              handleOnChange={onTotalUnitsChange}
              placeholder={t("Enter total")}
              min="0"
              isDisabled={isDisabled}
              error={touched.totalUnits && errors.totalUnits}
            />
          </>
        ) : null}

        {currentPlant?.country?.code ===
        countries.find((country) => country.code === "FR")?.code || 
        submission?.productsInFrance ? (
          <div className="srs-input-extra-content">
            <Input
              type="number"
              label={
                totalPercentageOfRecycledPlasticsUsedInProductsInFranceLabel
              }
              name="totalPercentageOfRecycledPlasticsUsedInProductsInFrance"
              min="0"
              max="100"
              placeholder={t("Add total percentage")}
              handleOnChange={
                handleTotalPercentageOfRecycledPlasticsInFranceChange
              }
              inputWrapperClassName="col-sm-8"
              inputStyle={{ paddingRight: "110px" }}
              labelClassName="col-sm-4"
              error={
                touched.totalPercentageOfRecycledPlasticsInFrance &&
                errors.totalPercentageOfRecycledPlasticsInFrance
              }
              value={values.totalPercentageOfRecycledPlasticsInFrance || 0}
              isDisabled={isDisabled}
            />
            <div className="srs-input-extra-content-body">
              <button type="button" className="btn btn-primary" disabled>
                %
              </button>
            </div>
          </div>
        ) : null}

        <div className="srs-input-extra-content">
          <Input
            type="number"
            label={estimatedShareExportedOutsideEuLabel}
            name="estimatedShareExportedOutsideEu"
            min="0"
            max="100"
            placeholder={t("Add expected share")}
            handleOnChange={handleEstimatedShareExportedOutsideEuChange}
            inputWrapperClassName="col-sm-8"
            inputStyle={{ paddingRight: "110px" }}
            labelClassName="col-sm-4"
            error={
              touched.estimatedShareExportedOutsideEu &&
              errors.estimatedShareExportedOutsideEu
            }
            value={values.estimatedShareExportedOutsideEu || 0}
            isDisabled={isDisabled}
          />
          <div className="srs-input-extra-content-body">
            <button type="button" className="btn btn-primary" disabled>
              %
            </button>
          </div>
        </div>
        <div className="srs-input-extra-content">
          <Input
            type="number"
            label={estimatedShareExportedInsideEuLabel}
            name="estimatedShareExportedInsideEu"
            min="0"
            max="100"
            placeholder={t("Add expected share")}
            handleOnChange={handleEstimatedShareExportedInsideEuChange}
            inputWrapperClassName="col-sm-8"
            inputStyle={{ paddingRight: "110px" }}
            labelClassName="col-sm-4"
            error={
              touched.estimatedShareExportedInsideEu &&
              errors.estimatedShareExportedInsideEu
            }
            value={values.estimatedShareExportedInsideEu || 0}
            isDisabled={isDisabled}
          />
          <div className="srs-input-extra-content-body">
            <button type="button" className="btn btn-primary" disabled>
              %
            </button>
          </div>
        </div>
        <div className="row my-4">
          <SubmissionButtons
            disabled={!!isDisabled}
            loading={loading}
            onSave={setIsExitOnSubmit}
            onCancel={() => {
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: "convertingOutput",
              })
            }}
          />
        </div>
      </form>
    </div>
  )
}

export default SubmissionConverterFormOutput
