import { useFormik } from "formik"
import React, { useEffect } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"

import Input from "../../../components/Input/Input"
import { handleToast } from "../../../utils/messages"
import { checkConfirmationCode, userConfirmPassword } from "../service/api"
import LoginNewPasswordValidationSchema from "./LoginNewPasswordValidationSchema"

interface LoginNewPasswordForm {
  code: string
  password: string
}

const LoginNewPasswordForm = () => {
  const { params }: { params: { code: string } } = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    checkConfirmationCode({ code: params.code }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (!data) {
        handleToast(["Code does not exists"])
        history.push("/")
      }
    })
  }, [history, params.code])

  const handleSubmit = async ({ password, code }: LoginNewPasswordForm) => {
    const { errors } = await userConfirmPassword({ password, code })

    if (errors) {
      handleToast(errors, "error")
    } else {
      handleToast(["Password changed. You can login now with new password"])
      history.push("/")
    }
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      code: params.code,
    },
    validationSchema: LoginNewPasswordValidationSchema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors } = formik

  return (
    <>
      <form className="pb-5" onSubmit={formik.handleSubmit}>
        <Input
          label="Password"
          type="password"
          name="password"
          value={values.password}
          handleOnChange={formik.handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.password && errors.password}
        />
        <div className="d-flex justify-content-between">
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
          >
            Confirm
            <i className="fas fa-arrow-right ml-3" />
          </button>
        </div>
      </form>
    </>
  )
}

export default LoginNewPasswordForm
