import axios, { AxiosStatic, AxiosResponse } from "axios"

import { ACCESS_TOKEN_KEY, TOKEN_EXPIRED } from "../constants"
import { windowStorage } from "./windowStorage"

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL

export const request = (): AxiosStatic => {
  const accessToken = windowStorage.get(ACCESS_TOKEN_KEY)
  axios.defaults.headers.authorization = accessToken && `Bearer ${accessToken}`

  const responseOnFulfilled = (response: any) => {
    const {
      data: { errors },
    } = response

    if (errors && errors.length) {
      const errorMessage = errors[0].message
      if (errorMessage === "Unauthorized") {
        if (windowStorage.get(ACCESS_TOKEN_KEY)) {
          windowStorage.clear()
          windowStorage.clear(true)
          windowStorage.set(TOKEN_EXPIRED, "true")
          window.location.replace("/auth/login")
        }
      } else if (errorMessage === "User blocked") {
        windowStorage.clear()
        windowStorage.clear(true)
        window.location.replace("/auth/login")
      }
    }

    return response
  }

  const responseOnRejected = (error: any) => {
    return Promise.reject(error)
  }

  axios.interceptors.response.use(responseOnFulfilled, responseOnRejected)

  return axios
}

export interface ErrorMessage {
  message: string
}

export interface CustomAciosResponse extends AxiosResponse {
  errors?: ErrorMessage[]
}

export const graph = async (
  query: string,
  variables: object = {},
): Promise<CustomAciosResponse> => {
  const url = process.env.REACT_APP_GRAPHQL_URL || ""
  try {
    const { data } = await request().post(url, {
      query,
      variables,
    })

    return data
  } catch (error) {
    // @ts-ignore
    return error
  }
}
