import React from "react"
import { useTranslation } from "react-i18next"
import { useRouteMatch } from "react-router-dom"

import ApplicationListTable from "../components/ApplicationListTable/ApplicationListTable"

const ApplicationEdit = () => {
  const { t } = useTranslation()
  const { params }: { params: { compositionId: string; linkId: string } } =
    useRouteMatch()

  return (
    <>
      <h1 className="mb-5">{t("Edit applications")}</h1>
      <ApplicationListTable
        compositionId={parseInt(params.compositionId, 0)}
        linkId={parseInt(params.linkId, 0)}
      />
    </>
  )
}

export default ApplicationEdit
