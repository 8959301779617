import {
  recyclingActivity,
  FetchSubmissionsCredentials,
  fetchSubmissionsActivity,
} from "modules/NewAudit/types"

import { restHelper } from "../../@common/service/api"
import { ID } from "../../@common/types"
import {
  Submission,
  PaginateSubmissions,
  SectorSubmission,
  FetchSubmissionInput,
  CreateSubmissionInput,
  CreateConverterSubmissionInput,
  UpdateSubmissionInput,
  CreateSectorSubmissionInput,
  UpdateSectorSubmissionInput,
  DeclineSubmissionInput,
  SubmissionStatusChangeInput,
  CreateConverterSubmissionOutput,
} from "../types"

export const queries = {
  submissions: `
    query submissions(
      $dateStart: String
      $dateEnd: String
      $status: String
      $companyId: Int
      $countryId: Int
      $sortBy: String
      $sortDirection: String
      $limit: Int
      $page: Int
      $isInput: Boolean
      $type: Int
      $polymers: [String]
    ) {
      submissions(
        dateStart: $dateStart
        dateEnd: $dateEnd
        status: $status
        companyId: $companyId
        countryId: $countryId
        sortBy: $sortBy
        sortDirection: $sortDirection
        limit: $limit
        page: $page
        isInput: $isInput
        type: $type
        polymers: $polymers
      ) {
        submissions {
          id
          collectionDate
          volumeGross
          contaminationUnit
          volumeNet
          volumeNetOutside
          acceptedDate
          yearlyNumber
          createdAt
          wasteType
          isInput
          status
          netOutputEU
          netOutputNonEU
          administrationNumber
          isMaterialFromOutsideEurope
          company {
            name
            country { code }
          }
          productId
          epsProduct
          productsInFrance
          totalPercentageOfRecycledPlasticsInFrance
          composition {
            id
            name
            productName
          }
          country { code }
          materialsGroup {
            id
            name
          }
          totalUsedVolume
          type
          totalDemonstratedDuringAudit
          estimatedShareExportedOutsideEu
          estimatedShareExportedInsideEu
        }
        total
      }
    }
  `,
  submission: `
    query submission($id: Int!) {
      submission(id: $id) {
        id
        collectionDate
        createdAt
        volumeGross
        contaminationUnit
        volumeNet
        volumeNetOutside
        grossOutputEU
        grossOutputNonEU
        netOutputEU
        netOutputNonEU
        virginAdditives
        isInput
        status
        administrationNumber
        company {
          id
          name
        }
        composition {
          id
          productName
        }
        country {
          id
          code
          name
        }
        materialsGroup {
          id
          name
        }
        type
        isMaterialFromOutsideEurope
        canMaterialDemonstrateForAudit
        totalDemonstratedDuringAudit
        wasteType
        estimatedShareExportedOutsideEu
        estimatedShareExportedInsideEu
        productId
        productsInFrance
        totalPercentageOfRecycledPlasticsInFrance
        epsProduct
        totalPet
        totalUnits
      }
    }
  `,
  // groupedSubmission: `
  //   query groupedSubmission($compositionId: Int) {
  //     groupedSubmission(compositionId: $compositionId) {
  //       submissions {
  //         id
  //         collectionDate
  //         createdAt
  //         volumeGross
  //         contaminationUnit
  //         volumeNet
  //         composition {
  //           id
  //           name
  //           productName
  //         }
  //       }
  //     }
  //   }
  // `,
  submissionSectorSubmissions: `
    query {
      submissionSectorSubmissions {
        sourceType
        q1
        q2
        q3
        q4
        year
      }
    }
  `,
  submissionSectorSubmission: `
    query submissionSectorSubmission($year: Int!) {
      submissionSectorSubmission(year: $year) {
        id
        sourceType
        year
        q1
        q2
        q3
        q4
      }
    }
  `,
  companySubmision: `
   query companySubmision(
     $dateStart: String
     $dateEnd: String
     $companyIds: [Int]
   ) {
        companySubmision(
          dateStart: $dateStart
          dateEnd: $dateEnd
          companyIds: $companyIds
        ) {
            recycle{
                submissionIds
                materials{
                    material
                    eu {
                        postConsumer  {
                            sector
                            totalInput
                            totalInputRecycling
                        }
                        preConsumer {
                            sector
                            totalInput
                            totalInputRecycling
                        }
                        totalOutput
                    }
                    nonEu {
                        postConsumer {
                            sector
                            totalInput
                            totalInputRecycling
                        }
                        preConsumer {
                            sector
                            totalInput
                            totalInputRecycling
                        }
                        totalOutput
                    }
                    totalOutput
                }
            }
            convert{
                submissionIds
                materials{
                    material
                    eu {
                        totalInput 
                        products {
                            sector
                            sectorOutput
                        }
                        totalOutput
                    }
                    nonEu {
                        totalInput 
                        products {
                            sector
                            sectorOutput
                        }
                        totalOutput
                    }
                }
            }
        }
    }`,
}

export const mutations = {
  submissionCreate: `
    mutation submissionCreate(
      $companyId: Int!
      $countryId: Int
      $compositionId: Int
      $materialsGroupId: Int
      $collectionDate: Date!
      $volumeGross: Float!
      $contaminationUnit: ContaminationUnit
      $contaminationPercentage: Float
      $volumeNet: Float!
      $volumeNetOutside: Float
      $sectorAssociationValues: [SectorAssociationValueInput]
      $grossOutputEU: Float
      $grossOutputNonEU: Float
      $netOutputEU: Float
      $netOutputNonEU: Float
      $totalPercentageOfRecycledPlasticsInFrance: Int
      $virginAdditives: Int
      $isInput: Boolean!
      $isMaterialFromOutsideEurope: Boolean
      $administrationNumber: String
      $yearlySubmission: Boolean
      $wasteFromEu: Boolean
    ) {
      submissionCreate(
        companyId: $companyId
        countryId: $countryId
        compositionId: $compositionId
        materialsGroupId: $materialsGroupId
        collectionDate: $collectionDate
        volumeGross: $volumeGross
        contaminationUnit: $contaminationUnit
        contaminationPercentage: $contaminationPercentage
        volumeNet: $volumeNet
        volumeNetOutside: $volumeNetOutside
        sectorAssociationValues: $sectorAssociationValues
        grossOutputEU: $grossOutputEU
        grossOutputNonEU: $grossOutputNonEU
        netOutputEU: $netOutputEU
        netOutputNonEU: $netOutputNonEU
        isMaterialFromOutsideEurope: $isMaterialFromOutsideEurope
        totalPercentageOfRecycledPlasticsInFrance: $totalPercentageOfRecycledPlasticsInFrance
        virginAdditives: $virginAdditives
        isInput: $isInput
        administrationNumber: $administrationNumber
        yearlySubmission: $yearlySubmission
        wasteFromEu: $wasteFromEu
      ) { id }
    }
  `,
  submissionConverterCreate: `
    mutation submissionConverterCreate(
      $companyId: Int!
      $countryId: Int
      $materialsGroupId: Int
      $collectionDate: Date!
      $type: Int
      $isInput: Boolean!
      $volumeNet: Float!
      $volumeNetOutside: Float
      $totalDemonstratedDuringAudit: Float!
      $wasteType: String
      $epsProduct: String
      $isMaterialFromOutsideEurope: Boolean
      $canMaterialDemonstrateForAudit: Boolean
      $yearlySubmission: Boolean
      $wasteFromEu: Boolean
    ) {
      submissionConverterCreate(
        companyId: $companyId
        countryId: $countryId
        materialsGroupId: $materialsGroupId
        collectionDate: $collectionDate
        type: $type
        isInput: $isInput
        volumeNet: $volumeNet
        volumeNetOutside: $volumeNetOutside
        totalDemonstratedDuringAudit: $totalDemonstratedDuringAudit
        wasteType: $wasteType
        epsProduct: $epsProduct
        isMaterialFromOutsideEurope: $isMaterialFromOutsideEurope
        canMaterialDemonstrateForAudit: $canMaterialDemonstrateForAudit
        yearlySubmission: $yearlySubmission
        wasteFromEu: $wasteFromEu
      ) { id }
    }
  `,
  submissionOutputConverterCreate: `
    mutation submissionOutputConverterCreate(
      $companyId: Int!
      $productId: Int
      $countryId: Int
      $materialsGroupId: Int
      $collectionDate: Date!
      $type: Int
      $isInput: Boolean!
      $volumeNet: Float!
      $volumeNetOutside: Float
      $totalPet: Float
      $totalUnits: Float
      $totalPercentageOfRecycledPlasticsInFrance: Int
      $estimatedShareExportedOutsideEu: Int
      $estimatedShareExportedInsideEu: Int
      $yearlySubmission: Boolean
      $productsInFrance: Boolean
    ) {
      submissionOutputConverterCreate(
        companyId: $companyId
        productId: $productId
        countryId: $countryId
        materialsGroupId: $materialsGroupId
        collectionDate: $collectionDate
        type: $type
        isInput: $isInput
        volumeNet: $volumeNet
        volumeNetOutside: $volumeNetOutside
        totalPet: $totalPet
        totalUnits: $totalUnits
        totalPercentageOfRecycledPlasticsInFrance: $totalPercentageOfRecycledPlasticsInFrance
        estimatedShareExportedOutsideEu: $estimatedShareExportedOutsideEu
        estimatedShareExportedInsideEu: $estimatedShareExportedInsideEu
        yearlySubmission: $yearlySubmission
        productsInFrance: $productsInFrance
      ) { id }
    }
  `,
  submissionUpdate: `
    mutation submissionUpdate(
      $id: Int!
      $companyId: Int!
      $countryId: Int
      $productId: Int
      $compositionId: Int
      $materialsGroupId: Int
      $collectionDate: Date!
      $volumeGross: Float!
      $contaminationUnit: ContaminationUnit
      $volumeNet: Float!
      $volumeNetOutside: Float
      $sectorAssociationValues: [SectorAssociationValueInput]
      $grossOutputEU: Float
      $grossOutputNonEU: Float
      $netOutputEU: Float
      $netOutputNonEU: Float
      $virginAdditives: Int
      $administrationNumber: String
      $totalDemonstratedDuringAudit: Int
      $wasteType: String
      $isMaterialFromOutsideEurope: Boolean
      $canMaterialDemonstrateForAudit: Boolean
      $totalPercentageOfRecycledPlasticsInFrance: Int
      $estimatedShareExportedOutsideEu: Int
      $estimatedShareExportedInsideEu: Int
      $yearlySubmission: Boolean
      $wasteFromEu: Boolean
    ) {
      submissionUpdate(
        id: $id
        companyId: $companyId
        countryId: $countryId
        productId: $productId
        compositionId: $compositionId
        materialsGroupId: $materialsGroupId
        collectionDate: $collectionDate
        volumeGross: $volumeGross
        contaminationUnit: $contaminationUnit
        volumeNet: $volumeNet
        volumeNetOutside: $volumeNetOutside
        sectorAssociationValues: $sectorAssociationValues
        grossOutputEU: $grossOutputEU
        grossOutputNonEU: $grossOutputNonEU
        netOutputEU: $netOutputEU
        netOutputNonEU: $netOutputNonEU
        virginAdditives: $virginAdditives
        administrationNumber: $administrationNumber
        totalDemonstratedDuringAudit: $totalDemonstratedDuringAudit
        wasteType: $wasteType
        isMaterialFromOutsideEurope: $isMaterialFromOutsideEurope
        canMaterialDemonstrateForAudit: $canMaterialDemonstrateForAudit
        totalPercentageOfRecycledPlasticsInFrance: $totalPercentageOfRecycledPlasticsInFrance
        estimatedShareExportedOutsideEu: $estimatedShareExportedOutsideEu
        estimatedShareExportedInsideEu: $estimatedShareExportedInsideEu
        yearlySubmission: $yearlySubmission
        wasteFromEu: $wasteFromEu
      ) { id }
    }
  `,
  submissionDelete: `
    mutation submissionDelete(
      $id: Int!
    ) {
      submissionDelete(
        id: $id
      )
    }
  `,
  submissionApprove: `
    mutation submissionApprove(
      $id: Int!
    ) {
      submissionApprove(
        id: $id
      )
    }
  `,
  submissionDecline: `
    mutation submissionDecline(
      $id: Int!
    ) {
      submissionDecline(
        id: $id
      )
    }
  `,
  submissionSectorSubmissionCreate: `
    mutation submissionSectorSubmissionCreate(
      $sectorAssociationValues: [SectorAssociationValueInput]!
    ) {
      submissionSectorSubmissionCreate(
        sectorAssociationValues: $sectorAssociationValues
      )
    }
  `,
  submissionUpdateSectorSubmissions: `
    mutation submissionUpdateSectorSubmissions(
      $sectorAssociationValues: [SectorAssociationValueInput]!
    ) {
      submissionUpdateSectorSubmissions(
        sectorAssociationValues: $sectorAssociationValues
      )
    }
  `,
  submissionStatusChange: `
    mutation submissionStatusChange(
      $id: Int!
      $status: String!
    ) {
      submissionStatusChange(
        id: $id
        status: $status
      )
    }
  `,
}

export const getSubmissions = restHelper<
  PaginateSubmissions,
  FetchSubmissionInput
>(queries, "submissions")

export const getSubmission = restHelper<Submission, ID>(queries, "submission")

// export const getGroupedSubmission = restHelper<
//   Submission,
//   { compositionId?: number }
// >(queries, 'groupedSubmission');

export const postSubmission = restHelper<Submission, CreateSubmissionInput>(
  mutations,
  "submissionCreate",
)

export const postConverterSubmissionInput = restHelper<
  Submission,
  CreateConverterSubmissionInput
>(mutations, "submissionConverterCreate")

export const postConverterSubmissionOutput = restHelper<
  Submission,
  CreateConverterSubmissionOutput
>(mutations, "submissionOutputConverterCreate")

export const putSubmission = restHelper<Submission, UpdateSubmissionInput>(
  mutations,
  "submissionUpdate",
)

export const approveSubmission = restHelper<boolean, ID>(
  mutations,
  "submissionApprove",
)

export const declineSubmission = restHelper<boolean, DeclineSubmissionInput>(
  mutations,
  "submissionDecline",
)

export const deleteSubmission = restHelper<boolean, ID>(
  mutations,
  "submissionDelete",
)

export const postSectorSubmission = restHelper<
  boolean,
  CreateSectorSubmissionInput
>(mutations, "submissionSectorSubmissionCreate")

export const getSectorSubmissions = restHelper<SectorSubmission[], {}>(
  queries,
  "submissionSectorSubmissions",
)

export const getSectorSubmission = restHelper<
  SectorSubmission[],
  { year: number }
>(queries, "submissionSectorSubmission")

export const getCompanySubmissions = restHelper<
  fetchSubmissionsActivity,
  FetchSubmissionInput
>(queries, "companySubmision")

export const updateSectorSubmission = restHelper<
  boolean,
  UpdateSectorSubmissionInput
>(mutations, "submissionUpdateSectorSubmissions")

export const submissionStatusChange = restHelper<
  boolean,
  SubmissionStatusChangeInput
>(mutations, "submissionStatusChange")
