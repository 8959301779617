import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { handleToast } from "../../utils/messages"
import { windowStorage } from "../../utils/windowStorage"
import { actionHandler } from "../@common/actions"
import {
  FETCH_SUBMISSIONS,
  FETCH_SECTOR_SUBMISSION,
  SET_SELECTED_COMPANY,
  SELECTED_COMPANY_KEY,
} from "./constants"
import {
  getSubmissions,
  postSubmission,
  getSectorSubmissions,
  postConverterSubmissionInput,
  postConverterSubmissionOutput,
} from "./service/api"
import {
  Submission,
  SectorSubmission,
  FetchSubmissionInput,
  CreateSubmissionInput,
  CreateConverterSubmissionInput,
  CreateConverterSubmissionOutput,
} from "./types"

/**
 * SUBMISSION
 */

interface SetSubmissionsPayload {
  submissions: Submission[]
  submissionsQty: number
}

export interface SetSubmissions {
  type: string
  payload: SetSubmissionsPayload
}

export const setSubmissions = (
  payload: SetSubmissionsPayload,
): SetSubmissions => ({
  type: FETCH_SUBMISSIONS,
  payload,
})

export const fetchSubmissions =
  (credentials: FetchSubmissionInput = {}) =>
  async (dispatch: Dispatch) => {
    actionHandler(getSubmissions(credentials), ({ submissions, total }) => {
      dispatch(
        setSubmissions({
          submissions,
          submissionsQty: total,
        }),
      )
    })
  }

export const setSelectedCompany = (payload: any) => ({
  type: SET_SELECTED_COMPANY,
  payload,
})

export const fetchSelectedCompany = () => async (dispatch: Dispatch) => {
  dispatch(
    setSelectedCompany({
      selectedCompany: windowStorage.get(
        SELECTED_COMPANY_KEY,
        undefined,
        false,
      ),
    }),
  )
}

export const createSubmissions =
  (credentials: CreateSubmissionInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postSubmission(credentials), async () => {
      await dispatch(fetchSubmissions())
      handleToast(["Submission added"])
      if (credentials.successCallback) {
        credentials.successCallback()
      }
    })
  }

export const createConverterSubmissionsInput =
  (credentials: CreateConverterSubmissionInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postConverterSubmissionInput(credentials), async () => {
      await dispatch(fetchSubmissions())
      handleToast(["Submission added"])
      if (credentials.successCallback) {
        credentials.successCallback()
      }
    })
  }

export const createConverterSubmissionsOutput =
  (credentials: CreateConverterSubmissionOutput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postConverterSubmissionOutput(credentials), async () => {
      await dispatch(fetchSubmissions())
      handleToast(["Submission added"])
      if (credentials.successCallback) {
        credentials.successCallback()
      }
    })
  }

/**
 * SECTOR SUBMISSION
 */

interface SetSectorSubmissionPayload {
  sectorSubmissions: SectorSubmission[]
}

export interface SetSectorSubmission {
  type: string
  payload: SetSectorSubmissionPayload
}

export const setSectorSubmission = (
  payload: SetSectorSubmissionPayload,
): SetSectorSubmission => ({
  type: FETCH_SECTOR_SUBMISSION,
  payload,
})

export const fetchSectorSubmissions = () => async (dispatch: Dispatch) => {
  actionHandler(getSectorSubmissions(), async (sectorSubmissions) => {
    dispatch(setSectorSubmission({ sectorSubmissions }))
  })
}

export type SubmissionActions = SetSubmissions | SetSectorSubmission
