import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { history } from "../../utils/routes"

const Breadcrumbs = () => {
  interface PageData {
    title?: string
    id?: any
  }
  const { t } = useTranslation()

  const appTitle = "RecoTrace"

  const homeBreadcrumbs = () => {
    document.title = appTitle
    return <></>
  }
  const state = history.location.state as PageData

  const pageBreadcrubms = (pageTitle: any) => {
    document.title = pageTitle ? `${appTitle} - ${pageTitle}` : appTitle

    let breadcrumbActive = ''
    const breadcrumbLinks = []

    if (state?.title === "Audits") {
      breadcrumbActive = t("Audits")
    }

    if (state?.id === "converter") {
      breadcrumbLinks.push({
        pathname: "/submissions/new-converting",
        state: {
          title: t("Converting Activity"),
        },
      })
      breadcrumbActive = state && state.title ? state.title : ''
    }

    if (
      state?.title === "New audit" ||
      state?.title === "Existing audits" ||
      state?.title === "Annual percentage"
    ) {
      breadcrumbActive = state && state.title ? state.title : ''
      breadcrumbLinks.push({
        pathname: "/companies",
        state: {
          title: "Manage companies",
        },
      })
      breadcrumbLinks.push({
        pathname: `/audit-reports/${state.id}`,
        state: {
          title: t("Audits"),
        },
      })
    }

    if (
      history.location.pathname === "/waste-origin" ||
      history.location.pathname === "/waste-origin/new"
    ) {
      breadcrumbActive = t("Waste origin")
      breadcrumbLinks.push({
        pathname: "/submissions/new/",
        state: {
          title: t("Recycling Activity"),
        },
      })
      breadcrumbLinks.push( {
        pathname: "/submissions/new/input",
        state: {
          title: t("Submit input data"),
        },
      })
    }
    if (
      history.location.pathname === "/used-in" ||
      history.location.pathname === "/used-in/new"
    ) {
      breadcrumbActive = t("Used In")
      breadcrumbLinks.push({
        pathname: "/submissions/new-converting",
        state: {
          title: t("Converting Activity"),
        },
      })
      breadcrumbLinks.push( {
        pathname: "/submissions/new-converting/output",
        state: {
          title: t("Submit output data"),
        },
      })
    }
    if (
      state?.id &&
      (history.location.pathname === `/submissions/edit/${state.id}/input` ||
        history.location.pathname === `/submissions/edit/${state.id}/output` ||
        history.location.pathname ===
          `/submissions/edit/${state.id}/inputConverter` ||
        history.location.pathname ===
          `/submissions/edit/${state.id}/outputConverter`)
    ) {
      breadcrumbActive = t("Edit submission")
      breadcrumbLinks.push({
        pathname: "/submissions",
        state: {
          title: "Manage submitted data",
        },
      })
    }
    if (
      history.location.pathname === "/submissions/new/input" ||
      history.location.pathname === "/submissions/new/output"
    ) {
      breadcrumbLinks.push({
        pathname: "/submissions/new/",
        state: {
          title: t("Recycling Activity"),
        },
      })
    }

    if (history.location.pathname === "/submissions") {
      breadcrumbActive = t("Manage submitted data")
    }

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link
              to={{
                pathname: "/",
                state: {
                  title: t("Homepage"),
                },
              }}
            >
              {t("Homepage")}
            </Link>
          </li>
          {breadcrumbLinks.map(bl => (
            <li className="breadcrumb-item">
              <Link to={bl}>
                {bl.state.title}
              </Link>
            </li>
          ))}
          <li className="breadcrumb-item active">{breadcrumbActive !== '' ? breadcrumbActive : pageTitle}</li>
        </ol>
      </nav>
    )
  }

  const pageTitle = state?.title || ""
  let Dashboard = false
  if (
    pageTitle === "Dashboard" ||
    history.location.pathname === "/" ||
    history.location.pathname === "/auth/login" ||
    history.location.pathname === "/auth/register" ||
    history.location.pathname === "/auth/finish-registration" ||
    history.location.pathname === "/auth/login/forgotten-password" ||
    history.location.pathname === "/auth/manual" ||
    history.location.pathname === "/manual"
  ) {
    Dashboard = true
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="row">
            {Dashboard ? homeBreadcrumbs() : pageBreadcrubms(pageTitle)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs
