import React from "react"

import { BranchProps } from "../../../components/Tree/Tree"
import ConfirmButton from "../../../views/partials/ConfirmButton"

const MaterialBranch = ({ node, actionHandler, level = 1 }: BranchProps) => (
  <div className="w-100 pointer-events-none">
    <div className="srs-branch">
      <div className="d-flex align-items-center justify-content-between pointer-events-none">
        <h6 className="mb-0">{node.title}</h6>
        <div className="srs-branch-actions pointer-events-auto">
          <button
            type="button"
            className="btn btn-link p-2"
            onClick={() => actionHandler(node, "visibility")}
          >
            <i
              className={`fa ${node.visible ? "fa-eye" : "fa-eye-slash"}`}
              title={`${
                node.visible
                  ? "Set invisible for select in submission"
                  : "Set visible for select in submission"
              }`}
            />
          </button>
          <button
            type="button"
            className="btn btn-link p-2"
            onClick={() => actionHandler(node, "selectable")}
          >
            <i
              className={`fa ${
                node.selectable ? "fa-check-square" : "fa-square"
              }`}
              title={`${
                node.selectable
                  ? "Set not selectable for select in submission"
                  : "Set selectable for select in submission"
              }`}
            />
          </button>
          {level < 5 ? (
            <button
              type="button"
              className="btn btn-link p-2"
              onClick={() => actionHandler(node, "add")}
              disabled={level >= 5}
            >
              <i className="fa fa-plus" title="Add children material" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-link p-2"
            onClick={() => actionHandler(node, "edit")}
          >
            <i className="fa fa-pen" title="Edit material" />
          </button>
          <ConfirmButton
            title="Remove"
            text="Are you sure you wish to remove this item?"
            confirmText="Remove"
            icon="trash"
            className="btn-link p-2"
            confirmClassName="btn-outline-danger"
            danger
            onConfirm={() => actionHandler(node, "delete")}
          >
            <i className="fa fa-trash-alt" title="Delete material" />
          </ConfirmButton>
        </div>
      </div>
    </div>
  </div>
)

export default MaterialBranch
