import { useFormik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import Input from "../../../components/Input/Input"
import { RECOTRACE_LINK } from "../../../constants"
import { history } from "../../../utils/routes"
import { actionHandler } from "../../@common/actions"
import { login, setTokensAction } from "../actions"
import { loginRequest } from "../service/api"
import LoginFormValidationShema from "./LoginFormValidationShema"
// @ts-ignore
import Manual from "./howto.pdf"

interface LoginData {
  email: string
  password: string
}

const LoginForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    console.log(t(""))
  }, [t])
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    ({ email, password }: LoginData) => {
      if (!loading) {
        setLoading(true)
        actionHandler(loginRequest({ email, password }), (response) => {
          dispatch(setTokensAction(response))
          history.push("/auth/register/finish-registration")
        }).catch(() => {
          setLoading(false)
        })
      }
    },
    [dispatch, loading],
  )

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginFormValidationShema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors } = formik

  return (
    <>
      <h2 className="h1 mt-5 mb-5">{t("Login")}</h2>
      <form className="pb-5" onSubmit={formik.handleSubmit}>
        <Input
          label={t("Email address")}
          type="email"
          name="email"
          value={values.email}
          handleOnChange={formik.handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.email && errors.email}
        />
        <Input
          label={t("Password")}
          type="password"
          name="password"
          value={values.password}
          handleOnChange={formik.handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          maxLength={20}
          error={touched.password && errors.password}
        />
        <div className="d-flex justify-content-end mb-3">
          <Link to="/auth/login/forgotten-password">
            {t("I don't remember password")}
          </Link>
        </div>
        <div className="d-flex justify-content-between">
          <Link
            className="btn btn-primary rounded-bottom-left"
            to="/auth/register"
          >
            <i className="fas fa-unlock mr-2" /> {t("Register")}
          </Link>
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
            disabled={loading}
          >
            {t("Log in")}
            <i className="fas fa-arrow-right ml-3" />
          </button>
        </div>

        <Link to={Manual} target="_blank" className="btn btn-link px-0 mt-3">
          {t("Need help with registration?")}
        </Link>
        <br />
        <Link
          to={{ pathname: RECOTRACE_LINK }}
          target="_blank"
          className="btn btn-link px-0 mb-3"
        >
          {t("Want to know more about RecoTrace?")}
        </Link>
      </form>
    </>
  )
}

export default LoginForm
