import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { handleToast } from "../../utils/messages"
import { getDashboardsUrl } from "./service/api"

const Dashboards = () => {
  const { t } = useTranslation()

  const [dashboardsUrl, setDashboardsUrl] = useState("")

  useEffect(() => {
    getDashboardsUrl().then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setDashboardsUrl(data)
      }
    })
  }, [])

  return (
    <>
      <h1 className="mb-5">{t("Dashboards")}</h1>
      {dashboardsUrl ? (
        <iframe
          title={t("Dashboards")}
          src={dashboardsUrl}
          className="srs-iframe"
        />
      ) : (
        <div>{t("There is no data to display")}</div>
      )}
    </>
  )
}

export default Dashboards
