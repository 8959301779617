import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { handleToast } from "../../../utils/messages"
import { refreshToken } from "../../auth/actions"
import RequestCompanyAccess from "../../companies/components/ManageCompanies/RequestCompanyAccess"
import { getAllPlantsList } from "../../companies/service/api"
import { Company } from "../../companies/types"

interface CompanyRequestModalProps {
  closeModal: Function
}

const CompanyRequestModal = (
  props: React.PropsWithChildren<CompanyRequestModalProps>,
) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { closeModal } = props

  const [modalVisible, setModalVisible] = useState(false)

  const [companies, setCompanies] = useState<Company[]>([])

  const fetchCompanies = () => {
    getAllPlantsList().then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setCompanies(data)
      }
    })
  }

  useEffect(() => {
    fetchCompanies()
    setTimeout(() => {
      setModalVisible(true)
    }, 0)
  }, [])

  const onSubmitted = () => {
    dispatch(refreshToken())
    closeModal()
  }

  let modalClassName = "d-block srs-modal srs-modal-xl modal display-block fade"
  if (modalVisible) {
    modalClassName += " show"
  }

  return (
    <div className="modal-open">
    <div className={modalClassName} role="dialog" aria-hidden="true">
      {modalVisible && <div className="srs-modal-overlay" />}
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content p-4">
          <div className="modal-header">
            <button
              type="button"
              className="srs-modal-close position-absolute close"
              onClick={() => closeModal()}
            >
              <span>&times;</span>
            </button>
            <h4 className="font-weight-bold text-center pb-4">
              {t("Select plants to request access to")}:
            </h4>
          </div>
          
          <RequestCompanyAccess
            companies={companies}
            onSubmitted={onSubmitted}
          />
        </div>
      </div>
    </div>
    </div>
  )
}

export default CompanyRequestModal
