import React, { useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom"

import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import CompanyForm from "../components/CompanyForm/CompanyForm"
import { getCompany } from "../service/api"
import { Company } from "../types"

// import { useDispatch } from 'react-redux';
// import { fetchCompanies } from '../actions';
// import useStateGetter from '../../../hooks/useStateGetter';
// import { Company } from '../types';

// CompanyForm

const CompanyEdit = () => {
  const { params }: { params: { id: string } } = useRouteMatch()
  const [company, setCompany] = useState<Company>()
  const { id } = params
  // const dispatch = useDispatch();
  // const companies = useStateGetter<Company[]>(['company', 'companies']) ?? [];
  const handleOnCompanySuccessUpdate = () => {
    history.goBack()
  }

  useEffect(() => {
    if (id) {
      getCompany({ id: parseInt(id, 0) }).then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }

        if (data) {
          setCompany(data)
        }
      })
    }
    // dispatch(fetchCompanies());
  }, [id])

  return (
    <>
      <h1 className="mb-5">Manage {company?.name}</h1>
      {company && (
        <CompanyForm
          initialCompany={company}
          onSuccessHandler={handleOnCompanySuccessUpdate}
        />
      )}
    </>
  )
}

export default CompanyEdit
