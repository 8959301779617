import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import Input from "../../components/Input/Input"
import Modal from "../../components/Modal/Modal"
import Tree, {
  TreeNode,
  BranchActionHandler,
  polimerTreeUpdateData,
} from "../../components/Tree/Tree"
import useStateGetter from "../../hooks/useStateGetter"
import { history } from "../../utils/routes"
import {
  fetchMaterials,
  createMaterial,
  updateMaterialTree,
  deleteMaterialTree,
} from "./actions"
import MaterialBranch from "./components/MaterialBranch"
import {
  putMaterialTitle,
  showHideMaterial,
  setSelectableMaterial,
} from "./service/api"

const Materials = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()
  const materials = useStateGetter<TreeNode[]>(["material", "materials"]) ?? []

  useEffect(() => {
    dispatch(fetchMaterials())
  }, [dispatch])

  const dragEndCallback = (
    dragedElement: number,
    targetElement: number,
    isIndentElement: boolean,
  ) => {
    const childrenIds = polimerTreeUpdateData(dragedElement)
    dispatch(
      updateMaterialTree({
        dragedElement,
        targetElement,
        isIndentElement,
        childrenIds,
      }),
    )
  }

  const [title, setTitle] = useState("")
  const [parentId, setParentId] = useState(0)
  const [id, setId] = useState<null | number>(null)

  const handleAddMaterial = async () => {
    if (title) {
      await dispatch(createMaterial({ title, parentId }))
      setParentId(0)
      setIsModalOpen(!isModalOpen)
    }
  }

  const handleUpdateMaterial = async () => {
    if (id && title) {
      await putMaterialTitle({ title, id })
      setParentId(0)
      setId(null)
      setTitle("")
      dispatch(fetchMaterials())
      setIsModalOpen(!isModalOpen)
    }
  }

  const handleMaterialsBranchAction: BranchActionHandler = async (
    { id, title },
    actionName,
  ) => {
    switch (actionName) {
      case "visibility":
        await showHideMaterial({ id })
        dispatch(fetchMaterials())
        break
      case "selectable":
        await setSelectableMaterial({ id })
        dispatch(fetchMaterials())
        break
      case "add":
        setId(null)
        setTitle("")
        setParentId(id)
        setIsModalOpen(!isModalOpen)
        break
      case "edit":
        setId(id)
        setTitle(title)
        setIsModalOpen(!isModalOpen)
        break
      case "delete":
        dispatch(deleteMaterialTree({ id }))
        break
      default:
        break
    }
  }

  const { t } = useTranslation()

  return (
    <div className="srs-materials text-right">
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="mb-5">{t("Manage waste origin")}</h1>
        <button
          type="button"
          className="btn btn-success rounded-top-right mb-3"
          onClick={() => {
            setTitle("")
            setIsModalOpen(!isModalOpen)
          }}
        >
          {t("Add New Product")}
          <i className="fas fa-plus ml-3" />
        </button>
      </div>

      <Modal isOpen={isModalOpen} isOpenHandler={setIsModalOpen}>
        <div className="modal-header">
          <h5 className="modal-title">
            <strong>{id ? "Edit material" : "New material"}</strong>
          </h5>
        </div>
        <div className="modal-body">
          <Input
            label="Name"
            name="title"
            value={title}
            handleOnChange={(e) => setTitle(e.target.value)}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
          />
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary rounded-bottom-left"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            Close
          </button>
          <button
            type="submit"
            disabled={!title}
            className="btn btn-success rounded-bottom-right"
            onClick={id ? handleUpdateMaterial : handleAddMaterial}
          >
            Save
          </button>
        </div>
      </Modal>
      <div className="srs-tree-container bg-white border border-primary">
        <Tree
          nodes={materials}
          dragEndCallback={dragEndCallback}
          Branch={MaterialBranch}
          branchActionHandler={handleMaterialsBranchAction}
          isDraggable
        />
      </div>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left mr-2" /> Back
        </button>
      </div>
    </div>
  )
}

export default Materials
