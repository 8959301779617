import React, { useState, useContext } from "react"

import Checkbox from "components/Checkbox/Checkbox"
import { BranchProps } from "components/Tree/Tree"

import CompositionFormTreeContext from "./CompositionFormTreeContext"

const wasteTypeMap: { [key: string]: string } = {
  "Pre-C": "Pre",
  "Post-C": "Post",
}

const CompositionFormTreeBranch = ({ node, actionHandler }: BranchProps) => {
  const { checkedTreeBranchInput, treeBranchExtraProps } = useContext(
    CompositionFormTreeContext,
  )

  const { id, title, selectable, wasteType } = node
  const isExtraProps = !!treeBranchExtraProps
  const [isChecked, setIsChecked] = useState(checkedTreeBranchInput !== 0)
  const [isConsumerType, setIsConsumerType] = useState("")

  const isCurrentChecked = id !== checkedTreeBranchInput && isChecked === true
  const predefinedWasteType = wasteTypeMap[wasteType || ""]

  if (isCurrentChecked && !isExtraProps) {
    setIsChecked(false)
    setIsConsumerType("")
  }

  if (treeBranchExtraProps && treeBranchExtraProps[id] && isChecked === false) {
    const { preConsumer } = treeBranchExtraProps[id]
    setIsChecked(true)
    setIsConsumerType(preConsumer === 100 ? "pre" : "post")
  }

  const branchId = `branch_${id}`

  const handleMainCheckbox = (checked: boolean) => {
    setIsChecked(checked)
    actionHandler(node, "onMaterialCheck", {})

    if (predefinedWasteType) {
      handleConsumerTypeCheck(predefinedWasteType)
    }

    if (checked === false) {
      actionHandler(node, "", {
        overall: 0,
        consume: 0,
      })
      actionHandler(node, "onRemoveCheck", {})
    }
  }

  const handleConsumerTypeCheck = (checkedType: string) => {
    setIsConsumerType(checkedType)
    actionHandler(node, "onMaterialConsumerTypeCheck", { checkedType })
  }

  const inputContent = () => (
    <div className="d-flex">
      <Checkbox
        name="type_pre"
        checkboxWrapperClassName="mr-2"
        label="Pre-consumer"
        value={isConsumerType === "Pre"}
        handleOnChange={() => handleConsumerTypeCheck("Pre")}
        isDisabled={isExtraProps}
      />
      <Checkbox
        name="type_post"
        label="Post-consumer"
        value={isConsumerType === "Post"}
        handleOnChange={() => handleConsumerTypeCheck("Post")}
        isDisabled={isExtraProps}
      />
    </div>
  )

  return (
    <div className={`srs-branch w-100 ${isExtraProps && "text-muted"}`}>
      {selectable ? (
        <div className="d-flex align-items-center justify-content-between pr-3">
          <Checkbox
            name={`${branchId}_checkbox`}
            label={title}
            value={isChecked}
            handleOnChange={() => handleMainCheckbox(!isChecked)}
            isDisabled={isExtraProps}
          />
          {!predefinedWasteType && isChecked && inputContent()}
        </div>
      ) : (
        <div className="p-2">{title}</div>
      )}
    </div>
  )
}

export default CompositionFormTreeBranch
