import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Route, useRouteMatch, useHistory } from "react-router-dom"

import register from "../service/register"
import RegisterAssociationRepresentativeForm from "./RegisterAssociationRepresentativeForm"
import RegisterCompanyForm from "./RegisterCompanyForm"
import RegisterPersonalForm from "./RegisterPersonalForm"

const Register = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  useEffect(() => {
    if (!register.isPersonalData) {
      history.push(path)
    }
  }, [history, path])

  const { t } = useTranslation()

  return (
    <div className="container">
      <div className="pt-5 pb-5">
        <h1 className=" mb-5">{t("Create account")}</h1>
        <Route exact path={path}>
          <RegisterPersonalForm />
        </Route>
        <Route path={`${path}/company`}>
          <RegisterCompanyForm />
        </Route>
        <Route path={`${path}/association-representative`}>
          <RegisterAssociationRepresentativeForm />
        </Route>
      </div>
    </div>
  )
}

export default Register
