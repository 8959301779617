import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import Modal from "components/Modal/Modal"

import { history } from "utils/routes"
import { getCountryFlagClassName } from "utils/strings"
import { windowStorage } from "utils/windowStorage"

import useUserFromToken from "hooks/useUserFromToken"

import { ACTIVE_PLANT_ID_KEY, EuCountriesArray } from "../../../../constants"
import { SELECTED_COMPANY_KEY } from "../../../submissions/constants"
import { sortByCompanyName, sortByCountry } from "../../service/sort"
import { Company } from "../../types"
import CompanyForm from "../CompanyForm/CompanyForm"
import CompanyDetailsModal from "./CompanyDetailsModal"
import CompanyRoleLabel from "./CompanyRoleLabel"

interface ManageCompaniesTableRrrProps {
  companies: Company[]
}

const ManageCompaniesTableRrr = (
  props: React.PropsWithChildren<ManageCompaniesTableRrrProps>,
) => {
  const { t } = useTranslation()

  const { isRrr, isAuditor } = useUserFromToken()

  const { companies } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCompany, setCompany] = useState<Company>()

  const [sortedCompanies, setSortedCompanies] = useState<Company[]>([])
  const [nameSortDirection, setNameSortDirection] = useState("ascending")
  const [countrySortDirection, setCountrySortDirection] = useState("ascending")
  const [isCompanyDetailsOpened, setIsCompanyDetailsOpened] =
    useState<boolean>(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0)
  const [isSelectedCompanyPlant, setIsSelectedCompanyPlant] =
    useState<boolean>(false)

  const onCloseCompanyDetailsModal = () => {
    setIsCompanyDetailsOpened(false)
  }

  const handleOnCompanyLinkClicked = (
    company: Company,
    path: string,
    title: string,
  ) => {
    windowStorage.set(SELECTED_COMPANY_KEY, company as any, false)
    windowStorage.set(ACTIVE_PLANT_ID_KEY, `${company.id}`, false)
    history.push(path, {
      title,
    })
  }

  const handleOnCompanySuccessUpdate = () => {
    setIsModalOpen(false)
  }

  const showDetailsModal = (company: Company, isPlant = false) => {
    setIsSelectedCompanyPlant(isPlant)
    setSelectedCompanyId(company.id || 0)
    setIsCompanyDetailsOpened(true)
  }

  const contactInformationTable = (company: Company) => {
    const countryFromEU =
      company.country && company.country.code
        ? EuCountriesArray.includes(company.country.code)
        : ""
    return (
      <table className="table table-hover text-left">
        <tbody>
          <tr>
            <th>
              <strong>{t("Contact information")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Company Name")}:</th>
            <td>{company.name}</td>
          </tr>
          <tr>
            <th>{t("Country")}:</th>
            <td>
              {(() => {
                if (countryFromEU) {
                  return (
                    <span
                      className={getCountryFlagClassName(company.country?.code)}
                    />
                  )
                }
                return <span>Non-EU</span>
              })()}
            </td>
          </tr>
          <tr>
            <th>{t("Address")}:</th>
            <td>{company.address}</td>
          </tr>
          <tr>
            <th>{t("Vat number")}:</th>
            <td>{company.vatNumber}</td>
          </tr>
          <tr>
            <th>{t("CEO name")}:</th>
            <td>{company.ceoName}</td>
          </tr>
          <tr>
            <th>{t("CEO email")}:</th>
            <td>{company.ceoEmail}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  const companyRow = (company: Company) => {
    return (
      <tr key={company.id}>
        <td className="align-middle">
          <span
            className="btn-link"
            onClick={() => company.parent && showDetailsModal(company.parent)}
          >
            {company.parent?.name}
          </span>
        </td>
        <td className="align-middle">
          <span
            className="btn-link"
            onClick={() => showDetailsModal(company, true)}
          >
            {company.name}
          </span>
        </td>
        <td className="align-middle">
          <CompanyRoleLabel role={company.roleId} />
        </td>
        <td className="align-middle text-center">
          <span className={getCountryFlagClassName(company.country?.code)} />
        </td>
        <td className="align-middle">
          {!isAuditor && (
            <button
              type="button"
              className="btn btn-sm btn-outline-primary text-left w-100 my-1"
              onClick={() =>
                handleOnCompanyLinkClicked(
                  company,
                  "/submissions",
                  "Manage Submissions",
                )
              }
            >
              <i className="fas fa-tasks btn-icon mr-1" />
              {t("Manage Submissions")}
            </button>
          )}
          {!isRrr ||
            (!isAuditor && (
              <button
                type="button"
                className="btn btn-sm btn-outline-success text-left w-100 my-1"
                onClick={() =>
                  handleOnCompanyLinkClicked(
                    company,
                    "/submissions/new",
                    "New Submissions",
                  )
                }
              >
                <i className="fas fa-plus btn-icon mr-1" />
                {t("New Submissions")}
              </button>
            ))}

          <Link
            to={{
              pathname: `/audit-reports/${company.id}`,
              state: {
                title: t("Audits"),
                id: company.id,
              },
            }}
            type="button"
            className="btn btn-sm btn-outline-primary text-left w-100 my-1"
          >
            <i className="fas fa-edit btn-icon mr-1" />
            {t("Audits")}
          </Link>

          <div className="col-12">
            <ReactTooltip
              id={`tooltip_${company.id}`}
              backgroundColor="white"
              textColor="black"
            >
              {contactInformationTable(company)}
            </ReactTooltip>
          </div>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary text-left w-100 my-1"
            key={company.id}
            data-tip=""
            data-for={`tooltip_${company.id}`}
          >
            <i className="fas fa-info btn-icon mr-1" />
            {t("Contact information")}
          </button>
          {!isRrr ||
            (!isAuditor && (
              <Link
                to={{
                  pathname: `/plants/edit/${company.id}`,
                  state: {
                    title: t("Manage Plant"),
                  },
                }}
                type="button"
                className="btn btn-sm btn-outline-warning text-left w-100 my-1"
              >
                <i className="fas fa-plus btn-icon mr-1" />
                {t("Edit")}
              </Link>
            ))}
          {isRrr && (
            <button
              onClick={() => {
                setIsModalOpen(true)
                setCompany(company)
              }}
              type="button"
              className="btn btn-sm btn-outline-success text-left w-100 my-1"
            >
              <i className="fas fa-pen btn-icon mr-1" />
              {t("New Plant")}
            </button>
          )}
        </td>
      </tr>
    )
  }

  useEffect(() => {
    setSortedCompanies(sortByCompanyName(companies, nameSortDirection))
  }, [companies, nameSortDirection])

  return (
    <>
      <div>
        <table className="table table-light table-bordered">
          <thead>
            <tr>
              <th
                className="cursor-pointer"
                onClick={() => {
                  setNameSortDirection(
                    nameSortDirection === "ascending"
                      ? "descending"
                      : "ascending",
                  )
                }}
              >
                {t("Company Name")}
                <span
                  className={`pl-2 fa text-primary ${
                    nameSortDirection === "descending"
                      ? "fa-angle-down"
                      : "fa-angle-up"
                  }`}
                />
              </th>
              <th>{t("Plant name")}</th>
              <th className="text-center">{t("Activity / role")}</th>
              <th
                className="text-center cursor-pointer"
                onClick={() => {
                  setCountrySortDirection(
                    countrySortDirection === "ascending"
                      ? "descending"
                      : "ascending",
                  )
                  setSortedCompanies(
                    sortByCountry(companies, countrySortDirection),
                  )
                }}
              >
                {t("Country")}
                <span
                  className={`pl-2 fa text-primary ${
                    countrySortDirection === "descending"
                      ? "fa-angle-down"
                      : "fa-angle-up"
                  }`}
                />
              </th>
              <th className="text-center actions-col">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {sortedCompanies.length ? (
              sortedCompanies.map((company) => companyRow(company))
            ) : (
              <tr>
                <td className="align-middle text-center" colSpan={6}>
                  {t("There is no data to display")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={isModalOpen}
        isOpenHandler={setIsModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <h2 className="pl-5 pt-5">{t("Company data")}</h2>
        <div className="p-5">
          <CompanyForm
            onSuccessHandler={handleOnCompanySuccessUpdate}
            selectCompany={false}
            selectedCompany={selectedCompany?.parent}
          />
        </div>
      </Modal>
      <CompanyDetailsModal
        {...{
          companyId: selectedCompanyId,
          isPlant: isSelectedCompanyPlant,
          closeModal: onCloseCompanyDetailsModal,
          isCompanyDetailsOpened,
        }}
      />
    </>
  )
}

export default ManageCompaniesTableRrr
