import { toast } from "react-toastify"

import { ErrorMessage } from "./api"

export const handleToast = (
  messages: string[] | ErrorMessage[],
  type = "success",
) =>
  messages.forEach((message: string | ErrorMessage) => {
    const messageString =
      typeof message === "string" ? message : message.message
    if (type === "success") {
      toast.success(messageString)
    } else if (type === "warning") {
      toast.warning(messageString)
    } else if (type === "info") {
      toast.info(messageString)
    } else {
      toast.error(messageString)
    }
  })

export const confirmMessage = (
  yesCallback?: () => void,
  message = "Are you sure you wish to delete this item?",
) => {
  // eslint-disable-next-line no-alert
  if (window.confirm(message)) {
    if (yesCallback) {
      yesCallback()
    }
  }
}
