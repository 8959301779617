import { useState, useEffect } from "react"

import { getSubmission } from "../service/api"
import { Submission } from "../types"

const useSubmission = (id: number) => {
  const [submission, setSubmission] = useState<Submission>()

  useEffect(() => {
    if (id) {
      getSubmission({ id }).then(({ data }) => {
        setSubmission(data)
      })
    }
  }, [id])

  return submission
}

export default useSubmission
