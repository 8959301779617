import * as Yup from "yup"

const UserAccountFormValidationShema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(6, "Name must be at least 6 characters."),
  password: Yup.string().min(6, "Password must be at least 6 characters."),
  email: Yup.string().email("Field does not contains correct email"),
})

export default UserAccountFormValidationShema
