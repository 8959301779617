import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

import { CompositionType } from "../../enums"
import { GenericComposition } from "../../types"

type Props = {
  compositions: GenericComposition[]
  handleArchiveComposition: (id: number) => void
  type: CompositionType
}

const GenericCompositionList: React.FC<Props> = ({
  compositions,
  handleArchiveComposition,
  type,
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <>
      <Link
        className="btn btn-success rounded-top-right mb-3 float-right"
        to={`${type}/new`}
      >
        <i className="fas fa-plus-circle fa-lg mr-3" />
        {t("Add")}
      </Link>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="col-sm-11">{t("Name")}</th>
            <th className="col-sm-1">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {compositions.map(({ id, productName }: GenericComposition) => (
            <tr key={id}>
              <td className="align-middle">
                <Link to={`${type}/show/${id}`}>{productName}</Link>
              </td>
              <td className="text-center">
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      style={{ width: "100%" }}
                      onClick={() => handleArchiveComposition(id)}
                    >
                      <i className="fas fa-archive" /> {t("Archive")}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
          {!compositions.length && (
            <tr>
              <td colSpan={2} className="text-center">
                {t("There is no data to display")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="justify-content-between pb-5">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-right float-right"
          onClick={() => history.goBack()}
        >
          {t("Next")} <i className="fas fa-arrow-right mr-2" />
        </button>
      </div>
    </>
  )
}

export default GenericCompositionList
