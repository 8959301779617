import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { history } from "../../../utils/routes"
import { getNotifications, putNotification } from "../service/api"
import { Notification } from "../types"
import { Link } from "react-router-dom"
import { handleToast } from "utils/messages"
import Modal from "components/Modal/Modal"
import NotificationAuditView from "../components/NotificationAudit"

const NotificationList = () => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [showAudit, setShowAudit] = useState(false)
  const [showedNote, setShowedNote] = useState(0)

  const { t } = useTranslation()

  const rejectNotification = (id: number) => {
    putNotification({
      id,
      status: 'REJECT',
    }).then((res)=>{
      console.log(res)
      if (res.errors) {
        handleToast(res.errors, "error")
      } else {
        handleToast(["Notification updated"], "info")
        history.push('/notifications')
      }
    })
  }

  const onShowAudit = (id: number) => {
    setShowedNote(id)
    setShowAudit(true)
  }

  useEffect(() => {
    getNotifications().then((response) => {
      const { data } = response
      if (data) {
        setNotifications(data)
      }
    })
  }, [])

  return (
    <div>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="mb-5">{t("Manage notifications")}</h1>
        <Link
          className="btn btn-success rounded-top-right mb-3"
          to={`/notifications/new`}
        >
          <i className="fas fa-plus-circle fa-lg mr-3" />
          {t("Add")}
        </Link>
      </div>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="col-sm-9">{t("Title")}</th>
            <th className="col-sm-2">{t("Status")}</th>
            <th className="col-sm-1">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => {
            return (
              <tr key={notification.id}>
                <td className="align-middle">{notification.subject}</td>
                <td className="align-middle">{notification.status}</td>
                <td className="text-center">
                  <div className="row">
                    <div className="col-12">
                      {notification.status === 'SAVE'&& (
                        <button
                          type="button"
                          className="btn btn-outline-warning btn-sm"
                          style={{ width: "100%" }}
                          onClick={() =>
                            history.push(`/notifications/edit/${notification.id}`, {
                              title: t("Edit Notifications"),
                            })
                          }
                        >
                          <i className="fas fa-edit" /> {t("Edit")}
                        </button>
                      )}
                      {notification.status === 'PUBLISH'&& (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-info btn-sm"
                            style={{ width: "100%" }}
                            onClick={() => onShowAudit(notification.id)}
                          >
                            <i className="fas fa-edit" /> {t("Audit")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-sm"
                            style={{ width: "100%" }}
                            onClick={() => rejectNotification(notification.id)}
                          >
                            <i className="fas fa-edit" /> {t("Reject")}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className={showAudit? 'modal-open' : ''}>

      <Modal
          isOpen={showAudit}
          isOpenHandler={setShowAudit}
          wrapperClass="srs-modal-lg"
        >
          <div className="modal-header">
            <h3 className="modal-title">
              <strong>{t("Notification")}</strong>
            </h3>
          </div>
          <div className="modal-body">
            <NotificationAuditView id={showedNote} ></NotificationAuditView>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary rounded-bottom-left"
              onClick={() => setShowAudit(!showAudit)}
            >
              {t("Close")}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default NotificationList
