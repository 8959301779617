import { useFormik } from "formik"
import UploadAuditMultiple from "modules/Audit/containers/auditOptions/UploadAuditMultiple"
import { AuditConvertingActivityTable } from "modules/NewAudit/components/AuditConvertingActivityTable"
import { AuditRecyclingActivityTable } from "modules/NewAudit/components/AuditRecyclingActivityTable"
import { activityTypes, wasteTypes, statuses } from "modules/NewAudit/constants"
import { getAuditStatisticFromSubmissions } from "modules/NewAudit/helpers"
import { convertingActivity, recyclingActivity } from "modules/NewAudit/types"
import { getPlant } from "modules/companies/service/api"
import { Company } from "modules/companies/types"
import React, { useEffect, useState, useCallback } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"

import FormError from "components/FormError/FormError"
import Label from "components/Label/Label"
import Select from "components/Select/Select"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"

import useStateGetter from "hooks/useStateGetter"

import { fetchSubmissions } from "../../../submissions/actions"
import SubmissionListTableBody from "../../../submissions/components/SubmissionListTable/SubmissionListTableBodyForAudits"
import { Submission } from "../../../submissions/types"
import { AUDIT_DATE_FORMAT, auditTypes } from "../../constants"
import { auditCreate } from "../../service/api"

const NewAudit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const [plant, setPlant] = useState<Company>()
  const [step, setStep] = useState<string>("input")
  const [sinceDate, setSinceDate] = useState<any>()
  const [untilDate, setUntilDate] = useState<any>()
  const [setDate, setSetDate] = useState<boolean>(false)
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
  const [sortByMemo, setSortByMemo] = useState<string[]>([
    "s.createdAt",
    "DESC",
  ])

  const [auditStatistic, setAuditStatistic] = useState<{
    recycle: Array<recyclingActivity>
    convert: Array<convertingActivity>
  }>({
    convert: [],
    recycle: [],
  })

  const submissions =
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? []
  const [toggledSubbmisions, setToggleSubbmision] = useState<Submission[]>([])

  const [toggleAllSubmissions, setToggleAllSubmissions] = useState<{
    inputAllToggle: boolean
    outputAllToggle: boolean
  }>({
    inputAllToggle: false,
    outputAllToggle: false,
  })

  const [activityType, setActivityType] = useState<any>(activityTypes[0])
  const [wasteType, setWasteType] = useState<any>(wasteTypes[0])
  const [statusFilter, setStatusFilter] = useState<any>(statuses[0])

  const discardAllAuditChanges = (isDiscardStatistic = true) => {
    values.auditType = null
    setToggleSubbmision([])
    setToggleAllSubmissions({
      inputAllToggle: false,
      outputAllToggle: false,
    })

    if (isDiscardStatistic) {
      setAuditStatistic({
        convert: [],
        recycle: [],
      })
    }
  }

  const INITIAL_FORM_VALUES = {
    auditType: null,
    sinceDate,
    untilDate,
    companyId: id,
    submissions: [] as Submission[],
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: () => {},
  })
  const { values, errors, setFieldValue, touched } = formik

  const onSortChanged = (sortBy: string, sortDirection: string): void => {
    setSortByMemo([sortBy, sortDirection])
  }

  const getPlantData = () => {
    if (id) {
      getPlant({ id: parseInt(id, 0) }).then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }

        if (data) {
          setPlant(data)
        }
      })
    }
  }

  const getSubmissions = useCallback(() => {
    if (id) {
      dispatch(
        fetchSubmissions({
          companyId: parseInt(id),
          sortBy: sortByMemo[0],
          sortDirection: sortByMemo[1],
          limit: 999,
          polymers: ['PVC'],
          // dateStart: `${sinceDate.getFullYear()}/${sinceDate.getMonth()}`,
          // dateEnd: `${untilDate.getFullYear()}/${untilDate.getMonth()}`,
        }),
      )
    }
  }, [dispatch, id, sortByMemo])

  useEffect(() => {
    setFieldValue("submissions", toggledSubbmisions)
  }, [setFieldValue, toggledSubbmisions])

  useEffect(() => {
    if (sinceDate && untilDate && id) {
      dispatch(
        fetchSubmissions({
          companyId: parseInt(id),
          sortBy: sortByMemo[0],
          sortDirection: sortByMemo[1],
          limit: 999,
          polymers: ['PVC'],
          dateStart: `${sinceDate}`,
          dateEnd: `${untilDate}`,
        }),
      )
    }
  }, [sinceDate, untilDate])

  useEffect(() => {
    setActivityType(activityTypes[0])
    setWasteType(wasteTypes[0])
    setStatusFilter(statuses[0])
  }, [step])

  useEffect(() => {
    getPlantData()
    getSubmissions()
    discardAllAuditChanges()
  }, [])

  useEffect(() => {
    setAuditStatistic(
      toggledSubbmisions.length > 0
        ? getAuditStatisticFromSubmissions(toggledSubbmisions)
        : {
            convert: [],
            recycle: [],
          },
    )
  }, [toggledSubbmisions])

  const saveAudit = (draft: boolean) => {
    if (!draft && !uploadedFiles.length)
      return handleToast([`Please upload the file`], "errors")

    const companyId = id as string
    const { auditType }: any = values

    const credentials = {
      companyId: parseInt(companyId, 0),
      submissions: values.submissions.map((sub) => sub.id),
      createdAt: new Date(sinceDate),
      finishedAt: new Date(untilDate),
      auditType: auditType && auditType.value,
      draft,
      files: uploadedFiles,
    }

    auditCreate(credentials)
      .then((res) => {
        if (res.data?.id) {
          handleToast(["Audit created."], "success")
          history.push(`/existing-audit/${id}`, {
            title: t("Existing audits"),
            id,
          })
        }
      })
      .catch(() => {
        if (errors) {
          handleToast(["Cannot create audit"], "error")
        }
      })
      .finally(() => {})
  }

  if (submissions.length > 0 && setDate === false) {
    setSetDate(true)

    const dates: any[] = []

    for (let i = 0; i < submissions.length; i++) {
      dates.push(new Date(submissions[i].collectionDate))
    }

    const maxDate = new Date(Math.max.apply(null, dates))
    const minDate = new Date(Math.min.apply(null, dates))

    setSinceDate(minDate)
    setUntilDate(maxDate)
  }

  const onStartDateChanged = (date: Date) => {
    setSinceDate(date)
  }

  const onEndDateChanged = (date: Date) => {
    setUntilDate(date)
  }

  return (
    <>
      <h1 className="mb-5">
        {t("New audit of")} <strong>{plant?.name}</strong>
      </h1>
      <div className="row">
        <div className="col-12 mb-5">
          {sinceDate && (
            <div className="row">
              <div className="col-4">
                <div className="form-group row align-items-center ">
                  <Label
                    label={`${t("Time period since")}:`}
                    name="since"
                    labelClassName="col-sm-5"
                  />
                  <div className="col-sm-7">
                    <DatePicker
                      className="form-control"
                      selected={new Date(sinceDate)}
                      dateFormat={AUDIT_DATE_FORMAT}
                      showMonthYearPicker
                      showPopperArrow={false}
                      startDate={new Date(sinceDate)}
                      endDate={new Date(untilDate)}
                      maxDate={new Date(untilDate)}
                      onChange={(startDate: Date) =>
                        onStartDateChanged(startDate)
                      }
                    />
                    <FormError
                      error={touched.sinceDate && (errors.sinceDate as string)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group row align-items-center ">
                  <Label
                    label={`${t("Time period until")}:`}
                    name="until"
                    labelClassName="col-sm-5"
                  />
                  <div className="col-sm-7">
                    <DatePicker
                      className="form-control"
                      selected={new Date(untilDate)}
                      dateFormat={AUDIT_DATE_FORMAT}
                      showMonthYearPicker
                      showPopperArrow={false}
                      startDate={new Date(sinceDate)}
                      endDate={new Date(untilDate)}
                      minDate={new Date(sinceDate)}
                      onChange={(startDate: Date) =>
                        onEndDateChanged(startDate)
                      }
                    />
                    <FormError
                      error={touched.untilDate && (errors.untilDate as string)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <Select
                  name="auditType"
                  label={`${t("Audit type")}:`}
                  options={auditTypes}
                  value={values.auditType}
                  handleOnChange={(selectedOption) =>
                    setFieldValue("auditType", selectedOption)
                  }
                  inputWrapperClassName="col-sm-7 pl-0"
                  labelClassName="col-sm-5  pr-0"
                  error={touched.auditType && errors.auditType}
                />
              </div>
              {(step === "input" || step === "output") && (
                <div className="col-4">
                  <Select
                    name="activityType"
                    label={`${t("Activity type")}:`}
                    options={activityTypes}
                    value={activityType}
                    handleOnChange={(s) => setActivityType(s)}
                    inputWrapperClassName="col-sm-7 pl-0"
                    labelClassName="col-sm-5 pr-0"
                  />
                </div>
              )}
              {step === "input" && (
                <div className="col-4">
                  <Select
                    name="wasteType"
                    label={`${t("Waste type")}:`}
                    options={wasteTypes}
                    value={wasteType}
                    handleOnChange={(s) => setWasteType(s)}
                    inputWrapperClassName="col-sm-7 pl-0"
                    labelClassName="col-sm-5 pr-0"
                  />
                </div>
              )}
              {(step === "input" || step === "output") && (
                <div className="col-4">
                  <Select
                    name="statusType"
                    label={`${t("Status")}:`}
                    options={statuses}
                    value={statusFilter}
                    handleOnChange={(s) => setStatusFilter(s)}
                    inputWrapperClassName="col-sm-7 pl-0"
                    labelClassName="col-sm-5 pr-0"
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="col-12">
          <div className="row">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <button
                  className={`button-as-href nav-link ${
                    step === "input" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="true"
                  onClick={(e) => setStep("input")}
                >
                  {t("Step")} 1: {t("Input")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`button-as-href nav-link ${
                    step === "output" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="true"
                  onClick={(e) => setStep("output")}
                >
                  {t("Step")} 2: {t("Output")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`button-as-href nav-link ${
                    step === "upload" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="true"
                  onClick={(e) => setStep("upload")}
                >
                  {t("Step")} 3: {t("Upload Audit Report")}
                </button>
              </li>
            </ul>
          </div>
        </div>
        {step === "input" && (
          <SubmissionListTableBody
            submissions={submissions}
            toggledSubbmisions={toggledSubbmisions}
            setToggleSubbmision={setToggleSubbmision}
            toggleAllSubmissions={toggleAllSubmissions}
            setToggleAllSubmissions={setToggleAllSubmissions}
            inputData="input"
            onSortChanged={onSortChanged}
            getSubmissions={getSubmissions}
            isMain={false}
            isDisabled={false}
            filterActivityType={activityType.value}
            filterWasteType={wasteType.value}
            filterStatus={statusFilter.value}
          />
        )}
        {step === "output" && (
          <SubmissionListTableBody
            submissions={submissions}
            toggledSubbmisions={toggledSubbmisions}
            setToggleSubbmision={setToggleSubbmision}
            toggleAllSubmissions={toggleAllSubmissions}
            setToggleAllSubmissions={setToggleAllSubmissions}
            inputData="output"
            onSortChanged={onSortChanged}
            getSubmissions={getSubmissions}
            isMain={false}
            isDisabled={false}
            filterActivityType={activityType.value}
            filterStatus={statusFilter.value}
          />
        )}
        {step === "upload" && (
          <UploadAuditMultiple
            setFiles={setUploadedFiles}
            files={uploadedFiles}
          />
        )}
        {(step === "input" || step === "output") &&
          auditStatistic.recycle.length > 0 && (
            <>
              <AuditRecyclingActivityTable tableData={auditStatistic.recycle} />
            </>
          )}
        {(step === "input" || step === "output") &&
          auditStatistic.convert.length > 0 && (
            <>
              <AuditConvertingActivityTable
                tableData={auditStatistic.convert}
              />
            </>
          )}
        <div className="col-12">
          <div className="row my-4">
            <div className="col-sm-5" />
            <div className="col-sm-7">
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-primary rounded-bottom-left"
                  onClick={() => {
                    discardAllAuditChanges()
                  }}
                >
                  {t("Discard all changes")}
                </button>
                <button
                  type="submit"
                  className="btn btn-success-light rounded-bottom-left"
                  onClick={() => saveAudit(true)}
                  // disabled={loading}
                >
                  {t("Save as a draft and exit")}
                </button>
                {step === "input" && (
                  <button
                    type="button"
                    className="btn btn-success rounded-bottom-right ml-2"
                    onClick={(e) => {
                      setStep("output")
                    }}
                  >
                    {t("Continue to next step")}
                  </button>
                )}
                {step === "output" && (
                  <button
                    type="button"
                    className="btn btn-success rounded-bottom-right ml-2"
                    onClick={(e) => {
                      setStep("upload")
                    }}
                  >
                    {t("Continue to last step")}
                  </button>
                )}
                {step === "upload" && (
                  <button
                    type="submit"
                    className="btn btn-success rounded-bottom-right ml-2"
                    onClick={() => {
                      saveAudit(false)
                    }}
                  >
                    {t("Save and exit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewAudit
