import { useFormik } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import FormError from "../../../components/FormError/FormError"
import Input from "../../../components/Input/Input"
import Select, { SelectValue } from "../../../components/Select/Select"
import useStateGetter from "../../../hooks/useStateGetter"
import { fetchCountries } from "../../countries/actions"
import { register } from "../actions"
import registerService from "../service/register"
import { CreateCompanyInput } from "../types"
import RegisterCompanyFormValidationShema from "./RegisterCompanyFormValidationShema"

const RegisterCompanyForm = () => {
  const dispatch = useDispatch()
  const countries =
    useStateGetter<{ id: string; name: string }[]>(["country", "countries"]) ??
    []

  const COUNTRIES = countries.map((countrie) => ({
    value: parseInt(countrie.id, 0),
    label: countrie.name,
  }))
  const { role, ...restData } = registerService.personalData

  const handleSubmit = (credentials: CreateCompanyInput) => {
    const { selectedCountry, ...rest } = credentials
    dispatch(
      register({
        roleId: role?.value || 0,
        ...restData,
        company: {
          ...rest,
          ...{ country: selectedCountry?.value || 0 },
        },
      }),
    )
  }

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  const formik = useFormik({
    initialValues: {
      ...registerService.companyData,
    },
    validationSchema: RegisterCompanyFormValidationShema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors, setFieldValue } = formik

  useEffect(() => {
    registerService.companyData = values
  }, [values])

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCountry", selectedOption)
  }
  // TODO change Label to be as {children}

  const { t } = useTranslation()
  return (
    <form className="mb-5" onSubmit={formik.handleSubmit}>
      <h2 className="h4 mb-3">{t("Company data")}</h2>
      <Select
        name="selectedCountry"
        label={t("Country")}
        options={COUNTRIES}
        value={values.selectedCountry}
        handleOnChange={onSelectChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.selectedCountry && errors.selectedCountry}
      />
      <Input
        label={t("VAT without country code and dots")}
        name="vatNumber"
        value={values.vatNumber}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.vatNumber && errors.vatNumber}
      />
      <div className="form-group row align-items-center">
        <label htmlFor="name" className="col-sm-4 col-form-label">
          {t("Company Name")}{" "}
          <span
            className="text-primary"
            data-tip="A company may include one or more production plants. "
          >
            (i)
          </span>
        </label>
        <div className="col-sm-8">
          <input
            id="name"
            className="form-control"
            type="text"
            name="name"
            value={values.name}
            onChange={formik.handleChange}
          />
          <FormError error={touched.name && errors.name} />
          <ReactTooltip />
        </div>
      </div>
      <Input
        label={t("Address")}
        name="address"
        value={values.address}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.address && errors.address}
      />
      <Input
        label={t("Name of CEO")}
        name="ceoName"
        value={values.ceoName}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.ceoName && errors.ceoName}
      />
      <Input
        label={t("Email of CEO")}
        type="email"
        name="ceoEmail"
        value={values.ceoEmail}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.ceoEmail && errors.ceoEmail}
      />
      <div className="d-flex justify-content-between">
        <Link
          className="btn btn-outline-primary rounded-bottom-left"
          to="/auth/register"
        >
          <i className="fas fa-arrow-left mr-2" /> {t("Back")}
        </Link>
        <button type="submit" className="btn btn-success rounded-bottom-right">
          {t("Finish registration")} <i className="fas fa-arrow-right ml-3" />
        </button>
      </div>
    </form>
  )
}

export default RegisterCompanyForm
