import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import AuthGuard from "../../components/AuthGuard/AuthGuard"
import { UserRoles } from "../users/types"
import AuditList from "./containers/AuditList"

const Audit = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Route exact path={`${path}`}>
        <AuthGuard
          roles={[
            UserRoles.ADMIN,
            UserRoles.AUDITOR,
            UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
          ]}
        >
          <AuditList />
        </AuthGuard>
      </Route>
    </>
  )
}

export default Audit
