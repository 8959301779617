import React from "react"
import { useTranslation } from "react-i18next"

import PolyrecLogo from "../../assets/images/logo-polyrec.png"
import RecoLogo from "../../assets/images/logo-recovinyl.png"
import {
  ADDITIONAL_INFO_LINK,
  CONTACTS_LINK,
  PRIVACY_AND_POLICY_LINK,
} from "../../constants"
import styles from "./footer.module.scss"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className={`${styles.container} container`}>
      <div className={styles.footerFlex}>
        <div className={styles.imgPart}>
          <img className={styles.logo} src={RecoLogo} alt="Recotrace logo" />
          <div className={styles.line} />
          <img className={styles.logo} src={PolyrecLogo} alt="Polyrec logo" />
        </div>
        <div className={styles.informPart}>
          <a className={styles.link} target="_blank" href={CONTACTS_LINK}>
            {t("Contact")}
          </a>
          <a className={styles.link} href={PRIVACY_AND_POLICY_LINK}>
            {t("Privacy Policy & Data Protection")}
          </a>
          <a
            className={styles.link}
            target="_blank"
            href={ADDITIONAL_INFO_LINK}
          >
            {t("Additional information")}
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
