import { Company } from "../companies/types"
import { Submission } from "../submissions/types"
import { User } from "../users/types"

export enum AuditStatus {
  CREATED = 1,
  ASSIGNED,
  IN_PROGRESS,
  FINISHED,
}

export interface Document {
  downloadHash: string
  id: number
}

export interface DocumentIdInput {
  id: number
}

export interface Audit {
  id: number
  company: Company
  createdAt: Date
  finishedAt: Date
  createdBy: User
  finishedBy: User
  auditor: User
  submission: Submission
  submissions: []
  auditType: string
  status: number
  document: [Document]
  draft: boolean
  isDeleted: boolean
  deletedAt: Date
  deletedBy: User
}
