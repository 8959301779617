import { USER_METADATA_KEY_PREFIX } from "../constants"

export const windowStorage = {
  get(key: string, initialValue: any | null = null, local = true) {
    try {
      const item = local
        ? window.localStorage.getItem(key)
        : window.sessionStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  },
  set(key: string, value: any | string, local = true) {
    try {
      if (local) {
        window.localStorage.setItem(key, JSON.stringify(value))
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(value))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  },
  remove(key: string, local = true) {
    if (local) {
      window.localStorage.removeItem(key)
    } else {
      window.sessionStorage.removeItem(key)
    }
  },
  clear(local = true) {
    if (local) {
      window.localStorage.clear()
    } else {
      window.sessionStorage.clear()
    }
  },
}

export const getUserMetadata = (userId: number) => {
  const userKey = USER_METADATA_KEY_PREFIX + userId
  return windowStorage.get(userKey)
}

export const setUserMetadata = (
  userId: number,
  data: { [key: string]: number | string },
  update = false,
) => {
  const userKey = USER_METADATA_KEY_PREFIX + userId
  let updatedData = data
  if (update) {
    const userData = windowStorage.get(userKey)
    updatedData = { ...userData, ...data }
  }
  windowStorage.set(userKey, updatedData)
}
