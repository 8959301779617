import React from "react"

interface Props {
  key?: number
  text?: string
  isSelected?: boolean | false
  onClickHandler?: () => void
}

const SelectableItem: React.FC<Props> = ({
  key,
  text,
  isSelected,
  onClickHandler,
}) => {
  return (
    <button
      key={key}
      type="button"
      className={`btn btn-muted w-100 mb-1 ${
        isSelected && "btn-outline-primary"
      }`}
      onClick={onClickHandler}
    >
      {text}
      {isSelected ? <i className="ml-2 fas fa-check" /> : null}
    </button>
  )
}

export default SelectableItem
