import React from "react"
import { useTranslation } from "react-i18next"

const ManualRegister = () => {
  const { t } = useTranslation()

  const frameUrl = "https://sway.office.com/s/NpZxp7IdyKDsdQho/embed"

  return (
    <div className="container py-5">
      <h1 className="mb-5">{t("Manual")}</h1>
      <iframe
        title={t("Dashboards")}
        src={frameUrl}
        className="srs-iframe srs-iframe--sway"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts"
        scrolling="no"
        allowFullScreen
      />
    </div>
  )
}

export default ManualRegister
