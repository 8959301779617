import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import Input from "components/Input/Input"

import useUserFromToken from "hooks/useUserFromToken"

interface ButtonOptions {
  buttonText: string
  className: string
}

interface StatusOptions {
  status: number
  statusName: string
  className: string
  buttonOptions: ButtonOptions
}

const statusOptions: Array<StatusOptions> = [
  {
    status: 2,
    statusName: "Contract paid",
    className: "text-success",
    buttonOptions: {
      buttonText: "Contract accept",
      className: "btn-outline-success",
    },
  },
  {
    status: 1,
    statusName: "Contract pending",
    className: "text-warning",
    buttonOptions: {
      buttonText: "Contract pending",
      className: "btn-outline-warning",
    },
  },
  {
    status: 3,
    statusName: "Contract declined",
    className: "text-danger",
    buttonOptions: {
      buttonText: "Contract decline",
      className: "btn-outline-danger",
    },
  },
]

const getVisibleButtonOptions = (actualStatus: number) =>
  statusOptions.reduce((accumulator, options) => {
    if (options.status !== actualStatus) {
      accumulator.push(options)
    }
    return accumulator
  }, [] as Array<StatusOptions>)

const getStatusOptions = (
  paymentStatus: number,
  propName: keyof StatusOptions,
) => {
  const options = statusOptions.find(({ status }) => status === paymentStatus)
  return options ? (options[propName] as string) : ""
}

interface ContractModalComponentProps {
  onSubmitForm: (event: React.FormEvent<HTMLFormElement>) => void
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onStatusChange: (value: number) => void
  downloadLink: string
  selectedFile: File | undefined
  paymentStatus: number | undefined
  paymentAmount: string | undefined
  companyName: string | undefined
}

const Contract: React.FC<ContractModalComponentProps> = ({
  onSubmitForm,
  onFileChange,
  onInputChange,
  onStatusChange,
  downloadLink,
  selectedFile,
  paymentStatus,
  paymentAmount,
  companyName,
}) => {
  const { t } = useTranslation()
  const { isAdmin } = useUserFromToken()
  const formEl = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (selectedFile === undefined) {
      const form = formEl?.current
      if (form) {
        form.reset()
      }
    }
  }, [selectedFile])
  return (
    <div>
      <div className="modal-header">
        <h3 className="modal-title">
          <strong>{t("Contract information")}</strong>
        </h3>
        {isAdmin && (
          <h3 className="modal-title">Company name: {companyName}</h3>
        )}
      </div>
      <form onSubmit={onSubmitForm} ref={formEl}>
        <div className="modal-body">
          <Input
            name="file"
            type="file"
            label={t("Choose file")}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            handleOnChange={onFileChange}
          />
          {downloadLink && (
            <div className="form-group row align-items-center ">
              <div className="col-sm-4 col-form-label">
                {t("Contract download link")}
              </div>
              <a
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className="col-sm-8"
              >
                {downloadLink}
              </a>
            </div>
          )}
          <Input
            label={t("Contract fee")}
            name="payment"
            type="number"
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            value={paymentAmount}
            handleOnChange={onInputChange}
            placeholder={t("Enter contract fee")}
          />
          <div className="form-group row align-items-center ">
            <div className="col-sm-4 col-form-label">{t("Fee status")}</div>
            <div className="col-sm-8">
              <div className="row align-items-center m-0">
                <div
                  className={`col-sm-4 ${getStatusOptions(
                    paymentStatus || 0,
                    "className",
                  )}`}
                >
                  {t(getStatusOptions(paymentStatus || 0, "statusName"))}
                </div>
                {isAdmin &&
                  paymentStatus &&
                  getVisibleButtonOptions(paymentStatus).map(
                    ({ status, buttonOptions: { buttonText, className } }) => (
                      <div className="col-sm-4" key={buttonText}>
                        <button
                          type="button"
                          className={`btn btn-sm text-left w-100 ${className}`}
                          onClick={() => onStatusChange(status)}
                        >
                          {t(buttonText)}
                        </button>
                      </div>
                    ),
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right float-right"
          >
            {t("Finish")}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Contract
