import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../../constants"
import { windowStorage } from "../../utils/windowStorage"
import { AuthAction } from "./actions"
import { LOGIN, LOGOUT } from "./constants"

export interface AuthState {
  accessToken: Nullable<string>
  refreshToken: Nullable<string>
}

const accessToken = windowStorage.get(ACCESS_TOKEN_KEY)
const refreshToken = windowStorage.get(REFRESH_TOKEN_KEY)

const initialState: AuthState = {
  accessToken,
  refreshToken,
}

const auth = (state: AuthState = initialState, action: AuthAction) => {
  switch (action.type) {
    case LOGIN:
      windowStorage.set(ACCESS_TOKEN_KEY, action.payload.accessToken)
      if (action.payload.refreshToken) {
        windowStorage.set(REFRESH_TOKEN_KEY, action.payload.refreshToken)
      }
      return {
        ...state,
        ...action.payload,
      }
    case LOGOUT:
      windowStorage.clear(false)
      windowStorage.set(ACCESS_TOKEN_KEY, "")
      windowStorage.set(REFRESH_TOKEN_KEY, "")
      return {
        ...state,
        ...{ accessToken: null, refreshToken: null },
      }

    default:
      return state
  }
}

export default auth
