import React from "react"

export interface LabelProps {
  name: string
  label?: string | JSX.Element
  labelClassName: string
}

const Label: React.FC<LabelProps> = ({ label, name, labelClassName }) => {
  return (
    <label htmlFor={name} className={`${labelClassName} col-form-label`}>
      {label}
    </label>
  )
}

export default Label
