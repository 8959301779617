import React from "react"

import { CompanyRole } from "../../types"

type CompanyRoleProps = {
  role: CompanyRole
}

const ROLE_LABELS = {
  [CompanyRole.AUDITOR]: "Auditor",
  [CompanyRole.RECOVINYL_REGIONAL_REPRESENTATIVE]:
    "Recovinyl regional representative",
  [CompanyRole.RECYCLER]: "Recycling",
  [CompanyRole.CONVERTER]: "Converting",
  [CompanyRole.RECYCLER_AND_CONVERTER]: "Recycling and converting",
  [CompanyRole.POLYMERS_ORGANIZATION]: "Polymers Organization",
}

const CompanyRoleLabel = (props: React.PropsWithChildren<CompanyRoleProps>) => {
  return <span>{ROLE_LABELS[props.role]}</span>
}

export default CompanyRoleLabel
