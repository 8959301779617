import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import submitInputData from "../../../assets/images/dashboardicons/submitInputData.svg"
import submitOutputData from "../../../assets/images/dashboardicons/submitOutputData.svg"
import { history } from "../../../utils/routes"

const SubmissionNew: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="srs-dashboard-links">
        <div className="mb-5">
          <h2 className="mb-5">{t("Select Submission type")}:</h2>
          <div className="row d-flex justify-content-between">
            <div className="card text-center">
              <Link
                to={{
                  pathname: "/submissions/new/input",
                  state: {
                    title: t("Submit input data"),
                  },
                }}
              >
                <div className="card-body">
                  <div className="icon-area">
                    <img
                      src={submitInputData}
                      className="card-img-top"
                      alt=""
                    />
                  </div>
                  <h5 className="card-title">{t("Submit input data")}</h5>
                </div>
              </Link>
            </div>
            <div className="card text-center">
              <Link
                to={{
                  pathname: "/submissions/new/output",
                  state: {
                    title: t("Submit output data"),
                  },
                }}
              >
                <div className="card-body">
                  <div className="icon-area">
                    <img
                      src={submitOutputData}
                      className="card-img-top"
                      alt=""
                    />
                  </div>
                  <h5 className="card-title">{t("Submit output data")}</h5>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-primary rounded-bottom-left"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left mr-2" /> {t("Back")}
          </button>
        </div>
      </div>
    </>
  )
}

export default SubmissionNew
