import { sum } from "ramda"
import { useReducer, createContext } from "react"

interface MaxValueState {
  [key: string]: number
}

type MaxValuePayload = MaxValueState

export interface RegionAction {
  type: string
  payload: MaxValuePayload
}

const getBaseCalculationValues = (
  state: MaxValueState,
  payload: MaxValuePayload,
) => {
  const { maxValue, ...rest } = payload
  const key = Object.keys(rest)[0]
  const inputValue = payload[key]
  const stateTotal = sum(Object.values(state))
  const capability = maxValue - stateTotal

  return { key, inputValue, maxValue, capability }
}

const incStateTotal = (state: MaxValueState, payload: MaxValuePayload) => {
  const { key, inputValue, capability } = getBaseCalculationValues(
    state,
    payload,
  )
  if (capability > inputValue) {
    return { ...state, ...{ [key]: state[key] + inputValue } }
  }

  return { ...state, ...{ [key]: state[key] + capability } }
}

const decStateTotal = (state: MaxValueState, payload: MaxValuePayload) => {
  const { key, inputValue } = getBaseCalculationValues(state, payload)
  let decValue = state[key] - inputValue
  if (decValue <= 0) {
    decValue = 0
  }

  return { ...state, ...{ [key]: decValue } }
}

const setStateTotal = (state: MaxValueState, payload: MaxValuePayload) => {
  const { key, inputValue, maxValue } = getBaseCalculationValues(state, payload)
  const restStateValues = Object.keys(state)
    .filter((stateKey) => stateKey !== key)
    .map((filteredKeys) => state[filteredKeys])
  const restStateValuesSum = sum(restStateValues)

  if (restStateValuesSum + inputValue > maxValue) {
    return { ...state, ...{ [key]: maxValue - restStateValuesSum } }
  }

  return { ...state, ...{ [key]: inputValue } }
}

const resetStateTotal = (state: MaxValueState) => {
  return Object.fromEntries(Object.keys(state).map((key) => [key, 0]))
}

function reducer(state: MaxValueState, action: RegionAction) {
  switch (action.type) {
    case "increment": {
      return incStateTotal(state, action.payload)
    }
    case "decrement":
      return decStateTotal(state, action.payload)
    case "set":
      return setStateTotal(state, action.payload)
    case "update":
      return { ...state, ...action.payload }
    case "reset":
      return resetStateTotal(action.payload)
    default:
      throw new Error()
  }
}

const useMaxValueState = (initialState: MaxValueState) => {
  return useReducer(reducer, initialState)
}

const initialContextMaxValue: {
  state: { [key: number]: number }
  localDispatch: any
  unit?: string
  maxValue: number
} = {
  state: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  localDispatch: () => {},
  unit: "kg",
  maxValue: 0,
}

const MaxValueContext = createContext(initialContextMaxValue)

export { MaxValueContext }

export default useMaxValueState
