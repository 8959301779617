import React from "react"
import { useTranslation } from "react-i18next"

import Input from "../../../components/Input/Input"
import Modal from "../../../components/Modal/Modal"
import Tree, { TreeNode } from "../../../components/Tree/Tree"
import { history } from "../../../utils/routes"
import ProductBranch from "./ProductBranch"

type ProductsManageProps = {
  handleProductName: (productName: string) => void
  handleDragEndCallback: (
    dragedElement: number,
    targetElement: number,
    isIndentElement: boolean,
  ) => void
  handleProductsBranchAction: (node: TreeNode, actionName: string) => void
  handleModalToggle: (isModalOpen: boolean) => void
  handleAddProduct: () => void
  handleUpdateProduct: () => void
  isModalOpen: boolean
  productName: string
  products: TreeNode[]
  productId?: number
}

const ProductsManage = ({
  handleProductName,
  handleDragEndCallback,
  handleProductsBranchAction,
  handleModalToggle,
  handleAddProduct,
  handleUpdateProduct,
  isModalOpen,
  productId,
  productName,
  products,
}: ProductsManageProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="mb-5">{t("Manage products")}</h1>
        <button
          type="button"
          className="btn btn-success rounded-top-right mb-3"
          onClick={() => handleModalToggle(!isModalOpen)}
        >
          {t("Add New Product")}
          <i className="fas fa-plus ml-3" />
        </button>
      </div>

      <div className="srs-tree-container bg-white border border-primary">
        <Tree
          nodes={products}
          dragEndCallback={handleDragEndCallback}
          Branch={ProductBranch}
          branchActionHandler={handleProductsBranchAction}
          isDraggable
        />
      </div>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left mr-2" /> Back
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        isOpenHandler={handleModalToggle}
        wrapperClass="srs-modal-lg"
      >
        <div className="modal-header">
          <h3 className="modal-title">
            <strong>New Product</strong>
          </h3>
        </div>
        <div className="modal-body">
          <Input
            label="Name"
            name="title"
            value={productName}
            handleOnChange={(e) => handleProductName(e.target.value.trim())}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
          />
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary rounded-bottom-left"
            onClick={() => handleModalToggle(!isModalOpen)}
          >
            Close
          </button>
          <button
            type="submit"
            disabled={!productName}
            className="btn btn-success rounded-bottom-right"
            onClick={productId ? handleUpdateProduct : handleAddProduct}
          >
            {productId ? "Update" : "Save"}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ProductsManage
