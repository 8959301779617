import { ApplicationActions } from "./actions"
import {
  FETCH_APPLICATIONS,
  FETCH_LINKS,
  FETCH_APPLICATION_LINKS,
  FETCH_SECTOR_APPLICATIONS,
  FETCH_ASSIGN_END_CONFIG,
} from "./constants"
import { Application, Link, ApplicationLink, SectorApplication, AssignEndConfig } from "./types"

export interface ApplicationState {
  applications: Application[]
  applicationLinks: ApplicationLink[]
  links: Link[]
  linksQty: number
  sectorApplications: SectorApplication[]
  assignEndConf: AssignEndConfig | null
}

const initialState: ApplicationState = {
  applications: [],
  applicationLinks: [],
  links: [],
  linksQty: 0,
  sectorApplications: [],
  assignEndConf: null,
}

const application = (
  state: ApplicationState = initialState,
  action: ApplicationActions,
) => {
  switch (action.type) {
    case FETCH_APPLICATIONS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_LINKS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_APPLICATION_LINKS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_SECTOR_APPLICATIONS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_ASSIGN_END_CONFIG:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default application
