import uploadPDF from "assets/images/auditicons/misc-uploadPDF.svg"
import axios from "axios"
import React, { useEffect, useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"

const UploadFile = ({ initFile, setFilePath }: any) => {
  const [fileName, setFileName] = useState<string>("")

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader()

        reader.onabort = () => {
          console.log("file reading was aborted")
        }
        reader.onerror = () => {
          console.log("file reading has failed")
        }

        const formData = new FormData()
        if (file) {
          formData.append("file", file)
        }
        const url = `${process.env.REACT_APP_API_URL}notifications/uploadfile`
        axios({
          url,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        }).then((response) => {
          if (response.data.status === 1) {
            setFileName(file.name)
            setFilePath(response.data.filePath)
          } else {
          }
        })
      })
    },
    [setFilePath],
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  useEffect(() => {
    if (initFile) {
      setFileName(initFile)
    }
  }, [initFile])
  
  return (
    <>
      { fileName === "" ? (
        <div
          className="drop-place col-12 mt-2 mb-4 text-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} accept=".doc, application/pdf, .docx" />
          <div className="col-12 mt-4">
            <img src={uploadPDF} />
          </div>
          <div className="col-12 mt-2">
            <span className="align-middle">
              Drag & drop your file here to upload it
            </span>
          </div>
          <div className="col-12 mt-2">
            <span className="align-middle">or</span>
          </div>
          <div className="col-12 mt-2">
            <button type="button" className="btn btn-primary">
              Browse files on your device
            </button>
          </div>
          
        </div>
      ) : `${fileName} uploaded!`}
    </>
  )
}

export default UploadFile