import React, { useEffect, useState } from "react"
import { Formik, Form, FieldArray } from 'formik'

import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Company } from "modules/companies/types"
import { SubmissionConverterArrayInputValidationShema } from "./SubmissionConverterInputValidationShema"
import SubmissionConverterNewInputFormFields from "./SubmissionConverterNewInputFormFields"
import {BaseSelectedValue} from "components/Select/Select"
import { fetchCountries } from "modules/countries/actions"
import { getPlants } from "modules/companies/service/api"
import { handleToast } from "utils/messages"
import { getPrevYear } from "../../utils";
import { history } from "utils/routes"
import { createConverterSubmissionsInput } from "modules/submissions/actions"

/* Set date to -1 month in form */
const actualDate = new Date()
actualDate.setMonth(actualDate.getMonth() - 1)

export type SubmissionType = {
  company: BaseSelectedValue<number> | null
  country: BaseSelectedValue<number> | null
  countryNonEu: BaseSelectedValue<number> | null
  materialGroup: BaseSelectedValue<number> | null
  date: Date
  total: number
  totalOutside: number
  totalDemonstratedDuringAudit: number
  isMaterialFromOutsideEurope: boolean
  canMaterialDemonstrateForAudit: boolean
  wasteType?: string
  epsProduct?: BaseSelectedValue<string> | null
  yearlySubmission?: boolean
  originFromEu?: boolean
}

const INITIAL_FORM_VALUES: SubmissionType = {
  company: null,
  country: null,
  countryNonEu: null,
  date: actualDate,
  materialGroup: null,
  total: 0,
  totalOutside: 0,
  totalDemonstratedDuringAudit: 0,
  isMaterialFromOutsideEurope: false,
  canMaterialDemonstrateForAudit: false,
  wasteType: "",
  epsProduct: null,
  yearlySubmission: false,
  originFromEu: false
}

const SubmissionConverterFormsNewInput = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [plants, setPlants] = useState<Company[]>([])

  useEffect(() => {
    dispatch(fetchCountries())
    getPlants().then((d) => {
      const fetchedPlants = d.data
      if (fetchedPlants) {
        setPlants(fetchedPlants)
      }
      if (d.errors) {
        handleToast(d.errors, "error")
      }
    })
  }, [dispatch])

  return (
    <div>
      <Formik
       initialValues={{ submissions: [INITIAL_FORM_VALUES] }}
       validationSchema={SubmissionConverterArrayInputValidationShema}
       onSubmit={async (values) => {
        if (!loading) {
          setLoading(true)
          const promises = values.submissions.map((submission, index) => {
            const companyId = submission.company ? submission.company.value : 0
            const countryId = submission.country? submission.country.value : null
            const materialsGroupId = submission.materialGroup?.value

            const date = submission.date
            if (submission.yearlySubmission) {
              date.setFullYear(getPrevYear())
              date.setMonth(11)
            }
              
            if (companyId) {
              
              const credentails = {
                companyId,
                countryId,
                materialsGroupId,
                collectionDate: date,
                isInput: true,
                type: 1,
                volumeNet: submission.total,
                volumeNetOutside: submission.totalOutside,
                totalDemonstratedDuringAudit: submission.totalDemonstratedDuringAudit,
                wasteType: submission.wasteType || '',
                isMaterialFromOutsideEurope: submission.isMaterialFromOutsideEurope,
                canMaterialDemonstrateForAudit: submission.canMaterialDemonstrateForAudit,
                epsProduct: submission.epsProduct?.value,
                yearlySubmission: submission.yearlySubmission ?? false,
                wasteFromEu: submission.originFromEu,
                successCallback: () => {},
              }
              if (values.submissions.length -1 === index) {
                credentails.successCallback= () => {
                  setLoading(false)
                  history.push("/submissions/new-converting", {
                    title: t("Converting Activity"),
                    tab: "convertingInput",
                  })
                }
              }
              return dispatch(createConverterSubmissionsInput(credentails))
            }
          
          })
          await Promise.all(promises)
          
          setLoading(false)
        }
      }}
      >
      {({ values }) => (
        <Form>
          <div className="mb-5">
            <h2>{t("Input into converting activity")}</h2>
          </div>
          <FieldArray
            name="submissions"
            render={arrayHelpers => (
              <>
              {values.submissions && values.submissions.length && (
                values.submissions.map((submission, index) => {
                  return (
                    <>
                    <SubmissionConverterNewInputFormFields submission={submission} plants={plants} index={index}/>
                    <div className="row my-4">                        
                      <div className="col-sm-9 col-md-8 col-lg-7">
                        <button
                          type="button"
                          className="btn btn-success-light rounded-bottom-left"
                          onClick={() => {
                            arrayHelpers.insert(index+1, {...INITIAL_FORM_VALUES, 
                              company: submission.company,
                              date: submission.date, 
                              yearlySubmission: !!submission.yearlySubmission
                            })
                          }}
                        >
                          <i className="fas fa-plus mr-3" />
                          {t("Add new Submission")}
                        </button>
                      </div>
                      <div className="col-sm-3 col-md-4 col-lg-1">
                        {index !== 0 && (
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-primary rounded-bottom-left"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            {t("Cancel")}
                          </button>
                          )}
                      </div>
                    </div>
                    </>
                  )                  
                })
              )}

              </>
            )}
          />
          <button
            type="submit"
            className="btn btn-success float-right rounded-bottom-right ml-2"
            disabled={loading}
          >
            {t("Save")}
            <i className="fas fa-arrow-right ml-3" />
          </button>
        </Form>
      )}
      </Formik>      
    </div>
  )
}

export default SubmissionConverterFormsNewInput
