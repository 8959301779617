import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import {
  TreeNode,
  BranchActionHandler,
  polimerTreeUpdateData,
} from "../../../components/Tree/Tree"
import useStateGetter from "../../../hooks/useStateGetter"
import {
  fetchProducts,
  createProduct,
  updateProductTree,
  removeProduct,
} from "../actions"
import ProductsManage from "../components/ProductsManage"
import {
  putProduct,
  setSelectableProduct,
  showHideProduct,
} from "../service/api"

const ProductsManageContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [productId, setProductId] = useState<number | undefined>()
  const [productName, setProductName] = useState("")
  const [positionId, setPositionId] = useState(0)
  const [parentId, setParentId] = useState(0)
  const dispatch = useDispatch()
  const products = useStateGetter<TreeNode[]>(["product", "products"]) ?? []

  const handleAddProduct = async () => {
    if (productName.length) {
      await dispatch(
        createProduct({ title: productName, positionId, parentId }),
      )
      setParentId(0)
      setPositionId(0)
      setIsModalOpen(!isModalOpen)
    }
  }

  const handleUpdateProduct = async () => {
    if (productName.length && productId) {
      await putProduct({ id: productId, title: productName })
      dispatch(fetchProducts())
      setIsModalOpen(!isModalOpen)
    }
  }

  const handleProductsBranchAction: BranchActionHandler = async (
    { id, title: aTitle },
    actionName,
  ) => {
    switch (actionName) {
      case "visibility":
        await showHideProduct({ id })
        dispatch(fetchProducts())
        break
      case "selectable":
        await setSelectableProduct({ id })
        dispatch(fetchProducts())
        break
      case "add":
        setParentId(id)
        setIsModalOpen(!isModalOpen)
        break
      case "edit":
        setProductId(id)
        setProductName(aTitle)
        setIsModalOpen(!isModalOpen)
        break
      case "delete":
        dispatch(removeProduct({ id }))
        break
      default:
        break
    }
  }

  const dragEndCallback = (
    dragedElement: number,
    targetElement: number,
    isIndentElement: boolean,
  ) => {
    const childrenIds = polimerTreeUpdateData(dragedElement)
    dispatch(
      updateProductTree({
        dragedElement,
        targetElement,
        isIndentElement,
        childrenIds,
      }),
    )
  }

  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])

  useEffect(() => {
    if (
      isModalOpen === false &&
      productName !== "" &&
      productId !== undefined
    ) {
      setProductName("")
      setProductId(undefined)
    }
  }, [productId, isModalOpen, productName])

  return (
    <ProductsManage
      handleProductName={setProductName}
      handleDragEndCallback={dragEndCallback}
      handleProductsBranchAction={handleProductsBranchAction}
      handleModalToggle={setIsModalOpen}
      handleAddProduct={handleAddProduct}
      handleUpdateProduct={handleUpdateProduct}
      isModalOpen={isModalOpen}
      productId={productId}
      productName={productName}
      products={products}
    />
  )
}

export default ProductsManageContainer
