import axios, { AxiosStatic, AxiosResponse } from "axios"
import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"

import { handleToast, confirmMessage } from "../../utils/messages"
import { history } from "../../utils/routes"
import { Translation } from "./types"

const CountryLanguageEdit = () => {
  const [trans, setTrans] = useState([])
  const dispatch = useDispatch()
  const form = document.querySelector("form")!

  const { code } = useParams<{ code: string }>()

  const handleUpdateTranslates = () => {
    const data = new FormData(form)
    const url = `${process.env.REACT_APP_API_URL}translates/${code}`
    const translationArray = {}
    data.forEach((value, key) => {
      Object.assign(translationArray, { [key]: value })
    })
    axios({
      url,
      method: "PUT",
      data: { translations: translationArray },
    }).then(
      (response) => {
        handleToast([response.data.msg], "success")
      },
      (error) => {
        handleToast(error, "error")
      },
    )
  }

  const handleUpdateSingleTranslate = (label: string, id_name: string) => {
    const url = `${process.env.REACT_APP_API_URL}translates/${code}`
    const translation = (
      document.getElementById(id_name.toString()) as HTMLInputElement
    ).value
    const labelString = label.toString()
    axios({
      url,
      method: "PUT",
      data: { translations: { [labelString]: translation } },
    }).then(
      (response) => {
        handleToast([response.data.msg], "success")
      },
      (error) => {
        handleToast(error, "error")
      },
    )
  }

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_API_URL}translates/${code}`,
      method: "GET",
    }).then((data) => setTrans(data.data))
  }, [code, dispatch])

  return (
    <>
      <form id="language-form">
        <table className="table table-light table-bordered">
          <thead>
            <tr>
              <th>Label</th>
              <th>Translation</th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {(trans || []).map((translation: Translation) => {
              const id_name = `translation-${translation.id}`
              return (
                <tr key={translation.id}>
                  <td className="align-middle">{translation.label}</td>
                  <td className="align-middle">
                    <input
                      name={translation.label}
                      id={id_name}
                      className="col-12"
                      type="text"
                      defaultValue={translation.translation}
                    />
                  </td>
                  <td className="align-middle">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() =>
                        handleUpdateSingleTranslate(translation.label, id_name)
                      }
                    >
                      <i className="fa fa-sync" /> Update
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </form>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left mr-2" /> Back
        </button>
        <button
          type="button"
          className="btn btn-success rounded-bottom-right"
          onClick={() => handleUpdateTranslates()}
        >
          <i className="fa fa-sync" /> Update all
        </button>
      </div>
    </>
  )
}

export default CountryLanguageEdit
