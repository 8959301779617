import { Company } from "../companies/types"
import { Composition } from "../compositions/types"
import { Country } from "../countries/types"
import { MaterialGroup } from "../materials/service/api"

export enum ContaminationUnit {
  KG = "KG",
  PERCENT = "PERCENT",
}
export interface Submission {
  id?: number
  company: Company
  country?: Country
  userId: number
  countryId?: number
  composition?: Composition
  materialsGroup?: MaterialGroup
  createdAt?: Date
  collectionDate: string
  volumeGross: number
  yearlyNumber: string
  contaminationUnit?: ContaminationUnit
  volumeNet: number
  volumeNetOutside: number
  acceptedBy: number
  acceptedDate: string
  wasteType?: string
  totalUsedVolume?: number
  grossOutputEU?: number
  grossOutputNonEU?: number
  netOutputEU: number
  netOutputNonEU: number
  virginAdditives: number
  isInput: boolean
  status: string
  administrationNumber: string
  isMaterialFromOutsideEurope: boolean
  canMaterialDemonstrateForAudit: boolean
  type: number
  totalDemonstratedDuringAudit: number
  totalPercentageOfRecycledPlasticsInFrance: number
  estimatedShareExportedInsideEu: number
  estimatedShareExportedOutsideEu: number
  productId?: number
  productsInFrance: boolean
  epsProduct?: string
  totalPet?: number
  totalUnits?: number
}

export interface Summary {
  yearlyNumber: string
  wasteType?: string
  totalUsedVolume?: number
  grossOutputEU?: number
  grossOutputNonEU?: number
  netOutputEU?: number
  netOutputNonEU?: number
}

export interface PaginateSubmissions {
  submissions: Submission[]
  total: number
}

export interface SectorSubmission {
  id?: number
  year: number
  sourceType: string
  q1: number
  q2: number
  q3: number
  q4: number
}

export interface FetchSubmissionInput {
  dateStart?: string
  dateEnd?: string
  sortBy?: string
  sortDirection?: string
  companyId?: number
  status?: string
  limit?: number
  page?: number
  isInput?: boolean
  type?: number
  polymers?: string[]
}

export interface CreateSubmissionInput {
  id?: number
  companyId: number
  countryId?: number | null
  compositionId?: number | null
  materialGroupId?: number | null
  collectionDate: Date
  volumeGross: number
  contaminationUnit?: ContaminationUnit
  contaminationPercentage?: number | null
  volumeNet: number
  sectorAssociationValues?: [SectorSubmission]
  grossOutputEU?: number
  grossOutputNonEU?: number
  netOutputEU?: number
  netOutputNonEU?: number
  virginAdditives?: number
  isInput: boolean
  administrationNumber: string
  yearlySubmission?: boolean
  wasteFromEu?: boolean
  successCallback?: () => void
}

export interface CreateConverterSubmissionInput {
  id?: number
  companyId: number
  countryId?: number | null
  materialGroupId?: number | null
  collectionDate: Date
  isInput: boolean
  type: number
  volumeNet: number
  totalDemonstratedDuringAudit: number
  wasteType: string
  epsProduct?: string
  isMaterialFromOutsideEurope: boolean
  canMaterialDemonstrateForAudit: boolean
  yearlySubmission?: boolean
  wasteFromEu?: boolean
  successCallback?: () => void
}

export interface CreateConverterSubmissionOutput {
  id?: number
  companyId: number
  countryId?: number | null
  materialGroupId?: number | null
  collectionDate: Date
  productId: number
  isInput: boolean
  type: number
  volumeNet: number
  estimatedShareExportedOutsideEu: number
  estimatedShareExportedInsideEu: number
  yearlySubmission?: boolean
  productsInFrance?: boolean
  successCallback?: () => void
}

export interface DeclineSubmissionInput {
  id: number
}

export type UpdateSubmissionInput = CreateSubmissionInput

export interface CreateSectorSubmissionInput {
  sectorAssociationValues: SectorSubmission[]
}

export interface SubmissionStatusChangeInput {
  id: number
  status: string
}

export type UpdateSectorSubmissionInput = CreateSectorSubmissionInput

export enum SubmissionStatusForAudit {
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
}

export enum SubmissionStatus {
  CREATED = "CREATED",
  SENT = "SENT",
  AUDIT_IN_PROGRESS = "AUDIT_IN_PROGRESS",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
}

export const SUBMISSION_STATUS_LIST = [
  { value: SubmissionStatusForAudit.DECLINED, label: "Declined" },
  { value: SubmissionStatusForAudit.ACCEPTED, label: "Accepted" },
]
