import React, { Suspense } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { renderRoutes } from "react-router-config"
import { Router, Switch, Route } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"

import "./App.scss"
import "./i18n"
import routes from "./routes"
import { history } from "./utils/routes"
import Breadcrumbs from "./views/partials/Breadcrumbs"
import Footer from "./views/partials/Footer"
import Header from "./views/partials/Header"

const AppRouter: React.FC = () => renderRoutes(routes)
const queryClient = new QueryClient()

const App = () => (
  <QueryClientProvider client={queryClient}>
    <div className="srs-app">
      <Router history={history}>
        <Suspense fallback={null}>
          <Header />
          <main className="srs-app-main">
            <Switch>
              <Breadcrumbs />
            </Switch>
            <AppRouter />
          </main>
          <Footer />
        </Suspense>
      </Router>
    </div>
  </QueryClientProvider>
)

export default App
