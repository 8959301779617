import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"

import { history } from "../../../utils/routes"
import { getCompany } from "../../companies/service/api"
import { Company } from "../../companies/types"
import { setSelectedCompany } from "../actions"
import AuditSubmissionTable from "../components/AuditSubmissionTable/AuditSubmissionTable"

const SubmissionList = () => {
  const { t } = useTranslation()
  const [title, setTitle] = useState("")
  const dispatch = useDispatch()

  const { params }: { params: { companyId: string | undefined } } =
    useRouteMatch()

  const prepareTitle = (company: Company) => {
    const companyName = company.name
    let mainCompanyName = ""
    if (company.parent) {
      mainCompanyName = company.parent.name
    }
    return `${mainCompanyName} (${companyName})`
  }

  useEffect(() => {
    const getPlantById = async () => {
      const companyId = params.companyId ? +params.companyId : -1
      const { data } = await getCompany({ id: companyId })
      if (data) {
        dispatch(setSelectedCompany({ selectedCompany: data }))
        setTitle(prepareTitle(data))
      }
    }
    getPlantById()
  }, [params.companyId, dispatch])

  return (
    <>
      <h1 className="mb-5" style={{ minHeight: 55, fontWeight: 100 }}>
        {title}
      </h1>
      <AuditSubmissionTable />
      <button
        type="button"
        className="btn btn-outline-primary rounded-bottom-left"
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left mr-2" /> {t("Back")}
      </button>
    </>
  )
}

export default SubmissionList
