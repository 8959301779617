import React from "react"
import { useTranslation } from "react-i18next"

import ApplicationListTable from "../components/ApplicationListTable/ApplicationListTable"

const ApplicationList = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <h1>{t("Applications list")}</h1>
      </div>
      <p className="mb-5">{t("Data from the previous year")}</p>
      <ApplicationListTable />
    </>
  )
}

export default ApplicationList
