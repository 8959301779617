import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import Input from "../../../components/Input/Input"
import Modal from "../../../components/Modal/Modal"
import Select, {
  BaseSelectedValue,
  SelectValue,
} from "../../../components/Select/Select"
import { EuCountriesArray } from "../../../constants"
import { handleToast } from "../../../utils/messages"
import { checkCompanyVatNumber } from "../../companies/service/api"
import { CompanyVatCheck } from "../../companies/types"
import { getCountries } from "../../countries/service/api"
import { Country } from "../../countries/types"

export interface Props {
  setFieldValue: (name: string, value: any) => void
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  country: null | SelectValue
  vatNumber: string
  companyName: string
}

const RegisterPersonalFormCompany: React.FC<Props> = ({
  setFieldValue,
  handleChange,
  country,
  vatNumber,
  companyName,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [countries, setCountries] = useState<Country[]>([])

  const [showVatSearch, setShowVatSearch] = useState<boolean>(false)

  const COUNTRIES = countries.map((_country) => ({
    value: _country.id,
    label: _country.code,
  }))

  COUNTRIES.sort(function (a, b) {
    const textA = a.label.toUpperCase()
    const textB = b.label.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  const countryFromEU = useMemo(() => {
    if (country) {
      const countryCode = country as BaseSelectedValue
      return EuCountriesArray.includes(countryCode.label)
    }
    return true
  }, [country])

  useEffect(() => {
    getCountries().then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setCountries(data)
      }
    })
  }, [dispatch])

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("country", selectedOption)
    setFieldValue("companyName", "")
  }

  const onVatNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event)
    if (countryFromEU) {
      setFieldValue("companyName", "")
    }
  }

  useEffect(() => {
    if (country) {
      setShowVatSearch(true)
    } else {
      setShowVatSearch(false)
    }
  }, [country])

  const [vatErrorModalOpen, setVatErrorModalOpen] = useState(false)
  const vatErrorModal = () => (
    <Modal
      isOpen={vatErrorModalOpen}
      isOpenHandler={setVatErrorModalOpen}
      wrapperClass="srs-modal-lg"
    >
      <div className="px-5 py-3">
        <h2 className="pb-3">{t("Your VAT number couldn't be validated")}</h2>
        <p>
          {t(
            "RecoTrace uses the VIES VAT validation service provided by the European Commission",
          )}
          .
        </p>
        <p>
          {t("Please try again at")}{" "}
          <a
            href="https://ec.europa.eu/taxation_customs/vies/vatRequest.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/taxation_customs/vies/vatRequest.html
          </a>{" "}
          {t("for more information")}.
        </p>
      </div>
    </Modal>
  )

  const checkVatNumber = useCallback(() => {
    const countryCode = country as BaseSelectedValue

    checkCompanyVatNumber({
      countryCode: countryCode.label,
      vatNumber,
    }).then(({ data, errors }) => {
      if (errors) {
        setVatErrorModalOpen(true)
        // handleToast(errors, 'error');
      } else {
        let isValid = false
        let isIssetInDB = false
        let newCompanyName = ""

        if (data) {
          const response = data as CompanyVatCheck
          console.log(response)
          if (response.valid) {
            isValid = true
            newCompanyName = response.name
          }
          if (response.vatNumber === "isset") {
            isIssetInDB = true
          }
        }

        setFieldValue("companyName", newCompanyName)

        if (isValid) {
          if (isIssetInDB) {
            const message = t(
              "Your VAT number is valid, but company with this VAT has already registered. Please contact RecoTrace admin",
            )
            handleToast([message], "error")
          } else {
            const message = t("Your VAT number is valid")
            handleToast([message])
          }
        } else {
          setVatErrorModalOpen(true)
        }
      }
    })
  }, [country, setFieldValue, t, vatNumber])

  return (
    <div className="d-flex">
      <Select
        name="country"
        options={COUNTRIES}
        value={country}
        handleOnChange={onSelectChange}
        wrapperClassName="srs-select-sm mb-0"
        placeholder=""
      />
      <div className="w-100 position-relative">
        <Input
          name="vatNumber"
          value={vatNumber}
          handleOnChange={onVatNumberChange}
          wrapperClassName="w-100 ml-1 mb-0"
          placeholder={t("Vat Number")}
          readOnly={!country && countryFromEU}
        />
        {showVatSearch && countryFromEU ? (
          <button
            type="button"
            className="btn btn-link btn-input-icon"
            onClick={checkVatNumber}
          >
            <i className="fas fa-search" />
          </button>
        ) : null}
      </div>
      <Input
        name="companyName"
        value={companyName}
        handleOnChange={handleChange}
        wrapperClassName="w-100 ml-1 mb-0"
        placeholder={t("Company Name")}
        readOnly={countryFromEU}
      />
      {vatErrorModal()}
    </div>
  )
}

export default RegisterPersonalFormCompany
