import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import Checkbox from "../../../components/Checkbox/Checkbox"
import Select, {
  SelectValue,
  BaseSelectedValue,
} from "../../../components/Select/Select"
import { USER_FIELDS_OF_ACTIVITY } from "../../users/types"
import { register } from "../actions"
import registerService from "../service/register"
import RegisterAssociationRepresentativeFormValidationShema from "./RegisterAssociationRepresentativeFormValidationShema"

interface CreateAssociationRepresentativeInput {
  sector: Nullable<BaseSelectedValue<number>>
  external: boolean
}

const RegisterAssociationRepresentativeForm = () => {
  const dispatch = useDispatch()
  const { role, email, name, password } = registerService.personalData

  const handleSubmit = (credentials: CreateAssociationRepresentativeInput) => {
    console.info("credentials", credentials)
    dispatch(
      register({
        roleId: role?.value || 0,
        email,
        name,
        password,
        sectorAssociation: {
          nameId: credentials?.sector?.value || 0,
          isExternal: credentials.external,
        },
      }),
    )
  }

  const formik = useFormik({
    initialValues: {
      sector: null,
      internal: true,
      external: false,
    },
    validationSchema: RegisterAssociationRepresentativeFormValidationShema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors, setFieldValue } = formik

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("sector", selectedOption)
  }

  const { t } = useTranslation()

  return (
    <form className="mb-5" onSubmit={formik.handleSubmit}>
      <h2 className="h4 mb-3">{t("Register Association Representative")}</h2>
      <Select
        name="sector"
        label={t("Field of activity")}
        options={USER_FIELDS_OF_ACTIVITY}
        value={formik.values.sector}
        handleOnChange={onSelectChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.sector && errors.sector}
      />
      <Checkbox
        label={t("Internal pre-consumer waste recycled")}
        name="internal"
        value={values.internal}
        isDisabled
        handleOnChange={formik.handleChange}
        checkboxWrapperClassName="mb-0"
        error={touched.internal && errors.internal}
      />
      <Checkbox
        label={t("Volume of recyclate brought from an external source")}
        name="external"
        value={values.external}
        handleOnChange={formik.handleChange}
        error={touched.external && errors.external}
      />
      <div className="d-flex justify-content-between">
        <Link
          className="btn btn-outline-primary rounded-bottom-left"
          to="/auth/register"
        >
          <i className="fas fa-arrow-left mr-2" /> {t("Back")}
        </Link>
        <button type="submit" className="btn btn-success rounded-bottom-right">
          {t("Register")} <i className="fas fa-arrow-right ml-3" />
        </button>
      </div>
    </form>
  )
}

export default RegisterAssociationRepresentativeForm
