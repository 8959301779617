import Checkbox from "components/Checkbox/Checkbox"
import { useFormik } from "formik"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import * as Yup from "yup"

import Input from "../../components/Input/Input"
import Modal from "../../components/Modal/Modal"
import useStateGetter from "../../hooks/useStateGetter"
import { history } from "../../utils/routes"
import {
  fetchCountries,
  createCountries,
  updateCountries,
  removeCountry,
} from "./actions"
import { Country } from "./types"

const CountryFormValidationShema = Yup.object().shape({
  code: Yup.string()
    .length(2, "Country code must have 2 characters")
    .required("Country code is required."),
  name: Yup.string().required("Country name is required."),
})

const Countries = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [id, setId] = useState(0)
  const dispatch = useDispatch()
  const countries = useStateGetter<Country[]>(["country", "countries"]) ?? []

  const handleOnSubmit = ({ code, name, isEu }: { code: string; name: string; isEu: boolean }) => {
    if (id === 0) {
      dispatch(createCountries({ code, name, isEu }))
    } else {
      dispatch(updateCountries({ id, code, name, isEu }))
    }
    setIsModalOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      isEu: false,
    },
    validationSchema: CountryFormValidationShema,
    onSubmit: handleOnSubmit,
  })

  const { values, handleChange, touched, errors, setFieldValue, handleSubmit } =
    formik

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  useEffect(() => {
    if (!isModalOpen) {
      setId(0)
      setFieldValue("code", "")
      setFieldValue("name", "")
      setFieldValue("isEu", false)
    }
  }, [isModalOpen, setFieldValue])

  const handleUpdateCountry = (country: Country) => {
    setId(country.id)
    setFieldValue("code", country.code)
    setFieldValue("name", country.name)
    setFieldValue("isEu", country.isEu)
    setIsModalOpen(true)
  }

  const handleDeleteCountry = (countryId: number) => {
    dispatch(removeCountry({ id: countryId }))
  }

  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="mb-5">{t("Manage countries")}</h1>
        <button
          type="button"
          className="btn btn-success rounded-top-right mb-3"
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          {t("Add new")}
          <i className="fas fa-plus ml-3" />
        </button>
      </div>

      <Modal isOpen={isModalOpen} isOpenHandler={setIsModalOpen}>
        <div className="modal-header">
          <h5 className="modal-title">
            <strong>New country</strong>
          </h5>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <Input
              label="Code"
              name="code"
              value={values.code}
              handleOnChange={handleChange}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              error={touched.code && errors.code}
            />
            <Input
              label="Name"
              name="name"
              value={values.name}
              handleOnChange={handleChange}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              error={touched.name && errors.name}
            />
            <Checkbox
              label={t(
                " Is part of EU",
              )}
              name="isEu"
              value={values.isEu}
              checkboxWrapperClassName="form-group row pt-2"
              labelClassName="col-sm-4"
              handleOnChange={handleChange}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary rounded-bottom-left"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="col-sm-11">Name</th>
            <th className="col-sm-1">Actions</th>
          </tr>
        </thead>
        <tbody>
          {countries.map((country) => {
            return (
              <tr key={country.id}>
                <td className="align-middle">{country.name}</td>
                <td className="text-center">
                  <div className="row">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-outline-warning btn-sm text-left"
                        style={{ width: "100%" }}
                        onClick={() => handleUpdateCountry(country)}
                      >
                        <i className="fas fa-edit" /> Edit
                      </button>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm text-left"
                        style={{ width: "100%" }}
                        onClick={() => {
                          handleDeleteCountry(country.id)
                        }}
                      >
                        <i className="fas fa-archive" /> Archive
                      </button>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-outline-info btn-sm text-left"
                        style={{ width: "100%" }}
                        onClick={() =>
                          history.push(`/countries-edit/${country.code}`, {
                            title: t("Translations"),
                          })
                        }
                      >
                        <i className="fas fa-flag" /> Translations
                      </button>
                    </div>
                  </div>
                  {/* <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      confirmMessage(() => {
                        handleDeleteCountry(country.id);
                      });
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </button> */}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-outline-primary rounded-bottom-left"
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left mr-2" /> Back
      </button>
    </>
  )
}

export default Countries
