import { restHelper } from "../../@common/service/api"
import { UpdateTreeDragAndDropInput, ID } from "../../@common/types"

export interface Material {
  id: number
  title: string
  visible?: boolean
  selectable?: boolean
  wasteType?: string
  children: Material[]
}

export interface MaterialGroup {
  id: number
  name: string
}

export interface PostMaterialInput {
  title: string
  parentId?: number
}

export interface PutMaterialTitleInput {
  id: number
  title: string
}

export interface DeleteMaterialInput {
  id: number
}

export const queries = {
  materials: `
    query materials(
      $onlyVisible: Boolean
    ) {
      materials(
        onlyVisible: $onlyVisible
      ) {
        id
        title
        visible
        selectable
        wasteType
        children {
          id
          title
          visible
          selectable
          parentId
          wasteType
          children {
            id
            title
            visible
            selectable
            parentId
            wasteType
            children {
              id
              title
              visible
              selectable
              parentId
              wasteType
            },
            children {
              id
              title
              visible
              selectable
              parentId
              wasteType
              children {
                id
                title
                visible
                selectable
                parentId
                wasteType
              }
            }
          }
        }
      }
    }
  `,
  materialsGroupList: `
    query {
      materialsGroupList {
        id,
        name
      }
    }
  `,
}

export const mutations = {
  materialCreate: `
    mutation materialCreate(
      $title: String!
      $parentId: Int
    ) {
      materialCreate(
        title: $title
        parentId: $parentId
      ) {
        id
      }
    } 
  `,
  materialShowHide: `
    mutation materialShowHide(
      $id: Int!
    ) {
      materialShowHide(
        id: $id
      ) {
        id
      }
    } 
  `,
  materialSelectable: `
    mutation materialSelectable(
      $id: Int!
    ) {
      materialSelectable(
        id: $id
      ) {
        id
      }
    } 
  `,
  materialTreeUpdate: `
    mutation materialTreeUpdate(
      $dragedElement: Int!
      $targetElement: Int!
      $isIndentElement: Boolean!
      $childrenIds: [Int]!
    ) {
      materialTreeUpdate(
        dragedElement: $dragedElement
        targetElement: $targetElement
        isIndentElement: $isIndentElement
        childrenIds: $childrenIds
      ) {
        id
      }
    } 
  `,
  materialUpdateTitle: `
    mutation materialUpdateTitle(
      $id: Int!
      $title: String!
    ) {
      materialUpdateTitle(
        id: $id
        title: $title
      ) {
        id
      }
    } 
  `,
  materialDelete: `
    mutation materialDelete($id: Int!) {
      materialDelete(id: $id)
    } 
  `,
}

export const getMaterials = restHelper<Material[], object>(queries, "materials")

export const getMaterialGroups = restHelper<MaterialGroup[], object>(
  queries,
  "materialsGroupList",
)

export const postMaterial = restHelper<Material, PostMaterialInput>(
  mutations,
  "materialCreate",
)

export const showHideMaterial = restHelper<boolean, ID>(
  mutations,
  "materialShowHide",
)

export const setSelectableMaterial = restHelper<boolean, ID>(
  mutations,
  "materialSelectable",
)

export const putMaterialTree = restHelper<Material, UpdateTreeDragAndDropInput>(
  mutations,
  "materialTreeUpdate",
)

export const putMaterialTitle = restHelper<Material, PutMaterialTitleInput>(
  mutations,
  "materialUpdateTitle",
)

export const deleteMaterial = restHelper<Material, DeleteMaterialInput>(
  mutations,
  "materialDelete",
)
