import { useState } from "react"

import { isLikeNumber } from "../utils/numbers"

type SetterFunction = (numberValue: number | string) => void

const useMaxNumberState = (
  initialNumber = 0,
  maxNumber = 100,
): [number, SetterFunction] => {
  const [number, setNumber] = useState(initialNumber)
  const setNumberValue = (numberValue: number | string) => {
    if (isLikeNumber(numberValue)) {
      const n = +numberValue
      if (n > maxNumber) {
        setNumber(maxNumber)
      } else if (n < 0) {
        setNumber(0)
      } else if (n <= maxNumber) {
        setNumber(n)
      }
    }
  }

  return [number, setNumberValue]
}

export default useMaxNumberState
