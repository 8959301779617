import axios from "axios"
import React, { useState, useEffect } from "react"

import { handleToast } from "utils/messages"

import { Contract } from "../../types"
import ContractComponent from "./ContractView"

interface ContractComponentProps {
  contractInfo: Contract | undefined
}

export default ({ contractInfo }: ContractComponentProps) => {
  const [downloadLink, setDownloadLink] = useState<string>("")
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [paymentStatus, setPaymentStatus] = useState<number | undefined>()
  const [paymentAmount, setPaymentAmount] = useState<string | undefined>()
  const [companyName, setCompanyName] = useState<string | undefined>()

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData()
    if (selectedFile) {
      formData.append("file", selectedFile)
    }
    if (contractInfo) {
      formData.append("contractId", contractInfo.id)
    }
    if (paymentAmount) {
      formData.append("paymentAmount", paymentAmount)
    }
    if (paymentStatus) {
      formData.append("paymentStatus", String(paymentStatus))
    }
    if (contractInfo?.company.id) {
      formData.append("companyId", String(contractInfo?.company.id))
    }

    axios({
      url: `contract/update`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: any) => {
        if (response.data.message) {
          handleToast([response.data.message])
        }
      })
      .catch((error: any) => {
        handleToast([error.message], "error")
      })
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files
    if (files) {
      setSelectedFile(files[0])
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentAmount(event.target.value)
  }

  const onStatusChange = (value: number) => {
    setPaymentStatus(value)
  }

  useEffect(() => {
    setDownloadLink(contractInfo?.downloadLink || "")
    setPaymentStatus(contractInfo?.paymentStatus)
    setPaymentAmount(String(contractInfo?.paymentAmount))
    setCompanyName(contractInfo?.company.name)
  }, [contractInfo])

  return (
    <ContractComponent
      {...{
        onSubmitForm,
        onFileChange,
        onInputChange,
        onStatusChange,
        downloadLink,
        paymentStatus,
        paymentAmount,
        selectedFile,
        companyName,
      }}
    />
  )
}
