import { UserRoles } from "./types"

export const USERS_PREFIX = "@USERS/"
export const FETCH_USERS = `${USERS_PREFIX}FETCH_USERS`
export const FETCH_USER = `${USERS_PREFIX}FETCH_USER`
export const FETCH_USER_COMPANIES = `${USERS_PREFIX}FETCH_USER_COMPANIES`

export const USER_ROLES_LIST = [
  {
    value: UserRoles.RECYCLER,
    label: "Recycling",
  },
  {
    value: UserRoles.RECYCLER_AND_CONVERTER,
    label: "Recycling and Converting",
  },
  {
    value: UserRoles.CONVERTER,
    label: "Converting",
  },
  {
    value: UserRoles.AUDITOR,
    label: "Auditor",
  },
  {
    value: UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
    label: "Recovinyl Regional Representative",
  },
  // {
  //   value: UserRoles.SECTOR_ASSOCIATION_REPRESENTATIVE,
  //   label: 'Sector Association Representative',
  // },
]

