import React from "react"

import { dateFormat } from "utils/dates"
import { getCountryFlagClassName } from "utils/strings"

import { COLLECTION_DATE_FORMAT } from "../../constants"
import { Submission } from "../../types"

export const outputTooltip = (submission: Submission, t: any) => {
  return (
    <table className="table table-hover text-left">
      <tbody>
        <tr>
          <th>
            <strong>{t("General")}</strong>
          </th>
        </tr>
        <tr>
          <th>{t("Submission Date")}:</th>
          <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
        </tr>
        <tr>
          <th>{t("Submission by")}:</th>
          <td>{submission.company.name}</td>
        </tr>
        <tr>
          <th>
            <strong>{t("Waste Information")}</strong>
          </th>
        </tr>
        <tr>
          <th>
            {t("Month sold (recycler) / Month used in production (converter)")}:
          </th>
          <td>
            {dateFormat(submission.collectionDate, COLLECTION_DATE_FORMAT)}
          </td>
        </tr>
        <tr>
          <th>
            {t(`Total Net Input ${submission.materialsGroup?.name} (kg)`)}:
          </th>
          <td>{submission.volumeGross}</td>
        </tr>
        <tr>
          <th>{t("Net Output EU")}:</th>
          {/* @ts-ignore */}
          <td>
            {(
              (submission.estimatedShareExportedInsideEu / 100) *
              submission.volumeNet
            ).toFixed(2)}
          </td>
        </tr>
        <tr>
          <th>{t("Net Output non EU")}:</th>
          {/* @ts-ignore */}
          <td>
            {(
              (submission.estimatedShareExportedOutsideEu / 100) *
              submission.volumeNet
            ).toFixed(2)}
          </td>
        </tr>
        <tr>
          <th>{t("Administration Number")}:</th>
          <td>{submission.administrationNumber}</td>
        </tr>
      </tbody>
    </table>
  )
}

export const inputTooltip = (
  submission: Submission,
  t: any,
  EuCountriesArray: Array<{}>,
) => {
  const countryFromEU =
    submission.country && submission.country.code
      ? EuCountriesArray.includes(submission.country.code)
      : ""

  const productName = submission.composition
    ? submission.composition.productName
        .replace(" - Post-Consumer", "")
        .replace(" - Pre-Consumer", "")
    : ""

  return (
    <table className="table table-hover text-left">
      <tbody>
        <tr>
          <th>
            <strong>{t("General")}</strong>
          </th>
        </tr>
        <tr>
          <th>{t("Submission Date")}:</th>
          <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
        </tr>
        <tr>
          <th>{t("Submission by")}:</th>
          <td>{submission.company.name}</td>
        </tr>
        <tr>
          <th>
            <strong>{t("Waste Information")}</strong>
          </th>
        </tr>
        <tr>
          <th>{t("Month Received")}:</th>
          <td>
            {dateFormat(submission.collectionDate, COLLECTION_DATE_FORMAT)}
          </td>
        </tr>
        <tr>
          <th>{t("Waste origin")}:</th>
          <td>{productName}</td>
        </tr>
        <tr>
          <th>{t("Waste received from")}:</th>
          <td>
            {(() => {
              if (countryFromEU) {
                return (
                  <span
                    className={getCountryFlagClassName(
                      submission.country?.code,
                    )}
                  />
                )
              }
              return <span>Non-EU</span>
            })()}
          </td>
        </tr>
        <tr>
          <th>{t("Total Incoming Waste (kg)")}:</th>
          <td>{submission.volumeGross}</td>
        </tr>
        <tr>
          <th>{t("Contamination")}:</th>
          <td>
            {Math.round(
              (1 - submission.volumeNet / submission.volumeGross) * 100,
            )}
          </td>
        </tr>
        <tr>
          <th>{t("Input into the final recycling operation (kg)")}:</th>
          <td>{submission.volumeNet}</td>
        </tr>
        <tr>
          <th>{t("Administration Number")}:</th>
          <td>{submission.administrationNumber}</td>
        </tr>
      </tbody>
    </table>
  )
}

export const converterOutputTooltip = (submission: Submission, t: any) => {
  return (
    <table className="table table-hover text-left">
      <tbody>
        <tr>
          <th>
            <strong>{t("General")}</strong>
          </th>
        </tr>
        <tr>
          <th>{t("Submission Date")}:</th>
          <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
        </tr>
        <tr>
          <th>{t("Submission by")}:</th>
          <td>{submission.company.name}</td>
        </tr>
        <tr>
          <th>
            <strong>{t("Waste Information")}</strong>
          </th>
        </tr>
        <tr>
          <th>{t("Polymer")}:</th>
          <td>{submission.materialsGroup?.name}</td>
        </tr>
        <tr>
          <th>{t("Waste type")}:</th>
          <td>{submission.wasteType}</td>
        </tr>
        <tr>
          <th>{t("Total weight recycled Plastic (kg) used")}:</th>
          <td>{submission.volumeNet}</td>
        </tr>
        <tr>
          <th>
            {t(
              "Estimated share of recycled plastic used in products exported outside EU and UK (%)",
            )}
            :
          </th>
          <td>{submission.estimatedShareExportedOutsideEu}</td>
        </tr>
        <tr>
          <th>
            {t(
              "Estimated share recycled plastic used in products exported to Norway and Switzerland (%)",
            )}
            :
          </th>
          <td>{submission.estimatedShareExportedInsideEu}</td>
        </tr>
        {submission.productsInFrance && (
          <tr>
            <th>
              {t(
                "Total percentage of recycled plastics used in products for the French market (%)",
              )}
              :
            </th>
            <td>{submission.totalPercentageOfRecycledPlasticsInFrance}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export const converterInputTooltip = (
  submission: Submission,
  t: any,
  submissionCountryFromEU: boolean,
) => {
  return (
    <table className="table table-hover text-left">
      <tbody>
        <tr>
          <th>
            <strong>{t("General")}</strong>
          </th>
        </tr>
        <tr>
          <th>{t("Submission Date")}:</th>
          <td>{submission.createdAt && dateFormat(submission.createdAt)}</td>
        </tr>
        <tr>
          <th>{t("Submission by")}:</th>
          <td>{submission.company.name}</td>
        </tr>
        <tr>
          <th>
            <strong>{t("Waste Information")}</strong>
          </th>
        </tr>
        <tr>
          <th>{t("Recycled plastic brought / received from")}:</th>
          <td>
            {(() => {
              if (submissionCountryFromEU) {
                return (
                  <span
                    className={getCountryFlagClassName(
                      submission.country?.code,
                    )}
                  />
                )
              }
              return <span>Non-EU</span>
            })()}
          </td>
        </tr>
        <tr>
          <th>{t("Polymer")}:</th>
          <td>{submission.materialsGroup?.name}</td>
        </tr>
        <tr>
          <th>{t("Waste type")}:</th>
          <td>{submission.wasteType}</td>
        </tr>
        <tr>
          <th>{t("Total recycled plastic (Kg)")}:</th>
          <td>{submission.volumeNet}</td>
        </tr>
        <tr>
          <th>{t("Total recycled plastic for audit (Kg)")}:</th>
          <td>{submission.totalDemonstratedDuringAudit}</td>
        </tr>
      </tbody>
    </table>
  )
}
