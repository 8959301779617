import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { handleToast } from "utils/messages"

import { getPlants } from "../../../companies/service/api"
import { Company, CompanyRole } from "../../../companies/types"

const FinishRecyclerPlantsList: React.FC<{
  handleStepChange: any
}> = ({ handleStepChange }) => {
  const [plants, setPlants] = useState<Company[]>()
  const { t } = useTranslation()

  useEffect(() => {
    getPlants({ all: true }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setPlants(data)
      }
    })
  }, [setPlants])

  return (
    <>
      <h2 className="mb-5">{t("Plants list")}:</h2>
      {plants &&
        plants.map((plant, index) => {
          return (
            <div key={plant.id}>
              <h3>
                {t("Plant")} {index + 1}
              </h3>
              <table
                className="table table-light"
                style={{ marginTop: "30px" }}
              >
                <thead>
                  <tr>
                    <th className="text-left">{t("Plant name & address")}</th>
                    <th className="text-left">{t("Vat number")}</th>
                    {plant.roleId === CompanyRole.RECYCLER ||
                    plant.roleId === CompanyRole.RECYCLER_AND_CONVERTER ||
                    plant.roleId === CompanyRole.CONVERTER ? (
                      <th className="text-left">{t("Activity / role")}</th>
                    ) : (
                      ""
                    )}
                    {plant.roleId === CompanyRole.RECYCLER ||
                    plant.roleId === CompanyRole.RECYCLER_AND_CONVERTER ||
                    plant.roleId === CompanyRole.CONVERTER ? (
                      <th className="text-left">{t("Polymer(s) processed")}</th>
                    ) : (
                      ""
                    )}
                    <th className="text-right">{t("Capacity")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="text-left"
                      style={{ verticalAlign: "middle" }}
                    >
                      <p>{plant.name}</p>
                      <p>{plant.address}</p>
                    </td>
                    <td
                      className="text-left"
                      style={{ verticalAlign: "middle" }}
                    >
                      {plant.vatNumber}
                    </td>
                    {plant.roleId === CompanyRole.RECYCLER ||
                    plant.roleId === CompanyRole.RECYCLER_AND_CONVERTER ||
                    plant.roleId === CompanyRole.CONVERTER ? (
                      <td
                        className="text-left"
                        style={{ verticalAlign: "middle" }}
                      >
                        {
                          {
                            [CompanyRole.RECYCLER]: "Recycling",
                            [CompanyRole.CONVERTER]: "Converting",
                            [CompanyRole.RECYCLER_AND_CONVERTER]:
                              "Recycling and converting",
                          }[plant.roleId]
                        }
                      </td>
                    ) : (
                      ""
                    )}
                    {plant.roleId === CompanyRole.RECYCLER ||
                    plant.roleId === CompanyRole.RECYCLER_AND_CONVERTER ||
                    plant.roleId === CompanyRole.CONVERTER ? (
                      <td
                        className="text-left"
                        style={{ verticalAlign: "middle" }}
                      >
                        <ul>
                          {plant.polymers?.map((polymer) => (
                            <li>{polymer.name}</li>
                          ))}
                        </ul>
                      </td>
                    ) : (
                      ""
                    )}
                    <td
                      className="text-right"
                      style={{ verticalAlign: "middle" }}
                    >
                      {plant.capacities}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        })}
      <div className="text-right">
        <button
          type="button"
          className="btn btn-success rounded-bottom-right"
          onClick={() => handleStepChange(3)}
        >
          {t("Next")} <i className="fas fa-arrow-right ml-3" />
        </button>
      </div>
    </>
  )
}

export default FinishRecyclerPlantsList
