import React, { Dispatch, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import ConfirmButton from "views/partials/ConfirmButton"

import { dateFormat, isDaysAfter } from "utils/dates"
import { handleToast } from "utils/messages"
import { history } from "utils/routes"
import { getCountryFlagClassName } from "utils/strings"

import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { EuCountriesArray } from "../../../../constants"
import { traverseToFindPath } from "../../../genericCompositions/utils"
import { fetchProducts } from "../../../products/actions"
import {
  CAN_DELETE_SUBMISSION_TO_DAYS,
  CAN_EDIT_SUBMISSION_TO_DAYS,
  COLLECTION_DATE_FORMAT,
} from "../../constants"
import { approveSubmission, deleteSubmission } from "../../service/api"
import { Submission, SubmissionStatus } from "../../types"
import {
  inputTooltip,
  outputTooltip,
  converterOutputTooltip,
  converterInputTooltip,
} from "./SubmissionTooltips"

interface Props {
  submissionType: string
  submissions: Submission[]
  onSortChanged(sortBy: string, sortDirection: string): void
  getSubmissions(): void
}
const SubmissionListTableBody: React.FC<Props> = ({
  submissions,
  getSubmissions,
  onSortChanged,
  submissionType = "",
}) => {
  const { t } = useTranslation()
  const { isAdmin, isRrr, isSectorAssociationRepresentative } =
    useUserFromToken()

  const [submissionOrder, setsSubmissionOrder] = useState("DESC")
  const [dateOrder, setDateOrder] = useState("DESC")
  const [wasteType, setWasteType] = useState("DESC")
  const dispatch = useDispatch()
  const products = useStateGetter<any[]>(["product", "products"]) ?? []

  useEffect(() => {
    dispatch(fetchProducts())
  }, [])

  const onSortClicked = (
    sortBy: string,
    lastSortDirection: string,
    stateHandler: Dispatch<string>,
  ) => {
    let sortDirection = "DESC"
    if (lastSortDirection === sortDirection) {
      sortDirection = "ASC"
    }
    stateHandler(sortDirection)
    onSortChanged(sortBy, sortDirection)
  }

  const canChangeSubmission = (createdAt: Date | undefined) => {
    // Nick asked to save this logic for future
    // if (isAdmin) {
    //   return true
    // }
    // if (createdAt && isDaysAfter(CAN_EDIT_SUBMISSION_TO_DAYS, createdAt)) {
    //   return false
    // }

    return true
  }

  const handleOnClickEdit = async (
    id: number | undefined,
    createdAt: Date | undefined,
    isInput: boolean,
    type: number,
  ) => {
    if (canChangeSubmission(createdAt)) {
      const redirectToEditForm = () => {
        if (type === 0) {
          if (isInput) {
            return "input"
          }
          return "output"
        }
        if (isInput) {
          return "inputConverter"
        }
        return "outputConverter"
      }

      if (id) {
        history.push(`/submissions/edit/${id}/${redirectToEditForm()}`, {
          title: t("Edit Submission"),
          id,
        })
      }
    } else {
      handleToast(
        [
          `Edit action is disabled because this submission was added more than ${CAN_EDIT_SUBMISSION_TO_DAYS} days ago.`,
        ],
        "warning",
      )
    }
  }

  const handleAcceptSubmission = async (id?: number) => {
    if (id) {
      const { errors } = await approveSubmission({ id })
      if (errors) {
        handleToast(errors, "error")
      } else {
        handleToast(["Submission accepted"])
        getSubmissions()
      }
    }
  }

  const handleDeleteSubmission = async (
    id: number | undefined,
    createdAt: Date | undefined,
  ) => {
    if (id && createdAt) {
      if (canChangeSubmission(createdAt)) {
        const { errors } = await deleteSubmission({ id })
        if (errors) {
          handleToast(errors, "error")
        } else {
          handleToast(["Submission deleted"])
          getSubmissions()
        }
      } else {
        handleToast(
          [
            `Delete action is disabled because this submission was added more than ${CAN_DELETE_SUBMISSION_TO_DAYS} days ago.`,
          ],
          "warning",
        )
      }
    }
  }

  const showConfirmButton = (submission: Submission) => {
    return submission.status === SubmissionStatus.CREATED && (isRrr || isAdmin)
  }

  const getProductTitle = (productId: number): string => {
    const productComposition = traverseToFindPath(products, productId)

    if (productComposition) {
      return productComposition.title
    }

    return "None"
  }

  return (
    <>
      <table className="table table-light table-bordered text-center">
        <thead>
          <tr>
            <th
              onClick={() =>
                onSortClicked(
                  "s.createdAt",
                  submissionOrder,
                  setsSubmissionOrder,
                )
              }
            >
              {t("Submission number")}
              <span
                className={`pl-2 fa text-primary cursor-pointer ${
                  submissionOrder === "ASC" ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th
              onClick={() =>
                onSortClicked("s.collectionDate", dateOrder, setDateOrder)
              }
            >
              {submissionType === "input" ||
              submissionType === "convertingInput"
                ? t("Reception date")
                : t("Delivery date")}
              <span
                className={`pl-2 fa text-primary cursor-pointer ${
                  dateOrder === "ASC" ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            {submissionType === "input" && (
              <>
                <th>{t("Waste origin")}</th>
                <th
                  onClick={() =>
                    onSortClicked("wasteType", wasteType, setWasteType)
                  }
                >
                  {t("Waste type")}
                  <span
                    className={`pl-2 fa text-primary cursor-pointer ${
                      wasteType === "ASC" ? "fa-angle-down" : "fa-angle-up"
                    }`}
                  />
                </th>
                <th>{t("Waste received from")}</th>
                <th>{t("Plant Country")}</th>
                <th>{t("Net input")}</th>
              </>
            )}
            {submissionType === "output" && (
              <>
                <th>{t("Polymer")}</th>
                <th>{t("Plant Country")}</th>
                <th>{t("Net Output EU + UK")} </th>
                <th>{t("Net Output non-EU")} </th>
              </>
            )}
            {submissionType === "convertingInput" && (
              <>
                <th>{t("Polymer")}</th>
                <th>{t("Waste type")}</th>
                <th>{t("Recycled plastic from")}</th>
                <th>{t("Total")}</th>
              </>
            )}
            {submissionType === "convertingOutput" && (
              <>
                <th>{t("Polymer")}</th>
                <th>{t("Used In")}</th>
                <th>{t("Total")}</th>
              </>
            )}
            <th>{t("Status")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {submissions.length ? (
            submissions.map((submission) => {
              const submissionCountryFromEU = submission.country
                ? EuCountriesArray.includes(submission.country.code)
                : false
              const companyCountryFromEU = submission.company.country
                ? EuCountriesArray.includes(submission.company.country.code)
                : false

              if (submission.wasteType === "Post") {
                submission.wasteType = "Post-C"
              } else if (submission.wasteType === "Pre") {
                submission.wasteType = "Pre-C"
              }
              return (
                <tr key={submission.id}>
                  <td className="align-middle">{submission.yearlyNumber}</td>
                  <td className="align-middle">
                    {dateFormat(
                      submission.collectionDate,
                      COLLECTION_DATE_FORMAT,
                    )}
                  </td>
                  {submissionType === "input" && (
                    <>
                      <td className="align-middle">
                        {submission.composition
                          ? submission.composition.productName
                              .replace(" - Post-Consumer", "")
                              .replace(" - Pre-Consumer", "")
                          : ""}
                      </td>
                      <td className="align-middle">
                        {submission.wasteType ? submission.wasteType : "None"}
                      </td>
                      <td className="align-middle">
                        {(() => {
                          if (submissionCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">
                        {(() => {
                          if (companyCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.company?.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">{submission.volumeNet}</td>
                    </>
                  )}
                  {submissionType === "output" && (
                    <>
                      <td className="align-middle">
                        {submission.materialsGroup?.name
                          ? submission.materialsGroup?.name
                          : "-"}
                      </td>
                      <td className="align-middle">
                        {(() => {
                          if (companyCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.company?.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">{submission.netOutputEU}</td>
                      <td className="align-middle">
                        {submission.netOutputNonEU}
                      </td>
                    </>
                  )}
                  {submissionType === "convertingInput" && (
                    <>
                      <td className="align-middle">
                        {submission.materialsGroup?.name
                          ? submission.materialsGroup?.name
                          : "-"}
                      </td>
                      <td className="align-middle">
                        {submission.wasteType ? submission.wasteType : "None"}
                      </td>

                      {submission.isMaterialFromOutsideEurope
                        ? (() => {
                            if (submissionCountryFromEU) {
                              return (
                                <td className="align-middle p-0">
                                  <div className="col-12 mb-3">
                                    <span
                                      className={getCountryFlagClassName(
                                        submission.country?.code,
                                      )}
                                    />
                                  </div>
                                  <hr />
                                  <div className="col-12 mp-3">Non-EU</div>
                                </td>
                              )
                            }
                            return <td className="align-middle">Non-EU</td>
                          })()
                        : (() => {
                            if (submissionCountryFromEU) {
                              return (
                                <td className="align-middle">
                                  <span
                                    className={getCountryFlagClassName(
                                      submission.country?.code,
                                    )}
                                  />
                                </td>
                              )
                            }
                          })()}

                      {submission.isMaterialFromOutsideEurope ? (
                        (() => {
                          if (submission?.country && submissionCountryFromEU) {
                            return (
                              <td className="align-middle p-0">
                                <div
                                  className="col-12"
                                  style={{ marginBottom: "1.2rem" }}
                                >
                                  {submission.totalDemonstratedDuringAudit}
                                </div>
                                <hr />
                                <div className="col-12 mp-3">
                                  {submission.volumeNet -
                                    submission.totalDemonstratedDuringAudit}
                                </div>
                              </td>
                            )
                          }
                          return (
                            <td className="align-middle">
                              {submission.volumeNet}
                            </td>
                          )
                        })()
                      ) : (
                        <td className="align-middle">{submission.volumeNet}</td>
                      )}
                    </>
                  )}
                  {submissionType === "convertingOutput" && (
                    <>
                      <td className="align-middle">
                        {submission.materialsGroup?.name
                          ? submission.materialsGroup?.name
                          : "-"}
                      </td>
                      <td className="align-middle">
                        {getProductTitle(Number(submission.productId))}
                      </td>
                      <td className="align-middle">{submission.volumeNet}</td>
                    </>
                  )}
                  <td className="align-middle">
                    {submission.status === t("SENT") ||
                    submission.status === t("AUDIT_IN_PROGRESS")
                      ? t("PENDING")
                      : t(submission.status)}
                  </td>
                  <td className="align-middle">
                    <div className="text-primary">
                      <div className="row">
                        <div className="col-12">
                          <ReactTooltip
                            id={`tooltip_${submission.id}`}
                            backgroundColor="white"
                            textColor="black"
                          >
                            {submissionType === "input" &&
                              inputTooltip(submission, t, EuCountriesArray)}
                            {submissionType === "output" &&
                              outputTooltip(submission, t)}
                            {submissionType === "convertingOutput" &&
                              converterOutputTooltip(submission, t)}
                            {submissionType === "convertingInput" &&
                              converterInputTooltip(
                                submission,
                                t,
                                submissionCountryFromEU,
                              )}
                          </ReactTooltip>
                          <Link
                            to={{
                              pathname: `submissions/show/${submission.id}/${submissionType}`,
                              state: {
                                title: t("Submission Detail"),
                              },
                            }}
                            style={{ width: "100%" }}
                            key={submission.id}
                            data-tip=""
                            data-for={`tooltip_${submission.id}`}
                          >
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-sm text-left"
                              style={{ width: "100%" }}
                            >
                              <i className="fas fa-eye btn-icon mr-1" />{" "}
                              {t("View submission")}
                            </button>
                          </Link>
                        </div>
                      </div>
                      {!isSectorAssociationRepresentative && (
                        <div className="row mt-1">
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-outline-warning btn-sm text-left"
                              style={{ width: "100%" }}
                              onClick={() =>
                                handleOnClickEdit(
                                  submission.id,
                                  submission.createdAt,
                                  submission.isInput,
                                  submission.type,
                                )
                              }
                            >
                              <i className="fas fa-pen btn-icon mr-1" />{" "}
                              {t("Edit")}
                            </button>
                          </div>
                        </div>
                      )}
                      {!isSectorAssociationRepresentative && (
                        <div className="row mt-1">
                          <div className="col-12">
                            {showConfirmButton(submission) && (
                              <button
                                type="button"
                                className="btn btn-outline-success btn-sm text-left"
                                style={{ width: "100%" }}
                                onClick={() =>
                                  handleAcceptSubmission(submission.id)
                                }
                              >
                                <i className="fas fa-check btn-icon mr-1" />{" "}
                                {t("Send submission")}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {!isSectorAssociationRepresentative && (
                        <div className="row mt-1">
                          <div className="col-12">
                            <ConfirmButton
                              title="Remove"
                              text={t(
                                "Are you sure you wish to remove this submission?",
                              )}
                              confirmText={t("Remove")}
                              icon="trash"
                              className="btn-sm text-left w-100 btn-outline-danger"
                              confirmClassName="btn-outline-danger"
                              danger
                              onConfirm={() =>
                                handleDeleteSubmission(
                                  submission.id,
                                  submission.createdAt,
                                )
                              }
                            >
                              <i className="fas fa-trash btn-icon mr-1" />
                              {t("Remove")}
                            </ConfirmButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={submissionType === "input" ? 9 : 7}>
                There is no submitted data to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default SubmissionListTableBody
