import UploadAuditMultiple from "modules/Audit/containers/auditOptions/UploadAuditMultiple"
import { auditCreate } from "modules/Audit/service/api"
import { getCompany, getPlants } from "modules/companies/service/api"
import { SUBMISSION_DATE_FORMAT } from "modules/submissions/constants"
import { getCompanySubmissions } from "modules/submissions/service/api"
import { getUser } from "modules/users/service/api"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { useQuery } from "react-query"
import { Option } from "react-select/src/filters"
import { ToastContainer } from "react-toastify"
import ConfirmButton from "views/partials/ConfirmButton"

import Checkbox from "components/Checkbox/Checkbox"
import Select, { mapToOptions } from "components/Select/Select"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"

import { AuditConvertingActivityTable } from "./components/AuditConvertingActivityTable"
import { AuditRecyclingActivityTable } from "./components/AuditRecyclingActivityTable"
import { activityTypes, auditTypes, wasteTypes } from "./constants"
import { convertingActivity, recyclingActivity } from "./types"

const SELECTED_PLANTS_LOCAL_STORAGE_KEY = "newAuditPageSelectedPlants"

export const AdditionalAudit = () => {
  const { t } = useTranslation()
  const [filterType, setFilterType] = useState<any>(activityTypes[0])
  const [auditType, setAuditType] = useState<any>(auditTypes[0])
  // const [wasteType, setWasteType] = useState<any>(wasteTypes[0])

  // Check if PVC only

  const { data: materials } = useQuery("materials", getCompany)

  const isPvcOnly = useMemo(() => {
    return (
      materials?.data?.polymers?.length === 1 &&
      materials.data.polymers[0].name === "PVC"
    )
  }, [materials])

  // date filtering start
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").date(1).toDate(),
  )
  const [endDate, setEndDate] = useState(new Date())
  // date filtering end

  // plants filtering start
  const [selectedPlants, setSelectedPlants] = useState<Option[]>([])
  const [plantsOptions, setPlantsOptions] = useState<Option[]>([])

  useEffect(() => {
    history.push("/audit-main", {
      title: t("Audits"),
    })

    getPlants().then((d) => {
      const storedSelectedPlants = window.localStorage.getItem(
        SELECTED_PLANTS_LOCAL_STORAGE_KEY,
      )

      const fetchedPlants = d?.data ?? []

      setSelectedPlants(
        storedSelectedPlants
          ? JSON.parse(storedSelectedPlants)
          : mapToOptions(fetchedPlants),
      )
      setPlantsOptions(mapToOptions(fetchedPlants) as Option[])
    })
  }, [])

  useEffect(() => {
    if (selectedPlants.length > 0) {
      window.localStorage.setItem(
        SELECTED_PLANTS_LOCAL_STORAGE_KEY,
        JSON.stringify(selectedPlants),
      )
    }
  }, [selectedPlants])
  // plants filtering ends

  // general fetching start
  const { data: submissions, refetch } = useQuery("auditData", () =>
    getCompanySubmissions({
      dateStart: `${startDate.getFullYear()}/${startDate.getMonth() + 1}`,
      dateEnd: `${endDate.getFullYear()}/${endDate.getMonth() + 1}`,
      companyIds: selectedPlants?.map((p: any) => p.value),
    }),
  )

  useEffect(() => {
    refetch()
  }, [startDate, endDate, selectedPlants])
  // general fetching end

  // file upload start
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
  // file upload end

  // user start
  const { data: user } = useQuery("getUser", getUser, {
    refetchOnWindowFocus: false,
  })
  // user end

  // form submit start
  const [checkboxConfirmed, setCheckboxConfirmed] = useState(false)

  const handleSubmit = () => {
    if (!checkboxConfirmed)
      return handleToast([`Checkbox is required`], "errors")
    if (!uploadedFiles.length)
      return handleToast([`Please upload the file`], "errors")

    let submissionIds: any = []
    if (filterType.value === "all") {
      submissionIds = submissions?.data?.recycle.submissionIds?.concat(
        submissions.data.convert.submissionIds,
      )
    } else {
      // @ts-ignore
      submissionIds = submissions?.data[filterType.value]?.submissionIds
    }

    const credentials = {
      companyId: user?.data?.companies[0]?.id ?? 0,
      submissions: submissionIds,
      createdAt: new Date(startDate),
      finishedAt: new Date(endDate),
      auditType: auditType && auditType.value,
      draft: false,
      files: uploadedFiles,
      provider: "polyreceu",
    }

    if (!credentials.submissions.length)
      return handleToast(["You cant submit empty audit"], "warning")

    auditCreate(credentials)
      .then((res) => {
        if (res?.data?.id) {
          window.localStorage.removeItem(SELECTED_PLANTS_LOCAL_STORAGE_KEY)
          window.location.search = "success"
        } else {
          if (res.errors) handleToast([res.errors[0].message], "error")
        }
      })
      .catch((errors) => {
        handleToast(["Cannot create audit"], "error")
      })
  }
  // form submit end

  // check if success param exist
  useEffect(() => {
    if (window.location.search.includes("success"))
      handleToast(
        [
          "Thank you! Your data has been marked as audited and will be counted towards the Circular Plastics Alliance goals",
        ],
        "success",
      )
  }, [])

  return (
    <div>
      <ToastContainer />
      <div className="row mb-4">
        <div className="col-4">
          <label className="form-label" htmlFor="">
            {t("From")}
          </label>
          <DatePicker
            name="startDate"
            className="form-control"
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            selectsStart
            showMonthYearPicker
            startDate={startDate}
            maxDate={endDate}
            dateFormat={SUBMISSION_DATE_FORMAT}
          />
        </div>
        <div className="col-4">
          <label className="form-label" htmlFor="">
            {t("To")}
          </label>
          <DatePicker
            name="endDate"
            className="form-control"
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            selectsEnd
            showMonthYearPicker
            minDate={moment(startDate).add(1, "M").toDate()}
            dateFormat={SUBMISSION_DATE_FORMAT}
          />
        </div>
        <div className="col-4 multi-select-wrapper">
          <label className="form-label" htmlFor="plants">
            Plant(s)
          </label>
          <MultiSelect
            options={plantsOptions}
            value={selectedPlants}
            labelledBy={"plants"}
            onChange={(e: any) => {
              setSelectedPlants(e)
            }}
          />
        </div>
        <div className="col-4">
          <label className="form-label" htmlFor="">
            {t("Activity type")}
          </label>
          <Select
            name="activityType"
            options={activityTypes}
            value={filterType}
            handleOnChange={(s) => setFilterType(s)}
          />
        </div>
        {/* <div className="col-4">
          <label className="form-label" htmlFor="">
            {t("Waste type")}
          </label>
          <Select
            name="wasteType"
            options={wasteTypes}
            value={wasteType}
            handleOnChange={(s) => setWasteType(s)}
          />
        </div> */}
        <div className="col-4">
          <label className="form-label" htmlFor="">
            {t("Audit type")}
          </label>
          <Select
            name="status"
            options={auditTypes}
            value={auditType}
            handleOnChange={(s) => setAuditType(s)}
          />
        </div>
      </div>
      {filterType.value === "recycle" || filterType.value === "all" ? (
        <AuditRecyclingActivityTable
          tableData={
            // submissions?.data?.recycle?.materials.map((m) => {
            //   if (wasteType.value !== "all") {
            //     if (wasteType.value === "pre-c") {
            //       return {
            //         ...m,
            //         eu: {
            //           ...m.eu,
            //           postConsumer: null,
            //         },
            //         nonEu: {
            //           ...m.nonEu,
            //           postConsumer: null,
            //         },
            //       }
            //     }
            //     if (wasteType.value === "post-c") {
            //       return {
            //         ...m,
            //         eu: {
            //           ...m.eu,
            //           preConsumer: null,
            //         },
            //         nonEu: {
            //           ...m.nonEu,
            //           preConsumer: null,
            //         },
            //       }
            //     }
            //   }

            //   return m
            // })
            submissions?.data?.recycle?.materials as Array<recyclingActivity>
          }
        />
      ) : null}
      {filterType.value === "convert" || filterType.value === "all" ? (
        <AuditConvertingActivityTable
          tableData={
            submissions?.data?.convert?.materials as Array<convertingActivity>
          }
        />
      ) : null}
      <Checkbox
        name={"confirm"}
        label="I declare that the data displayed in the table above matches the one in the auditing report and has been audited"
        value={checkboxConfirmed}
        handleOnChange={(e) => setCheckboxConfirmed(e.target.checked)}
      />
      <UploadAuditMultiple setFiles={setUploadedFiles} files={uploadedFiles} />
      <div className="modal-footer">
        {!isPvcOnly ? (
          <ConfirmButton
            className="float-right btn btn-success rounded-bottom-right"
            onConfirm={handleSubmit}
            text="Submitting this data is a final and an irreversible action. Please make sure all information on the page is correct. No changes will be possible after the submission. Are you sure you want to submit?"
            title="Confirm submit"
            confirmText="Yes, submit"
            confirmClassName="bg-success text-white"
            isCancelDanger
          >
            {t("Submit")}
          </ConfirmButton>
        ) : (
          <button
            className="float-right btn btn-success rounded-bottom-right"
            disabled
          >
            Submit
          </button>
        )}
      </div>
    </div>
  )
}
