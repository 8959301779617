import { getUser } from "modules/users/service/api"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import assignEndApplications from "../assets/images/dashboardicons/assignEndApplications.svg"
import audits from "../assets/images/dashboardicons/audits.svg"
import dashboardIcon from "../assets/images/dashboardicons/dashboard2.svg"
import manageAuditorsAndRRRs from "../assets/images/dashboardicons/manageAuditorsAndRRRs.svg"
import manageCountries from "../assets/images/dashboardicons/manageCountries.svg"
import manageMaterials from "../assets/images/dashboardicons/manageMaterials.svg"
import managePages from "../assets/images/dashboardicons/managePages.svg"
import managePlant from "../assets/images/dashboardicons/managePlant.svg"
import manageSubmittedData from "../assets/images/dashboardicons/manageSubmittedData.svg"
import manageUsers from "../assets/images/dashboardicons/manageUsers.svg"
import useUserFromToken from "../hooks/useUserFromToken"
import { getCompany, getPlants } from "../modules/companies/service/api"
import { Company } from "../modules/companies/types"
import "./DashboardLinks.scss"
import useStateGetter from "hooks/useStateGetter"
import { useDispatch } from "react-redux"
import useApplicationConfig from "hooks/useApplicationConfig"

const DashboardLinks = () => {
  const {
    name,
    isRrr,
    isAuditor,
    isAdmin,
    isRecycler,
    isSectorAssociationRepresentative,
    isRecyclerAndConverter,
    isConverter,
  } = useUserFromToken()
  const { t } = useTranslation()
  const [plants, setPlants] = useState<Company[]>([])
  const { data: user } = useQuery("user", getUser)

  const { data: materials, isLoading } = useQuery("materials", getCompany)

  const configApplicationEnd = useApplicationConfig()

  const isPvcOnly = () => {
    return (
      materials?.data?.polymers?.length === 1 &&
      materials.data.polymers[0].name === "PVC" &&
      isAuditor === false
    )
  }

  const columnsNumber = useCallback(() => {
    if (isAdmin) {
      return "col-3"
    }
    return "col-4"
  }, [isAdmin])

  const fetchPlants = () => {
    getPlants().then((response) => {
      if (response.data) {
        setPlants(response.data as Company[])
      }
    })
  }

  // fetch plants at start
  useEffect(() => {
    columnsNumber()
    fetchPlants()
  }, [columnsNumber])

  return (
    <div className="srs-dashboard-links mt-5">
      <div className="mb-5">
        <h2 className="mb-5">
          {t("Welcome")} {name}
        </h2>
        <div className="row">
          {/* {isSectorAssociationRepresentative && (
            <>
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link to={{
                  pathname: "/submissions/new",
                  state: {
                    title: t("Submit volume"),
                  },
                }}>
                  <div className="card-body">
                    <h5 className="card-title">{t('Submit volume')}</h5>
                    <img src={dashboardIcon} className="card-img-top" alt="" />
                  </div>
                </Link>
              </div>
              </div>
              <div className={columnsNumber()}>
                <div className="card text-center">
                    <Link to={{
                      pathname: "/submissions/",
                      state: {
                        title: t("Assign volume to region"),
                      },
                    }}>
                    <div className="card-body">
                      <h5 className="card-title">
                        {t('Assign volume to region')}
                      </h5>
                      <img src={europeIcon} className="card-img-top" alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )} */}

          {/* {(isConverter) && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link to={{
                    pathname: "/submissions",
                    state: {
                      title: t("Recycling Activity"),
                    },
                  }}>
                  <div className="card-body">
                    <div className="icon-area"><img
                      src={manageSubmittedData}
                      className="card-img-top"
                      alt=""
                    /></div>
                    <h5 className="card-title">{t('Recycling Activity')}</h5>

                  </div>
                </Link>
              </div>
            </div>
          )} */}

          {(isRecyclerAndConverter ||
            isRecycler ||
            isRrr ||
            isAdmin ||
            isSectorAssociationRepresentative) && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/submissions/new",
                    state: {
                      title: t("Recycling Activity"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img
                        src={manageSubmittedData}
                        className="card-img-top"
                        alt=""
                      />
                    </div>
                    <h5 className="card-title">{t("Recycling Activity")}</h5>
                  </div>
                </Link>
              </div>
              <ReactTooltip />
            </div>
          )}

          {(isConverter || isRecyclerAndConverter || isAdmin || isRrr) && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/submissions/new-converting",
                    state: {
                      title: t("Converting Activity"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img
                        src={manageSubmittedData}
                        className="card-img-top"
                        alt=""
                      />
                    </div>
                    <h5 className="card-title">{t("Converting Activity")}</h5>
                  </div>
                </Link>
              </div>
              <ReactTooltip />
            </div>
          )}

          {(isAdmin ||
            isRrr ||
            isRecycler ||
            isConverter ||
            isAuditor ||
            isRecyclerAndConverter ||
            isSectorAssociationRepresentative) && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/submissions",
                    state: {
                      title: t("Manage submitted data"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img
                        src={manageSubmittedData}
                        className="card-img-top"
                        alt=""
                      />
                    </div>
                    <h5 className="card-title">{t("Manage submitted data")}</h5>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {/* {(isAuditor || isAdmin || isRrr) && (
            <>
              <div className="card text-center">
                <Link to="/audits">
                  <div className="card-body">
                    <h5 className="card-title">{t('Audits')}</h5>
                    <img
                      src={manageSubmissionsIcon}
                      className="card-img-top"
                      alt=""
                    />
                  </div>
                </Link>
              </div>
            </>
          )} */}

          {!isAuditor && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/dashboards",
                    state: {
                      title: t("Dashboards"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img
                        src={dashboardIcon}
                        className="card-img-top"
                        alt=""
                      />
                    </div>
                    <h5 className="card-title">{t("Dashboards")}</h5>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(isRrr || isAuditor || isSectorAssociationRepresentative) && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/companies",
                    state: {
                      title: t("Manage companies"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img src={managePlant} className="card-img-top" alt="" />
                    </div>
                    <h5 className="card-title">{t("Manage companies")}</h5>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(isAdmin || isRecycler || isRecyclerAndConverter || isConverter) && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/plants",
                    state: {
                      title:
                        plants && plants.length > 1
                          ? t("Manage plants")
                          : t("Manage plant"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img src={managePlant} className="card-img-top" alt="" />
                    </div>
                    <h5 className="card-title">
                      {plants && plants.length > 1
                        ? t("Manage plants")
                        : t("Manage plant")}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(isRecycler || isRrr) && configApplicationEnd?.registrationOpen && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/applications",
                    state: {
                      title: t("Assign End Applications"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img
                        src={assignEndApplications}
                        className="card-img-top"
                        alt=""
                      />
                    </div>
                    <h5 className="card-title">
                      {t("Assign End Applications")}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(isRrr || isAuditor) && (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/plants-selection",
                    state: {
                      title: t("Plants selection"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img src={audits} className="card-img-top" alt="" />
                    </div>
                    <h5 className="card-title">{t("Recovinyl Audits")}</h5>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {!isPvcOnly() && !isLoading && !isAdmin ? (
            <div className={columnsNumber()}>
              <div className="card text-center">
                <Link
                  to={{
                    pathname: "/audit-main",
                    state: {
                      title: t("Audits"),
                    },
                  }}
                >
                  <div className="card-body">
                    <div className="icon-area">
                      <img src={audits} className="card-img-top" alt="" />
                    </div>
                    <h5 className="card-title">{t("Audits")}</h5>
                  </div>
                </Link>
              </div>
            </div>
          ) : null}

          {isAdmin && (
            <div className="dashboard-admin-section row mt-5">
              <div className={columnsNumber()}>
                <div className="card text-center card-admin">
                  <Link
                    to={{
                      pathname: "/users/manage",
                      state: {
                        title: t("Manage users"),
                      },
                    }}
                  >
                    <div className="card-body">
                      <div className="icon-area">
                        <img
                          src={manageUsers}
                          className="card-img-top"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">{t("Manage users")}</h5>
                      <h6>Administrative</h6>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={columnsNumber()}>
                <div className="card text-center card-admin">
                  <Link
                    to={{
                      pathname: "/companies-admin",
                      state: {
                        title: t("Manage companies"),
                      },
                    }}
                  >
                    <div className="card-body">
                      <div className="icon-area">
                        <img
                          src={managePlant}
                          className="card-img-top"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">{t("Manage companies")}</h5>
                      <h6>Administrative</h6>
                    </div>
                  </Link>
                </div>
              </div>
              {user?.data?.company?.id == 429 && (
                <div className={columnsNumber()}>
                  <div className="card text-center card-admin">
                    <Link
                      to={{
                        pathname: "/users/manage/auditors",
                        state: {
                          title: t("Manage RRR, Auditors and SAR"),
                        },
                      }}
                    >
                      <div className="card-body">
                        <div className="icon-area">
                          <img
                            src={manageAuditorsAndRRRs}
                            className="card-img-top"
                            alt=""
                          />
                        </div>
                        <h5 className="card-title">
                          {t("Manage RRR, Auditors and SAR")}
                        </h5>
                        <h6>Administrative</h6>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              <div className={columnsNumber()}>
                <div className="card text-center card-admin">
                  <Link
                    to={{
                      pathname: "/countries",
                      state: {
                        title: t("Manage countries"),
                      },
                    }}
                  >
                    <div className="card-body">
                      <div className="icon-area">
                        <img
                          src={manageCountries}
                          className="card-img-top"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">{t("Manage countries")}</h5>
                      <h6>Administrative</h6>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={columnsNumber()}>
                <div className="card text-center card-admin">
                  <Link
                    to={{
                      pathname: "/materials",
                      state: {
                        title: t("Manage waste origin"),
                      },
                    }}
                  >
                    <div className="card-body">
                      <div className="icon-area">
                        <img
                          src={manageMaterials}
                          className="card-img-top"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">{t("Manage waste origin")}</h5>
                      <h6>Administrative</h6>
                    </div>
                  </Link>
                </div>
              </div>

              <div className={columnsNumber()}>
                <div className="card text-center card-admin">
                  <Link
                    to={{
                      pathname: "/products/manage",
                      state: {
                        title: t("Manage used in"),
                      },
                    }}
                  >
                    <div className="card-body">
                      <div className="icon-area">
                        <img
                          src={manageMaterials}
                          className="card-img-top"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">{t("Manage used in")}</h5>
                      <h6>Administrative</h6>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={columnsNumber()}>
                <div className="card text-center card-admin">
                  <Link
                    to={{
                      pathname: "/notifications",
                      state: {
                        title: t("Manage Notifications"),
                      },
                    }}
                  >
                    <div className="card-body">
                      <div className="icon-area">
                        <img
                          src={manageMaterials}
                          className="card-img-top"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">{t("Manage notifications")}</h5>
                      <h6>Administrative</h6>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DashboardLinks
