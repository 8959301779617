import React, { useEffect, useRef, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

// import './UserSelect.scss';
import useOnClickOutside from "../../hooks/useOnClickOutside"
import useStateGetter from "../../hooks/useStateGetter"
import { setTokensAction } from "../../modules/auth/actions"
import { fetchUsers } from "../../modules/users/actions"
import { userEmulate, getUsers } from "../../modules/users/service/api"
import { User } from "../../modules/users/types"
import { handleToast } from "../../utils/messages"
import { windowStorage } from "../../utils/windowStorage"

const UserSelect = () => {
  const ref = useRef<HTMLHeadingElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const usersList = useStateGetter<User[]>(["user", "users"])
  useOnClickOutside(ref, () => setIsOpen(false))

  const handleChangeUser = (userId: string) => {
    userEmulate({ id: parseInt(userId, 10) }).then((response) => {
      const { data, errors } = response

      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        dispatch(
          setTokensAction({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          }),
        )
        windowStorage.clear(false)
        windowStorage.set("emulate", "true")
        windowStorage.set("returnToken", data.returnToken)
        window.location.reload()
      }
    })
  }

  // useEffect(() => {
  //   dispatch(fetchUsers());
  // }, [dispatch]);

  const { t } = useTranslation()

  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [users, setUsers] = useState<User[]>([])

  const runSubmitSearchForm = useCallback(() => {
    getUsers({
      name: searchName,
      email: searchName,
      allowedToImpersonate: true,
    }).then(({ data }) => {
      // if (errors) {
      // handleToast(errors, 'error');
      // }
      if (data) {
        setUsers(data as User[])
      }
    })
  }, [searchName])

  const fetchUsers = useCallback(() => {
    if (searchName || searchEmail) {
      runSubmitSearchForm()
    }
  }, [runSubmitSearchForm, searchEmail, searchName])

  useEffect(() => {
    const searchTimeout = setTimeout(() => fetchUsers(), 1000)
    return () => clearTimeout(searchTimeout)
  }, [fetchUsers])

  useEffect(() => {
    getUsers({ allowedToImpersonate: true }).then(({ data }) => {
      if (data) setUsers(data ?? [])
    })
  }, [dispatch])

  return (
    <div className="srs-dropdown ml-4" ref={ref}>
      <div
        className={`srs-dropdown-inner ${
          isOpen ? "srs-dropdown-inner-open" : ""
        }`}
      >
        <button
          type="button"
          className="btn srs-dropdown-btn"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{t("View as")}...</span>
          <i
            className={`srs-dropdown-icon fas ${
              !isOpen ? "fa-chevron-down" : "fa-chevron-up"
            }`}
          />
        </button>
        {isOpen ? (
          <div className="srs-dropdown-list">
            <form className="form-inline mb-12">
              <div className="form-group col-md-12">
                <input
                  name="name"
                  className="form-control w-100"
                  type="text"
                  placeholder={t("Search")}
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </form>
            {users?.map((user: User) => {
              if (user.blocked === null) {
                return (
                  <button
                    key={user.id}
                    type="button"
                    className="btn srs-dropdown-btn srs-dropdown-btn-inner py-1"
                    onClick={() => {
                      handleChangeUser(user.id)
                    }}
                  >
                    <div className="srs-dropdown-btn-inner-title">
                      {user.name}
                    </div>
                    <div className="srs-dropdown-btn-inner-sub">
                      {user.email}
                    </div>
                  </button>
                )
              }
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default UserSelect
