import { TFunctionResult } from "i18next"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import NumberFormat, { NumberFormatValues } from "react-number-format"

interface SubmissionGrossOutpuyInput {
  id: string
  className: any
  grossValue: any
  setGrossValueFunction: any
  disabled: boolean
  error?: string
  label?: TFunctionResult
  infoTooltip?: string | boolean | TFunctionResult
  labelClassName?: string
}

const SubmissionFormGrossOutputInput: React.FC<SubmissionGrossOutpuyInput> = ({
  id,
  className,
  grossValue,
  setGrossValueFunction,
  disabled,
  error = "",
  label = false,
  infoTooltip = false,
  labelClassName,
}) => {
  const errorClasses = "form-text text-danger"
  const invalidCode = "Comma"
  const maxValue = 999999999999999.99
  const minValue = 0

  const { t } = useTranslation()
  const [isInvalidCodeUsed, setIsInvalidCodeUsed] = useState<any>(false)

  const handlePressComma = (e: any) => {
    if (e.code === invalidCode) {
      setIsInvalidCodeUsed(true)
    } else {
      setIsInvalidCodeUsed(false)
    }
  }

  useEffect(() => {
    const obj = document.getElementById(id)
    if (obj) {
      obj.addEventListener("keydown", handlePressComma)
    }
    return () => {
      if (obj) {
        obj.removeEventListener("keydown", handlePressComma)
      }
    }
  })

  const isAlowed = (values: NumberFormatValues) => {
    if (values !== undefined && values.floatValue !== undefined) {
      return values.floatValue >= minValue && values.floatValue <= maxValue
    }
    return true
  }

  return (
    <>
      {label !== null && (
        <div className={`${labelClassName} col-form-label`}>
          <div>
            {label}
            {infoTooltip && <span data-tip={infoTooltip}>{" (i)"}</span>}
          </div>
        </div>
      )}
      <div className="col-sm-8">
        <NumberFormat
          id={id}
          className={className}
          type="text"
          value={grossValue}
          onValueChange={(values) => setGrossValueFunction(values.value)}
          thousandSeparator={false}
          decimalSeparator="."
          decimalScale={2}
          disabled={disabled}
          isAllowed={isAlowed}
        />
      </div>
      {error && <small className={errorClasses}>{error}</small>}
      {isInvalidCodeUsed && (
        <small className={errorClasses}>
          {t("Please use. (period) instead of, (comma)")}
        </small>
      )}
    </>
  )
}

export default SubmissionFormGrossOutputInput
