import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import SubmissionConverterFormInput from "./components/SubmissionConverterForm/SubmissionConverterFormInput"
import SubmissionConverterFormOutput from "./components/SubmissionConverterForm/SubmissionConverterFormOutput"
import AuditSubmissions from "./containers/AuditSubmissions"
import SectorSubmissionEdit from "./containers/SectorSubmissionEdit"
import SubmissionDetail from "./containers/SubmissionDetail"
import SubmissionEdit from "./containers/SubmissionEdit"
import SubmissionList from "./containers/SubmissionList"
import SubmissionNew from "./containers/SubmissionNew"
import SubmissionSelection from "./containers/SubmissionSelection"
import SubmissionSelectionConverter from "./containers/SubmissionSelectionConverter"
import SubmissionConverterFormsNewInput from "./components/SubmissionConverterForm/SubmissionConverterFormsNewInput"
import SubmissionConverterFormsNewOutput from "./components/SubmissionConverterForm/SubmissionConverterFormsNewOutput"

const Submissions = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Route exact path={path}>
        <SubmissionList />
      </Route>
      <Route exact path={`${path}/new/:type`}>
        <SubmissionNew />
      </Route>
      <Route exact path={`${path}/new/`}>
        <SubmissionSelection />
      </Route>
      <Route exact path={`${path}/show/:id/convertingInput`}>
        <SubmissionConverterFormInput isDisabled />
      </Route>
      <Route exact path={`${path}/show/:id/convertingOutput`}>
        <SubmissionConverterFormOutput isDisabled />
      </Route>
      <Route exact path={`${path}/show/:id/:type`}>
        <SubmissionDetail />
      </Route>
      <Route exact path={`${path}/edit/:id/:type`}>
        <SubmissionEdit />
      </Route>
      <Route exact path={`${path}/edit/sector/:year`}>
        <SectorSubmissionEdit />
      </Route>
      <Route exact path={`${path}/audit/:companyId`}>
        <AuditSubmissions />
      </Route>

      <Route exact path={`${path}/new-converting/`}>
        <SubmissionSelectionConverter />
      </Route>
      <Route exact path={`${path}/new-converting/input`}>
        <SubmissionConverterFormsNewInput />
      </Route>
      <Route exact path={`${path}/new-converting/output`}>
        <SubmissionConverterFormsNewOutput />
      </Route>
    </>
  )
}

export default Submissions
