import React from "react"
import { useTranslation } from "react-i18next"

import Input from "components/Input/Input"
import Label from "components/Label/Label"

import { ContaminationUnit } from "../../types"
import SubmissionFormGrossOutputInput from "./SubmissionFormGrossOutputInput"

interface SubmissionInputProps {
  errors: any
  touched: any
  values: any
  disabled: boolean
  contaminationUnit: ContaminationUnit
  setContaminationUnit: any
  netVolume: any
  handleContaminationChange: any
  contamination: any
  setTotalIncomeWaste: any
  wrapperClassName?: string
}

const SubmissionFormInputPartials: React.FC<SubmissionInputProps> = ({
  errors,
  touched,
  values,
  disabled,
  contaminationUnit,
  setContaminationUnit,
  netVolume,
  handleContaminationChange,
  contamination,
  setTotalIncomeWaste,
  wrapperClassName=""
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`form-group row align-items-center ${wrapperClassName}`}>
        <SubmissionFormGrossOutputInput
          id="input-total-incoming-waste"
          className="form-control"
          grossValue={values.volume ? values.volume : ""}
          setGrossValueFunction={setTotalIncomeWaste}
          disabled={disabled}
          error={
            touched.volume && errors.volume
              ? t("Total incoming waste have to be more than 0")
              : ""
          }
          labelClassName="col-sm-4"
          label={`${t("Total incoming waste (kg)")} (*)`}
        />
      </div>
      <div className="srs-input-extra-content">
        <Input
          type="number"
          label={t("Contamination")}
          name="contamination"
          min="0"
          max="100"
          placeholder={t("Add contamination in 1-100%")}
          handleOnChange={handleContaminationChange}
          inputWrapperClassName="col-sm-8"
          inputStyle={{ paddingRight: "110px" }}
          labelClassName="col-sm-4"
          wrapperClassName={wrapperClassName}
          error={touched.contamination && errors.contamination}
          isDisabled={disabled}
          value={contamination || ""}
        />
        <div className="srs-input-extra-content-body">
          <button
            type="button"
            className={`btn ${
              contaminationUnit === ContaminationUnit.PERCENT && "btn-primary"
            }`}
            // onClick={() => setContaminationUnit(ContaminationUnit.PERCENT)}
            disabled
          >
            %
          </button>
        </div>
      </div>

      <Input
        label={t("Input into the final recycling operation (kg)")}
        name="netVolume"
        value={
          netVolume === values.volume
            ? values.volume - values.volume * (contamination / 100)
            : netVolume
        }
        inputWrapperClassName="col-sm-8"
        wrapperClassName={wrapperClassName}
        labelClassName="col-sm-4"
        isDisabled
      />
    </>
  )
}

export default SubmissionFormInputPartials
