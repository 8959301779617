import { graph, ErrorMessage } from "../../../utils/api"

export const restHelper =
  <T, C extends object>(
    resolver: { [key: string]: string },
    mutationName: string,
  ) =>
  async (
    credentials: C | object = {},
  ): Promise<{ data?: T; errors?: ErrorMessage[] }> => {
    const { data, errors } = await graph(resolver[mutationName], credentials)

    if (errors?.length) {
      return { errors }
    }

    return { data: data[mutationName] }
  }
