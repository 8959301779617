import React from "react"

import "./Products.scss"
import ProductsManageContainer from "./containers/ProductsManage"

const Products = () => (
  <div className="srs-products">
    <ProductsManageContainer />
  </div>
)

export default Products
