import React, { ChangeEvent, useEffect, useState } from "react"
import { FormikErrors, useFormikContext } from 'formik'
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Company, CompanyRole } from "modules/companies/types"
import useStateGetter from "hooks/useStateGetter"
import { Country } from "../../../countries/types"
import Select, {
  mapToOptions,
} from "components/Select/Select"
import Checkbox from "components/Checkbox/Checkbox"
import Label from "components/Label/Label"
import { SUBMISSION_DATE_FORMAT } from "modules/submissions/constants"
import FormError from "components/FormError/FormError"
import { getPrevYear } from "../../utils";
import Input from "components/Input/Input"
import { SubmissionType } from "./SubmissionConverterFormsNewInput"


/* Set date to -1 month in form */
const actualDate = new Date()
actualDate.setMonth(actualDate.getMonth() - 1)

const epsProducts = [
  {
    value: "Foam particles",
    label: "Foam particles",
  },
  {
    value: "GPPS pellets",
    label: "GPPS pellets",
  },
  {
    value: "Styrene",
    label: "Styrene",
  },
  {
    value: "RMS",
    label: "RMS",
  },
  {
    value: "XPS",
    label: "XPS",
  },
  {
    value: "Compacted EPS",
    label: "Compacted EPS",
  },
]
interface Props {
  submission: SubmissionType
  plants: Company[]
  index: number
}
type SubmissionFormsType = {submissions: Array<SubmissionType>}

const SubmissionConverterNewInputFormFields: React.FC<Props> = ({
  submission,
  plants = [],
  index,
}) => {
  const { t } = useTranslation()
  const [isDisabled, setIsDisabled] = useState(false)  
  const [companies, setCompanies] = useState<Company[]>([])
  const [countriesForAudit, setCountriesForAudit] = useState<Country[]>([])
  const [countriesForNonEU, setCountriesForNonEu] = useState<Country[]>([])
  const [isConsumerType, setIsConsumerType] = useState("")
  const [currentPlant, setCurrentPlant] = useState<Company>()
  const countries = useStateGetter<Country[]>(["country", "countries"]) ?? []
  // const [countries, setCountries] = useState<Country[]>([])
  const [isNotPVC, setIsNotPVC] = useState(false)
  const { setFieldValue, errors, touched } = useFormikContext<SubmissionFormsType>()

  const checkCompositionNotPVC = (compositionName: string) => {
    return !compositionName.startsWith('PVC')
  }

  useEffect(()=>{
    setCompanies(plants.filter((company) =>
      [CompanyRole.CONVERTER, CompanyRole.RECYCLER_AND_CONVERTER].includes(
        company.roleId,
      ),)
    )
  }, [plants])

  useEffect(() => {
    countries.push({
      id: 999,
      name: "Other",
      code: "NONEU",
      isEu: false,
    } as Country)

    setCountriesForNonEu(countries)
  }, [countries])

  const currentErrors = errors?.submissions?.[index] as FormikErrors<SubmissionType> || {}
  const currentTouched = touched?.submissions?.[index] || {}

  const checkIsOutsideEu = (selectedOption: any) => {
    setFieldValue(`submissions.${index}.country`, selectedOption)

    const country = countries.find((country) => country.id === selectedOption.value)

    if (!country?.isEu) {
      setFieldValue(`submissions.${index}.isMaterialFromOutsideEurope`, true)
      setCountriesForAudit(countriesForNonEU.slice(0, -1))
    } else {
      setFieldValue(`submissions.${index}.isMaterialFromOutsideEurope`, false)
    }
  }
  const onChangeMaterialDemonstrateForAudi = (newValue: boolean) => {
    setFieldValue(`submissions.${index}.canMaterialDemonstrateForAudit`, newValue)
    setFieldValue(`submissions.${index}.country`, null)
    if (submission.canMaterialDemonstrateForAudit === true) {
      setFieldValue(`submissions.${index}.isMaterialFromOutsideEurope`, false)
    }
  }

  const handleConsumerTypeCheck = (checkedType: string) => {
    setIsConsumerType(checkedType)
    setFieldValue(`submissions.${index}.wasteType`, checkedType)
  }

  return (
    <div key={index}>
      <Select
        name={`submissions.${index}.company`}
        label={t("Plant name (*)")}
        options={mapToOptions(companies)}
        value={submission.company}
        handleOnChange={(selectedOption: any) => {
          const plant = companies?.find((a) => a.id == selectedOption?.value)
          if (!plant?.polymers?.find((p) => p.id == submission.materialGroup?.value)){
            setFieldValue(`submissions.${index}.materialGroup`, null)
            submission.materialGroup = null
            setIsNotPVC(false)
          }
          setCurrentPlant(plant)
          setFieldValue(`submissions.${index}.company`, selectedOption)
        }}
        inputWrapperClassName="col-sm-8"
        wrapperClassName="no-gutters mb-2 ml-n5"
        labelClassName="col-sm-4 col-form-label"
        error={currentErrors.company && currentTouched.company}
        isDisabled={isDisabled}
      />
      <Select
        name={`submissions.${index}.materialGroup`}
        label={`${t("Polymer")} (*)`}
        options={mapToOptions(currentPlant?.polymers || [])}
        value={submission.materialGroup}
        handleOnChange={(selectedOption: any) => {
          setFieldValue(`submissions.${index}.materialGroup`, selectedOption)
          setIsNotPVC(checkCompositionNotPVC(selectedOption.label))
        }}
        inputWrapperClassName="col-sm-8"
        wrapperClassName="no-gutters mb-2 ml-n5"
        labelClassName="col-sm-4 col-form-label"
        error={currentErrors.materialGroup && currentTouched.materialGroup}
        isDisabled={isDisabled}
      />
      {submission?.materialGroup?.label == "EPS" && (
        <Select
          name={`submissions.${index}.epsProduct`}
          options={epsProducts}
          value={submission.epsProduct}
          handleOnChange={(selectedOption) =>
            setFieldValue(`submissions.${index}.epsProduct`, selectedOption)
          }
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"          
          wrapperClassName="no-gutters mb-2"
          label="EPS product type"
          error={currentErrors.epsProduct && currentTouched.epsProduct}
          isDisabled={isDisabled}
        />
      )}
      <div className="form-group row align-items-center no-gutters mb-2">
        <Label label={t("Date (*)")} name="." labelClassName="col-sm-4" />
        <div className="col-sm-8">
          <DatePicker
            className="form-control"
            selected={submission.date}
            dateFormat={SUBMISSION_DATE_FORMAT}
            showMonthYearPicker
            showPopperArrow={false}
            onChange={(date: Date) => setFieldValue(`submissions.${index}.date`, date)}
            disabled={isDisabled}
          />
          <FormError error={currentTouched.date && (currentErrors.date as string)} />
        </div>
      </div>
      { isNotPVC && <div className="form-group row align-items-center no-gutters mb-2">
          <div className="col-sm-4"></div>
          <div className={"col-sm-8"}>
            <Checkbox
              name={`submissions.${index}.yearlySubmission`}
              label={`Please check this box if the volume consist the total of ${getPrevYear()}`}
              value={submission.yearlySubmission}
              handleOnChange={(val)=>{
                if (val.target.checked) {
                  const date = new Date()
                  date.setFullYear(getPrevYear())
                  date.setMonth(11)
                  setFieldValue(`submissions.${index}.date`, date)
                }
                setFieldValue(`submissions.${index}.yearlySubmission`, val.target.checked)
              }}
            />
          </div>
        </div>
      }
      {!submission.canMaterialDemonstrateForAudit && (
        <Select
          name="country"
          label="Recycled plastic from"
          options={mapToOptions(countriesForNonEU)}
          value={submission.country}
          handleOnChange={checkIsOutsideEu}
          inputWrapperClassName="col-sm-8"
          wrapperClassName="no-gutters mb-2"
          labelClassName="col-sm-4"
          error={currentTouched.country && currentErrors.country}
          isDisabled={isDisabled}
          infoTooltip="(i) Recycled plastic bought/received or from internally recycled waste. If from internally recycled waste, select the country your recycling plant is in."
        />
      )}
      { isNotPVC && 
        <div className="form-group row align-items-center no-gutters mb-2">
          <div className="col-sm-4"></div>
          <div className={"col-sm-8"}>
            <Checkbox 
              name={`submissions.${index}.originFromEu`}
              label={'Please check box if you are unable to provide origin country, but waste is from EU+UK'}
              handleOnChange={(val)=>{
                setFieldValue(`submissions.${index}.originFromEu`, val.target.checked)
                if (val.target.checked) {
                  setFieldValue(`submissions.${index}.isMaterialFromOutsideEurope`, false)
                }
              }}
            />
          </div>
        </div>
      }
      <Input
        label={t("Total recycled plastic (kg)")}
        name="payment"
        type="number"
        min="0"
        inputWrapperClassName="col-sm-8"
        wrapperClassName="no-gutters mb-2"
        labelClassName="col-sm-4"
        value={submission.total || 0}
        handleOnChange={(
          event: ChangeEvent<HTMLInputElement>,
        ) => {
          setFieldValue(`submissions.${index}.total`, event.target.valueAsNumber)
        }}
        placeholder={t("Enter total")}
        isDisabled={isDisabled}
        infoTooltip={
          !submission.canMaterialDemonstrateForAudit
            ? `${t(
                "(i) Includes recycled plastics from external and internal recycling.",
              )}`
            : `${t(
                "Imports of recycled plastic (Kg) made from European waste",
              )}`
        }
        isRequired
        error={currentTouched.total && currentErrors.total}
      />

      { submission.isMaterialFromOutsideEurope && (
        <Checkbox
          label={t(
            'Please check the box if recycled plastic is imported ("Other" is selected above) and some/all of it is made from European waste and you can demonstrate it during auditing.',
          )}
          name="canMaterialDemonstrateForAudit"
          value={submission.canMaterialDemonstrateForAudit}
          handleOnChange={() =>
            onChangeMaterialDemonstrateForAudi(
              !submission.canMaterialDemonstrateForAudit,
            )
          }
          checkboxWrapperClassName="mb-3"
          isDisabled={isDisabled}
        />
      )}
      { submission.canMaterialDemonstrateForAudit && (
        <>
          <Input
            label={t(
              "Total imported recycled plastic made from European waste (kg)",
            )}
            name="totalDemonstratedDuringAudit"
            type="number"
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            value={submission.totalDemonstratedDuringAudit || 0}
            handleOnChange={(e: any) => {
              setFieldValue(`submissions.${index}.totalDemonstratedDuringAudit`, e.target.valueAsNumber)
            }}
            placeholder={t("Enter total")}
            isDisabled={isDisabled}
            infoTooltip="(i) European waste - waste originating in EU or UK"
            isRequired
            error={
              currentTouched.totalDemonstratedDuringAudit &&
              currentErrors.totalDemonstratedDuringAudit
            }
          />
          <Select
            name="country"
            label="Waste origin"
            options={mapToOptions(countriesForAudit)}
            value={submission.country}
            handleOnChange={(selectedOption) =>
              setFieldValue(`submissions.${index}.country`, selectedOption)
            }
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={currentTouched.country && currentErrors.country}
            isDisabled={isDisabled}
          />
          <div className="form-group row align-items-center ">
            <Label label={t("Waste Type")} name="." labelClassName="col-sm-4" />
            <div className="col-sm-8 row">
              <div className="col-sm-4">
                <Checkbox
                  name="wasteType_pre"
                  label="Pre-consumer"
                  handleOnChange={() => handleConsumerTypeCheck("Pre")}
                  value={isConsumerType === "Pre"}
                  isDisabled={isDisabled}
                />
              </div>
              <div className="col-sm-4">
                <Checkbox
                  name="wasteType_post"
                  label="Post-consumer"
                  handleOnChange={() => handleConsumerTypeCheck("Post")}
                  value={isConsumerType === "Post"}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </>
      )}
      
    </div>
  )
}

export default SubmissionConverterNewInputFormFields
