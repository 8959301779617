import { getMaterialGroups, MaterialGroup } from "modules/materials/service/api"
import React, { useEffect, useMemo, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import { mapToOptions } from "components/Select/Select"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"
import { getCountryFlagClassName } from "utils/strings"
import { windowStorage } from "utils/windowStorage"

import { ACTIVE_PLANT_ID_KEY, EuCountriesArray } from "../../../../constants"
import { SELECTED_COMPANY_KEY } from "../../../submissions/constants"
import {
  getCompanies,
  setArchiveCompany,
  setUnarchiveCompany,
} from "../../service/api"
import { Company } from "../../types"
import CompanyDetailsModal from "./CompanyDetailsModal"
import CompanyRoleLabel from "./CompanyRoleLabel"

const ManageCompanies = () => {
  const { t } = useTranslation()

  const [allPlants, setAllPlants] = useState<Company[]>([])
  const [allPolymers, setAllPolymers] = useState<MaterialGroup[]>([])

  const [searchName, setSearchName] = useState("")
  const [searchVatNumber, setSearchVatNumber] = useState("")
  const [searchPolymers, setSearchPolymers] = useState<any[]>([])
  const [searchCountry, setSearchCountry] = useState("")

  useEffect(() => {
    getCompanies({ all: true }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setAllPlants(data)
      }
    })

    getMaterialGroups().then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setAllPolymers(data)
        setSearchPolymers(mapToOptions(data))
      }
    })
  }, [])

  const runSubmitSearchForm = useCallback(() => {
    getCompanies({
      name: searchName,
      vatNumber: searchVatNumber,
      isInput: false,
      country: searchCountry,
      polymers: searchPolymers.map((polymer) => polymer.value),
    }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setAllPlants(data as Company[])
      }
    })
  }, [searchCountry, searchName, searchVatNumber, searchPolymers])

  const fetchCompanies = useCallback(() => {
    if (searchName || searchVatNumber || searchCountry || searchPolymers) {
      runSubmitSearchForm()
    } else {
      runSubmitSearchForm()
    }
  }, [
    runSubmitSearchForm,
    searchCountry,
    searchName,
    searchVatNumber,
    searchPolymers,
  ])

  useEffect(() => {
    const searchTimeout = setTimeout(() => fetchCompanies(), 300)
    return () => clearTimeout(searchTimeout)
  }, [fetchCompanies])

  const contactInformationTable = (company: Company) => {
    const countryFromEU =
      company.country && company.country.code
        ? EuCountriesArray.includes(company.country.code)
        : ""
    return (
      <table className="table table-hover text-left">
        <tbody>
          <tr>
            <th>
              <strong>{t("Contact information")}</strong>
            </th>
          </tr>
          <tr>
            <th>{t("Company Name")}:</th>
            <td>{company.name}</td>
          </tr>
          <tr>
            <th>{t("Country")}:</th>
            <td>
              {(() => {
                if (countryFromEU) {
                  return (
                    <span
                      className={getCountryFlagClassName(company.country?.code)}
                    />
                  )
                }
                return <span>Non-EU</span>
              })()}
            </td>
          </tr>
          <tr>
            <th>{t("Address")}:</th>
            <td>{company.address}</td>
          </tr>
          <tr>
            <th>{t("Vat number")}:</th>
            <td>{company.vatNumber}</td>
          </tr>
          <tr>
            <th>{t("CEO name")}:</th>
            <td>{company.ceoName}</td>
          </tr>
          <tr>
            <th>{t("CEO email")}:</th>
            <td>{company.ceoEmail}</td>
          </tr>
        </tbody>
      </table>
    )
  }
  const [isCompanyDetailsOpened, setIsCompanyDetailsOpened] =
    useState<boolean>(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0)
  const [isSelectedCompanyPlant, setIsSelectedCompanyPlant] =
    useState<boolean>(false)

  const showDetailsModal = (company: Company, isPlant = false) => {
    setIsSelectedCompanyPlant(isPlant)
    setSelectedCompanyId(company.id || 0)
    setIsCompanyDetailsOpened(true)
  }

  const handleOnCompanyLinkClicked = (company: Company, path: string) => {
    windowStorage.set(SELECTED_COMPANY_KEY, company as any, false)
    windowStorage.set(ACTIVE_PLANT_ID_KEY, `${company.id}`, false)
    history.push(path)
  }
  const onCloseCompanyDetailsModal = () => {
    setIsCompanyDetailsOpened(false)
  }

  const [currentTab, setCurrentTab] = useState("active")

  const onChangeTabClicked = (
    e: React.MouseEvent<HTMLAnchorElement>,
    value: string,
  ) => {
    e.preventDefault()
    setCurrentTab(value)
  }

  const getTabs = () => {
    return (
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item">
          <a
            href="#active"
            className={`nav-link ${currentTab === "active" ? "active" : ""} `}
            data-toggle="pill"
            role="tab"
            aria-controls="pills-inputData"
            aria-selected="true"
            onClick={(e) => onChangeTabClicked(e, "active")}
          >
            {t("Active")}
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#archived"
            className={`nav-link ${currentTab === "archived" ? "active" : ""}`}
            data-toggle="pill"
            role="tab"
            aria-controls="pills-outputData"
            aria-selected="false"
            onClick={(e) => onChangeTabClicked(e, "archived")}
          >
            {t("Archived")}
          </a>
        </li>
      </ul>
    )
  }

  const archiveCompanySubmit = (company: Company) => {
    setArchiveCompany({ id: company.id }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        if (data) {
          handleToast(["Company  archived"])
        }
        runSubmitSearchForm()
      }
    })
  }

  const unarchiveCompanySubmit = (company: Company) => {
    setUnarchiveCompany({ id: company.id }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        if (data) {
          handleToast(["Company  active"])
        }
        runSubmitSearchForm()
      }
    })
  }

  const [sortKey, setSortKey] = React.useState<string>("name")
  const [sortDesc, setSortDesc] = React.useState<boolean>(true)

  const requestSort = (key: string) => {
    if (key === sortKey) {
      setSortDesc(!sortDesc)
    } else {
      setSortKey(key)
    }
  }

  const sortedCompanies = useMemo(() => {
    const sortableItems = allPlants.filter(p => ((currentTab === "active" && !p.archived) || (p.archived && currentTab === "archived") ))
    sortableItems.sort((a: any, b: any) => {
      if (sortKey === "country") {
        if (a.country.name.toLowerCase() < b.country.name.toLowerCase()) {
          return sortDesc ? 1 : -1
        }
        if (a.country.name.toLowerCase() > b.country.name.toLowerCase()) {
          return sortDesc ? -1 : 1
        }
      } else {
        if (a.parent[sortKey].toLowerCase() < b.parent[sortKey].toLowerCase()) {
          return sortDesc ? 1 : -1
        }
        if (a.parent[sortKey].toLowerCase() > b.parent[sortKey].toLowerCase()) {
          return sortDesc ? -1 : 1
        }
      }
      return 0
    })
    return sortableItems
  }, [allPlants, sortDesc, sortKey])

  const companyRow = (company: Company) => {
    
    return (
      <tr key={company.id}>
        <td className="align-middle">
          <span
            className="btn-link"
            onClick={() => company.parent && showDetailsModal(company.parent)}
          >
            {company.parent?.name}
          </span>
        </td>
        <td className="align-middle">
          <span
            className="btn-link"
            onClick={() => showDetailsModal(company, true)}
          >
            {company.name}
          </span>
        </td>
        <td className="align-left">
          {company.polymers?.map((polymer) => (
            <span key={`polymer_${company.id}_${polymer.id}`}>
              {polymer.name}
              {", "}
            </span>
          ))}
        </td>
        <td className="align-middle">
          <CompanyRoleLabel role={company.roleId} />
        </td>
        <td className="align-middle text-center">
          <span className={getCountryFlagClassName(company.country?.code)} />
        </td>
        <td className="align-middle">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary text-left w-100 my-1"
            onClick={() =>
              handleOnCompanyLinkClicked(company, "/submissions")
            }
          >
            <i className="fas fa-tasks btn-icon mr-1" />
            {t("Manage Submissions")}
          </button>

          <div className="col-12">
            <ReactTooltip
              id={`tooltip_${company.id}`}
              backgroundColor="white"
              textColor="black"
            >
              {contactInformationTable(company)}
            </ReactTooltip>
          </div>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary text-left w-100 my-1"
            key={company.id}
            data-tip=""
            data-for={`tooltip_${company.id}`}
          >
            <i className="fas fa-info btn-icon mr-1" />
            {t("Contact information")}
          </button>
          <Link
            to={{
              pathname: `/plants/edit/${company.id}`,
              state: {
                title: t("Manage Plant"),
              },
            }}
            type="button"
            className="btn btn-sm btn-outline-warning text-left w-100 my-1"
          >
            <i className="fas fa-pen btn-icon mr-1" />
            {t("Edit")}
          </Link>
          {company.archived && (
            <button
              type="button"
              className="btn btn-sm btn-outline-success text-left w-100 my-1"
              key={company.id}
              onClick={() => unarchiveCompanySubmit(company)}
            >
              <i className="fas fa-check btn-icon mr-1" />
              {t("Active")}
            </button>
          )}
          {!company.archived && (
            <button
              type="button"
              className="btn btn-sm btn-outline-danger text-left w-100 my-1"
              key={company.id}
              onClick={() => archiveCompanySubmit(company)}
            >
              <i className="fas fa-trash btn-icon mr-1" />
              {t("Archive")}
            </button>
          )}
        </td>
      </tr>
    )
  }

  return (
    <>
      <h1 className="mb-5">{t("Manage companies")}</h1>
      {getTabs()}
      <h4 className="mb-3">{t("Search")}</h4>
      <form className="form-inline mb-3 row">
        <div className="form-group col-md-3">
          <input
            name="name"
            className="form-control w-100"
            type="text"
            placeholder={t("Name")}
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>
        <div className="form-group col-md-3">
          <input
            name="vatNumber"
            className="form-control w-100"
            type="text"
            placeholder={t("Vat Number")}
            onChange={(e) => setSearchVatNumber(e.target.value)}
          />
        </div>
        <div className="col-3 multi-select-wrapper">
          <MultiSelect
            options={mapToOptions(allPolymers)}
            value={searchPolymers as any[]}
            onChange={(e: any) => {
              setSearchPolymers(e)
            }}
            labelledBy={""}
            hasSelectAll
          />
        </div>
        <div className="form-group col-md-3">
          <input
            name="country"
            className="form-control w-100"
            type="text"
            placeholder={t("Country")}
            onChange={(e) => setSearchCountry(e.target.value)}
          />
        </div>
      </form>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="cursor-pointer" onClick={() => requestSort("name")}>
              {t("Company Name")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th className="text-center">{t("Plant name")}</th>
            <th className="text-center">{t("Polymers")}</th>
            <th className="text-center">{t("Activity / role")}</th>
            <th
              className="cursor-pointer"
              onClick={() => requestSort("country")}
            >
              {t("Country")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th className="text-center actions-col">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {sortedCompanies.length ? (
            sortedCompanies.map((company) => companyRow(company))
          ) : (
            <tr>
              <td className="align-middle text-center" colSpan={6}>
                {t("There is no data to display")}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <button
        type="button"
        className="btn btn-outline-primary rounded-bottom-left"
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left mr-2" /> {t("Back")}
      </button>
      <CompanyDetailsModal
        {...{
          companyId: selectedCompanyId,
          isPlant: isSelectedCompanyPlant,
          closeModal: onCloseCompanyDetailsModal,
          isCompanyDetailsOpened,
        }}
      />
    </>
  )
}

export default ManageCompanies
