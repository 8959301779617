import React from "react"

import useUserFromToken from "../../../hooks/useUserFromToken"
import SectorSubmissionForm from "../components/SectorSubmissionForm/SectorSubmissionForm"
import SubmissionNewForms from "../components/SubmissionForm/SubmissionNewForms"

const SubmissionNew: React.FC = () => {
  const { isSectorAssociationRepresentative } = useUserFromToken()

  return (
    <>
      {isSectorAssociationRepresentative ? (
        <SectorSubmissionForm />
      ) : (
        <SubmissionNewForms />
      )}
    </>
  )
}

export default SubmissionNew
