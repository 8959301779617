import * as Yup from "yup"

const RegisterCompanyFormValidationShema = Yup.object().shape({
  selectedCountry: Yup.object().typeError("Country is required."),
  vatNumber: Yup.string().required("VAT number is required"),
  name: Yup.string().required("Name is required"),
  address: Yup.string().required("Address is required"),
  // holding: Yup.string().required('Holding / group is required.'),
  ceoEmail: Yup.string().nullable().email("Invalid email"),
  companyRole: Yup.object().typeError("Role is required"),
  agreeTerms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
})

export default RegisterCompanyFormValidationShema
