import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import Modal from "../../../components/Modal/Modal"
import { ACTIVE_PLANT_ID_KEY } from "../../../constants"
import useUserFromToken from "../../../hooks/useUserFromToken"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import { windowStorage } from "../../../utils/windowStorage"
import { setSelectedCompany } from "../../submissions/actions"
import { SELECTED_COMPANY_KEY } from "../../submissions/constants"
import CompanyForm from "../components/CompanyForm/CompanyForm"
import {
  getCompanies,
  getPlants,
  approvePlant,
  rejectPlant,
} from "../service/api"
import { Company } from "../types"
import PlantsListTable from "./PlantsListTable"

const CompaniesList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false)
  const dispatch = useDispatch()
  const [plants, setPlants] = useState<Company[]>([])

  const { isAdmin, isRecycler, isRecyclerAndConverter, isConverter } =
    useUserFromToken()
  const canAddPlant =
    isRecycler || isAdmin || isRecyclerAndConverter || isConverter
  const [searchName, setSearchName] = useState("")
  const [searchAddress, setSearchAddress] = useState("")
  const [searchVatNumber, setSearchVatNumber] = useState("")
  const [searchCountry, setSearchCountry] = useState("")

  const handleOnSelectCompanyActionClick = (company: Company, path: string) => {
    windowStorage.set(SELECTED_COMPANY_KEY, company, false)
    dispatch(setSelectedCompany({ selectedCompany: company }))
    windowStorage.set(ACTIVE_PLANT_ID_KEY, `${company.id}`, false)
    history.push(path)
  }

  const fetchPlants = () => {
    getPlants({ all: true })
      .then((response) => {
        if (response.data) {
          setPlants(response.data as Company[])
        }
      })
      .catch(() => {
        // handleToast([`Error during getting plants list: ${error}`], 'errors');
        handleToast([`Couldn't get plants list`], "errors")
      })
  }

  const runSubmitSearchForm = useCallback(() => {
    getCompanies({
      name: searchName,
      address: searchAddress,
      vatNumber: searchVatNumber,
      isInput: false,
      country: searchCountry,
      archive: false,
    }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setPlants(data as Company[])
      }
    })
  }, [searchAddress, searchCountry, searchName, searchVatNumber])

  const fetchCompanies = useCallback(() => {
    if (searchName || searchAddress || searchVatNumber || searchCountry) {
      runSubmitSearchForm()
    } else {
      fetchPlants()
    }
  }, [
    runSubmitSearchForm,
    searchAddress,
    searchCountry,
    searchName,
    searchVatNumber,
  ])

  useEffect(() => {
    const show_register_modal = window.sessionStorage.getItem(
      "show_register_modal",
    )
    if (
      show_register_modal === '"true"' &&
      (isRecyclerAndConverter || isRecycler || isConverter)
    ) {
      setIsModalRegisterOpen(true)
      window.sessionStorage.removeItem("show_register_modal")
    }
  }, [isRecyclerAndConverter, isRecycler, isConverter])

  useEffect(() => {
    const searchTimeout = setTimeout(() => fetchCompanies(), 300)
    return () => clearTimeout(searchTimeout)
  }, [fetchCompanies])

  const handleOnCompanySuccessUpdate = () => {
    setIsModalOpen(false)
    fetchCompanies()
  }

  const handleOnApproveActionClick = (company: Company) => {
    approvePlant({ id: company.id }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        if (data.approved) {
          handleToast(["Plant approved"])
        } else {
          handleToast(["Plant was not approved"], "error")
        }
        fetchCompanies()
      }
    })
  }

  const handleOnRejectActionClick = (company: Company) => {
    rejectPlant({ id: company.id }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        if (data.approved) {
          handleToast(["Plant approved"])
        } else {
          handleToast(["Plant was not approved"], "error")
        }
        fetchCompanies()
      }
    })
  }

  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="mb-5">{t("Manage plants")}</h1>
        {canAddPlant ? (
          <button
            type="button"
            className="btn btn-success rounded-top-right mb-3"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            {t("Add new")}
            <i className="fas fa-plus ml-3" />
          </button>
        ) : null}
      </div>

      {!isRecycler && (
        <>
          <h4 className="mb-3">{t("Search")}</h4>
          <form className="form-inline mb-3 row">
            <div className="form-group col-md-3">
              <input
                name="name"
                className="form-control w-100"
                type="text"
                placeholder={t("Name")}
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
            <div className="form-group col-md-3">
              <input
                name="address"
                className="form-control w-100"
                type="text"
                placeholder={t("Address")}
                onChange={(e) => setSearchAddress(e.target.value)}
              />
            </div>
            <div className="form-group col-md-3">
              <input
                name="vatNumber"
                className="form-control w-100"
                type="text"
                placeholder={t("Vat Number")}
                onChange={(e) => setSearchVatNumber(e.target.value)}
              />
            </div>
            {/* <div className="form-check srs-checkbox false">
            <input name="isInputSearch" id="isInputSearch" type="checkbox" value="true" className="form-check-input" placeholder="Is input" onChange={ runSubmitSearchForm }/>
            <label htmlFor="isInputSearch" className="cursor-pointer col-sm-12 col-form-label">Is Input</label>
          </div> */}
            <div className="form-group col-md-3">
              <input
                name="country"
                className="form-control w-100"
                type="text"
                placeholder={t("Country")}
                onChange={(e) => setSearchCountry(e.target.value)}
              />
            </div>
          </form>
        </>
      )}

      <PlantsListTable
        companies={plants}
        handleOnApproveActionClick={handleOnApproveActionClick}
        handleOnRejectActionClick={handleOnRejectActionClick}
        handleOnSelectCompanyActionClick={handleOnSelectCompanyActionClick}
      />

      <Modal
        isOpen={isModalOpen}
        isOpenHandler={setIsModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <h2 className="pl-5 pt-5">{t("Company data")}</h2>
        <div className="p-5">
          <CompanyForm
            onSuccessHandler={handleOnCompanySuccessUpdate}
            selectCompany={isAdmin || false}
          />
        </div>
      </Modal>

      <Modal
        isOpen={isModalRegisterOpen}
        isOpenHandler={setIsModalRegisterOpen}
        wrapperClass="srs-modal-lg"
      >
        <h2 className="pl-5 pt-5">{t("Registration finished")}</h2>
        <div className="p-5">
          <p>{t("Thanks for finishing the registration process")}.</p>
          <p>
            {t(
              "If you want to view or edit your company or plants details, please go to Manage Account.",
            )}
          </p>
          <div className="d-flex justify-content-between align-items-end">
            <div className="float-left">
              <Link
                to={{
                  pathname: "/users/account",
                  state: {
                    title: t("Manage Account"),
                  },
                }}
              >
                <button
                  type="button"
                  className="btn btn-outline-primary rounded-bottom-left"
                >
                  {t("Manage account")}
                </button>
              </Link>
            </div>
            <div className="float-right">
              <button
                type="button"
                className="btn btn-outline-primary rounded-bottom-right"
                onClick={() => setIsModalRegisterOpen(false)}
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <button
        type="button"
        className="btn btn-outline-primary rounded-bottom-left"
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left mr-2" /> {t("Back")}
      </button>
    </>
  )
}

export default CompaniesList
