import uploadPDF from "assets/images/auditicons/misc-uploadPDF.svg"
import axios from "axios"
import React, { useEffect, useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"

import { handleToast } from "utils/messages"

import { deleteDocument } from "../../service/api"

const UploadEditAudit = ({ setFile, document, isDisabled = false }: any) => {
  const [fileName, setFileName] = useState<string>("")
  const [removeFile, setRemoveFile] = useState<boolean>(false)

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader()

        reader.onabort = () => {
          console.log("file reading was aborted")
        }
        reader.onerror = () => {
          console.log("file reading has failed")
        }

        const formData = new FormData()
        if (file) {
          formData.append("file", file)
        }
        const url = `${process.env.REACT_APP_API_URL}audit/uploadfile`
        axios({
          url,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        }).then((response) => {
          if (response.data.status === 1) {
            setFileName(file.name)
            setFile(file)
          } else {
            setFileName("Problem")
          }
        })
      })
    },
    [setFile],
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const removeDocument = (documentId: any) => {
    deleteDocument({ id: parseInt(documentId, 10) }).then((response) => {
      if (response.errors) {
        handleToast(response.errors, "error")
      } else {
        setTimeout(() => {
          setRemoveFile(true)
          handleToast(["File removed"])
        }, 0)
      }
    })
  }
  console.log(isDisabled)
  return (
    <>
      {document && !removeFile && !isDisabled && (
        <div className="col-12 mt-2">
          <span className="align-middle">
            Drag & drop your new audit file here to replace this file :{" "}
            <a target="_blank" href={document?.hash}>
              {document?.fileName}
            </a>
          </span>
          <button
            type="button"
            className="btn btn-danger ml-3"
            onClick={() => removeDocument(document.id)}
          >
            <span>Remove</span>
            <i className="fas fa-trash btn-icon mr-1" />
          </button>
        </div>
      )}
      {!isDisabled && (
        <div
          className="drop-place col-12 mt-2 mb-4 text-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className="col-12 mt-4">
            <img src={uploadPDF} />
          </div>
          <div className="col-12 mt-2">
            <span className="align-middle">
              Drag & drop your new audit file here to replace file{" "}
            </span>
          </div>
          <div className="col-12 mt-2">
            <span className="align-middle">or</span>
          </div>
          <div className="col-12 mt-2">
            <button type="button" className="btn btn-primary">
              Browse files on your device
            </button>
          </div>
          {fileName != "" && `${fileName} uploaded!`}
        </div>
      )}
      {isDisabled && (
        <div className="col-12 mt-2 bg-white p-3">
          <span className="align-middle">
            File :{" "}
            <a target="_blank" href={document?.hash}>
              {document?.fileName}
            </a>
          </span>
        </div>
      )}
    </>
  )
}

export default UploadEditAudit
