import { sum } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

import { activityTypes } from "../constants"
import { kilogramsToTonnes } from "../helpers"
import styles from "../styles.module.scss"
import { convertingActivity } from "../types"

export const AuditConvertingActivityTable: React.FC<{
  tableData: Array<convertingActivity>
}> = ({ tableData = [] }) => {
  const { t } = useTranslation()

  return (
    <>
      <p className={styles.title}>{activityTypes[2].label}</p>
      <table id="converting-table" className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="text-center">{t("Polymer")}</th>
            <th className="text-center">{t("Origin")}</th>
            <th className="text-center">{t("Total input, t")}</th>
            {/* <th className="text-center">{t("Sector")}</th> */}
            <th className="text-center">{t("Output, t")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item: convertingActivity, index: number) => {
            const euLength = item?.eu?.products?.length ?? 0
            const nonEuLength = item?.nonEu?.products?.length ?? 0

            return (
              <tr key={`item_${index}`}>
                <td>{item.material}</td>
                <td className={styles.group}>
                  <div>
                    <tr className={styles.cell}>EU</tr>
                    {/* <tr>&nbsp;</tr> */}
                    {/* {Array.from(Array(euLength))?.map((_, index) => (
                      <tr key={`subEu_${index}`}>&nbsp;</tr>
                    ))} */}
                    <tr className={styles.cell}>Non-EU</tr>
                    {/* <tr>&nbsp;</tr> */}
                    {/* {Array.from(Array(nonEuLength))?.map((_, index) => (
                      <tr key={`subEu_${index}`}>&nbsp;</tr>
                    ))} */}
                    <tr className={styles.cell}>Total</tr>
                  </div>
                </td>
                <td className={styles.group}>
                  <tr className={styles.cell}>
                    {kilogramsToTonnes(item.eu.totalInput)}
                  </tr>
                  {/* {Array.from(Array(euLength))?.map((a, i) => (
                    <tr key={`eu_${i}`} className={styles.cell}>
                      &nbsp;
                    </tr>
                  ))} */}
                  <tr className={styles.cell}>
                    {kilogramsToTonnes(item.nonEu.totalInput)}
                  </tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(item.eu.totalInput + item.nonEu.totalInput)}</tr>
                </td>
                {/* <td className={`${styles.group} ${styles.highlightLeft}`}>
                  <tr className={styles.cell}>&nbsp;</tr>
                  {item.eu.products?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {a.sector}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  <tr className={styles.cell}>&nbsp;</tr>
                  {item.nonEu.products.length ? (
                    item.nonEu.products.map((a, i) => (
                      <tr key={`a_${i}`} className={styles.cell}>
                        {a.sector}
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr className={styles.cell}>&nbsp;</tr>
                    </>
                  )}
                </td> */}
                <td className={styles.group}>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.eu.products?.map(a => a.sectorOutput)))}</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.nonEu.products?.map(a => a.sectorOutput)))}</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(item.totalOutput)}</tr>

                  {/* {item.eu.products?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {a.sectorOutput ? kilogramsToTonnes(a.sectorOutput) : ""}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  <tr className={styles.cell}>&nbsp;</tr>

                  {item.nonEu.products.length ? (
                    item.nonEu.products.map((a, i) => (
                      <tr key={`a_${i}`} className={styles.cell}>
                        {a.sectorOutput
                          ? kilogramsToTonnes(a.sectorOutput)
                          : ""}
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr className={styles.cell}>&nbsp;</tr>
                    </>
                  )} */}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
