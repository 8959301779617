import { restHelper } from "../../@common/service/api"
import { Submission } from "../../submissions/types"
import { User } from "../../users/types"
import { Audit, DocumentIdInput, Document } from "../types"

export const queries = {
  audits: `
    query audits(
      $companyId: Int
    ) {
      audits(
        companyId: $companyId
      ) {
          id
          createdAt
          finishedAt
          draft
          createdBy {
            name
            email
          }
          auditor {
            name
            email
          }
          status
          company{
            id
          }
          isDeleted
          deletedAt
          deletedBy {
            name
          }
      }
    }
  `,
  auditsForCurrentAuditor: `
  query auditsForCurrentAuditor {
    auditsForCurrentAuditor {
      id
      createdAt
      finishedAt
      createdBy {
        name
        email
      }
      auditor {
        name
        email
      }
      status
      company{
        id
      }
      document {
          hash
          fileName
        }
    
  }
    }
  `,
  audit: `
    query audit(
      $id: Int
    ) {
      audit(
        id: $id
      ) {
        id
        createdAt
        finishedAt
        draft
        createdBy {
          name
          email
        }
        auditor {
          name
          email
        }
        company{
          id
          name
        }
        submissions
        status
        auditType
        document {
          id
          hash
          fileName
        }
        
      }
    }
  `,
  auditors: `
    query auditors (
      $companyId: Int!
    ) {
      auditors (
        companyId: $companyId
      ) {
        id
        name
      }
    }
  `,
}

export const mutations = {
  auditAssignToAuditor: `
    mutation auditAssignToAuditor($id: Int!, $auditorId: Int!) {
      auditAssignToAuditor(id: $id, auditorId: $auditorId)
    }
  `,
  auditStatusChange: `
    mutation auditStatusChange($id: Int!, $status: Int!) {
      auditStatusChange(id: $id, status: $status)
    }
  `,
  auditCreate: `
    mutation auditCreate ($companyId: Int!, $submissions: [Int], $createdAt: Date, $finishedAt: Date, $auditType: String, $draft: Boolean, $files: [FileUpload], $provider:String) {
      auditCreate (companyId: $companyId, submissions: $submissions, createdAt: $createdAt, finishedAt: $finishedAt, auditType: $auditType, draft: $draft, files: $files, provider:$provider) { id }
    }
  `,
  auditUpdate: `
    mutation auditUpdate ($auditId: Int!, $companyId: Int!, $submissions: [Int], $createdAt: Date, $finishedAt: Date, $auditType: String, $draft: Boolean, $files: [FileUpload], $documentId: Int) {
      auditUpdate (auditId: $auditId, companyId: $companyId, submissions: $submissions, createdAt: $createdAt, finishedAt: $finishedAt, auditType: $auditType, draft: $draft, files: $files, documentId: $documentId) { id }
    }
  `,
  auditDelete: `
    mutation auditDelete(
      $id: Int!
    ) {
      auditDelete(
        id: $id
      )
    }
  `,
  documentDelete: `
    mutation documentDelete(
      $id: Int!
    ) {
      documentDelete(
        id: $id
      )
    }
  `,
}

export const getAudits = restHelper<Audit[], { companyId?: number }>(
  queries,
  "audits",
)

export const getAuditsForCurrentAuditor = restHelper<Audit[], object>(
  queries,
  "auditsForCurrentAuditor",
)

export const getAudit = restHelper<Audit, { id?: number }>(queries, "audit")

export const getAuditors = restHelper<User[], { companyId: number }>(
  queries,
  "auditors",
)

export const auditAssignToAuditor = restHelper<
  boolean,
  {
    id: number
    auditorId: number
  }
>(mutations, "auditAssignToAuditor")

export const auditStatusChange = restHelper<
  boolean,
  {
    id: number
    status: number
  }
>(mutations, "auditStatusChange")

export const auditCreate = restHelper<
  Audit,
  {
    auditId: number
    companyId: number
    submissions: Submission[]
    createdAt: Date
    finishedAt: Date
    draft: boolean
    files: any
    provider?: string
  }
>(mutations, "auditCreate")

export const auditUpdate = restHelper<
  Audit,
  {
    auditId: number
    companyId: number
    submissions: Submission[]
    createdAt: Date
    finishedAt: Date
    draft: boolean
    files: any[]
  }
>(mutations, "auditUpdate")

export const auditDelete = restHelper<
  Audit,
  {
    id: number
  }
>(mutations, "auditDelete")

export const deleteDocument = restHelper<Document, DocumentIdInput>(
  mutations,
  "documentDelete",
)
