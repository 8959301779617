import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, Route, useRouteMatch } from "react-router-dom"

import Modal from "../../../components/Modal/Modal"
import { RECOTRACE_LINK, TOKEN_EXPIRED } from "../../../constants"
import { windowStorage } from "../../../utils/windowStorage"
import "./Login.scss"
import LoginForm from "./LoginForm"
import LoginNewPasswordForm from "./LoginNewPasswordForm"
import LoginPasswordReminderForm from "./LoginPasswordReminderForm"

const Login = () => {
  const { t } = useTranslation()
  const { path } = useRouteMatch()

  const tokenExpired = windowStorage.get(TOKEN_EXPIRED)

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)

  useEffect(() => {
    if (tokenExpired) {
      windowStorage.remove(TOKEN_EXPIRED)
      setShowSessionExpiredModal(true)
    }
  }, [tokenExpired])

  const sessionExpiredModal = () => {
    return (
      <Modal
        isOpen={showSessionExpiredModal}
        isOpenHandler={setShowSessionExpiredModal}
        wrapperClass="srs-modal-lg"
      >
        <div className="modal-header">
          <h3 className="modal-title">
            <strong>{t("Your session has expired, please login.")}</strong>
          </h3>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-success rounded-bottom-right float-right"
            onClick={() => setShowSessionExpiredModal(false)}
          >
            {t("Close")}
          </button>
        </div>
      </Modal>
    )
  }

  return (
    <div className="w-100 row">
      <div className="srs-auth-login-background col" />
      <div className="srs-auth-login-content col pt-5 pb-5">
        <div className="wrapper-sm">
          <h1 className="mt-3">
            <a target="_blank" href={RECOTRACE_LINK}>
              {t("Welcome to RecoTrace")}
            </a>
          </h1>
          <br />
          <Route exact path={path}>
            <LoginForm />
          </Route>
          <Route path={`${path}/forgotten-password`}>
            <LoginPasswordReminderForm />
          </Route>
          <Route path={`${path}/create-password/:code`}>
            <LoginNewPasswordForm />
          </Route>
        </div>
      </div>
      {sessionExpiredModal()}
    </div>
  )
}

export default Login
