import React, { useEffect, useState } from "react"

import { handleToast } from "utils/messages"
import { getCountryFlagClassName } from "utils/strings"

import { getRecyclersAttachedToPlant } from "../../../users/service/api"
import { User } from "../../../users/types"
import { Company } from "../../types"

interface CompanyDetailsModalPlantRowProps {
  company: Company
}

const CompanyDetailsModalPlantRow = (
  props: React.PropsWithChildren<CompanyDetailsModalPlantRowProps>,
) => {
  const { company } = props

  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    getRecyclersAttachedToPlant({ id: company.id }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
        setUsers([])
      }
      if (data) {
        setUsers(data)
      }
    })
  }, [company.id])

  return (
    <tr>
      <td className="align-middle">{company.name}</td>
      <td className="align-middle text-center">
        <span className={getCountryFlagClassName(company.country?.code)} />
      </td>
      <td className="align-middle">
        {users.map((user) => (
          <div>
            <span className="mr-2">{user.name}</span>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </div>
        ))}
      </td>
    </tr>
  )
}

export default CompanyDetailsModalPlantRow
