import { useFormik } from "formik"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import * as Yup from "yup"

import Input from "../../../components/Input/Input"
import Select, {
  BaseSelectedValue,
  SelectValue,
} from "../../../components/Select/Select"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import { actionHandler } from "../../@common/actions"
import { USER_ROLES_LIST } from "../../users/constants"
import { UserRoles } from "../../users/types"
import { registerRequest } from "../service/api"
import registerService from "../service/register"
import { PersonalData } from "../types"
import RegisterPersonalFormCompany from "./RegisterPersonalFormCompany"

const RegisterPersonalForm = () => {
  const { t } = useTranslation()
  const { role, email, name } = registerService.personalData

  const [loading, setLoading] = useState(false)

  const RegisterPersonalFormValidationSchema = Yup.object().shape({
    role: Yup.object().typeError(t("Role is required")),
    name: Yup.string()
      .required(t("Full name is required"))
      .min(5, t("Full name must be at least 5 characters")),
    email: Yup.string().email("Invalid email").required(t("Email is required")),
    password: Yup.string()
      .min(6, t("Password must be at least 6 characters"))
      .required(t("Password is required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], t("Passwords do not match"))
      .required(t("Password confirm is required")),

    country: Yup.object().when("role", {
      is: (val) => {
        const valParsed = val as BaseSelectedValue
        return valParsed.value === UserRoles.RECYCLER
      },
      then: Yup.object().typeError(t("Country is required")),
      otherwise: Yup.object().nullable(),
    }),

    companyName: Yup.string().when("role", {
      is: (val) => {
        const valParsed = val as BaseSelectedValue
        return valParsed.value === UserRoles.RECYCLER
      },
      then: Yup.string()
        .required("Company name is required")
        .min(3, "Company name must be at least 6 characters"),
    }),
  })

  const handleSubmit = useCallback(
    async (credentials: PersonalData) => {
      if (!loading) {
        setLoading(true)
        registerService.personalData = credentials
        if (credentials?.role?.value) {
          actionHandler(
            registerRequest({
              ...credentials,
              ...{
                roleId: credentials.role.value,
                countryId: credentials.country
                  ? credentials.country?.value
                  : null,
              },
            }),
            () => {
              handleToast(["Account registered. \n Awaiting approval!"])
              history.push("/auth/login")
            },
          ).catch(() => {
            setLoading(false)
          })
        }
      }
    },
    [loading],
  )

  const formik = useFormik({
    initialValues: {
      role,
      email,
      name,
      password: "",
      confirmPassword: "",
      vatNumber: "",
      companyName: "",
      country: null,
    },
    validationSchema: RegisterPersonalFormValidationSchema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors, setFieldValue } = formik

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("role", selectedOption)
  }

  const prepareRolesForUser = USER_ROLES_LIST

  return (
    <form className="mb-5" onSubmit={formik.handleSubmit}>
      <div className="row mb-3">
        <label htmlFor="role" className="col-sm-4 col-form-label">
          {`${t("Company")} (*)`}
        </label>
        <div className="col-sm-8">
          <RegisterPersonalFormCompany
            setFieldValue={setFieldValue}
            handleChange={formik.handleChange}
            country={values.country}
            vatNumber={values.vatNumber}
            companyName={values.companyName}
          />
          {(touched.country && errors.country) ||
          (touched.companyName && errors.companyName) ? (
            <small className="form-text text-danger">
              {t("Company information is required")}
            </small>
          ) : null}
        </div>
      </div>
      <Select
        name="role"
        label={`${t("Choose a role / activity")} (*)`}
        options={prepareRolesForUser}
        value={values.role}
        handleOnChange={onSelectChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.role && errors.role}
      />
      <Input
        label={`${t("Full name")} (*)`}
        name="name"
        value={values.name}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.name && errors.name}
      />
      <Input
        label={`${t("Email address")} (*)`}
        type="email"
        name="email"
        value={values.email}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.email && errors.email}
        autocomplete="new-password"
      />
      <Input
        label={`${t("Password")} (*)`}
        type="password"
        name="password"
        value={values.password}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.password && errors.password}
        autocomplete="new-password"
      />
      <Input
        label={`${t("Confirm password")} (*)`}
        type="password"
        name="confirmPassword"
        value={values.confirmPassword}
        handleOnChange={formik.handleChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.confirmPassword && errors.confirmPassword}
      />
      <div className="d-flex justify-content-between">
        <Link
          className="btn btn-outline-primary rounded-bottom-left"
          to="/auth/login"
        >
          <i className="fas fa-arrow-left mr-2" /> {t("Login")}
        </Link>

        <button
          type="submit"
          className="btn btn-success rounded-bottom-right"
          disabled={loading}
        >
          {t("Submit")} <i className="fas fa-arrow-right ml-3" />
        </button>
      </div>
    </form>
  )
}

export default RegisterPersonalForm
