import axios from "axios"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { COLOR_MAP } from "../../../constants"
import useUserFromToken from "../../../hooks/useUserFromToken"
import { handleToast } from "../../../utils/messages"
import { submissionStatusChange } from "../../submissions/service/api"
import { SUBMISSION_STATUS_LIST } from "../../submissions/types"
import {
  auditAssignToAuditor,
  auditStatusChange,
  getAuditors,
  getAudits,
} from "../service/api"
import { Audit, AuditStatus } from "../types"
import AssignAuditor from "./modals/AssingAuditor"
import FinishAudit from "./modals/FinishAudit"

const AuditList = () => {
  const [audits, setAudits] = useState<Audit[]>()
  const [auditors, setAuditors] = useState<any[]>()
  const [showAuditorsModal, setShowAuditorsModal] = useState<boolean>(false)
  const [showAuditUploadModal, setShowAuditUploadModal] =
    useState<boolean>(false)
  const [auditInAssigning, setAuditInAssigning] = useState<number>()
  const [selectedAuditor, setSelectedAuditor] = useState<any>()
  const [selectedFile, setSelectedFile] = useState<string | Blob>()
  const { isAdmin } = useUserFromToken()
  const [submissionSelectedStatus, setSubmissionSelectedStatus] =
    useState<any>()
  const [totalFinishedAudits, setTotalFinishedAudits] = useState<number>(0)
  const { t } = useTranslation()

  const companyId = parseInt("116")

  const getAuditsList = useCallback(() => {
    getAudits({ companyId }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        setAudits(data)
      }
    })
  }, [companyId])

  useEffect(() => {
    getAuditsList()
  }, [getAuditsList])

  const handleShowAuditorsModal = (audit: Audit) => {
    console.log(audit)
    getAuditors({ companyId: audit.submission.company.id }).then(
      ({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }

        if (data) {
          setAuditors(data)
          setShowAuditorsModal(true)
          setAuditInAssigning(audit.id)
        }
      },
    )
  }

  const handleSetShowAuditUploadModal = (auditId: number) => {
    setAuditInAssigning(auditId)
    setShowAuditUploadModal(true)
  }

  const assignAuditorToAudit = () => {
    const auditorId = parseInt(selectedAuditor.value, 0)
    auditAssignToAuditor({ id: auditInAssigning, auditorId }).then(
      ({ errors }) => {
        if (errors) {
          handleToast(errors, "error")
        } else {
          handleToast(["Auditor assigned"])
          getAuditsList()
          setShowAuditorsModal(false)
        }
      },
    )
  }

  const handleAuditStatusChange = (auditId: number, status: number) => {
    auditStatusChange({ id: auditId, status }).then(({ errors }) => {
      if (errors) {
        handleToast(errors, "error")
      } else {
        handleToast(["Audit started"])
        getAuditsList()
      }
    })
  }

  const handleUploadFile = (event: any) => {
    event.preventDefault()
    const formData = new FormData()
    if (selectedFile) {
      formData.append("file", selectedFile)
    }

    const url = `http://localhost:4000/audit/uploadfile`
    axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          submissionStatusChange({
            id: response.data.submissionId,
            status: submissionSelectedStatus.value,
          }).then(({ errors }) => {
            if (errors) {
              handleToast(errors, "error")
            } else {
              handleToast([response.data.message])
              setShowAuditUploadModal(false)
              getAuditsList()
            }
          })
        } else {
          handleToast([response.data.message], "error")
        }
      })
      .catch(() => {
        handleToast(
          ["Only document files are allowed! (PDF, DOC, DOCX)"],
          "error",
        )
      })
  }

  const handleSetSelectedFile = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  const handleDownloadDocument = (doc: any, auditId: number) => {
    console.log(doc.fileName)
    const url = `/audit/${doc.downloadHash}/${auditId}`
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", doc.fileName.replace(" ", "")) // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  const handleSubmissionStatusChange = (selectedOption: any) => {
    setSubmissionSelectedStatus(selectedOption)
  }

  useEffect(() => {
    getAuditsList()
  }, [getAuditsList])

  return (
    <>
      <span style={{ width: "50%" }}>{t("Finished audits in percentage")}</span>
      <div
        style={{ width: "50%", height: "35px" }}
        className="progress w-100 col-9"
      >
        <div
          className={`progress-bar text-left pl-2 ${COLOR_MAP[3]}`}
          role="progressbar"
          style={{ width: `${totalFinishedAudits}%`, minWidth: "1%" }}
        >
          ({totalFinishedAudits} %)
        </div>
        <div style={{ width: `100%`, minWidth: "50%" }} />
      </div>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="text-center">{t("L.P")}</th>
            <th className="text-center">{t("Company")}</th>
            <th className="text-center">{t("Created at")}</th>
            <th className="text-center">{t("Volume Gross")}</th>
            <th className="text-center">{t("Volume Net")}</th>
            <th className="text-center">{t("Total")}</th>
            {isAdmin && (
              <th className="text-center">{t("Assigned auditor")}</th>
            )}
            <th className="text-center">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {(audits || []).map((audit: Audit, key) => {
            // const submissionType = audit.submission.isInput ? 'input' : 'output';
            return (
              <tr key={key}>
                <td>{key + 1}</td>
                {/* <td className="text-center">{audit.submission.company.name}</td> */}
                <td className="text-center">{audit.createdAt}</td>
                <td className="text-center">
                  {/* {audit.submission.volumeGross} */}
                </td>
                <td className="text-center">
                  {/* {audit.submission.volumeNet} */}
                </td>
                <td className="text-center">
                  {/* {audit.submission.volumeGross + audit.submission.volumeNet} */}
                </td>
                {isAdmin && (
                  <td className="text-center">
                    {audit.auditor !== null ? audit.auditor.name : "-"}
                  </td>
                )}
                <td>
                  {audit.status === 1 && (
                    <div className="row">
                      <div className="col-12">
                        <button
                          className="btn btn-outline-success btn-sm text-left"
                          style={{ width: "100%" }}
                          onClick={() => {
                            handleShowAuditorsModal(audit)
                          }}
                        >
                          {t("Assign auditor")}
                        </button>
                      </div>
                    </div>
                  )}
                  {audit.status === 2 && (
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin ? (
                          <button
                            className="btn btn-outline-success btn-sm text-left"
                            style={{ width: "100%" }}
                            onClick={() =>
                              handleAuditStatusChange(
                                audit.id,
                                AuditStatus.IN_PROGRESS,
                              )
                            }
                          >
                            {t("Start audit")}
                          </button>
                        ) : (
                          <div className="text-center">-</div>
                        )}
                      </div>
                    </div>
                  )}
                  {audit.status === 3 && (
                    <>
                      <div className="row">
                        <div className="col-12">
                          {/* <Link
                        className="btn btn-outline-primary btn-sm text-left"
                        style={{width: '100%'}}
                        to={`submissions/show/${audit.submission.id}/${audit.submission.isInput ? 'input' : 'output'}`}
                      >
                        {t('Show submission')}
                      </Link> */}
                        </div>
                      </div>
                      {!isAdmin && (
                        <div className="row mt-1">
                          <div className="col-12">
                            <button
                              className="btn btn-outline-info btn-sm text-left"
                              style={{ width: "100%" }}
                              onClick={() =>
                                handleSetShowAuditUploadModal(audit.id)
                              }
                            >
                              {t("Finish audit")}
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {audit.status === 4 &&
                    audit.document &&
                    audit.document.map((document: any, index: number) => {
                      return (
                        <div className="row mt-1" key={index}>
                          <div className="col-12">
                            <button
                              className="btn btn-outline-info"
                              onClick={() =>
                                handleDownloadDocument(document, audit.id)
                              }
                            >
                              {t("Download")} {document.fileName}
                            </button>
                          </div>
                        </div>
                      )
                    })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {auditors && (
        <AssignAuditor
          showAuditorsModal={showAuditorsModal}
          setShowAuditorsModal={setShowAuditorsModal}
          auditors={auditors}
          selectedAuditor={selectedAuditor}
          setSelectedAuditor={setSelectedAuditor}
          assignAuditorToAudit={assignAuditorToAudit}
        />
      )}
      <FinishAudit
        showAuditUploadModal={showAuditUploadModal}
        setShowAuditUploadModal={handleSetShowAuditUploadModal}
        handleUploadFile={handleUploadFile}
        setSelectedFile={handleSetSelectedFile}
        submissionStatusList={SUBMISSION_STATUS_LIST}
        onSubmissionStatusChange={handleSubmissionStatusChange}
        submissionSelectedStatus={submissionSelectedStatus}
      />
    </>
  )
}

export default AuditList
