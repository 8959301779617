import { restHelper } from "../../@common/service/api"
import { Contract } from "../types"

export const queries = {
  contract: `
    query contract(
      $companyId: Int
    ) {
      contract(
        companyId: $companyId
      ) {
        id
        hash
        fileName
        mimeType
        fileType
        downloadLink
        paymentStatus
        paymentAmount
        createdBy {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  `,
}

export const getContract = restHelper<Contract, { companyId: number }>(
  queries,
  "contract",
)
