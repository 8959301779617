import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { actionHandler } from "../@common/actions"
import { UpdateTreeDragAndDropInput } from "../@common/types"
import { FETCH_MATERIALS, FETCH_MATERIAL_GROUPS } from "./constants"
import material from "./reducer"
import {
  getMaterials,
  getMaterialGroups,
  postMaterial,
  putMaterialTree,
  deleteMaterial,
  Material,
  MaterialGroup,
  PostMaterialInput,
  DeleteMaterialInput,
} from "./service/api"

export interface SetMaterials {
  type: string
  payload: { materials: Material[] }
}

export interface SetMaterialGroups {
  type: string
  payload: { materialGroups: MaterialGroup[] }
}

export const setMaterials = (payload: {
  materials: Material[]
}): SetMaterials => ({
  type: FETCH_MATERIALS,
  payload,
})

export const setMaterialGroups = (payload: {
  materialGroups: MaterialGroup[]
}): SetMaterialGroups => ({
  type: FETCH_MATERIAL_GROUPS,
  payload,
})

export const fetchMaterials = () => async (dispatch: Dispatch) => {
  actionHandler(getMaterials({}), (materials) => {
    dispatch(setMaterials({ materials }))
  })
}

export const fetchMaterialGroups = () => async (dispatch: Dispatch) => {
  actionHandler(getMaterialGroups({}), (materialGroups) => {
    materialGroups.sort((a: MaterialGroup, b: MaterialGroup) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    dispatch(setMaterialGroups({ materialGroups }))
  })
}

export const createMaterial =
  (credentials: PostMaterialInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postMaterial(credentials), () => {
      dispatch(fetchMaterials())
    })
  }

export const updateMaterialTree =
  (credentials: UpdateTreeDragAndDropInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(putMaterialTree(credentials), () => {
      dispatch(fetchMaterials())
    })
  }

export const deleteMaterialTree =
  (credentials: DeleteMaterialInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(deleteMaterial(credentials), () => {
      dispatch(fetchMaterials())
    })
  }

export type MaterialActions = SetMaterials
