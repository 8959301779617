import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import GenericCompositionDetailContainer from "./containers/GenericCompositionDetailContainer"
import GenericCompositionListContainer from "./containers/GenericCompositionListContainer"
import GenericCompositionNewContainer from "./containers/GenericCompositionNewContainer"
import { CompositionType } from "./enums"

const GenericCompositions = () => {
  const { path } = useRouteMatch()
  const type = path.replace("/", "") as CompositionType
  return (
    <>
      <Route exact path={path}>
        <GenericCompositionListContainer type={type} />
      </Route>
      <Route exact path={`${path}/new`}>
        <GenericCompositionNewContainer type={type} />
      </Route>
      <Route exact path={`${path}/show/:id`}>
        <GenericCompositionDetailContainer type={type} />
      </Route>
    </>
  )
}

export default GenericCompositions
