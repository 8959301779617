import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import CompaniesList from "./containers/CompaniesList"
import CompanyEdit from "./containers/CompanyEdit"

// TODO check if we need this module

const Plants = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Route exact path={path}>
        <CompaniesList />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <CompanyEdit />
      </Route>
    </>
  )
}

export default Plants
