import React, { useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom"

import { getNotification } from "../service/api"
import { Notification } from "../types"
import NotificationView from "../components/NotificationView"

const NotificationDetail = () => {
  const [notification, setNotification] = useState<Notification>()
  const { params }: { params: { id: string } } = useRouteMatch()
  const id = parseInt(params.id)
  useEffect(() => {
    if (id) {
      getNotification({ id }).then(({ data }) => {
        setNotification(data)
      })
    }
  }, [id])

  return (
    <>
    {notification && (
      <NotificationView notification={notification} />
    ) }
    </>
  )
}

export default NotificationDetail
