export const ACCESS_TOKEN_KEY = "__srs_access-token__"
export const REFRESH_TOKEN_KEY = "__srs_refresh-token__"
export const TOKEN_EXPIRED = "__srs_token-expired__"
export const ACTIVE_COMPANY_KEY = "__srs_active_company__"
export const ACTIVE_PLANT_ID_KEY = "__srs_active_company_id__"
export const USER_METADATA_KEY_PREFIX = "__umd__"
export const ACTIVE_LANGUAGE_KEY = "__srs_active_language__"

export const DEFAULT_DATE_FORMAT = "MM/DD/yyyy"
export const DEFAULT_DATE_FORMAT_USER_MANAGEMENT = "DD/MM/yyyy"

export const DEFAULT_LANGUAGE = "uk"

export const COLOR_MAP: { [key: number]: string } = {
  0: "color-blue",
  1: "color-red",
  2: "color-orange",
  3: "color-green ",
  4: "color-violet",
  5: "color-cyan",
  6: "color-pink",
  7: "color-blue",
  8: "color-red",
  9: "color-orange",
  10: "color-green ",
  11: "color-violet",
  12: "color-cyan",
  13: "color-pink",
}

export const EuCountriesArray = [
  "AT",
  "IT",
  "BE",
  "LV",
  "BG",
  "LT",
  "HR",
  "LU",
  "CY",
  "MT",
  "CZ",
  "NL",
  "DE",
  "PL",
  "EE",
  "PT",
  "FI",
  "RO",
  "FR",
  "SK",
  "DE",
  "SI",
  "EL",
  "ES",
  "HU",
  "SE",
  "IE",
  "EN",
  "DK",
  "CH",
]

export const ADDITIONAL_INFO_LINK = "https://www.polyrec.eu/faq"
export const CONTACTS_LINK = "https://www.polyrec.eu/contact"
export const PRIVACY_AND_POLICY_LINK = "https://www.polyrec.eu/privacy-policy"
export const RECOTRACE_LINK = "https://www.polyrec.eu/recotrace"
