import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import useUserFromToken from "../../../hooks/useUserFromToken"
import { getCountryFlagClassName } from "../../../utils/strings"
import ConfirmButton from "../../../views/partials/ConfirmButton"
import CompanyDetailsModal from "../components/ManageCompanies/CompanyDetailsModal"
import CompanyRoleLabel from "../components/ManageCompanies/CompanyRoleLabel"
import { Company } from "../types"

interface PlantsListTableProps {
  companies: Company[]
  handleOnApproveActionClick: (company: Company) => void
  handleOnRejectActionClick: (company: Company) => void
  handleOnSelectCompanyActionClick: (company: Company, path: string) => void
}

const PlantsListTable = (
  props: React.PropsWithChildren<PlantsListTableProps>,
) => {
  const { t } = useTranslation()
  const {
    companies,
    handleOnApproveActionClick,
    handleOnRejectActionClick,
    handleOnSelectCompanyActionClick,
  } = props
  const { isAdmin, isRecycler } = useUserFromToken()

  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0)
  const [isCompanyDetailsOpened, setIsCompanyDetailsOpened] =
    useState<boolean>(false)

  const onCloseCompanyDetailsModal = () => {
    setIsCompanyDetailsOpened(false)
  }

  const showDetailsModal = (company: Company) => {
    setSelectedCompanyId(company.id || 0)
    setIsCompanyDetailsOpened(true)
  }

  const [sortKey, setSortKey] = React.useState<string>("approved")
  const [sortDesc, setSortDesc] = React.useState<boolean>(true)

  const requestSort = (key: string) => {
    if (key === sortKey) {
      setSortDesc(!sortDesc)
    } else {
      setSortKey(key)
    }
  }

  const sortedCompanies = useMemo(() => {
    const sortableItems = [...companies]
    sortableItems.sort((a: any, b: any) => {
      if (sortKey === "country") {
        if (a.country.name.toLowerCase() < b.country.name.toLowerCase()) {
          return sortDesc ? 1 : -1
        }
        if (a.country.name.toLowerCase() > b.country.name.toLowerCase()) {
          return sortDesc ? -1 : 1
        }
      } if (sortKey === "approved") {
        // Converting boolean value to number(0 or 1) and compare
        return Number(a.approved) - Number(b.approved);
      } else {
        if (a[sortKey].toLowerCase() < b[sortKey].toLowerCase()) {
          return sortDesc ? 1 : -1
        }
        if (a[sortKey].toLowerCase() > b[sortKey].toLowerCase()) {
          return sortDesc ? -1 : 1
        }
      }
      return 0
    })
    const sortableItemsFilter = sortableItems.filter(
      (item) => item.archived === false,
    )
    return sortableItemsFilter
  }, [companies, sortDesc, sortKey])

  const companyRow = (company: Company) => (
    <tr key={company.id}>
      <td className="align-middle">
        <div>
          <span
            className="btn-link"
            onClick={() => company && showDetailsModal(company)}
          >
            {company.name}
          </span>
          {!company.approved ? (
            <span className="text-danger">{t("(not approved)")}</span>
          ) : null}
        </div>
      </td>
      <td className="align-middle">
        <CompanyRoleLabel role={company.roleId} />
      </td>
      <td className="align-middle text-center">
        <span className={getCountryFlagClassName(company.country?.code)} />
      </td>
      <td className="text-center">
        {company.approved ? (
          <>
            <div className="row mt-1">
              <div className="col-12">
                <Link
                  to={{
                    pathname: `/submissions/new`,
                    state: {
                      title: t("New Submissions"),
                    },
                  }}
                  onClick={() =>
                    handleOnSelectCompanyActionClick(
                      company,
                      "/submissions/new",
                    )
                  }
                  className="btn btn-outline-success btn-sm text-left"
                  style={{ fontSize: "16px", width: "100%" }}
                >
                  <i className="fas fa-plus" /> {t("New Submissions")}
                </Link>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm text-left"
                  style={{ fontSize: "16px", width: "100%" }}
                  onClick={() =>
                    handleOnSelectCompanyActionClick(company, "/submissions")
                  }
                >
                  <i className="fas fa-align-left" />{" "}
                  {t("Manage submitted data")}
                </button>
              </div>
            </div>
          </>
        ) : null}

        <div className="row mt-1">
          <div className="col-12">
            <Link
              to={{
                pathname: `/plants/edit/${company.id}`,
                state: {
                  title: t("Update Plant"),
                },
              }}
              className="btn btn-outline-warning btn-sm text-left"
              style={{ fontSize: "16px", width: "100%" }}
            >
              <i className="fas fa-edit" /> {t("Edit")}
            </Link>
          </div>
        </div>
        {isAdmin && !company.approved && (
          <div className="row mt-1">
            <div className="col-12">
              <ConfirmButton
                title="Approve plant"
                text="Are you sure you wish to approve this plant?"
                confirmText="Approve"
                icon="question"
                className="btn btn-outline-success btn-sm text-left w-100"
                confirmClassName="btn-outline-success"
                onConfirm={() => handleOnApproveActionClick(company)}
              >
                <i className="fas fa-check" /> {t("Approve")}
              </ConfirmButton>
            </div>
          </div>
        )}
        {!isRecycler && !isAdmin && company.approved && (
          <div className="row mt-1">
            <div className="col-12">
              <ConfirmButton
                title="Reject plant"
                text="Are you sure you wish to reject this plant?"
                confirmText="Reject"
                icon="question"
                className="btn btn-outline-danger btn-sm text-left w-100"
                confirmClassName="btn-outline-danger"
                onConfirm={() => handleOnRejectActionClick(company)}
              >
                <i className="fas fa-trash" /> {t("Delete plant")}
              </ConfirmButton>
            </div>
          </div>
        )}
      </td>
    </tr>
  )

  return (
    <>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="cursor-pointer" onClick={() => requestSort("name")}>
              {t("Plant name")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th className="text-center">{t("Activity / role")}</th>
            <th
              className="text-center cursor-pointer"
              onClick={() => requestSort("country")}
            >
              {t("Country")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th className="text-center actions-col">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {sortedCompanies.length ? (
            sortedCompanies.map((company) => companyRow(company))
          ) : (
            <tr>
              <td className="align-middle text-center" colSpan={4}>
                {t("There is no data to display")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <CompanyDetailsModal
        {...{
          companyId: selectedCompanyId,
          isPlant: true,
          closeModal: onCloseCompanyDetailsModal,
          isCompanyDetailsOpened,
        }}
      />
    </>
  )
}

export default PlantsListTable
