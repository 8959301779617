import { Submission } from "modules/submissions/types"

import { EuCountriesArray } from "../../../constants"
import { convertingActivity, recyclingActivity } from "../types"

export const getAuditStatisticFromSubmissions = (
  submissions: Submission[],
): {
  recycle: Array<recyclingActivity>
  convert: Array<convertingActivity>
} => {
  const generatedAuditStatistic = submissions.reduce(
    (acc, curr) => {
      const isInput = curr.isInput
      const materialGroupName = curr?.materialsGroup?.name || null
      const wasteType =
        curr?.wasteType === "Pre-C" ? "preConsumer" : "postConsumer"
      const submissionType = curr.type
      const sector =
        curr?.composition?.productName
          ?.split(" - ")
          .slice(1)
          .join(" - ")
          .replace(/Post\-Consumer|Pre\-Consumer|\(|\)/gi, "") || "Other"
      const submissionCountryFromEUOrNotEU = curr.country
        ? EuCountriesArray.includes(curr.country.code)
          ? "eu"
          : "nonEu"
        : "nonEu"
      const outputEU = (curr?.netOutputEU as any) || 0
      const netOutputNonEU = (curr?.netOutputNonEU as any) || 0
      const volumeGross = (curr?.volumeGross as any) || 0
      const volumeNet = (curr?.volumeNet as any) || 0

      if (materialGroupName) {
        if (submissionType) {
          // submissionType value is 1, that's mean 'Converting'
          if (!acc.convert[materialGroupName]) {
            acc.convert[materialGroupName] = {
              material: materialGroupName,
              eu: {
                products: [],
                totalInput: 0,
                totalOutput: 0,
              },
              nonEu: {
                products: [],
                totalInput: 0,
                totalOutput: 0,
              },
              totalOutput: 0,
            }
          }

          if (isInput) {
            acc.convert[materialGroupName][
              submissionCountryFromEUOrNotEU
            ].totalInput += volumeNet
          } else {
            const euSectorOutput =
              curr.volumeNet -
              ((curr.estimatedShareExportedOutsideEu / 100) * curr.volumeNet +
                (curr.estimatedShareExportedInsideEu / 100) * curr.volumeNet)
            const noneuSectorOutput =
              (curr.estimatedShareExportedOutsideEu / 100) * curr.volumeNet +
              (curr.estimatedShareExportedInsideEu / 100) * curr.volumeNet

            const sectorOutput = euSectorOutput + noneuSectorOutput

            acc.convert[materialGroupName]["eu"].products.push({
              sector: sector === "Other" ? "" : sector,
              sectorOutput: euSectorOutput,
            })

            acc.convert[materialGroupName]["nonEu"].products.push({
              sector: sector === "Other" ? "" : sector,
              sectorOutput: noneuSectorOutput,
            })

            acc.convert[materialGroupName].eu.totalOutput += euSectorOutput
            acc.convert[materialGroupName].nonEu.totalOutput +=
              noneuSectorOutput
            acc.convert[materialGroupName].totalOutput += sectorOutput
          }
        } else {
          // submissionType value is 0, that's mean 'Recycling'
          if (!acc.recycle[materialGroupName]) {
            acc.recycle[materialGroupName] = {
              material: materialGroupName,
              eu: {
                postConsumer: [],
                preConsumer: [],
                totalOutput: 0,
              },
              nonEu: {
                postConsumer: [],
                preConsumer: [],
                totalOutput: 0,
              },
              totalInput: 0,
              totalInputRecycling: 0,
              totalOutput: 0,
            }
          }

          if (isInput) {
            acc.recycle[materialGroupName][submissionCountryFromEUOrNotEU][
              wasteType
            ].push({
              sector,
              totalInput: volumeNet,
              totalInputRecycling: volumeGross,
            })
            acc.recycle[materialGroupName].totalInput += volumeNet
            acc.recycle[materialGroupName].totalInputRecycling += volumeGross
          } else {
            acc.recycle[materialGroupName].eu.totalOutput += outputEU
            acc.recycle[materialGroupName].nonEu.totalOutput += netOutputNonEU
            acc.recycle[materialGroupName].totalOutput +=
              outputEU + netOutputNonEU
          }
        }
      }

      return acc
    },
    {
      recycle: {} as Record<string, recyclingActivity>,
      convert: {} as Record<string, convertingActivity>,
    },
  )

  return {
    recycle: Object.values(generatedAuditStatistic.recycle),
    convert: Object.values(generatedAuditStatistic.convert),
  }
}
