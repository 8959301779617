import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { history } from "../../../utils/routes"
import { getNotifications, acceptNotification } from "../service/api"
import { Notification, NotificationAudit } from "../../notifications/types"
import { Link } from "react-router-dom"
import { handleToast } from "utils/messages"
import Modal from "components/Modal/Modal"
import NotificationView from "modules/notifications/components/NotificationView"

const UserNotifications = () => {
  const [notificationsAud, setNotificationsAud] = useState<NotificationAudit[]>([])
  const [notification, setNotification] = useState<Notification>()
  const [showNotification, setShowNotification] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    getNotifications().then((response) => {
      const { data } = response
      if (data) {
        setNotificationsAud(data)
      }
    })
  }, [])

  const checkNotification = (notification: Notification) => {
    setNotification(notification)
    setShowNotification(true)
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="mb-5">{t("Check notifications")}</h1>
      </div>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="col-sm-9">{t("Title")}</th>
            <th className="col-sm-2">{t("Accepted")}</th>
            <th className="col-sm-1"></th>
          </tr>
        </thead>
        <tbody>
          {notificationsAud.map((na) => {
            if (na.notification)
            return (
              <tr key={na.id}>
                <td className="align-middle">
                  {na.notification.subject}
                </td>
                <td className="align-middle">{na.acceptAt}</td>
                <td>
                <button
                  type="button"
                  className="btn btn-outline-warning btn-sm"
                  style={{ width: "100%" }}
                  onClick={() => {if (na.notification) checkNotification(na.notification)}}
                >
                  <i className="fas fa-edit" /> {t("Check")}
                </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      { notification && (
        <Modal
          isOpen={showNotification}
          isOpenHandler={setShowNotification}
          wrapperClass="srs-modal-lg"
        >
          <div className="modal-header">
            <h3 className="modal-title">
              <strong>{t("Notification")}</strong>
            </h3>
          </div>
          <div className="modal-body">
            <NotificationView notification={notification} ></NotificationView>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary rounded-bottom-left"
              onClick={() => setShowNotification(!showNotification)}
            >
              {t("Close")}
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default UserNotifications
