import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import AuthGuard from "components/AuthGuard/AuthGuard"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"

import useUserFromToken from "hooks/useUserFromToken"

import Audit from "../../../Audit/Audit"
import ManageAudtis from "../../../Audit/components/ManageAudits"
import { UserRoles } from "../../../users/types"
import { getAllPlantsList, getPlants } from "../../service/api"
import { Company } from "../../types"
import ManageCompaniesTableRrr from "./ManageCompaniesTableRrr"
import RequestCompanyAccess from "./RequestCompanyAccess"

const ManageCompanies = () => {
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState("companies")

  const [companies, setCompanies] = useState<Company[]>([])
  const [allPlants, setAllPlants] = useState<Company[]>([])
  const { isSectorAssociationRepresentative } = useUserFromToken()

  const plantsWithoutAccess = useMemo(
    () => allPlants.filter((p) => !companies.find((c) => c.id === p.id)),
    [allPlants, companies],
  )

  useEffect(() => {
    getPlants({}).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setCompanies(data)
      }
    })
  }, [])

  useEffect(() => {
    getAllPlantsList().then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setAllPlants(data)
      }
    })
  }, [])

  const onRequestAccessSubmitted = () => {
    setCurrentTab("companies")
  }

  const onChangeTabClicked = (
    e: React.MouseEvent<HTMLAnchorElement>,
    value: string,
  ) => {
    e.preventDefault()
    setCurrentTab(value)
  }

  const getTabs = () => {
    return (
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item">
          <a
            href="#companies"
            className={`nav-link ${
              currentTab === "companies" ? "active" : ""
            } `}
            data-toggle="pill"
            role="tab"
            aria-controls="pills-inputData"
            aria-selected="true"
            onClick={(e) => onChangeTabClicked(e, "companies")}
          >
            {t("My companies")}
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#completed-audits"
            className={`nav-link ${
              currentTab === "completedAudits" ? "active" : ""
            }`}
            data-toggle="pill"
            role="tab"
            aria-controls="pills-outputData"
            aria-selected="false"
            onClick={(e) => onChangeTabClicked(e, "completedAudits")}
          >
            {t("Completed Audits")}
          </a>
        </li>
        {!isSectorAssociationRepresentative && (
          <li className="nav-item">
            <a
              href="#access"
              className={`nav-link ${currentTab === "access" ? "active" : ""}`}
              data-toggle="pill"
              role="tab"
              aria-controls="pills-outputData"
              aria-selected="false"
              onClick={(e) => onChangeTabClicked(e, "access")}
            >
              {t("Request access")}
            </a>
          </li>
        )}
      </ul>
    )
  }

  return (
    <AuthGuard
      roles={[
        UserRoles.AUDITOR,
        UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
        UserRoles.SECTOR_ASSOCIATION_REPRESENTATIVE,
      ]}
    >
      <h1 className="mb-5">{t("Manage companies")}</h1>

      {getTabs()}

      {currentTab === "companies" && (
        <ManageCompaniesTableRrr companies={companies} />
      )}
      {currentTab === "completedAudits" && <ManageAudtis />}
      {currentTab === "access" && (
        <RequestCompanyAccess
          companies={plantsWithoutAccess}
          onSubmitted={onRequestAccessSubmitted}
        />
      )}
      {currentTab === "audits" && <Audit />}

      <button
        type="button"
        className="btn btn-outline-primary rounded-bottom-left"
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left mr-2" /> {t("Back")}
      </button>
    </AuthGuard>
  )
}

export default ManageCompanies
