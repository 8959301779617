import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { TreeNode } from "../../../components/Tree/Tree"
import useStateGetter from "../../../hooks/useStateGetter"
import { fetchPlants } from "../../companies/actions"
import { fetchProducts } from "../../products/actions"
import GenericCompositionForm from "../components/GenericCompositionForm/GenericCompositionForm"
import { CompositionType } from "../enums"

interface Props {
  type: CompositionType
}

const GenericCompositionNewContainer: React.FC<Props> = ({ type }) => {
  const dispatch = useDispatch()

  const plants = useStateGetter<any>(["company", "plants"])

  const products = useStateGetter<TreeNode[]>(["product", "products"]) ?? []

  const selectedCompany = useStateGetter<any>(["submission", "selectedCompany"])

  const allowedPolymers = selectedCompany.polymers.map(({ name }: any) => name.toLowerCase())
  const filteredProducts = products.filter((product) =>
    allowedPolymers.includes(product.title.toLowerCase()),
  )

  useEffect(() => {
    dispatch(fetchProducts(true))
    dispatch(fetchPlants())
  }, [dispatch])

  return (
    <GenericCompositionForm
      products={filteredProducts}
      plants={plants}
      type={type}
      selectedCompany={selectedCompany}
    />
  )
}

export default GenericCompositionNewContainer
