/* eslint-disable @typescript-eslint/no-empty-function */
import React, { DragEvent, FC, useState } from "react"

import "./Tree.scss"

export interface TreeNode {
  id: number
  title: string
  visible?: boolean
  selectable?: boolean
  children: TreeNode[]
  parentIds?: number[]
  level?: number
  wasteType?: string
}

export type BranchActionHandler = (
  node: TreeNode,
  actionName: string,
  value?: unknown,
) => unknown

export interface BranchProps {
  node: TreeNode
  actionHandler: BranchActionHandler
  level?: number
}

interface TreeProps {
  nodes: TreeNode[]
  Branch?: FC<BranchProps>
  branchActionHandler?: BranchActionHandler
  dragEndCallback?: (
    dragedElement: number,
    targetElement: number,
    isIndentElement: boolean,
  ) => void
  isDraggable?: boolean
  level?: number
}

const getIsIndentElement = (target: HTMLElement | null, event: DragEvent) => {
  return !target?.dataset?.divider
}

let targetElement: HTMLElement | null = null
let targetElementId = "0"

const RanderTree = ({
  nodes,
  dragEndCallback,
  Branch,
  branchActionHandler = () => {},
  isDraggable = false,
  level = 1,
}: TreeProps) => {
  const [expandedIds, setExpandedIds] = useState<number[]>([])

  const handleOnDragOver = (event: DragEvent) => {
    const target = event.target as HTMLElement
    if (target?.dataset?.id) {
      targetElement = target
      targetElementId = target?.dataset?.id

      target.classList.add("bg-primary")

      const isIndentElement = getIsIndentElement(target, event)
      if (isIndentElement) {
        target.classList.add("bg-success")
      } else {
        target.classList.remove("bg-success")
      }
    }

    if (targetElement && target !== targetElement) {
      // eslint-disable-next-line no-unused-expressions
      targetElement?.classList.remove("bg-primary")
      // eslint-disable-next-line no-unused-expressions
      targetElement?.classList.remove("bg-success")
    }
  }

  const handleOnDragLeave = (event: DragEvent) => {
    const target = event.target as HTMLElement
    // if (target.tagName === 'LI') {
    target.classList.remove("bg-primary")
    target.classList.remove("bg-success")
    // }
  }

  const handleOnDragStart = (event: DragEvent) => {
    const dragedElement = event.currentTarget as HTMLElement
    console.log(dragedElement)
    if (dragedElement?.dataset?.id) {
      dragedElement.classList.add("bg-secondary")
    }
  }

  const handleOnDragEnd = (event: DragEvent) => {
    const dragedElement = event.target as HTMLElement

    if (dragedElement !== null) {
      dragedElement.classList.remove("bg-secondary")

      const isIndentElement = getIsIndentElement(targetElement, event)
      const dragedElementId = dragedElement?.dataset?.id

      if (
        dragEndCallback &&
        dragedElementId &&
        dragedElementId !== targetElementId
      ) {
        dragEndCallback(
          parseInt(dragedElementId, 0),
          parseInt(targetElementId, 0),
          isIndentElement,
        )
      }
      dragedElement.style.top = "0px"
      // eslint-disable-next-line no-unused-expressions
      targetElement?.classList.remove("bg-primary")
      // eslint-disable-next-line no-unused-expressions
      targetElement?.classList.remove("bg-success")
    }
  }

  const handleSetExpandedIds = (id: number) => {
    let expandedIdsContainer = []
    if (expandedIds.includes(id)) {
      expandedIdsContainer = expandedIds.filter(
        (expandedId) => expandedId !== id,
      )
    } else {
      expandedIdsContainer = [...expandedIds, id]
    }
    setExpandedIds(expandedIdsContainer)
  }

  return (
    <div className="srs-tree">
      {nodes.map((node: TreeNode) => {
        let firstChildrenIds: number[] = []
        if (node.children.length) {
          firstChildrenIds = node.children.map((ch) => ch.id)
        }

        return (
          <ul
            className={`srs-node mb-1 w-auto ${
              expandedIds.includes(node.id) ? "expanded" : "convoluted"
            } srs-tree-element-${node.id}`}
            key={node.id}
            data-id={node.id}
            data-first-children-ids={firstChildrenIds}
            style={{ visibility: "visible" }}
          >
            <li
              className="label d-flex align-items-center position-relative transition"
              draggable={isDraggable}
              onDragStart={handleOnDragStart}
              onDragOver={handleOnDragOver}
              onDragLeave={handleOnDragLeave}
              onDragEnd={handleOnDragEnd}
              data-id={node.id}
              data-parent-ids={node.parentIds ?? []}
            >
              {node.children && node.children.length > 0 && (
                <button
                  type="button"
                  className="btn btn-link pl-0 col-1"
                  onClick={() => handleSetExpandedIds(node.id)}
                >
                  <i className="fa fa-chevron-down" />
                </button>
              )}
              {node.children && node.children.length === 0 && (
                <div className="col-1"> </div>
              )}
              {Branch ? (
                <Branch
                  node={node}
                  actionHandler={branchActionHandler}
                  level={level}
                />
              ) : (
                node.title
              )}
              <div
                data-id={node.id}
                data-parent-ids={node.parentIds ?? []}
                data-divider="true"
                className="drag-divider"
              />
            </li>
            {node.children && node.children.length > 0 && (
              <RanderTree
                nodes={node.children}
                dragEndCallback={dragEndCallback}
                Branch={Branch}
                branchActionHandler={branchActionHandler}
                isDraggable={isDraggable}
                level={level + 1}
              />
            )}
          </ul>
        )
      })}
    </div>
  )
}

const insertToNodeParentIdsExtension =
  (nodeParentId: number | null = null, parentIds: number[] = []) =>
  (node: TreeNode): TreeNode => {
    let parentIdsCopy = parentIds
    if (nodeParentId && parentIds.includes(nodeParentId) === false) {
      parentIdsCopy = [...parentIds, nodeParentId]
    }
    return {
      id: node.id,
      title: node.title,
      visible: node.visible,
      selectable: node.selectable,
      wasteType: node.wasteType,
      children: node.children
        ? node.children.map(
            insertToNodeParentIdsExtension(node.id || null, parentIdsCopy),
          )
        : [],
      parentIds: parentIdsCopy,
    }
  }

const Tree = ({
  nodes,
  dragEndCallback,
  Branch,
  branchActionHandler = () => {},
  isDraggable = false,
}: TreeProps) => {
  const nodesWithParentIds = nodes.map(insertToNodeParentIdsExtension())
  return (
    <RanderTree
      nodes={nodesWithParentIds}
      dragEndCallback={dragEndCallback}
      Branch={Branch}
      branchActionHandler={branchActionHandler}
      isDraggable={isDraggable}
      level={1}
    />
  )
}

export const polimerTreeUpdateData = (dragedElement: number): number[] => {
  const childrenIds: number[] = []
  const dragedElementChildQueryResult: HTMLUListElement | null =
    document.querySelector(`ul.srs-tree-element-${dragedElement}`)
  if (dragedElementChildQueryResult) {
    const { firstChildrenIds } = dragedElementChildQueryResult.dataset
    if (firstChildrenIds) {
      firstChildrenIds.split(",").forEach((firstChildId) => {
        childrenIds.push(parseInt(firstChildId, 0))
      })
    }
  }
  return childrenIds
}

export default Tree
