import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { actionHandler } from "../@common/actions"
import { FETCH_COMPOSITIONS } from "./constants"
import { getCompositions, postComposition } from "./service/api"
import { Composition, CompositionPost } from "./types"

interface SetCompositionsPayload {
  compositions: Composition[]
}

export interface SetCompositions {
  type: string
  payload: SetCompositionsPayload
}

export const setCompositions = (
  payload: SetCompositionsPayload,
): SetCompositions => ({
  type: FETCH_COMPOSITIONS,
  payload,
})

export const fetchCompositions = () => async (dispatch: Dispatch) => {
  actionHandler(getCompositions(), (compositions) => {
    dispatch(setCompositions({ compositions }))
  })
}

export const createComposition =
  (credentials: CompositionPost) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postComposition(credentials), () => {
      dispatch(fetchCompositions())
    })
  }

export type CompositionActions = SetCompositions
