import { useFormik } from "formik"
import React, { FormEvent, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import * as Yup from "yup"

import Checkbox from "components/Checkbox/Checkbox"
import FormError from "components/FormError/FormError"
import Input from "components/Input/Input"
import Label from "components/Label/Label"
import Select, {
  mapToOptions,
  BaseSelectedValue,
} from "components/Select/Select"

import { isDaysAfter } from "utils/dates"
import { handleToast } from "utils/messages"
import { history } from "utils/routes"

import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { getPlants } from "../../../companies/service/api"
import { Company, CompanyRole } from "../../../companies/types"
import { fetchCountries } from "../../../countries/actions"
import { Country } from "../../../countries/types"
import { fetchMaterialGroups } from "../../../materials/actions"
import { createConverterSubmissionsInput } from "../../actions"
import {
  CAN_EDIT_SUBMISSION_TO_DAYS,
  SUBMISSION_DATE_FORMAT,
} from "../../constants"
import useSubmission from "../../hooks/useSubmission"
import { putSubmission } from "../../service/api"
import SubmissionButtons from "../SubmissionForm/SubmissionButtons"
import SubmissionConverterInputValidationShema from "./SubmissionConverterInputValidationShema"

/* Set date to -1 month in form */
const actualDate = new Date()
actualDate.setMonth(actualDate.getMonth() - 1)

const INITIAL_FORM_VALUES = {
  company: null,
  country: null,
  countryNonEu: null,
  date: actualDate,
  materialGroup: null,
  total: 0,
  totalOutside: 0,
  totalDemonstratedDuringAudit: 0,
  isMaterialFromOutsideEurope: false,
  canMaterialDemonstrateForAudit: false,
  wasteType: "",
  epsProduct: null,
}

interface IProps {
  isEdit?: boolean
  isDisabled?: boolean
}

const SubmissionConverterFormInput = ({ isEdit, isDisabled }: IProps) => {
  const dispatch = useDispatch()

  const redirectConfigs = {
    route: isEdit ? "/submissions" : "/submissions/new-converting",
    title: isEdit ? "Select Submission type:" : "Converting Activity",
  };

  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const submission = useSubmission(id ? parseInt(id, 0) : 0)

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<Company[]>([])
  const countries = useStateGetter<Country[]>(["country", "countries"]) ?? []
  const [countriesForAudit, setCountriesForAudit] = useState<Country[]>([])
  const [countriesForNonEU, setCountriesForNonEu] = useState<Country[]>([])
  const [isExitOnSubmit, setIsExitOnSubmit] = useState(false)
  const [isConsumerType, setIsConsumerType] = useState("")
  const [plants, setPlants] = useState<Company[]>()
  const [currentPlant, setCurrentPlant] = useState<Company>()

  const handleConsumerTypeCheck = (checkedType: string) => {
    setIsConsumerType(checkedType)
  }

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  const { id: userId, isAdmin } = useUserFromToken()

  useEffect(() => {
    // Check if the variable was specified
    if (typeof isAdmin === "boolean") {
      getCompaniesAsync()
    }
  }, [isAdmin])

  const handleOnSubmit = async (
    {
      company,
      country,
      date,
      materialGroup,
      total,
      totalOutside,
      totalDemonstratedDuringAudit,
      isMaterialFromOutsideEurope,
      canMaterialDemonstrateForAudit,
      epsProduct,
    }: {
      company: BaseSelectedValue<number> | null
      country: BaseSelectedValue<number> | null
      materialGroup: BaseSelectedValue<number> | null
      date: Date
      total: number
      totalOutside: number
      totalDemonstratedDuringAudit: number
      isMaterialFromOutsideEurope: boolean
      canMaterialDemonstrateForAudit: boolean
      epsProduct?: any
    },
    { resetForm }: { resetForm: () => void },
  ) => {
    if (!loading) {
      setLoading(true)
      const companyId = company?.value
      const countryId = country?.value
      const materialsGroupId = materialGroup?.value
      const isRecycledPlasticForAudisIsGreaterThanReceived =
        total < totalDemonstratedDuringAudit

      if (isRecycledPlasticForAudisIsGreaterThanReceived) {
        setLoading(false)
        dispatch(
          handleToast(
            [
              "Total recycled plastic for audit cannot be greater than received.",
            ],
            "error",
          ),
        )
      }

      if (
        companyId &&
        !isRecycledPlasticForAudisIsGreaterThanReceived &&
        !isEdit
      ) {
        const credentails = {
          companyId,
          countryId,
          materialsGroupId,
          collectionDate: date,
          isInput: true,
          type: 1,
          volumeNet: total,
          volumeNetOutside: totalOutside,
          totalDemonstratedDuringAudit,
          wasteType: isConsumerType,
          isMaterialFromOutsideEurope,
          canMaterialDemonstrateForAudit,
          epsProduct: epsProduct?.value,
          successCallback: () => {
            setLoading(false)
            if (isExitOnSubmit === false) {
              resetForm()
            } else {
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: "convertingInput",
              })
            }
          },
        }
        await dispatch(createConverterSubmissionsInput(credentails))
      }

      if (
        companyId &&
        !isRecycledPlasticForAudisIsGreaterThanReceived &&
        isEdit &&
        submission
      ) {
        const { createdAt } = submission
        const credentails = {
          companyId,
          countryId,
          materialsGroupId,
          collectionDate: date,
          isInput: true,
          volumeNet: total,
          volumeNetOutside: totalOutside,
          totalDemonstratedDuringAudit,
          wasteType: isConsumerType,
          isMaterialFromOutsideEurope,
          canMaterialDemonstrateForAudit,
          volumeGross: 0,
          successCallback: () => {
            setLoading(false)
            if (isExitOnSubmit === false) {
              resetForm()
            } else {
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: "convertingInput",
              })
            }
          },
        }

        if (canRemoveSubmission(createdAt)) {
          try {
            const { errors } = await putSubmission({
              ...credentails,
              ...{ id: submission.id },
            })
            if (errors) {
              handleToast(errors, "error")
            } else {
              handleToast(["Submission updated"])
              if (!isExitOnSubmit) {
                history.push(redirectConfigs.route)
              } else {
                history.push(redirectConfigs.route, {
                  title: t(redirectConfigs.title),
                  tab: "convertingInput",
                })
              }
            }
          } catch (error) {
            // @ts-ignore
            handleToast([error.message], "error")
          }
        } else {
          handleToast(
            [
              `Delete action is disabled because this submission was added more than ${CAN_EDIT_SUBMISSION_TO_DAYS} days ago.`,
            ],
            "warning",
          )
        }
      }
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: SubmissionConverterInputValidationShema,
    onSubmit: handleOnSubmit,
  })
  const { values, errors, setFieldValue, touched, handleSubmit } = formik

  useEffect(() => {
    countries.push({
      id: 999,
      name: "Other",
      code: "NONEU",
      isEu: false,
    } as Country)

    setCountriesForNonEu(countries)
  }, [countries])

  useEffect(() => {
    if (submission) {
      const {
        company,
        country,
        collectionDate,
        isMaterialFromOutsideEurope,
        canMaterialDemonstrateForAudit,
        volumeNet,
        volumeNetOutside,
        totalDemonstratedDuringAudit,
        wasteType,
        materialsGroup,
        epsProduct,
      } = submission

      if (country) {
        setFieldValue("country", { value: country.id, label: country.name })
      }

      setFieldValue("company", { value: company.id, label: company.name })
      setFieldValue("materialGroup", {
        value: materialsGroup?.id,
        label: materialsGroup?.name,
      })
      setFieldValue("date", new Date(collectionDate))
      setFieldValue("isMaterialFromOutsideEurope", isMaterialFromOutsideEurope)
      setFieldValue(
        "canMaterialDemonstrateForAudit",
        canMaterialDemonstrateForAudit,
      )
      setFieldValue("epsProduct", { value: epsProduct, label: epsProduct })
      setFieldValue("total", volumeNet)
      setFieldValue("totalOutside", volumeNetOutside)
      setFieldValue(
        "totalDemonstratedDuringAudit",
        totalDemonstratedDuringAudit,
      )

      if (wasteType === "Pre") {
        handleConsumerTypeCheck("Pre")
      }

      if (wasteType === "Post") {
        handleConsumerTypeCheck("Post")
      }

      if (
        localStorage.getItem("goto") !== null &&
        localStorage.getItem("goto")
      ) {
        localStorage.removeItem("goto")
      }
    }
  }, [setFieldValue, submission])

  const getCompaniesAsync = async () => {
    const { data, errors } = await getPlants()
    if (data) {
      setCompanies(data)
    }
    if (errors) {
      handleToast(errors, "error")
    }
  }

  const canRemoveSubmission = (createdAt: Date | undefined) => {
    // Nick asked to save this logic for future
    // if (isAdmin) {
    //   return true
    // }
    // if (createdAt && isDaysAfter(CAN_EDIT_SUBMISSION_TO_DAYS, createdAt)) {
    //   return false
    // }

    return true
  }

  const configurePlants = async () => {
    if (!plants) await getPlants().then((d) => setPlants(d.data))
    // @ts-ignore
    const plant = plants?.find((a) => a.id == values?.company?.value)
    setCurrentPlant(plant)
  }

  useEffect(() => {
    configurePlants()
  }, [configurePlants, values.company])

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  useEffect(() => {
    dispatch(fetchMaterialGroups())
  }, [dispatch])

  const onTotalChange = (e: any) => {
    setFieldValue("total", e.target.valueAsNumber)
  }

  const onTotalDemonstratedDuringAuditChange = (e: any) => {
    setFieldValue("totalDemonstratedDuringAudit", e.target.valueAsNumber)
  }

  const onChangeMaterialDemonstrateForAudi = (newValue: boolean) => {
    setFieldValue("canMaterialDemonstrateForAudit", newValue)
    setFieldValue("country", null)
    if (values.canMaterialDemonstrateForAudit === true) {
      setFieldValue("isMaterialFromOutsideEurope", false)
    }
  }

  const checkIsOutsideEu = (selectedOption: any) => {
    setFieldValue("country", selectedOption)

    const country = countries.find((country) => country.id === selectedOption.value)

    if (!country?.isEu) {
      setFieldValue("isMaterialFromOutsideEurope", true)
      setCountriesForAudit(countriesForNonEU.slice(0, -1))
    } else {
      setFieldValue("isMaterialFromOutsideEurope", false)
    }
  }

  /**
   * @todo figured out why we have here plants and companies as this data is taken from the same table
   * move filtering on backend side
   * use bitmap for company roles
   */
  const filteredCompanies = companies.filter((company) =>
    [CompanyRole.CONVERTER, CompanyRole.RECYCLER_AND_CONVERTER].includes(
      company.roleId,
    ),
  )

  return (
    <div>
      <form onSubmit={onFormSubmit}>
        <div className="mb-5">
          <h2>{t("Input into converting activity")}</h2>
        </div>

        {filteredCompanies && (
          <Select
            name="company"
            label={t("Plant name (*)")}
            options={mapToOptions(filteredCompanies)}
            value={values.company}
            handleOnChange={(selectedOption) =>
              setFieldValue("company", selectedOption)
            }
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.company && errors.company}
            isDisabled={isDisabled}
          />
        )}
        <div className="form-group row align-items-center ">
          <Label label={t("Date (*)")} name="." labelClassName="col-sm-4" />
          <div className="col-sm-8">
            <DatePicker
              className="form-control"
              selected={values.date}
              dateFormat={SUBMISSION_DATE_FORMAT}
              showMonthYearPicker
              showPopperArrow={false}
              onChange={(date: Date) => setFieldValue("date", date)}
              disabled={isDisabled}
            />
            <FormError error={touched.date && (errors.date as string)} />
          </div>
        </div>
        {!values.canMaterialDemonstrateForAudit && (
          <Select
            name="country"
            label="Recycled plastic from"
            options={mapToOptions(countriesForNonEU)}
            value={values.country}
            handleOnChange={(selectedOption) =>
              checkIsOutsideEu(selectedOption)
            }
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.country && errors.country}
            isDisabled={isDisabled}
            infoTooltip="(i) Recycled plastic bought/received or from internally recycled waste. If from internally recycled waste, select the country your recycling plant is in."
          />
        )}
        <Select
          name="materialGroup"
          label={`${t("Polymer")} (*)`}
          options={mapToOptions(currentPlant?.polymers || [])}
          value={values.materialGroup}
          handleOnChange={(selectedOption) =>
            setFieldValue("materialGroup", selectedOption)
          }
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.materialGroup && errors.materialGroup}
          isDisabled={isDisabled}
        />
        {/* @ts-ignore */}
        {values?.materialGroup?.label == "EPS" ? (
          <Select
            name="epsProduct"
            options={[
              {
                value: "Foam particles",
                label: "Foam particles",
              },
              {
                value: "GPPS pellets",
                label: "GPPS pellets",
              },
              {
                value: "Styrene",
                label: "Styrene",
              },
              {
                value: "RMS",
                label: "RMS",
              },
              {
                value: "XPS",
                label: "XPS",
              },
            ]}
            value={values.epsProduct}
            handleOnChange={(selectedOption) =>
              setFieldValue("epsProduct", selectedOption)
            }
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            label="EPS product type"
            error={touched.epsProduct && errors.epsProduct}
            isDisabled={isDisabled}
          />
        ) : null}

        <div>
          <Input
            label={t("Total recycled plastic (kg)")}
            name="payment"
            type="number"
            min="0"
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            value={values.total || 0}
            handleOnChange={onTotalChange}
            placeholder={t("Enter total")}
            isDisabled={isDisabled}
            infoTooltip={
              !values.canMaterialDemonstrateForAudit
                ? `${t(
                    "(i) Includes recycled plastics from external and internal recycling.",
                  )}`
                : `${t(
                    "Imports of recycled plastic (Kg) made from European waste",
                  )}`
            }
            isRequired
            error={touched.total && errors.total}
          />

          {values.isMaterialFromOutsideEurope && (
            <Checkbox
              label={t(
                'Please check the box if recycled plastic is imported ("Other" is selected above) and some/all of it is made from European waste and you can demonstrate it during auditing.',
              )}
              name="canMaterialDemonstrateForAudit"
              value={values.canMaterialDemonstrateForAudit}
              handleOnChange={() =>
                onChangeMaterialDemonstrateForAudi(
                  !values.canMaterialDemonstrateForAudit,
                )
              }
              checkboxWrapperClassName="mb-3"
              isDisabled={isDisabled}
            />
          )}
        </div>
        {values.canMaterialDemonstrateForAudit && (
          <Input
            label={t(
              "Total imported recycled plastic made from European waste (kg)",
            )}
            name="totalDemonstratedDuringAudit"
            type="number"
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            value={values.totalDemonstratedDuringAudit || 0}
            handleOnChange={onTotalDemonstratedDuringAuditChange}
            placeholder={t("Enter total")}
            isDisabled={isDisabled}
            infoTooltip="(i) European waste - waste originating in EU or UK"
            isRequired
            error={
              touched.totalDemonstratedDuringAudit &&
              errors.totalDemonstratedDuringAudit
            }
          />
        )}
        {values.canMaterialDemonstrateForAudit && (
          <Select
            name="country"
            label="Waste origin"
            options={mapToOptions(countriesForAudit)}
            value={values.country}
            handleOnChange={(selectedOption) =>
              setFieldValue("country", selectedOption)
            }
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.country && errors.country}
            isDisabled={isDisabled}
          />
        )}

        {values.canMaterialDemonstrateForAudit ? (
          <div className="form-group row align-items-center ">
            <Label label={t("Waste Type")} name="." labelClassName="col-sm-4" />
            <div className="col-sm-8 row">
              <div className="col-sm-4">
                <Checkbox
                  name="wasteType_pre"
                  label="Pre-consumer"
                  handleOnChange={() => handleConsumerTypeCheck("Pre")}
                  value={isConsumerType === "Pre"}
                  isDisabled={isDisabled}
                />
              </div>
              <div className="col-sm-4">
                <Checkbox
                  name="wasteType_post"
                  label="Post-consumer"
                  handleOnChange={() => handleConsumerTypeCheck("Post")}
                  value={isConsumerType === "Post"}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="row my-4">
          <SubmissionButtons
            disabled={!!isDisabled}
            loading={loading}
            onSave={setIsExitOnSubmit}
            onCancel={() => {
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: "convertingInput",
              })
            }}
          />
        </div>
      </form>
      <ReactTooltip />
    </div>
  )
}

export default SubmissionConverterFormInput
