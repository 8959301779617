import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { renderRoutes } from "react-router-config"

import useUserFromToken from "../hooks/useUserFromToken"
import { refreshToken } from "../modules/auth/actions"
import FinishRegistration from "../modules/auth/containers/FinishRegistration"
import NewUserNotifications from "modules/users/containers/NewUserNotifications"

const Dashboard = (props: any) => {
  const dispatch = useDispatch()
  const { routes } = props.route
  const { exp } = useUserFromToken()
  let isRefreshToken = false
  if (exp !== Infinity) {
    const todaySeconds = new Date().getTime() / 1000
    const timeBeforeRefresh = 1800 // 1800 = 30 min
    if (todaySeconds + timeBeforeRefresh > exp) {
      isRefreshToken = true
    }
  }

  useEffect(() => {
    if (isRefreshToken) {
      dispatch(refreshToken())
    }
  }, [dispatch, isRefreshToken])
  return (
    <div className="container pb-5">
      {renderRoutes(routes)}
      <FinishRegistration />
      <NewUserNotifications />
    </div>
  )
}

export default Dashboard
