import { Dispatch } from "redux"

import { REFRESH_TOKEN_KEY } from "../../constants"
import { handleToast } from "../../utils/messages"
import { history } from "../../utils/routes"
import { windowStorage } from "../../utils/windowStorage"
import { actionHandler } from "../@common/actions"
import { LOGIN, LOGOUT } from "./constants"
import {
  getRefreshToken,
  registerRequest,
  loginRequest,
  RegisterInput,
  LoginInput,
  LoginResponse,
} from "./service/api"

export interface SetTokesAction {
  type: string
  payload: LoginResponse
}

export const setTokensAction = (payload: LoginResponse): SetTokesAction => ({
  type: LOGIN,
  payload,
})

export const logoutAction = (): { type: string } => ({ type: LOGOUT })

interface History {
  push: (path: string) => void
}

export const refreshToken = () => async (dispatch: Dispatch) => {
  if (windowStorage.get(REFRESH_TOKEN_KEY)) {
    actionHandler(
      getRefreshToken({ refreshToken: windowStorage.get(REFRESH_TOKEN_KEY) }),
      (accessToken) => {
        dispatch(setTokensAction({ accessToken }))
      },
    )
  }
}

export const register = (credentials: RegisterInput) => async () => {
  actionHandler(registerRequest(credentials), () => {
    handleToast(["Account registered. \n Awaiting approval!"])
    history.push("/auth/login")
  })
}

export const login =
  (credentials: LoginInput) => async (dispatch: Dispatch) => {
    actionHandler(loginRequest(credentials), (response) => {
      dispatch(setTokensAction(response))
      history.push("/auth/register/finish-registration")
    })
  }

export type AuthAction = SetTokesAction
