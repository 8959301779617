import { Notification, NotificationAudit } from "../../notifications/types"
import { restHelper } from "../../@common/service/api"
import { ID } from "../../@common/types"
import {
  User,
  CreateUserInput,
  UserIdInput,
  AttachUserToCompanyInput,
  AttachUserToCompanies,
  UserUpdateInput,
  AcceptNotificationInput,
} from "../types"

export const queries = {
  users: `
  query users($name: String, $email: String, $allowedToImpersonate: Boolean) {
    users(name: $name, email: $email, allowedToImpersonate: $allowedToImpersonate) {
        id
        email
        name
        lastActive
        lastActiveEmulate
        accepted
        loginAttempts
        blocked
        canBeImpersonated
        role
      }
    }
  `,
  allAuditors: `
    query {
      allAuditors {
        id
        email
        name
        lastActive
        accepted
        loginAttempts
        blocked
        companies {
          id
          name
          address
          country {
            id
            code
            name
            visible
          }
          parent {
            id
            name
          }
          vatNumber
          ceoName
          ceoEmail
          capacities
          archived
        }
      }
    }
  `,
  allSectorAssociationRepresentatives: `
    query {
      allSectorAssociationRepresentatives {
        id
        email
        name
        lastActive
        accepted
        loginAttempts
        blocked
        companies {
          id
          name
          address
          country {
            id
            code
            name
            visible
          }
          parent {
            id
            name
          }
          vatNumber
          ceoName
          ceoEmail
          capacities
          archived
        }
      }
    }
  `,
  allRecovinylRegionalRepresentative: `
    query {
      allRecovinylRegionalRepresentative {
        id
        email
        name
        lastActive
        accepted
        loginAttempts
        blocked
        companies {
          id
          name
          address
          country {
            id
            code
            name
            visible
          }
          parent {
            id
            name
          }
          vatNumber
          ceoName
          ceoEmail
          capacities
          archived
        }
        companiesToAttach {
          id
          name
        }
      }
    }
  `,
  companyUsers: `
    query {
      companyUsers {
        id
        email
        name
        lastActive
        accepted
        loginAttempts
        blocked
        plants {
          id
          name
          address
          country {
            id
            code
            name
            visible
          }
        }
      }
    }
  `,
  user: `
    query user(
      $id: Int
    ) {
      user(
        id: $id
      ) {
        id
        email
        name
        lastActive
        lastActiveEmulate
        accepted
        loginAttempts
        blocked
        registrationFinished
        company {
          id
          name
          address
          country {
            id
            code
            name
            visible
          }
          vatNumber
          ceoName
          ceoEmail
          capacities
        }
        plants {
          id
          name
          address
          country {
            id
            code
            name
            visible
          }
          vatNumber
          ceoName
          ceoEmail
          capacities
        }
        companies {
          id
          name
          address
          country {
            id
            code
            name
            visible
          }
          parent {
            id
            name
          }
          vatNumber
          ceoName
          ceoEmail
          capacities
        }
        children {
          id
          email
          name
          companies {
            id
            name
            country {
              id
              code
              name
            }
            parent {
              id
              name
            }
            address
          }
          plants {
            id
            name
            country {
              id
              code
              name
            }
            address
          }
        }
      }
    }
  `,
  userBackToAdminAccount: `
    query userBackToAdminAccount($returnToken: String!) {
      userBackToAdminAccount(
        returnToken: $returnToken
      ) {
        accessToken
        refreshToken
      }
    }
  `,
  recyclersAttachedToPlant: `
    query recyclersAttachedToPlant(
      $id: Int!
    ) {
      recyclersAttachedToPlant(
        id: $id
      ) {
        id
        name
        email
      }
    }
  `,
  userNotifications: `
    query {
      userNotifications {
        id
        acceptAt
        notification {
          id 
          subject 
          content
          status
          filePath
        }
      }
    }
  `,
}

export const mutations = {
  userCreate: `
    mutation userCreate(
      $email: String!
      $name: String!
      $companyId: Int
    ) {
      userCreate(
        email: $email
        name: $name
        companyId: $companyId
      ) { id }
    }
  `,
  userChangeStatus: `
    mutation userChangeStatus(
      $id: Int!
    ) {
      userChangeStatus(
        id: $id
      ) { blocked }
    }
  `,
  userActivate: `
    mutation userActivate(
      $id: Int!
    ) {
      userActivate(
        id: $id
      ) { id }
    }
  `,
  userDelete: `
    mutation userDelete(
      $id: Int!
    ) {
      userDelete(
        id: $id
      )
    }
  `,
  userUpdate: `
    mutation userUpdate(
      $id: Int!
      $name: String
      $email: String
      $password: String
      $company: CompanyInput
    ) {
      userUpdate(
        id: $id
        name: $name
        email: $email
        password: $password
        company: $company
      ) {
        name
      }
    }
  `,
  userAttachToCompany: `
    mutation userAttachToCompany ($userId: Int!) {
      userAttachToCompany (userId: $userId)
    }
  `,
  attachApprovedPlants: `
    mutation attachApprovedPlants(
      $userId: Int!,
      $companyIds: [Int]
    ) {
      attachApprovedPlants(
        userId: $userId
        companyIds: $companyIds
      )
    }
  `,
  userAttachToPlant: `
    mutation userAttachToPlant(
      $userId: Int!,
      $companyId: Int!
    ) {
      userAttachToPlant(
        userId: $userId
        companyId: $companyId
      )
    }
  `,
  userUnattachFromPlant: `
    mutation userUnattachFromPlant(
      $userId: Int!,
      $companyId: Int!
    ) {
      userUnattachFromPlant(
        userId: $userId
        companyId: $companyId
      )
    }
  `,
  userAttachPlants: `
    mutation userAttachPlants(
      $userId: Int!,
      $companyIds: [Int]
    ) {
      userAttachPlants(
        userId: $userId
        companyIds: $companyIds
      )
    }
  `,
  userEmulate: `
    mutation userEmulate(
      $id: Int!
    ) {
      userEmulate (id: $id) {
        accessToken
        refreshToken
        returnToken
      }
    }
  `,
  userRequestPlantsAccess: `
    mutation userRequestPlantsAccess(
      $plants: [Int]
    ) {
      userRequestPlantsAccess (plants: $plants)
    }
  `,
  userAcceptNotification: `
    mutation userAcceptNotification(
      $id: Int!
    ) {
      userAcceptNotification(
        id: $id
      )
    }
  `,
}

export const getUsers = restHelper<User[], object>(queries, "users")

export const getUsersSearch = restHelper<User[], object>(queries, "usersSearch")

export const getCompanyUsers = restHelper<User[], object>(
  queries,
  "companyUsers",
)

export const getAllAuditors = restHelper<User[], object>(queries, "allAuditors")

export const getAllSectorAssociationRepresentatives = restHelper<
  User[],
  object
>(queries, "allSectorAssociationRepresentatives")

export const getAllRecovinylRegionalRepresentative = restHelper<User[], object>(
  queries,
  "allRecovinylRegionalRepresentative",
)

export const getUser = restHelper<User, UserIdInput>(queries, "user")

export const userBackToAdminAccount = restHelper<
  {
    accessToken: string
    refreshToken: string
  },
  { returnToken: string }
>(queries, "userBackToAdminAccount")

export const getRecyclersAttachedToPlant = restHelper<User[], ID>(
  queries,
  "recyclersAttachedToPlant",
)

export const getNotifications = restHelper<NotificationAudit[], object>(queries, "userNotifications")

export const postUser = restHelper<boolean, CreateUserInput>(
  mutations,
  "userCreate",
)

export const deleteUser = restHelper<User, UserIdInput>(mutations, "userDelete")

export const activateUser = restHelper<User, UserIdInput>(
  mutations,
  "userActivate",
)

export const blockUnblockUser = restHelper<User, UserIdInput>(
  mutations,
  "userChangeStatus",
)

export const attachApprovedPlants = restHelper<User, AttachUserToCompanies>(
  mutations,
  "attachApprovedPlants",
)

export const attachUserToPlant = restHelper<User, AttachUserToCompanyInput>(
  mutations,
  "userAttachToPlant",
)

export const unattachUserFromPlant = restHelper<User, AttachUserToCompanyInput>(
  mutations,
  "userUnattachFromPlant",
)

export const userAttachPlants = restHelper<User, AttachUserToCompanies>(
  mutations,
  "userAttachPlants",
)

export const userRequestPlantsAccess = restHelper<User, object>(
  mutations,
  "userRequestPlantsAccess",
)

export const attachUserToCompany = restHelper<User, UserIdInput>(
  mutations,
  "userAttachToCompany",
)

export const putUser = restHelper<User, UserUpdateInput>(
  mutations,
  "userUpdate",
)

export const userEmulate = restHelper<
  {
    accessToken: string
    refreshToken: string
    returnToken: string
  },
  UserIdInput
>(mutations, "userEmulate")

export const acceptNotification = restHelper<{accepted: boolean}, AcceptNotificationInput>(
  mutations,
  "userAcceptNotification",
)