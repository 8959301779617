import { CountryAction } from "./actions"
import { FETCH_COUNTRIES } from "./constants"
import { Country } from "./types"

export interface CountryState {
  countries: Country[]
}

const initialState: CountryState = {
  countries: [],
}

const country = (state: CountryState = initialState, action: CountryAction) => {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default country
