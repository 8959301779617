import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import CompositionDetail from "./containers/CompositionDetail"
import CompositionList from "./containers/CompositionList"
import CompositionNew from "./containers/CompositionNew"

const Compositions = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Route exact path={path}>
        <CompositionList />
      </Route>
      <Route exact path={`${path}/new`}>
        <CompositionNew />
      </Route>
      <Route exact path={`${path}/show/:id`}>
        <CompositionDetail />
      </Route>
    </>
  )
}

export default Compositions
