import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import Checkbox from "components/Checkbox/Checkbox"
import Select, { SelectValue } from "components/Select/Select"

import { USER_FIELDS_OF_ACTIVITY } from "../../../users/types"
import { postFinishRegistration } from "../../service/api"

const RegisterAssociationRepresentativeFormValidationShema = Yup.object().shape(
  {
    sector: Yup.object().typeError("Field of activity is required."),
  },
)

const FinishSectorAssociationRepresentative = () => {
  const handleSubmit = async ({ sector, internal, external }: any) => {
    console.info("credentials", sector, internal, external)
    const { finishRegistration }: any = await postFinishRegistration({
      sector,
      external,
    })
    // TODO get payload
    if (finishRegistration) {
      console.info("not implemented")
    }
  }

  const formik = useFormik({
    initialValues: {
      sector: null,
      internal: true,
      external: false,
    },
    validationSchema: RegisterAssociationRepresentativeFormValidationShema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors, setFieldValue } = formik

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("sector", selectedOption)
  }

  const { t } = useTranslation()

  return (
    <form className="mb-5" onSubmit={formik.handleSubmit}>
      <Select
        name="sector"
        label={t("Field of activity")}
        options={USER_FIELDS_OF_ACTIVITY}
        value={formik.values.sector}
        handleOnChange={onSelectChange}
        inputWrapperClassName="col-sm-8"
        labelClassName="col-sm-4"
        error={touched.sector && errors.sector}
      />
      <Checkbox
        label={t("Internal pre-consumer waste recycled")}
        name="internal"
        value={values.internal}
        isDisabled
        handleOnChange={formik.handleChange}
        checkboxWrapperClassName="mb-0"
        error={touched.internal && errors.internal}
      />
      <Checkbox
        label={t("Volume of recyclate brought from an external source")}
        name="external"
        value={values.external}
        handleOnChange={formik.handleChange}
        error={touched.external && errors.external}
      />
      <div className="d-flex justify-content-between">
        <button type="submit" className="btn btn-success rounded-bottom-right">
          {t("Finish")} <i className="fas fa-check ml-3" />
        </button>
      </div>
    </form>
  )
}

export default FinishSectorAssociationRepresentative
