import { useFormik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import Input from "components/Input/Input"

import { handleToast } from "utils/messages"

import { putUser, postUser } from "../../service/api"
import { User } from "../../types"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").min(5).max(150),
  email: Yup.string().email("Invalid email").required("Name is required"),
})

interface Props {
  initialUser?: User
  parent?: User
  onSuccessHandler?: () => void
}

const UserForm: React.FC<Props> = ({
  initialUser,
  parent,
  onSuccessHandler,
}) => {
  const [userActionSuccess, setUserActionSuccess] = useState(false)
  const { t } = useTranslation()
  const { id } = initialUser ?? { id: null }

  const [loading, setLoading] = useState(false)

  const handleOnSubmit = useCallback(
    async ({ name, email }: { name: string; email: string }) => {
      if (!loading) {
        setLoading(true)
        let response: {
          data?: any
          errors?: any
        }
        let successToast = ""

        if (id === null) {
          const credencials =
            parent && parent.company
              ? { name, email, companyId: parent.company.id }
              : { name, email }

          response = await postUser(credencials)
          successToast = t("User added")
        } else {
          response = await putUser({ id: parseInt(id, 10), name })
          successToast = t("User updated")
        }

        setLoading(false)

        if (response.data) {
          setUserActionSuccess(true)
          handleToast([successToast])
        } else if (response.errors) {
          handleToast(response.errors, "error")
        }
      }
    },
    [id, loading, parent, t],
  )

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  })

  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    resetForm,
  } = formik

  useEffect(() => {
    if (initialUser) {
      setFieldValue("name", initialUser.name)
      setFieldValue("email", initialUser.email)
    }
  }, [initialUser, setFieldValue])

  useEffect(() => {
    if (userActionSuccess) {
      resetForm()
      if (onSuccessHandler) {
        onSuccessHandler()
      }
      setUserActionSuccess(false)
    }
  }, [userActionSuccess, onSuccessHandler, resetForm])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Input
          label={t("Name")}
          name="name"
          value={values.name}
          handleOnChange={handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.name && errors.name}
        />
        <Input
          name="email"
          value={values.email}
          label={t("Email")}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          handleOnChange={handleChange}
          error={touched.email && errors.email}
          isDisabled={!!id}
        />

        <div className="text-right">
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
            disabled={loading}
          >
            {id ? "Update" : "Submit"}
            <i className="fas fa-arrow-right ml-3" />
          </button>
        </div>
      </form>
    </>
  )
}

export default UserForm
