import { useFormik } from "formik"
import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import ReactTooltip from "react-tooltip"
import * as Yup from "yup"

import Input from "../../../components/Input/Input"
import Modal from "../../../components/Modal/Modal"
import Select, {
  mapToOptions,
  SelectValue,
} from "../../../components/Select/Select"
import useStateGetter from "../../../hooks/useStateGetter"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import { ACTIVITY_LIST } from "../../companies/constants"
import {
  putCompany,
  getCompany,
  postPlant,
  getCompanyPlants,
} from "../../companies/service/api"
import { Company } from "../../companies/types"
import { MaterialGroup } from "../../materials/service/api"

interface Props {
  companyPlants: Company[]
  setCompanyPlants(plants: Company[]): void
  materialGroups: MaterialGroup[]
}

const CompanyFormValidationShema = Yup.object().shape({
  selectedCountry: Yup.object().typeError("Country is required."),
  vatNumber: Yup.string().required("VAT number is required"),
  name: Yup.string().required("Name is required"),
  address: Yup.string().required("Address is required"),
  companyRole: Yup.object().typeError("Activity is required."),
  // ceoName: Yup.string().required('Name of CEO is required.'),
  // ceoEmail: Yup.string()
  //   .email('Invalid email')
  //   .required('Email of CEO is required.'),
})

const PlantsList: React.FC<Props> = ({
  companyPlants,
  setCompanyPlants,
  materialGroups,
}) => {
  const [selectedCompany, setCompany] = useState<Company>()
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false)

  const countries =
    useStateGetter<{ id: string; name: string }[]>(["country", "countries"]) ??
    []
  const COUNTRIES = countries.map((countrie) => ({
    value: parseInt(countrie.id, 0),
    label: countrie.name,
  }))

  const { id } = selectedCompany ?? { id: null }

  const [loading, setLoading] = useState(false)

  const getSelectedMultiValues = (values: Array<{ value: number }>) =>
    values.map((value) => value.value)

  // @ts-ignore
  const handlePlantDataSave = useCallback(
    async ({
      name,
      selectedCountry,
      address,
      vatNumber,
      ceoName,
      ceoEmail,
      capacities,
      companyPolymer,
      companyRole,
    }: any) => {
      if (!loading && companyPolymer.length > 0) {
        setLoading(true)
        let cachedErrors
        if (id) {
          const { errors } = await putCompany({
            id,
            name,
            country: selectedCountry.value,
            address,
            vatNumber,
            ceoName,
            ceoEmail,
            capacities: parseInt(capacities, 10),
            polymers: getSelectedMultiValues(companyPolymer),
            roleId: companyRole.value,
          })
          cachedErrors = errors
        } else {
          const { errors } = await postPlant({
            name,
            country: selectedCountry.value,
            address,
            vatNumber,
            ceoName,
            ceoEmail,
            capacities: parseInt(capacities, 10),
            polymers: getSelectedMultiValues(companyPolymer),
            roleId: companyRole.value,
          })
          cachedErrors = errors
        }
        setLoading(false)
        if (cachedErrors) {
          handleToast(cachedErrors, "error")
        } else {
          setIsCountryModalOpen(false)
          setCompany(undefined)
          resetForm()
          getCompanyPlants().then(({ data, errors }) => {
            if (errors) {
              handleToast(errors, "error")
            }
            if (data) {
              setCompanyPlants(data)
              if (id) {
                handleToast(["Plant updated"])
              } else {
                handleToast(["Plant added"])
              }
            }
          })
        }
      }
    },
    // @ts-ignore
    [id, loading, setCompanyPlants],
  )

  // @ts-ignore
  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      selectedCountry: null,
      vatNumber: "",
      ceoName: "",
      ceoEmail: "",
      capacities: 0,
      companyRole: null,
      companyPolymer: [],
    },
    validationSchema: CompanyFormValidationShema,
    onSubmit: handlePlantDataSave,
  })

  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    // @ts-ignore
    resetForm,
  } = formik

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCountry", selectedOption)
  }

  const onSelectChangeRole = (selectedOption: SelectValue) => {
    setFieldValue("companyRole", selectedOption)
  }

  const onSelectChangePolymer = (selectedOption: SelectValue) => {
    setFieldValue("companyPolymer", selectedOption)
  }

  const handleCompanyEdit = (id: any) => {
    resetForm({})
    getCompany({ id }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setCompany(data)
        setIsCountryModalOpen(true)
      }
    })
  }

  useEffect(() => {
    if (selectedCompany) {
      setFieldValue("name", selectedCompany?.name)
      setFieldValue("address", selectedCompany?.address)
      if (selectedCompany?.country) {
        setFieldValue("selectedCountry", {
          label: selectedCompany.country.name,
          value: selectedCompany.country.id,
        })
      }
      setFieldValue("id", selectedCompany?.id)
      setFieldValue("vatNumber", selectedCompany?.vatNumber)
      setFieldValue("ceoName", selectedCompany?.ceoName)
      setFieldValue("ceoEmail", selectedCompany?.ceoEmail)
      setFieldValue("capacities", selectedCompany?.capacities)

      const initialCompanyPolymer = selectedCompany.polymers?.map(
        (polymer) => ({
          value: polymer.id,
          label: polymer.name,
        }),
      )
      setFieldValue("companyPolymer", initialCompanyPolymer)

      const companyRole = ACTIVITY_LIST.find(
        (x) => x.value === selectedCompany.roleId,
      )
      setFieldValue("companyRole", companyRole)
    } else {
      setFieldValue("name", "")
      setFieldValue("address", "")

      setFieldValue("selectedCountry", null)

      setFieldValue("vatNumber", "")
      setFieldValue("ceoName", "")
      setFieldValue("ceoEmail", "")
      setFieldValue("capacities", "")
    }
  }, [setFieldValue, selectedCompany])

  const { t } = useTranslation()

  return (
    <>
      <Modal
        isOpen={isCountryModalOpen}
        isOpenHandler={setIsCountryModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <h2>{id ? t("Edit Plant") : t("Add new")}</h2>

        <form onSubmit={handleSubmit}>
          <Input
            label={t("Name")}
            name="name"
            value={values.name}
            handleOnChange={handleChange}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.name && errors.name}
          />
          <Select
            name="selectedCountry"
            label={t("Country")}
            options={COUNTRIES}
            value={values.selectedCountry}
            handleOnChange={onSelectChange}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.selectedCountry && errors.selectedCountry}
          />
          <Select
            name="companyRole"
            label={`${t("Activity")} (*)`}
            options={ACTIVITY_LIST}
            value={values.companyRole}
            handleOnChange={onSelectChangeRole}
            inputWrapperClassName="col-sm-8"
            wrapperClassName="align-items-center"
            labelClassName="col-sm-4"
            error={touched.companyRole && errors.companyRole}
          />
          <div className="srs-select form-group row align-items-center">
            <label className="col-sm-4 col-form-label">{`${t(
              "Polymer(s) processed",
            )} (*)`}</label>
            <div className="multi-select-wrapper col-sm-8">
              <MultiSelect
                options={mapToOptions(materialGroups)}
                value={values.companyPolymer}
                onChange={onSelectChangePolymer}
                labelledBy="companyPolymer"
                disableSearch
              />
              {touched.companyPolymer && values.companyPolymer.length === 0 ? (
                <small className="form-text text-danger">
                  Field of Polymer(s) processed is required.
                </small>
              ) : null}
            </div>
          </div>
          <Input
            name="address"
            value={values.address}
            label={t("Address")}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            handleOnChange={handleChange}
            error={touched.address && errors.address}
          />
          <Input
            name="vatNumber"
            value={values.vatNumber}
            label={t("Vat number")}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            handleOnChange={handleChange}
            error={touched.vatNumber && errors.vatNumber}
          />
          <span data-tip="For communication purposes only.">
            <Input
              name="ceoName"
              value={values.ceoName}
              label={`${t("CEO name")} (i)`}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              handleOnChange={handleChange}
              error={touched.ceoName && errors.ceoName}
            />
          </span>
          <span data-tip="For communication purposes only.">
            <Input
              name="ceoEmail"
              value={values.ceoEmail}
              label={`${t("CEO email")} (i)`}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              handleOnChange={handleChange}
              error={touched.ceoEmail && errors.ceoEmail}
            />
          </span>
          <span data-tip="Optionally add your production capacity to be able to visually track your own performance. This data will not be shared and is only visible for you.">
            <Input
              name="capacities"
              value={values.capacities}
              label={`${t("Capacities")} (i)`}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              handleOnChange={handleChange}
            />
          </span>
          <ReactTooltip />
          {/* <Checkbox
            name="isInputPlant"
            value={values.isInput}
            label={t('Is input')}
            checkboxWrapperClassName="mb-0 mr-0"
            handleOnChange={() => setFieldValue('isInput', !values.isInput)}
          /> */}
          <div className="text-right">
            <button
              type="submit"
              className="btn btn-outline-success rounded-bottom-right"
              disabled={loading}
            >
              {id ? t("Update") : t("Submit")}
              <i className="fas fa-arrow-right ml-3" />
            </button>
          </div>
        </form>
      </Modal>
      <h3 className="mb-3">{t("Plants")}</h3>
      <div className="text-right mb-3">
        <button
          className="btn btn-success rounded-top-right"
          onClick={() => {
            setIsCountryModalOpen(true)
            setCompany(undefined)
          }}
        >
          {t("Add new")}
          <i className="fas fa-plus ml-3" />
        </button>
      </div>

      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="text-center">{t("Plant name")}</th>
            <th className="text-center">{t("Country")}</th>
            <th className="text-center">{t("Address")}</th>
            <th className="text-center">{t("VAT number")}</th>
            <th className="text-center">{t("CEO data")}</th>
            <th className="text-center">{t("Approved")}</th>
            <th className="text-center">{t("Update")}</th>
          </tr>
        </thead>
        <tbody>
          {(companyPlants || []).map((plant: Company) => {
            return (
              <tr key={plant.id}>
                <td className="text-center align-middle">{plant.name}</td>
                <td className="text-center align-middle">
                  {plant.country?.name}
                </td>
                <td className="text-center align-middle">{plant.address}</td>
                <td className="text-center align-middle">{plant.vatNumber}</td>
                <td className="text-center align-middle">
                  {plant.ceoName}
                  <br />
                  {plant.ceoEmail}
                </td>
                <td className="text-center align-middle">
                  {plant.approved ? t("Yes") : t("Pending")}
                </td>
                <td className="text-center align-middle">
                  <div className="row">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-outline-warning btn-sm"
                        style={{ width: "100%" }}
                        onClick={() => handleCompanyEdit(plant.id)}
                      >
                        <i className="fas fa-edit" /> {t("Edit")}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left mr-2" /> {t("Back")}
        </button>
      </div>
    </>
  )
}

export default PlantsList
