import axios from "axios"
import { restHelper } from "../../@common/service/api"
import { ID, UpdateTreeDragAndDropInput } from "../../@common/types"
import {
  Application,
  Link,
  ApplicationLink,
  SectorApplication,
  UpdateLinkInput,
  GetSectorApplicationInput,
  CreateApplicationInput,
  UpdateApplicationInput,
  CreateApplicationLinkInput,
  CreateSectorApplicationInput,
  AssignEndConfig,
} from "../types"

export const queries = {
  applications: `
    query {
      applications {
        id
        title
        visible
        type
        category
        children {
          id
          title
          visible
          parentId
          children {
            id
            title
            visible
            parentId
            children {
              id
              title
              visible
              parentId
            },
            children {
              id
              title
              visible
              parentId
              children {
                id
                title
                visible
                parentId
              }
            }
          }
        }
      }
    }
  `,
  links: `
    query links ($page: Int) {
      links (page: $page) {
        links {
          id
          composition {
            id
            name
            productName
          }
          applicationLinks {
            id
            application {
              id
              title
            }
            volume
          }
          createdAt
        }
        total
      }
    }
  `,
  link: `
    query link($id: Int!) {
      link(id: $id) {
        id
        composition {
          id
          name
          productName
        }
        applicationLinks {
          id
          application {
            id
          }
          volume
        }

        createdAt
      }
    }
  `,
  applicationLinks: `
    query {
      applicationLinks {
        linkId
        application {
          id
          visible
        }
        volume
      }
    }
  `,
  sectorApplications: `
    query sectorApplications(
      $year: Int
      $sourceType: String
    ) {
      sectorApplications (
        year: $year
        sourceType: $sourceType
      ) {
        id,
        value
        sectorAssociationValue {
          sourceType
          year
        }
      }
    }
  `,
}

export const mutations = {
  applicationCreate: `
    mutation applicationCreate(
      $title: String!
      $positionId: Int!
      $parentId: Int
    ) {
      applicationCreate(
        title: $title
        positionId: $positionId
        parentId: $parentId
      ) {
        id
      }
    } 
  `,
  applicationUpdate: `
    mutation applicationUpdate(
      $id: Int!
      $title: String!
    ) {
      applicationUpdate(
        id: $id
        title: $title
      ) {
        id
      }
    } 
  `,
  applicationShowHide: `
    mutation applicationShowHide(
      $id: Int!
    ) {
      applicationShowHide(
        id: $id
      )
    } 
  `,
  applicationSubmit: `
    mutation applicationSubmit(
      $compositionId: Int!
      $submissionId: Int!
      $plantIds: [Int]
      $applications: [ApplicationLinkInput]!
    ) {
      applicationSubmit(
        compositionId: $compositionId
        submissionId: $submissionId
        applications: $applications
        plantIds: $plantIds
      )
    } 
  `,
  applicationTreeUpdate: `
    mutation applicationTreeUpdate(
      $dragedElement: Int!
      $targetElement: Int!
      $isIndentElement: Boolean!
      $childrenIds: [Int]!
    ) {
      applicationTreeUpdate(
        dragedElement: $dragedElement
        targetElement: $targetElement
        isIndentElement: $isIndentElement
        childrenIds: $childrenIds
      ) {
        id
      }
    } 
  `,
  applicationDelete: `
    mutation applicationDelete($id: Int!) {
      applicationDelete(id: $id)
    } 
  `,
  updateLink: `
    mutation updateLink(
      $linkId: Int!
      $applications: [ApplicationLinkInput]!
    ) {
      updateLink(
        linkId: $linkId
        applications: $applications
      )
    } 
  `,
  deleteLinks: `
    mutation deleteLinks($id: Int!) {
      deleteLinks(id: $id)
    } 
  `,
  sectorApplicationCreate: `
    mutation sectorApplicationCreate(
      $sectorAssociationValueId: Int!
      $value: String!
    ) {
      sectorApplicationCreate(
        sectorAssociationValueId: $sectorAssociationValueId
        value: $value
      ) { value }
    } 
  `,
  sectorApplicationDelete: `
    mutation sectorApplicationDelete($id: Int!) {
      sectorApplicationDelete(id: $id)
    } 
  `,
}

export const getApplications = restHelper<Application[], object>(
  queries,
  "applications",
)

export const postApplication = restHelper<Application, CreateApplicationInput>(
  mutations,
  "applicationCreate",
)

export const putApplication = restHelper<Application, UpdateApplicationInput>(
  mutations,
  "applicationUpdate",
)

export const showHideApplication = restHelper<boolean, ID>(
  mutations,
  "applicationShowHide",
)

export const putApplicationTree = restHelper<
  Application,
  UpdateTreeDragAndDropInput
>(mutations, "applicationTreeUpdate")

export const deleteApplication = restHelper<boolean, ID>(
  mutations,
  "applicationDelete",
)

export const getLinks = restHelper<Link[], object>(queries, "links")

export const getLink = restHelper<Link, ID>(queries, "link")

export const updateLink = restHelper<UpdateLinkInput[], ID>(
  mutations,
  "updateLink",
)

export const deleteLinks = restHelper<Link[], ID>(mutations, "deleteLinks")

export const getApplicationLinks = restHelper<ApplicationLink[], object>(
  queries,
  "applicationLinks",
)

export const postApplicationLink = restHelper<
  boolean,
  CreateApplicationLinkInput
>(mutations, "applicationSubmit")

/**
 * SECTOR_APPLICATIONS
 */

export const getSectorApplications = restHelper<
  SectorApplication[],
  GetSectorApplicationInput
>(queries, "sectorApplications")

export const postSectorApplication = restHelper<
  SectorApplication[],
  CreateSectorApplicationInput
>(mutations, "sectorApplicationCreate")

export const deleteSectorApplication = restHelper<boolean, ID>(
  mutations,
  "sectorApplicationDelete",
)

export const getAssignEndConfig = async (): Promise<any>=> {
  return axios({
    url: `${process.env.REACT_APP_API_URL}configuration/application`,
    method: "GET",
  })
}