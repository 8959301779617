import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { handleToast } from "utils/messages"

import { getCompany } from "../../service/api"
import { Company } from "../../types"
import CompanyDetailsModalPlantRow from "./CompanyDetailsModalPlantRow"
import CompanyDetailsModalPlantUsers from "./CompanyDetailsModalPlantUsers"

interface CompanyDetailsModalProps {
  companyId: number
  isPlant?: boolean
  isCompanyDetailsOpened: boolean
  closeModal: () => void
}

const CompanyDetailsModal = (
  props: React.PropsWithChildren<CompanyDetailsModalProps>,
) => {
  const { t } = useTranslation()

  const { companyId, isPlant, closeModal, isCompanyDetailsOpened } = props

  const [company, setCompany] = useState<Company | null>(null)

  const fetchCompanyDetails = (id: number) => {
    getCompany({ id }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
        setCompany(null)
      }
      if (data) {
        setCompany(data)
      }
    })
  }

  useEffect(() => {
    if (isCompanyDetailsOpened && companyId) {
      fetchCompanyDetails(companyId)
    } else {
      setCompany(null)
    }
  }, [companyId, isCompanyDetailsOpened])

  const companyPlants = () => {
    return (
      <>
        <h4>{t("Company plants")}:</h4>

        <table className="table table-light table-bordered">
          <thead>
            <tr>
              <th>{t("Plant name")}</th>
              <th className="text-center">{t("Plant country")}</th>
              <th>{t("Users")}</th>
            </tr>
          </thead>
          <tbody>
            {company?.branches?.map((plant) => (
              <CompanyDetailsModalPlantRow key={plant.id} company={plant} />
            ))}
          </tbody>
        </table>
      </>
    )
  }

  return companyId ? (
    <div
      className={`srs-modal srs-modal-lg modal display-block fade ${
        isCompanyDetailsOpened && "d-block show"
      }`}
      role="dialog"
      aria-hidden="true"
      onClick={closeModal}
    >
      <div className="srs-modal-overlay" />
      <div className="modal-dialog" role="document">
        <div className="modal-content p-4">
          <button
            type="button"
            className="srs-modal-close position-absolute close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="font-weight-bold text-center pb-4">
            {isPlant ? t("Plant details") : t("Company details")}:
          </h4>
          {company && (
            <>
              <p>
                <span className="font-weight-bold mr-2">{t("Profile")}:</span>
                {`${company.name} ${company.archived ? " (archived)" : ""}`}
              </p>
              <p>
                <span className="font-weight-bold mr-2">{t("Address")}:</span>
                {company.address}
              </p>
              <p>
                <span className="font-weight-bold mr-2">
                  {t("Company VAT")}:
                </span>
                {company.vatNumber}
              </p>
              <p>
                <span className="font-weight-bold mr-2">{t("CEO name")}:</span>
                {company.ceoName}
              </p>
              <p>
                <span className="font-weight-bold mr-2">{t("CEO email")}:</span>
                {company.ceoEmail}
              </p>
            </>
          )}

          <hr />

          {isPlant && <CompanyDetailsModalPlantUsers companyId={companyId} />}
          {!isPlant && companyPlants()}

          <div className="text-right">
            <button
              type="button"
              className="btn btn-primary rounded-bottom-right"
              onClick={closeModal}
            >
              {t("Close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default CompanyDetailsModal
