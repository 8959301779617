import React from "react"
import { useTranslation } from "react-i18next"

import Input from "components/Input/Input"
import Modal from "components/Modal/Modal"
import Select from "components/Select/Select"

interface FinishAuditProps {
  showAuditUploadModal: boolean
  setShowAuditUploadModal: any
  handleUploadFile: any
  setSelectedFile: any
  submissionStatusList: any
  onSubmissionStatusChange: any
  submissionSelectedStatus: any
}

const FinishAudit: React.FC<FinishAuditProps> = ({
  showAuditUploadModal,
  setShowAuditUploadModal,
  handleUploadFile,
  setSelectedFile,
  submissionStatusList,
  onSubmissionStatusChange,
  submissionSelectedStatus,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={showAuditUploadModal}
      isOpenHandler={setShowAuditUploadModal}
      wrapperClass="srs-modal-lg"
    >
      <div className="modal-header">
        <h3 className="modal-title">
          <strong>{t("Finish audit")}</strong>
        </h3>
      </div>
      <div className="row">
        <div className="col-12">
          <Select
            name="submissionStatus"
            options={submissionStatusList}
            label={t("Choose submission status")}
            value={submissionSelectedStatus}
            handleOnChange={(selectedOption) =>
              onSubmissionStatusChange(selectedOption)
            }
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
          />
        </div>
      </div>
      <form onSubmit={handleUploadFile}>
        <div className="modal-body">
          {submissionSelectedStatus && (
            <Input
              name="file"
              type="file"
              handleOnChange={setSelectedFile}
              label="Choose file"
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
            />
          )}
        </div>
        <div className="modal-footer">
          {submissionSelectedStatus && (
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right float-right"
            >
              {t("Finish")}
            </button>
          )}
        </div>
      </form>
    </Modal>

    // <Modal
    //   isOpen={showAuditUploadModal}
    //   isOpenHandler={setShowAuditUploadModal}
    //   wrapperClass="srs-modal-lg"
    // >
    //
    // </Modal>
  )
}

export default FinishAudit
