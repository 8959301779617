import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { dateFormat } from "utils/dates"
import { getCountryFlagClassName } from "utils/strings"

import { EuCountriesArray } from "../../../../constants"
import { COLLECTION_DATE_FORMAT } from "../../constants"
import { Submission } from "../../types"

interface Props {
  isInputData: boolean
  submissions: Submission[]
  onSortChanged(sortBy: string, sortDirection: string): void
  getSubmissions(): void
}
const SubmissionListTableBodyForPlantsSelection: React.FC<Props> = ({
  submissions,
  isInputData = false,
}) => {
  const { t } = useTranslation()

  const [sortKey, setSortKey] = React.useState<keyof Submission>("yearlyNumber")
  const [sortDesc, setSortDesc] = React.useState<boolean>(true)

  const requestSort = (key: keyof Submission) => {
    if (key !== sortKey) {
      setSortKey(key)
    }

    setSortDesc(!sortDesc)
  }

  const sortedSubmissions = useMemo(() => {
    const sortableItems = [...submissions]

    sortableItems.sort((a: Submission, b: Submission) => {
      let itemA: any = a[sortKey]
      let itemB: any = b[sortKey]

      switch (sortKey) {
        case "yearlyNumber":
          const itemAMonth = a.yearlyNumber.split("/")[0]
          const itemBMonth = b.yearlyNumber.split("/")[0]

          itemA =
            itemAMonth.length === 1 ? `${0}${a.yearlyNumber}` : a.yearlyNumber
          itemB =
            itemBMonth.length === 1 ? `${0}${b.yearlyNumber}` : b.yearlyNumber

          break
        default:
          break
      }

      if (itemA < itemB) {
        return !sortDesc ? 1 : -1
      }
      if (itemA > itemB) {
        return !sortDesc ? -1 : 1
      }

      return 0
    })

    return sortableItems
  }, [submissions, sortDesc, sortKey])

  return (
    <>
      <table className="table table-light table-bordered text-center">
        <thead>
          <tr>
            <th>{t("Type")}</th>
            <th
              className="cursor-pointer align-middle px-2"
              onClick={() => requestSort("yearlyNumber")}
            >
              {t("Submission number")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            <th
              className="cursor-pointer align-middle px-2"
              onClick={() => requestSort("collectionDate")}
            >
              {t("Submission date")}
              <span
                className={`pl-2 fa text-primary ${
                  sortDesc ? "fa-angle-down" : "fa-angle-up"
                }`}
              />
            </th>
            {isInputData ? (
              <>
                <th>{t("Country of origin")}</th>
                <th>{t("Plant Country")}</th>
                <th>{t("Net input")}</th>
              </>
            ) : (
              <>
                <th>{t("Plant Country")}</th>
                <th>{t("Net Output EU + UK")} </th>
                <th>{t("Net Output non-EU")} </th>
              </>
            )}
            <th>{t("Status")} </th>
          </tr>
        </thead>
        <tbody>
          {sortedSubmissions.length ? (
            sortedSubmissions.map((submission) => {
              const submissionCountryFromEU = submission.country
                ? EuCountriesArray.includes(submission.country.code)
                : false
              const companyCountryFromEU = submission.company.country
                ? EuCountriesArray.includes(submission.company.country.code)
                : false
              return (
                <tr key={submission.id}>
                  <td className="align-middle">
                    {submission.type === 1 ? t("Converting") : t("Recycling")}
                  </td>
                  <td className="align-middle">{submission.yearlyNumber}</td>
                  <td className="align-middle">
                    {dateFormat(
                      submission.collectionDate,
                      COLLECTION_DATE_FORMAT,
                    )}
                  </td>
                  {isInputData ? (
                    <>
                      <td className="align-middle">
                        {(() => {
                          if (submissionCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">
                        {(() => {
                          if (companyCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.company?.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">{submission.volumeNet}</td>
                    </>
                  ) : (
                    <>
                      <td className="align-middle">
                        {(() => {
                          if (companyCountryFromEU) {
                            return (
                              <span
                                className={getCountryFlagClassName(
                                  submission.company?.country?.code,
                                )}
                              />
                            )
                          }
                          return <span>Non-EU</span>
                        })()}
                      </td>
                      <td className="align-middle">{submission.netOutputEU}</td>
                      <td className="align-middle">
                        {submission.netOutputNonEU}
                      </td>
                    </>
                  )}
                  <td className="align-middle">
                    {submission.status === t("SENT") ||
                    submission.status === t("AUDIT_IN_PROGRESS")
                      ? t("PENDING")
                      : t(submission.status)}
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={isInputData ? 9 : 7}>
                There is no submitted data to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default SubmissionListTableBodyForPlantsSelection
