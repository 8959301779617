import noIcon from "assets/images/wizardicons/no.svg"
import yesIcon from "assets/images/wizardicons/yes.svg"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"

import Input from "components/Input/Input"

import { handleToast } from "utils/messages"
import { windowStorage } from "utils/windowStorage"

import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { getCompany, postPlant } from "../../../companies/service/api"
import { Company } from "../../../companies/types"
import { fetchMaterialGroups } from "../../../materials/actions"
import { MaterialGroup } from "../../../materials/service/api"
import {
  SHOW_REGISTER_DONE_MODAL,
  SHOW_REGISTER_DONE_MODAL_CONVERTER,
} from "../../../submissions/constants"
import { postUser } from "../../../users/service/api"
import { refreshToken } from "../../actions"
import { postFinishRegistration } from "../../service/api"
import FinishCompanyForm from "./FinishCompanyForm"
import "./FinishRecycler.scss"
import FinishRecyclerForm from "./FinishRecyclerForm"
import FinishRecyclerPlantsList from "./FinishRecyclerPlantsList"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Name is required."),
})

const FinishRegistrationAll = () => {
  const history = useHistory()
  const [showPlantForm, setShowPlantForm] = useState(false)
  const [showUserForm, setShowUserForm] = useState(false)
  const [step, setStep] = useState<number>()
  const [isDone, setIsDone] = useState<boolean>(false)
  const [isRegistrationFinished, setIsRegistrationFinished] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [company, setCompany] = useState<Company | null>(null)

  useEffect(() => {
    dispatch(fetchMaterialGroups())
  }, [dispatch])

  const materialGroups =
    useStateGetter<MaterialGroup[]>(["material", "materialGroups"]) ?? []

  useEffect(() => {
    getCompany().then(({ data }) => {
      if (data) {
        console.log(data)
        if (data) {
          if (step === 0) {
            setStep(1)
          }
          setCompany(data)
        }
      } else {
        console.log("non comapny")
      }
    })
  }, [step])

  const handleFinishRegistration = async () => {
    const { data } = await postFinishRegistration({
      fieldsOfActivity: "1",
    })
    if (data) {
      dispatch(refreshToken())
      setIsRegistrationFinished(true)
    }
  }

  const { registrationFinished } = useUserFromToken()
  useEffect(() => {
    if (
      isRegistrationFinished &&
      registrationFinished &&
      registrationFinished === true
    ) {
      windowStorage.set(SHOW_REGISTER_DONE_MODAL, "recycler", false)
      windowStorage.set(
        "show_register_modal",
        SHOW_REGISTER_DONE_MODAL_CONVERTER,
        false,
      )
      history.replace("/plants", {
        title: t("Manage plant"),
      })
    }
  }, [history, isRegistrationFinished, registrationFinished, t])

  const handleSuccessUserForm = async (
    { name, email }: any,
    { resetForm }: { resetForm: () => void },
  ) => {
    postUser({ name, email, companyId: company?.id }).then(
      async ({ data, errors }) => {
        if (data) {
          const message = t("User has been created")
          handleToast([message])
          if (isDone) {
            await handleFinishRegistration()
          }

          resetForm()
        }

        if (errors) {
          handleToast(errors, "error")
        }
      },
    )
  }

  useEffect(() => {
    setStep(0)
  }, [setStep])

  const handleStepChange = (s: number) => {
    setStep(s)
  }

  const getSelectedMultiValues = (values?: Array<{ id?: number }>) =>
    values?.map((value) => value.id)

  const handleOnePlantClicked = () => {
    if (company && company.country) {
      postPlant({
        name: company.name,
        address: company.address,
        country: company.country.id,
        vatNumber: company.vatNumber,
        ceoName: company.ceoName,
        ceoEmail: company.ceoEmail,
        capacities: company.capacities,
        roleId: company.roleId,
        polymers: getSelectedMultiValues(company.polymers),
      }).then(({ errors }) => {
        if (errors) {
          handleToast(errors, "error")
        } else {
          handleStepChange(3)
        }
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema,
    onSubmit: handleSuccessUserForm,
  })

  const { values, handleChange, touched, errors, handleSubmit } = formik

  return (
    <>
      {step === 0 && (
        <FinishCompanyForm
          handleStepChange={handleStepChange}
          handleFinishRegistration={handleFinishRegistration}
          materialGroups={materialGroups}
        />
      )}
      {step === 1 && !showPlantForm && (
        <>
          <span>
            {t("Are you submitting on behalf of more than one plant?")}
          </span>
          <div className="row d-flex registration-finish-choices">
            <div
              className="card text-center"
              onClick={() => setShowPlantForm(true)}
            >
              <div className="card-body">
                <div className="icon-area">
                  <img src={yesIcon} className="card-img-top" alt="" />
                </div>
                <h5 className="card-title">{t("Yes")}</h5>
              </div>
            </div>
            <div
              className="card text-center"
              onClick={() => handleOnePlantClicked()}
            >
              <div className="card-body">
                <div className="icon-area">
                  <img src={noIcon} className="card-img-top" alt="" />
                </div>
                <h5 className="card-title">{t("No")}</h5>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 1 && showPlantForm && (
        <FinishRecyclerForm
          handleStepChange={handleStepChange}
          materialGroups={materialGroups}
          back={() => setShowPlantForm(false)}
        />
      )}
      {step === 2 && (
        <div>
          <FinishRecyclerPlantsList handleStepChange={handleStepChange} />
        </div>
      )}
      {step === 3 && !showUserForm && (
        <>
          <span>
            {t("Would you like to add an additional user to this account?")}
          </span>
          <div className="row d-flex registration-finish-choices">
            <div
              className="card text-center"
              onClick={() => setShowUserForm(true)}
            >
              <div className="card-body">
                <div className="icon-area">
                  <img src={yesIcon} className="card-img-top" alt="" />
                </div>
                <h5 className="card-title">{t("Yes")}</h5>
              </div>
            </div>
            <div
              className="card text-center"
              onClick={async () => await handleFinishRegistration()}
            >
              <div className="card-body">
                <div className="icon-area">
                  <img src={noIcon} className="card-img-top" alt="" />
                </div>
                <h5 className="card-title">{t("No")}</h5>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 3 && showUserForm && (
        <>
          <form onSubmit={handleSubmit}>
            <Input
              label={`${t("Name")} (*)`}
              name="name"
              inputWrapperClassName="col-sm-8"
              handleOnChange={handleChange}
              value={values.name}
              labelClassName="col-sm-4"
              error={touched.name && errors.name}
            />
            <Input
              name="email"
              label={`${t("Email address")} (*)`}
              inputWrapperClassName="col-sm-8"
              handleOnChange={handleChange}
              value={values.email}
              labelClassName="col-sm-4"
              error={touched.email && errors.email}
            />
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-outline-primary rounded-bottom-left"
                onClick={() => setShowUserForm(false)}
                style={{ height: "50px" }}
              >
                <i className="fas fa-arrow-left mr-3" /> {t("Back")}
              </button>
              <div>
                <button
                  type="submit"
                  className="btn btn-success"
                  style={{ height: "50px" }}
                >
                  {t("Add user and create new")}{" "}
                  <i className="fas fa-plus m-3" />
                </button>
                <button
                  type="submit"
                  className="btn btn-warning rounded-bottom-right"
                  // onClick={() => setIsDone(true)}
                  onClick={() => setIsDone(true)}
                  style={{ height: "50px" }}
                >
                  {t("Add user and finish registration")}{" "}
                  <i className="fas fa-arrow-right ml-3" />
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default FinishRegistrationAll
