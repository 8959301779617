import { GenericCompositionActions } from "./actions"
import {
  FETCH_GENERIC_COMPOSITION,
  FETCH_GENERIC_COMPOSITIONS,
} from "./constants"
import { CompositionType } from "./enums"
import { GenericComposition } from "./types"

export interface GenericCompositionState {
  [key: string]: {
    list: GenericComposition[]
    item: GenericComposition
  }
}

const initialState: GenericCompositionState = {
  [CompositionType.USED_IN]: {
    list: [],
    item: {
      id: 0,
      productId: 0,
      productName: "",
      companyId: 0,
      type: CompositionType.USED_IN,
      isArchived: false,
    },
  },
}

const genericComposition = (
  state: GenericCompositionState = initialState,
  action: GenericCompositionActions,
) => {
  switch (action.type) {
    case FETCH_GENERIC_COMPOSITIONS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: action.payload.genericCompositions,
        },
      }
    case FETCH_GENERIC_COMPOSITION:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: action.payload.genericComposition,
        },
      }
    default:
      return state
  }
}

export default genericComposition
