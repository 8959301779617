import _, { map } from "lodash"
import { Company } from "modules/companies/types"
import { sum } from "ramda"
import React, {
  useEffect,
  useState,
  ChangeEvent,
  useContext,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { useDispatch } from "react-redux"
import { Option } from "react-select/src/filters"

import Input from "components/Input/Input"
import { mapToOptions } from "components/Select/Select"
import Tree, { BranchProps, TreeNode } from "components/Tree/Tree"

import { handleToast } from "utils/messages"
import { toPercent, fromPercent } from "utils/numbers"
import { history } from "utils/routes"

import useMaxValueState, { MaxValueContext } from "hooks/useMaxValueState"
import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { COLOR_MAP } from "../../../../constants"
import { getCompany, getPlants } from "../../../companies/service/api"
import { MaterialGroup } from "../../../materials/service/api"
import { fetchSubmissions } from "../../../submissions/actions"
import { Submission } from "../../../submissions/types"
import {
  fetchApplications,
  fetchLinks,
  createApplicationLink,
} from "../../actions"
import { updateLink, getLink } from "../../service/api"
import useApplicationConfig from "hooks/useApplicationConfig"

interface SelectedSubmission {
  id: number
  compositionId: number
  submissionId: number
  name: string
  value: number
  capability: number
  totalUsedVolume: number
}

export const ApplicationBranch = ({ node }: BranchProps) => {
  const { state, localDispatch, unit, maxValue } = useContext(MaxValueContext)
  const { id, children } = node
  const applicationBranchId = `applicationBranch_${id}`

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value !== "") {
      localDispatch({
        type: "set",
        payload: {
          [id]: parseInt(value, 0),
          maxValue,
        },
      })
    }
  }

  return (
    <div className="w-100">
      <div className="srs-branch col-12">
        <div className="pr-3 d-flex align-items-center justify-content-between col-12">
          <h6 className="mb-0 col-7">{node.title}</h6>
          {children.length === 0 && (
            <div className="d-flex col-5">
              <span className="ml-5 d-flex align-items-center">
                <button
                  type="button"
                  className="mr-2 btn btn-link btn-sm"
                  onClick={() =>
                    localDispatch({
                      type: "increment",
                      payload: {
                        [id]: 10,
                        maxValue,
                      },
                    })
                  }
                >
                  <i className="fas fa-plus-circle fa-lg" />
                </button>
              </span>
              <Input
                label={unit}
                name={applicationBranchId}
                value={state[id]}
                handleOnChange={handleOnInputChange}
                inputWrapperClassName="col-sm-8"
                labelClassName="col-sm-4"
                right
              />
              <span className="mr-5 d-flex align-items-center">
                <button
                  type="button"
                  className="ml-2 btn btn-link btn-sm"
                  onClick={() =>
                    localDispatch({
                      type: "decrement",
                      payload: {
                        [id]: 10,
                        maxValue,
                      },
                    })
                  }
                  disabled={state[id] === 0}
                >
                  <i className="fas fa-minus-circle fa-lg" />
                </button>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const groupSubmissions = (submissions: Submission[]) => {
  const submissionsWithComposition = submissions.filter(
    (s) => !!s.composition && !!s.composition.productName.length,
  )
  let totalValue = 0
  const groupedSubmissions = submissionsWithComposition.reduce(
    (group, product) => {
      console.debug(product.totalUsedVolume)
      const { composition } = product
      totalValue += product.volumeNet
      // @ts-ignore
      group[composition?.id] = group[composition?.id] ?? {
        compositionId: 0,
        submissionId: 0,
        value: 0,
        totalUsedVolume: 0,
        capability: 0,
        name: "",
        material: "",
        plantName: "",
      }
      // @ts-ignore
      group[composition?.id] = {
        // @ts-ignore
        value: group[composition?.id].value + product.volumeNet,
        totalUsedVolume:
          // @ts-ignore
          group[composition?.id].totalUsedVolume + product.totalUsedVolume,
        name: product.composition?.productName,
        submissionId: product.id,
        compositionId: product.composition?.id,
        material: product.materialsGroup?.name,
        capability: 0,
        id: product.composition?.id,
        plantName: product.company.name,
      }

      return group
    },
    {},
  )
  const groupedArray = Object.values(groupedSubmissions)

  return groupedArray.map((g: any) => ({
    ...g,
    scale: Number(toPercent(g.value - g.totalUsedVolume, totalValue)),
  }))
}

export const getAllApplicationsIds =
  (ids: { [key: number]: number }) =>
  (node: TreeNode): TreeNode => {
    const bodyIds = ids
    bodyIds[node.id] = 0
    return {
      id: node.id,
      title: node.title,
      children: node.children
        ? node.children.map(getAllApplicationsIds(bodyIds))
        : [],
    }
  }

interface Props {
  compositionId?: number
  linkId?: number
}

const ApplicationListTable: React.FC<Props> = ({ compositionId, linkId }) => {
  const { t } = useTranslation()
  const isEditMode = compositionId && linkId
  const [initialStateSum, setInitialStateSum] = useState(0)
  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [selectedSubmission, setSelectedSubmission] =
    useState<SelectedSubmission>()
  const storeSubmissions =
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? []

  const acceptedSubmissions = useMemo(
    () =>
      storeSubmissions.length
        ? storeSubmissions.filter(
            (s) =>
              s.status === "ACCEPTED" ||
              s.status === "SENT" ||
              s.status !== "DECLINED",
          )
        : [],
    [storeSubmissions],
  )
  const [filters, setFilters] = useState<Option[]>([])
  const storeApplications =
    useStateGetter<TreeNode[]>(["application", "applications"]) ?? []
  const [applications, setApplications] = useState<any>([])
  const { isRrr } = useUserFromToken()

  const configApplicationEnd = useApplicationConfig()

  useEffect(() => {
    setApplications(
      storeApplications.filter(
        (s) => s.title == selectedSubmission?.name.split(" -")[0],
      ),
    )
  }, [storeApplications, selectedSubmission])

  const ids = {}
  storeApplications.map(getAllApplicationsIds(ids))
  const [state, localDispatch] = useMaxValueState(ids)
  const stateSum = sum(Object.values(state))

  const [selectedSubmissionCapability, setSelectedSubmissionCapability] =
    useState(0)
  const [unit, setUnit] = useState("kg")
  const dispatch = useDispatch()
  const [groupedSubmissions, setGroupedSubmissions] = useState<any[]>()

  const [materials, setMaterials] = useState<MaterialGroup[] | null>(null)

  // fetch materials at start
  useEffect(() => {
    getPlants().then((plants) => {
      setCompanies(plants.data ?? [])
      const companiesOptions = mapToOptions(plants.data as Company[])
      // @ts-ignore
      setSelectedCompanies(companiesOptions)
    })
    getCompany().then((r) => {
      if (r.data?.polymers) {
        // @ts-ignore
        setFilters(mapToOptions(r?.data.polymers ?? []))
        setMaterials(
          r.data.polymers.sort((a, b) =>
            a.name.localeCompare(b.name),
          ) as MaterialGroup[],
        )
      } else {
        getPlants().then((plants) => {
          // @ts-ignore
          const polymers = plants?.data.flatMap((plant) => {
            return plant.polymers
          })
          const duplicates: string[] = []
          const uniquePolymers = polymers.filter(function (el: any) {
            if (duplicates.indexOf(el.name) == -1) {
              duplicates.push(el.name)
              return true
            }

            return false
          })
          setFilters(mapToOptions(uniquePolymers) as Option[])
          setMaterials(
            uniquePolymers.sort((a, b) =>
              //  @ts-ignore
              a.name.localeCompare(b.name),
            ) as MaterialGroup[],
          )
        })
      }
    })
  }, [])

  useEffect(() => {
    console.debug({ acceptedSubmissions })
    const grouped = groupSubmissions(acceptedSubmissions)
    const tempGrouped = grouped.filter((subm) => {
      if (filters.filter((a) => a.label == subm.name.split(" -")[0]).length > 0)
        return subm
    })

    const filteredByPlant = tempGrouped.filter((s) => {
      const companySubmissions = selectedCompanies.find(
        (c) => c.label === s.plantName,
      )
      return !!companySubmissions
    })

    setGroupedSubmissions(isRrr ? filteredByPlant : tempGrouped)
  }, [acceptedSubmissions, filters, selectedCompanies])

  const handleOnUnitChange = () => {
    const currentUnit = unit === "kg" ? "%" : "kg"
    setUnit(currentUnit)
    if (currentUnit === "kg") {
      setSelectedSubmissionCapability(selectedSubmission?.value ?? 0)
    } else {
      setSelectedSubmissionCapability(100)
    }
    localDispatch({ type: "reset", payload: ids })
  }

  const handleOnSelecSubmission = (sSubmission: SelectedSubmission) => {
    setSelectedSubmission(sSubmission)
    if (!isEditMode) {
      localDispatch({ type: "reset", payload: ids })
    }
    setSelectedSubmissionCapability(
      sSubmission.value - sSubmission.totalUsedVolume,
    )
  }

  /**
   * Initial data for update state (Submissions)
   */
  if (isEditMode && !selectedSubmission) {
    const selectedEditSubmission = groupedSubmissions?.find(
      (gs) => gs.compositionId === compositionId,
    )

    if (selectedEditSubmission) {
      handleOnSelecSubmission(selectedEditSubmission)
    }
  }

  useEffect(() => {
    if (linkId) {
      getLink({ id: linkId }).then(({ data }) => {
        const editIds: { [key: number]: number } = {}
        if (data) {
          data.applicationLinks.forEach((al) => {
            editIds[al.application?.id ?? 0] = al.volume
          })
          localDispatch({ type: "update", payload: editIds })
          setInitialStateSum(sum(Object.values(editIds)))
        }
      })
    }
  }, [linkId, localDispatch])

  const handleAddApplicatioLinks = async () => {
    const allApplications = Object.entries(state).map(
      ([applicationId, volume]) => {
        const applicationVolume =
          unit === "%"
            ? fromPercent(selectedSubmission?.value ?? 0, volume)
            : volume
        return {
          applicationId: parseInt(applicationId, 0),
          volume: applicationVolume,
        }
      },
    )
    const applications = allApplications.filter(
      (application) => application.volume > 0,
    )

    if (selectedSubmission?.id) {
      if (isEditMode) {
        await updateLink({
          linkId,
          applications,
        })
        history.goBack()
      } else {
        console.debug({
          compositionId: selectedSubmission.compositionId,
          submissionId: selectedSubmission.submissionId,
          applications,
          plantIds: selectedCompanies.map((value) => Number(value.value)),
        })
        await dispatch(
          createApplicationLink({
            compositionId: selectedSubmission.compositionId,
            submissionId: selectedSubmission.submissionId,
            applications,
            plantIds: selectedCompanies.map((value) => Number(value.value)),
          }),
        )

        handleToast(["Application updated"], "success")

        setTimeout(() => {
          const dateNow = new Date()
          const previousYear = dateNow.getFullYear() - 1
          const year = configApplicationEnd?.year ?? previousYear
          dispatch(
            fetchSubmissions({
              dateStart: year + "-01-01",
              dateEnd: year + "-12-01",
              limit: 10000,
            }),
          )
          dispatch(
            fetchLinks({
              limit: 10000,
            }),
          )
        }, 300)
      }
      localDispatch({ type: "reset", payload: ids })
      setSelectedSubmissionCapability(0)
      setSelectedSubmission(undefined)
    }
  }

  useEffect(() => {
    const dateNow = new Date()
    const previousYear = dateNow.getFullYear() - 1
    const year = configApplicationEnd?.year ?? previousYear
    dispatch(
      fetchSubmissions({
        dateStart: year + "-01-01",
        dateEnd: year + "-12-31",
        limit: 10000,
      }),
    )}, [storeSubmissions, dispatch]
  )

  useEffect(() => {
    dispatch(fetchApplications())}, [storeApplications, dispatch]
  )
  useEffect(() => {
    dispatch(
      fetchLinks({
        limit: 10000,
      }),
    )
  }, [])

  return (
    <div className="srs-application-list">
      <div className="flex-row d-flex">
        {isRrr ? (
          <div
            style={{ width: "350px" }}
            className="mb-3 mr-5 multi-select-wrapper"
          >
            <label htmlFor="plants">Plants</label>
            <MultiSelect
              options={mapToOptions(companies)}
              value={selectedCompanies}
              onChange={(e: any) => {
                setSelectedCompanies(e)
                setSelectedSubmission(undefined)
              }}
              labelledBy="plants"
              disableSearch
            />
          </div>
        ) : null}
        <div style={{ width: "350px" }} className="mb-3 multi-select-wrapper">
          <label htmlFor="plants">Polymers</label>
          <MultiSelect
            options={
              materials ? mapToOptions(materials as MaterialGroup[]) : []
            }
            value={filters as unknown as Option[]}
            onChange={(e: any) => {
              setFilters(e)
              setSelectedSubmission(undefined)
            }}
            labelledBy="polymers"
            disableSearch
          />
        </div>
      </div>
      <div className="mb-3">
        {groupedSubmissions?.map((submission, index) => {
          const { value, totalUsedVolume } = submission
          if (value === totalUsedVolume) {
            return null
          }

          if (isEditMode && compositionId !== submission.id) {
            return null
          }

          let submissionValue = value - totalUsedVolume
          let submissionPercentValue = "100"

          if (submission.id === selectedSubmission?.id) {
            if (unit === "%") {
              submissionValue -= fromPercent(submissionValue, stateSum)
              submissionPercentValue = (100 - stateSum).toString()
            } else {
              const percent = toPercent(stateSum, submissionValue)
              submissionValue = submissionValue - stateSum + initialStateSum
              submissionPercentValue = (100 - parseInt(percent, 0)).toString()
            }
          }

          return (
            <button
              key={submission.id}
              type="button"
              className={`bg-white btn btn-link w-100 d-flex border-bottom justify-content-center  ${
                selectedSubmission?.id === submission.id && "border-primary"
              }`}
              style={{
                whiteSpace: "initial",
                fontSize: "14px",
                alignItems: "center",
              }}
              onClick={() => handleOnSelecSubmission(submission)}
            >
              <p className={`w-100 col-3 mb-0 text-secondary text-left `}>
                {submission.name}
              </p>
              <div className="progress w-100 col-9" style={{ height: "35px" }}>
                <div
                  className={`progress-bar text-left pl-2 ${
                    selectedSubmission?.id == submission.id
                      ? COLOR_MAP[3]
                      : COLOR_MAP[0]
                  }`}
                  role="progressbar"
                  style={{ width: `${submissionPercentValue}%` }}
                >
                  ({submissionValue} kg)
                </div>
                <div style={{ width: `${100 - submission.scale}%` }} />
              </div>
            </button>
          )
        })}
      </div>
      <>
        {selectedSubmission && (
          <>
            <button
              type="button"
              className="mb-1 btn btn-outlined-warning w-100"
              onClick={handleOnUnitChange}
            >
              {t("Switch value unit to")}{" "}
              <big>{unit === "kg" ? "%" : "kg"}</big>
            </button>
            <div className="srs-tree-line-container">
              <MaxValueContext.Provider
                value={{
                  state,
                  localDispatch,
                  unit,
                  maxValue: isEditMode
                    ? selectedSubmission.value
                    : selectedSubmissionCapability,
                }}
              >
                <Tree nodes={applications} Branch={ApplicationBranch} />
              </MaxValueContext.Provider>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-primary rounded-bottom-left"
            onClick={() => history.goBack()}
          >
            <i className="mr-2 fas fa-arrow-left" /> {t("Back")}
          </button>
          {selectedSubmission && (
            <button
              type="button"
              className="btn btn-success rounded-bottom-right"
              onClick={handleAddApplicatioLinks}
            >
              {isEditMode ? "Update" : "Save"}
            </button>
          )}
        </div>
      </>
    </div>
  )
}

export default ApplicationListTable
