/* eslint-disable react/no-array-index-key */
import { sum, map, prop, uniq } from "ramda"
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import Select, { SelectValue } from "components/Select/Select"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"

import useStateGetter from "hooks/useStateGetter"

import { fetchSectorSubmissions } from "../../actions"
import { postSectorSubmission, updateSectorSubmission } from "../../service/api"
import { SectorSubmission } from "../../types"
import { computeInitialSectorSubmission } from "../../utils"
import "./SectorSubmissionForm.scss"

const setVolumes = (
  originalValues: number[],
  index: number,
  value: number,
): number[] => {
  const originalValuesCopy = [...originalValues]
  originalValuesCopy[index] = value
  return originalValuesCopy
}

const yearsOptions = [
  { label: "2018", value: 2018 },
  { label: "2019", value: 2019 },
  { label: "2020", value: 2020 },
]

interface Props {
  initialSectorSubmissions?: SectorSubmission[]
}

const SectorSubmissionForm: React.FC<Props> = ({
  initialSectorSubmissions,
}) => {
  const isCreateMode = !initialSectorSubmissions

  const stateSectorSubmissions =
    useStateGetter<SectorSubmission[]>(["submission", "sectorSubmissions"]) ??
    []
  const addedYears = uniq(map(prop("year"), stateSectorSubmissions))
  const years = yearsOptions.filter(
    (yearOption) => !addedYears.includes(yearOption.value),
  )

  const [year, setYear] = useState(years[0])
  const lastYear = years[0]
  useEffect(() => {
    setYear(lastYear)
  }, [lastYear, years.length])
  const dispatch = useDispatch()

  const [preVolumes, setPreVolumes] = useState([0, 0, 0, 0])
  const [postVolumes, setPostVolumes] = useState([0, 0, 0, 0])

  useEffect(() => {
    const { internal, external } = computeInitialSectorSubmission(
      initialSectorSubmissions,
    )
    setPreVolumes(internal.data)
    setPostVolumes(external.data)
  }, [initialSectorSubmissions])

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const credentials: { sectorAssociationValues: SectorSubmission[] } = {
      sectorAssociationValues: [
        {
          year: 0,
          sourceType: "internal",
          q1: preVolumes[0],
          q2: preVolumes[1],
          q3: preVolumes[2],
          q4: preVolumes[3],
        },
        {
          year: 0,
          sourceType: "external",
          q1: postVolumes[0],
          q2: postVolumes[1],
          q3: postVolumes[2],
          q4: postVolumes[3],
        },
      ],
    }

    const { internal, external } = computeInitialSectorSubmission(
      initialSectorSubmissions,
    )

    if (isCreateMode) {
      credentials.sectorAssociationValues[0].year = year.value
      credentials.sectorAssociationValues[1].year = year.value
      const { errors } = await postSectorSubmission(credentials)
      if (errors) {
        handleToast(errors, "error")
      }
    } else {
      credentials.sectorAssociationValues[0].id = internal.id
      credentials.sectorAssociationValues[1].id = external.id
      credentials.sectorAssociationValues[0].year = internal.year
      credentials.sectorAssociationValues[1].year = external.year

      const { errors } = await updateSectorSubmission(credentials)
      if (errors) {
        handleToast(errors, "error")
      }
    }

    handleToast(["Success"])
    history.push("/submissions")
  }

  const handleOnQuarterChange = (
    valueType: string,
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = event.target
    const numberValue = parseInt(value, 0)
    if (valueType === "pre") {
      setPreVolumes(setVolumes(preVolumes, index, numberValue))
    } else {
      setPostVolumes(setVolumes(postVolumes, index, numberValue))
    }
  }

  const onSelectChange = (selectedOption: SelectValue) => {
    const selectedYear = selectedOption as { label: string; value: number }
    setYear(selectedYear)
  }

  const preVolumesTotal = sum(preVolumes)
  const postVolumesTotal = sum(postVolumes)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchSectorSubmissions())
  }, [dispatch])

  return (
    <>
      <h1 className="mb-5">{t("Add Submission")}</h1>
      <div className="srs-sector-submission-form">
        {years.length || !isCreateMode ? (
          <form onSubmit={handleOnSubmit}>
            {isCreateMode && (
              <Select
                name="year"
                label={t("Years")}
                options={years}
                value={year}
                handleOnChange={onSelectChange}
                inputWrapperClassName="col-sm-8"
                labelClassName="col-sm-4"
              />
            )}
            <table className="table table-light table-bordered">
              <thead>
                <tr>
                  <th className="col-sm-7">{t("Quarter")}</th>
                  <th className="col-sm-1 text-center">{t("Q1")}</th>
                  <th className="col-sm-1 text-center">{t("Q2")}</th>
                  <th className="col-sm-1 text-center">{t("Q3")}</th>
                  <th className="col-sm-1 text-center">{t("Q4")}</th>
                  <th className="col-sm-1">{t("Total")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    {t("Internal pre-consumer waste recycled (T)")}
                  </td>
                  {preVolumes.map((value, index) => (
                    <td key={index}>
                      <input
                        type="text"
                        className="border-0 text-center"
                        value={value}
                        onChange={(event) =>
                          handleOnQuarterChange("pre", event, index)
                        }
                      />
                    </td>
                  ))}
                  <td className="text-center">{preVolumesTotal}</td>
                </tr>
                <tr>
                  <td className="align-middle">
                    {t(
                      "Volume of recyclate brought from an external source (T)",
                    )}
                  </td>
                  {postVolumes.map((value, index) => (
                    <td key={index}>
                      <input
                        type="text"
                        className="border-0 text-center"
                        value={value}
                        onChange={(event) =>
                          handleOnQuarterChange("post", event, index)
                        }
                      />
                    </td>
                  ))}
                  <td className="text-center">{postVolumesTotal}</td>
                </tr>
                <tr>
                  <td className="align-middle">{t("Total Uptake")}</td>
                  <td className="align-middle text-center">
                    {preVolumes[0] + postVolumes[0]}
                  </td>
                  <td className="align-middle text-center">
                    {preVolumes[1] + postVolumes[1]}
                  </td>
                  <td className="align-middle text-center">
                    {preVolumes[2] + postVolumes[2]}
                  </td>
                  <td className="align-middle text-center">
                    {preVolumes[3] + postVolumes[3]}
                  </td>
                  <td className="text-center">
                    {preVolumesTotal + postVolumesTotal}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-primary rounded-bottom-left"
                onClick={() => history.goBack()}
              >
                <i className="fas fa-arrow-left mr-2" /> {t("Back")}
              </button>
              <button
                type="submit"
                className="btn btn-success rounded-bottom-right"
              >
                {t("Submit")}
                <i className="fas fa-arrow-right ml-3" />
              </button>
            </div>
          </form>
        ) : (
          <div>
            {t("All years attached")}
            <Link className="btn btn-primary ml-3" to="/submissions">
              {t("Go to sector submissions")}
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

export default SectorSubmissionForm
