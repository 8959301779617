import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { useDispatch } from "react-redux"
import * as Yup from "yup"

import Checkbox from "components/Checkbox/Checkbox"
import Input from "components/Input/Input"
import Select, { mapToOptions, SelectValue } from "components/Select/Select"

import { handleToast } from "utils/messages"

import useStateGetter from "hooks/useStateGetter"

import { ACTIVITY_LIST } from "../../../companies/constants"
import { getCompany, postPlant } from "../../../companies/service/api"
import { Company, CompanyRole } from "../../../companies/types"
import { fetchCountries } from "../../../countries/actions"
import { MaterialGroup } from "../../../materials/service/api"

const FinishRecyclerForm: React.FC<{
  handleStepChange: any
  materialGroups: MaterialGroup[]
  back: () => void
}> = ({ handleStepChange, materialGroups, back }) => {
  const CompanyFormValidationShema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    selectedCountry: Yup.object().typeError("Country is required."),
    vatNumber: Yup.string().when("hasVatNumber", {
      is: true,
      then: Yup.string().required("Vat Number is required"),
    }),
    companyRole: Yup.object().typeError(
      "Field of Choose a role / activity is required.",
    ),
  })

  const dispatch = useDispatch()
  const [isDone, setIsDone] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const countries =
    useStateGetter<{ id: string; name: string }[]>(["country", "countries"]) ??
    []
  const { t } = useTranslation()

  const COUNTRIES = countries.map((countrie) => ({
    value: parseInt(countrie.id, 0),
    label: countrie.name,
  }))

  const [parentCompany, setParentCompany] = useState<Company>()

  useEffect(() => {
    getCompany().then(({ data }) => {
      if (data) {
        setParentCompany(data)
      }
    })
  }, [])

  const getSelectedMultiValues = (values: Array<{ value: number }>) =>
    values.map((value) => value.value)

  const handleOnSubmit = async (
    {
      name,
      selectedCountry,
      address,
      vatNumber,
      ceoName,
      ceoEmail,
      capacities,
      hasVatNumber,
      companyPolymer,
      companyRole,
    }: any,
    { resetForm }: { resetForm: () => void },
  ) => {
    // Polymers validation
    const preaprePolymersToSend =
      companyPolymer.length > 0 ? companyPolymer : []
    if (!(preaprePolymersToSend.length > 0)) return setLoading(false)

    if (!loading) {
      setLoading(true)
      let cachedErrors
      const { errors } = await postPlant({
        name,
        country: selectedCountry.value,
        address,
        vatNumber,
        ceoName,
        ceoEmail,
        capacities: parseInt(capacities, 0),
        hasVatNumber,
        polymers: getSelectedMultiValues(companyPolymer),
        roleId: companyRole.value,
      })
      cachedErrors = errors
      resetForm()

      setLoading(false)

      if (cachedErrors) {
        handleToast(cachedErrors, "error")
      } else {
        handleToast(["Plant saved"])
        if (isDone) {
          handleStepChange(2)
        }
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      selectedCountry: null,
      hasVatNumber: false,
      vatNumber: "",
      capacities: 0,
      companyRole: null,
      companyPolymer: [],
    },
    validationSchema: CompanyFormValidationShema,
    onSubmit: handleOnSubmit,
  })

  const { values, handleChange, touched, errors, setFieldValue, handleSubmit } =
    formik
  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCountry", selectedOption)
  }

  const onSelectChangeRole = (selectedOption: SelectValue) => {
    setFieldValue("companyRole", selectedOption)
  }

  const onSelectChangePolymer = (selectedOption: SelectValue) => {
    setFieldValue("companyPolymer", selectedOption)
  }

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  useEffect(() => {
    values.vatNumber = ""
  }, [values.hasVatNumber, values.vatNumber])

  const filteredActivities = ACTIVITY_LIST.filter((activity) => {
    if (parentCompany?.roleId === CompanyRole.RECYCLER_AND_CONVERTER) {
      return true
    }

    return parentCompany?.roleId === activity.value
  })

  return (
    <>
      <div className="mb-4 font-weight-bold">Register Plant</div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          label={`${t("Name")} (*)`}
          name="name"
          value={values.name}
          handleOnChange={handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.name && errors.name}
        />
        <Select
          name="companyRole"
          label={`${t("Activity")} (*)`}
          options={filteredActivities}
          value={values.companyRole}
          handleOnChange={onSelectChangeRole}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.companyRole && errors.companyRole}
        />
        <div className="srs-select form-group row align-items-center">
          <label className="col-sm-4 col-form-label">{`${t(
            "Polymer(s) processed",
          )} (*)`}</label>
          <div className="multi-select-wrapper col-sm-8">
            <MultiSelect
              options={mapToOptions(materialGroups)}
              value={values.companyPolymer}
              onChange={onSelectChangePolymer}
              labelledBy="companyPolymer"
              disableSearch
            />
            {touched.companyPolymer && values.companyPolymer.length === 0 ? (
              <small className="form-text text-danger">
                Field of Polymer(s) processed is required.
              </small>
            ) : null}
          </div>
        </div>

        <Select
          name="selectedCountry"
          label={`${t("Country")} (*)`}
          options={COUNTRIES}
          value={values.selectedCountry}
          handleOnChange={onSelectChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.selectedCountry && errors.selectedCountry}
        />
        <Input
          name="address"
          value={values.address}
          label={`${t("Address")} (*)`}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          handleOnChange={handleChange}
          error={touched.address && errors.address}
        />
        <Checkbox
          name="hasVatNumber"
          value={!values.hasVatNumber}
          label={t("Has same VAT number as company")}
          checkboxWrapperClassName="mb-0 mr-0"
          handleOnChange={() =>
            setFieldValue("hasVatNumber", !values.hasVatNumber)
          }
        />
        {values.hasVatNumber && (
          <Input
            name="vatNumber"
            value={values.vatNumber}
            label={`${t("Vat Number")} (*)`}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            handleOnChange={handleChange}
            error={touched.vatNumber && errors.vatNumber}
          />
        )}
        {!values.hasVatNumber && (
          <Input
            name="parentVatNumber"
            value={parentCompany?.vatNumber || ""}
            label={t("Vat Number")}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            isDisabled
          />
        )}
        <span>
          <Input
            name="capacities"
            value={values.capacities}
            label={`${t("Capacities")} (i)`}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            handleOnChange={handleChange}
            tooltip="Optionally add your production capacity to be able to visually track your own performance. This data will not be shared and is only visible for you."
          />
        </span>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outline-primary rounded-bottom-left"
            onClick={() => back()}
            disabled={loading}
            style={{ height: "50px" }}
          >
            <i className="fas fa-arrow-left mr-3" /> {t("Back")}
          </button>
          <div>
            <button
              type="submit"
              className="btn btn-success mr-8"
              onClick={() => setIsDone(false)}
              disabled={loading}
              style={{ height: "50px" }}
            >
              {t("Add plant and create new")} <i className="fas fa-plus ml-3" />
            </button>
            <button
              type="submit"
              className="btn btn-warning rounded-bottom-right"
              onClick={() => setIsDone(true)}
              disabled={loading}
              style={{ height: "50px" }}
            >
              {t("Add plant and next")}{" "}
              <i className="fas fa-arrow-right ml-3" />
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default FinishRecyclerForm
