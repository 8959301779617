import React, { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import manageAccount from "../../assets/images/dashboardicons/manageAccount.svg"
import managePlant from "../../assets/images/dashboardicons/managePlant.svg"
import manageUsers from "../../assets/images/dashboardicons/manageUsers.svg"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import useStateGetter from "../../hooks/useStateGetter"
import useUserFromToken from "../../hooks/useUserFromToken"
import { logoutAction } from "../../modules/auth/actions"
import { history } from "../../utils/routes"
import BackToAccountButton from "./BackToAccountButton"
import "./UserSelect.scss"

const ManageAccountDropdown = () => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLHeadingElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const {
    name,
    isAdmin,
    isRrr,
    isAuditor,
    isRecycler,
    isConverter,
    isRecyclerAndConverter,
    isSectorAssociationRepresentative,
  } = useUserFromToken()
  const accessToken = useStateGetter(["auth", "accessToken"])
  const isEmulated = !!localStorage.getItem("emulate")

  useOnClickOutside(ref, () => setIsOpen(false))

  const logout = () => {
    dispatch(logoutAction())
    history.push("/auth/login")
    window.location.reload()
  }
  const { t } = useTranslation()

  const canManagePlants =
    isAdmin || isRecycler || isConverter || isRecyclerAndConverter

  return (
    <div className="srs-dropdown ml-4 manage-account-dropdown" ref={ref}>
      <div
        className={`srs-dropdown-inner ${
          isOpen ? "srs-dropdown-inner-open" : ""
        }`}
      >
        <button
          type="button"
          className="btn srs-dropdown-btn add-three-dots"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{name}</span>
          <i
            className={`srs-dropdown-icon fas ${
              isOpen ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          />
        </button>
        {isOpen ? (
          <div className="srs-dropdown-list">
            <Link
              to={{
                pathname: "/users/account",
                state: {
                  title: t("Manage Account"),
                },
              }}
            >
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="btn srs-dropdown-btn srs-dropdown-btn-inner srs-dropdown-btn-icon"
              >
                <div className="manage-dropdown-title">
                  {t("Manage your profile")}
                </div>
                <div className="manage-dropdown-icon">
                  <img src={manageAccount} className="filter-blue" />
                </div>
              </button>
            </Link>

            <Link
              to={{
                pathname: "/users/notifications",
                state: {
                  title: t("Notifications"),
                },
              }}
            >
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="btn srs-dropdown-btn srs-dropdown-btn-inner srs-dropdown-btn-icon"
              >
                <div className="manage-dropdown-title">
                  {t("Notifications")}
                </div>
                <div className="manage-dropdown-icon">
                  <img src={manageAccount} className="filter-blue" />
                </div>
              </button>
            </Link>

            {canManagePlants && (
              <Link
                to={{
                  pathname: "/plants",
                  state: {
                    title: t("Manage plants"),
                  },
                }}
              >
                <button
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  className="btn srs-dropdown-btn srs-dropdown-btn-inner srs-dropdown-btn-icon"
                >
                  <div className="manage-dropdown-title">
                    {t("Manage plants")}
                  </div>
                  <div className="manage-dropdown-icon">
                    <img src={managePlant} className="filter-blue" />
                  </div>
                </button>
              </Link>
            )}
            {(isRecycler || isRecyclerAndConverter) && (
                <Link
                  to={{
                    pathname: "/waste-origin",
                    state: {
                      title: t("Manage ‘waste origin’"),
                    },
                  }}
                >
                  <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="btn srs-dropdown-btn srs-dropdown-btn-inner srs-dropdown-btn-icon"
                  >
                    <div className="manage-dropdown-title">
                      {t("Manage ‘waste origin’")}
                    </div>
                    <div className="manage-dropdown-icon">
                      <img src={managePlant} className="filter-blue" />
                    </div>
                  </button>
                </Link>
              )}
            {(isRecyclerAndConverter || isConverter) && (
              <Link
                to={{
                  pathname: "/used-in",
                  state: {
                    title: t("Manage ‘used in’"),
                  },
                }}
              >
                <button
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  className="btn srs-dropdown-btn srs-dropdown-btn-inner srs-dropdown-btn-icon"
                >
                  <div className="manage-dropdown-title">
                    {t("Manage ‘used in’")}
                  </div>
                  <div className="manage-dropdown-icon">
                    <img src={managePlant} className="filter-blue" />
                  </div>
                </button>
              </Link>
            )}
            {(isRrr || isAuditor) && (
              <Link
                to={{
                  pathname: "/companies",
                  state: {
                    title: t("Manage companies"),
                  },
                }}
              >
                <button
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  className="btn srs-dropdown-btn srs-dropdown-btn-inner srs-dropdown-btn-icon"
                >
                  <div className="manage-dropdown-title">
                    {t("Manage companies")}
                  </div>
                  <div className="manage-dropdown-icon">
                    <img src={managePlant} className="filter-blue" />
                  </div>
                </button>
              </Link>
            )}
            {isAdmin && (
              <Link
                to={{
                  pathname: "/users/manage",
                  state: {
                    title: t("Manage users"),
                  },
                }}
              >
                <button
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  className="btn srs-dropdown-btn srs-dropdown-btn-inner srs-dropdown-btn-icon"
                >
                  <div className="manage-dropdown-title">
                    {t("Manage users")}
                  </div>
                  <div className="manage-dropdown-icon">
                    <img src={manageUsers} className="filter-blue" />
                  </div>
                </button>
              </Link>
            )}
            {isEmulated && <BackToAccountButton />}
            {accessToken && !isEmulated && (
              <button
                type="button"
                className="btn btn-warning ml-12 srs-dropdown-btn srs-dropdown-logout"
                onClick={logout}
              >
                {t("Logout")}
              </button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ManageAccountDropdown
