import React from "react"

interface Props {
  error?: string | boolean
}

const FormError: React.FC<Props> = ({ error }) => {
  if (typeof error === "string") {
    return <small className="form-text text-danger">{error}</small>
  }

  return null
}

export default FormError
