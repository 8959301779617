import React, { useState, useEffect } from "react"

interface ConfirmButtonProps {
  title?: string
  text?: string
  icon?: string
  onConfirm: Function
  className?: string
  confirmClassName?: string
  isCancelDanger?: boolean
  confirmText?: string
  dataTip?: string
  danger?: boolean
  isDisabled?: boolean
}

const ConfirmButton = (props: React.PropsWithChildren<ConfirmButtonProps>) => {
  const {
    children,
    title,
    text,
    icon,
    onConfirm,
    className,
    confirmClassName,
    isCancelDanger,
    confirmText,
    dataTip,
    danger,
    isDisabled = false,
  } = props

  const [showModal, setShowModal] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const onClicked = () => {
    setShowModal(true)
    setTimeout(() => {
      setModalVisible(true)
    }, 100)
  }

  const hideModal = () => {
    setModalVisible(false)
    setShowModal(false)
  }

  const onConfirmClick = () => {
    hideModal()
    onConfirm()
  }

  const getModalElement = () => {
    return (
      <div
        className={`d-block srs-modal modal display-block fade ${
          modalVisible ? "show" : ""
        }`}
        role="dialog"
        aria-hidden="true"
      >
        {modalVisible && (
          <div className="srs-modal-overlay" onClick={hideModal} />
        )}
        <div className="modal-dialog" role="document">
          <div className="modal-content p-4 text-primary text-center">
            {icon ? (
              <i
                className={`fa-2x fas my-4 mx-auto fa-${icon} ${
                  danger ? "text-danger" : ""
                }`}
              />
            ) : null}
            <h4 className="font-weight-bold">{title}</h4>
            <p>{text}</p>
            <div className="d-flex justify-content-between mt-4">
              <button
                type="button"
                className={`btn ${
                  !isCancelDanger ? "btn-outline-primary" : "btn-outline-danger"
                }`}
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn ${confirmClassName}`}
                onClick={() => onConfirmClick()}
              >
                {confirmText || "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <button
        type="button"
        className={`btn ${className}`}
        onClick={onClicked}
        data-tip={dataTip}
        disabled={isDisabled}
      >
        {children}
      </button>
      {showModal ? getModalElement() : null}
    </>
  )
}

export default ConfirmButton
