import * as Yup from "yup"

const SubmissionOutputFormValidationShema = Yup.object().shape({
  company: Yup.object().typeError("Company is required."),
  date: Yup.string()
    .typeError("Need month Received.")
    .required("Month is required."),
  materialGroup: Yup.object()
    .typeError("Polymer is required.")
    .required("Polymer is required."),
})

export const SubmissionOutputArrayFormsValidationShema = Yup.object().shape({
  submissions: Yup.array().of(SubmissionOutputFormValidationShema)})

export default SubmissionOutputFormValidationShema
