import React, { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch, Link } from "react-router-dom"

import annualAuditPercentageIcon from "../../../assets/images/auditicons/tiles-annualAuditPercentage.svg"
import existindAuditsIcon from "../../../assets/images/auditicons/tiles-existingAudits.svg"
import newAuditIcon from "../../../assets/images/auditicons/tiles-newAudit.svg"
import viewPlantsDetailsIcon from "../../../assets/images/auditicons/tiles-viewPlantsDetails.svg"
import useStateGetter from "../../../hooks/useStateGetter"
import useUserFromToken from "../../../hooks/useUserFromToken"
import { handleToast } from "../../../utils/messages"
import { getPlant } from "../../companies/service/api"
import { Company } from "../../companies/types"
import { fetchSubmissions } from "../../submissions/actions"
import SubmissionListTableBody from "../../submissions/components/SubmissionListTable/SubmissionListTableBodyForAudits"
import { Submission } from "../../submissions/types"

const AuditReports = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const [plant, setPlant] = useState<Company>()
  const [tab, setTab] = useState("input")
  const [plantsDetails, setPlantsDetails] = useState("View")

  const [sortByMemo, setSortByMemo] = useState<string[]>([
    "s.createdAt",
    "DESC",
  ])

  const onSortChanged = (sortBy: string, sortDirection: string): void => {
    setSortByMemo([sortBy, sortDirection])
  }

  const getSubmissions = useCallback(() => {
    if (id) {
      dispatch(
        fetchSubmissions({
          companyId: parseInt(id),
          isInput: true,
          limit: 9999,
        }),
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    getSubmissions()
  }, [getSubmissions])

  const submissions =
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? []

  useEffect(() => {
    if (id) {
      getPlant({ id: parseInt(id, 0) }).then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }

        if (data) {
          setPlant(data)
        }
      })
    }
  }, [id])

  const onChangeTabClicked = (value: string): boolean => {
    if (id) {
      if (value === "output") {
        dispatch(
          fetchSubmissions({
            companyId: parseInt(id),
            isInput: false,
            limit: 9999,
          }),
        )
      }
      if (value === "input") {
        dispatch(
          fetchSubmissions({
            companyId: parseInt(id),
            isInput: true,
            limit: 9999,
          }),
        )
      }
    }
    setTab(value)
    return false
  }

  const toggleViewPlantsDetails = () => {
    if (plantsDetails === "View") {
      setPlantsDetails("Hide")
    } else {
      setPlantsDetails("View")
    }
  }

  const { isSectorAssociationRepresentative } = useUserFromToken()

  return (
    <>
      <h1 className="mb-5">
        {t("Audits")}: {plant?.name}
      </h1>
      <div className="row">
        <div className="col-12">
          <div className="srs-dashboard-links">
            <div className="row">
              {!isSectorAssociationRepresentative && (
                <div className="col-3">
                  <div className="card text-center">
                    <span className="clickable-card">
                      <Link
                        to={{
                          pathname: `/new-audit/${id}`,
                          state: {
                            title: t("New audit"),
                            id,
                          },
                        }}
                        type="button"
                      >
                        <div className="card-body">
                          <div className="icon-area">
                            <img
                              src={newAuditIcon}
                              className="card-img-top"
                              alt=""
                            />
                          </div>
                          <h5 className="card-title">{t("New audit")}</h5>
                        </div>
                      </Link>
                    </span>
                  </div>
                </div>
              )}
              <div className="col-3">
                <div className="card text-center">
                  <span className="clickable-card">
                    <Link
                      to={{
                        pathname: `/existing-audit/${plant?.id}`,
                        state: {
                          title: t("Existing audits"),
                          id,
                        },
                      }}
                      type="button"
                    >
                      <div className="card-body">
                        <div className="icon-area">
                          <img
                            src={existindAuditsIcon}
                            className="card-img-top"
                            alt=""
                          />
                        </div>
                        <h5 className="card-title">{t("Existing audits")}</h5>
                      </div>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-3">
                <div className="card text-center">
                  <span className="clickable-card">
                    <Link
                      to={{
                        pathname: `/annual-percentage/${plant?.id}`,
                        state: {
                          title: t("Annual percentage"),
                          id,
                        },
                      }}
                      type="button"
                    >
                      <div className="card-body">
                        <div className="icon-area">
                          <img
                            src={annualAuditPercentageIcon}
                            className="card-img-top"
                            alt=""
                          />
                        </div>
                        <h5 className="card-title">
                          {t("Annual audit percentage")}
                        </h5>
                      </div>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-3">
                <div className="card text-center">
                  <span
                    className="clickable-card"
                    onClick={(e) => toggleViewPlantsDetails()}
                  >
                    <div className="card-body">
                      <div className="icon-area">
                        <img
                          src={viewPlantsDetailsIcon}
                          className="card-img-top"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">
                        {plantsDetails} {t("Plant's details")}
                      </h5>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`submission-lists-reports mt-3 ${
                plantsDetails === "View" ? "d-none" : ""
              }`}
            >
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    href="#input"
                    className={`nav-link ${tab === "input" ? "active" : ""}`}
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-inputData"
                    aria-selected="true"
                    onClick={(e) => onChangeTabClicked("input")}
                  >
                    {t("Input")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#output"
                    className={`nav-link ${tab === "output" ? "active" : ""}`}
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-inputData"
                    aria-selected="true"
                    onClick={(e) => onChangeTabClicked("output")}
                  >
                    {t("Output")}
                  </a>
                </li>
              </ul>
              {tab === "input" && (
                <SubmissionListTableBody
                  submissions={submissions}
                  toggledSubbmisions={[]}
                  setToggleSubbmision={() => {}}
                  inputData="input"
                  onSortChanged={onSortChanged}
                  getSubmissions={getSubmissions}
                  isMain
                  toggleAllSubmissions={{} as any}
                  setToggleAllSubmissions={() => {}}
                  isDisabled={false}
                />
              )}
              {tab === "output" && (
                <SubmissionListTableBody
                  submissions={submissions}
                  toggledSubbmisions={[]}
                  setToggleSubbmision={() => {}}
                  inputData="output"
                  onSortChanged={onSortChanged}
                  getSubmissions={getSubmissions}
                  isMain
                  toggleAllSubmissions={{} as any}
                  setToggleAllSubmissions={() => {}}
                  isDisabled={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuditReports
