import moment from "moment/moment"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import ReactPaginate from "react-paginate"
import { useDispatch } from "react-redux"
import { Option } from "react-select/src/filters"

import Select, {
  BaseSelectedValue,
  mapToOptions,
  SelectValue,
} from "components/Select/Select"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"
import { windowStorage } from "utils/windowStorage"

import useStateGetter from "hooks/useStateGetter"

import { ACTIVE_PLANT_ID_KEY } from "../../../../constants"
import { getPlants, getCompany } from "../../../companies/service/api"
import { Company, CompanyRole } from "../../../companies/types"
import { MaterialGroup } from "../../../materials/service/api"
import { fetchSubmissions } from "../../actions"
import {
  SUBMISSION_DATE_FORMAT,
  PER_PAGE,
  SELECTED_COMPANY_KEY,
} from "../../constants"
import { Submission } from "../../types"
import SubmissionListTableBody from "./SubmissionListTableBody"
import useApplicationConfig from "hooks/useApplicationConfig"

const SubmissionListTable = () => {
  const state = history.location.state as { tab: string }

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [tab, setTab] = useState(state && state.tab ? state.tab : "input")
  const [selectedSubmissionType, setSubmissiontType] = useState("input")

  useEffect(() => {
    setSubmissiontType(tab)
  }, [tab])

  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(
    moment().subtract(endDate.getMonth(), "months").date(1).toDate(),
  )

  const [perPage, setPerPage] = useState<SelectValue>(PER_PAGE[0])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [sortByMemo, setSortByMemo] = useState<string[]>([
    "s.createdAt",
    "DESC",
  ])
  const [companyId, setCompanyId] = useState<number>(0)
  const [selectedCompany, setSelectedCompanyState] = useState<SelectValue>(null)

  const [polymer, setPolymer] = useState<Option[]>([])

  const STATUS_OPTIONS: BaseSelectedValue[] = [
    { value: "", label: t("ALL STATUSES") },
    // { value: 'CREATED', label: 'CREATED' },
    { value: "SENT", label: t("PENDING") },
    { value: "DECLINED", label: t("DECLINED") },
    { value: "ACCEPTED", label: t("ACCEPTED") },
  ]

  const [plants, setPlants] = useState<Company[]>([])
  // map plants values for Select
  const plantsOptions = useMemo(
    () => (plants && plants.length ? mapToOptions(plants) : []),
    [plants],
  )

  const [selectedStatus, setSelectedStatus] = useState<string>("")
  const [materials, setMaterials] = useState<MaterialGroup[] | null>(null)
  const [isRecycler, setIsRecycler] = useState(false)
  const [isConverter, setIsConverter] = useState(false)
  const [isRecyclerAndConverter, setIsRecyclerAndConverter] = useState(false)

  const configApplicationEnd = useApplicationConfig()

  const selectedStatusOption = useMemo(
    () =>
      STATUS_OPTIONS.filter(
        (s: BaseSelectedValue) => s.value === selectedStatus,
      ),
    [STATUS_OPTIONS, selectedStatus],
  )

  const onSelectStatusChanged = (selectedOption: SelectValue) => {
    const option = selectedOption as BaseSelectedValue
    const value = `${option.value}`
    setCurrentPage(0)
    setSelectedStatus(value)
  }

  useEffect(() => {
    getCompany({
      id: companyId,
    }).then((r) => {
      switch (r?.data?.roleId) {
        case CompanyRole.RECYCLER:
          setIsRecycler(true)

          break

        case CompanyRole.CONVERTER:
          setIsConverter(true)
          setTab("convertingInput")

          break

        case CompanyRole.RECYCLER_AND_CONVERTER:
          setIsRecyclerAndConverter(true)

          break

        default:
          break
      }
      setMaterials(
        r?.data?.polymers?.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        }) as MaterialGroup[],
      )
      // @ts-ignore
      setPolymer(mapToOptions(r.data?.polymers ?? []))
    })
  }, [companyId, selectedCompany])

  // fetch plants at start
  useEffect(() => {
    getPlants()
      .then((response) => {
        if (response.data) setPlants(response.data as Company[])
      })
      .catch(() => {
        // handleToast([`Error during getting plants list: ${error}`], 'errors');
        handleToast([`Couldn't get plants list`], "errors")
      })
  }, [])

  // select saved company or first one
  useEffect(() => {
    if (plants && plants.length) {
      let plant = plants[0]

      const rv_selected_plant_id = windowStorage.get(
        ACTIVE_PLANT_ID_KEY,
        null,
        false,
      )
      if (rv_selected_plant_id) {
        plant =
          plants.find((p) => p.id === parseInt(rv_selected_plant_id, 10)) ??
          plants[0]
      }

      if (plant && plant.id) {
        setCompanyId(plant.id)
        setSelectedCompanyState({
          label: plant.name,
          value: plant.id,
        })
        const plantId = plant?.id as any
        windowStorage.set(ACTIVE_PLANT_ID_KEY, plantId, false)
      }
    }
  }, [plants])

  const onChangeTabClicked = (
    e: React.MouseEvent<HTMLAnchorElement>,
    value: string,
  ): boolean => {
    e.preventDefault()
    setTab(value)
    setCurrentPage(0)
    return false
  }

  const onMaterialsChanged = (e: any[]) => {
    setPolymer(e as unknown as Option[])
  }

  const onAssignEndApplicationsClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault()
    history.push("/applications", {
      title: "Assign End Applications",
    })
  }

  const onStartDateChanged = (date: Date) => {
    setStartDate(date)
    // setCurrentPage(0);
  }

  const onEndDateChanged = (date: Date) => {
    setEndDate(date)
    setCurrentPage(0)
  }

  const onSelectChanged = (selectedOption: SelectValue) => {
    const option = selectedOption as { label: string; value: number }
    setCurrentPage(0)
    setSelectedCompanyState(option)
    setCompanyId(option.value)
    if (option.value) {
      windowStorage.set(ACTIVE_PLANT_ID_KEY, `${option.value}`, false)
    }
  }

  const onPerPageChanged = (pp: SelectValue) => {
    const perPageOption = pp as { label: string; value: number }
    setPerPage(perPageOption)
    setCurrentPage(0)
  }

  const onPageChanged = ({ selected }: { selected: number }) => {
    setCurrentPage(selected)
  }

  const onSortChanged = (sortBy: string, sortDirection: string): void => {
    setSortByMemo([sortBy, sortDirection])
  }

  const submissions =
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? []
  const submissionsQty =
    useStateGetter<number>(["submission", "submissionsQty"]) ?? 0

  // update perPage
  useEffect(() => {
    const perPageOption = perPage as { value: number }
    setPageCount(Math.ceil(submissionsQty / perPageOption.value))
  }, [submissionsQty, perPage])

  const getSubmissions = useCallback(() => {
    if (companyId) {
      const perPageOption = perPage as { label: string; value: number }
      dispatch(
        fetchSubmissions({
          companyId,
          status: selectedStatus,
          dateStart: `${startDate.getFullYear()}/${startDate.getMonth() + 1}`,
          dateEnd: `${endDate.getFullYear()}/${endDate.getMonth() + 1}`,
          sortBy: sortByMemo[0],
          sortDirection: sortByMemo[1],
          isInput: tab === "input" || tab === "convertingInput",
          page: currentPage,
          limit: perPageOption.value,
          type: tab === "convertingInput" || tab === "convertingOutput" ? 1 : 0,
        }),
      )
    }
  }, [
    companyId,
    selectedStatus,
    dispatch,
    endDate,
    startDate,
    tab,
    currentPage,
    perPage,
    sortByMemo,
  ])

  useEffect(() => {
    getSubmissions()
  }, [getSubmissions])

  const addNewSubmission = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (selectedCompany) {
      const selectedCompanyOption = selectedCompany as {
        label: string
        value: number
      }
      const selectedCompanyFull = plants.find(
        (c) => c.id === selectedCompanyOption.value,
      )
      if (selectedCompanyFull && SELECTED_COMPANY_KEY.length > 0) {
        windowStorage.set(SELECTED_COMPANY_KEY, selectedCompanyFull, false)
      }
    }
    history.push(`/submissions/new`, {
      title: "New Submission",
    })
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="mb-3 nav nav-pills" id="pills-tab" role="tablist">
            {isRecycler || isRecyclerAndConverter ? (
              <li className="nav-item">
                <a
                  href="#input"
                  className={`nav-link ${tab === "input" ? "active" : ""} `}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="true"
                  onClick={(e) => onChangeTabClicked(e, "input")}
                >
                  {t("Recycling Input")}
                </a>
              </li>
            ) : null}
            {isRecycler || isRecyclerAndConverter ? (
              <li className="nav-item">
                <a
                  href="#output"
                  className={`nav-link ${tab === "output" ? "active" : ""}`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-outputData"
                  aria-selected="false"
                  onClick={(e) => onChangeTabClicked(e, "output")}
                >
                  {t("Recycling Output")}
                </a>
              </li>
            ) : null}
            {isConverter || isRecyclerAndConverter ? (
              <li className="nav-item">
                <a
                  href="#convertingInput"
                  className={`nav-link ${
                    tab === "convertingInput" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="false"
                  onClick={(e) => onChangeTabClicked(e, "convertingInput")}
                >
                  {t("Converting Input")}
                </a>
              </li>
            ) : null}
            {isConverter || isRecyclerAndConverter ? (
              <li className="nav-item">
                <a
                  href="#convertingOutput"
                  className={`nav-link ${
                    tab === "convertingOutput" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-outputData"
                  aria-selected="false"
                  onClick={(e) => onChangeTabClicked(e, "convertingOutput")}
                >
                  {t("Converting Output")}
                </a>
              </li>
            ) : null}
            <li className="ml-auto text-right nav-item">
              {isRecycler ? (
                <a
                  href="#new-submission"
                  className="nav-link"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-new-submission"
                  aria-selected="false"
                  onClick={addNewSubmission}
                  style={{ paddingRight: 0 }}
                >
                  {t("Add new submission")}
                </a>
              ) : null}
              {isRecycler && configApplicationEnd?.registrationOpen && (
                <a
                  href="#applications"
                  className="nav-link"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-outputData"
                  aria-selected="false"
                  onClick={(e) => onAssignEndApplicationsClick(e)}
                  style={{ paddingRight: 0 }}
                >
                  {t("Assign End Applications")}
                </a>
              )}
            </li>
          </ul>
        </div>
        <div className="col-3">
          <label className="form-label" htmlFor="">
            {t("From")}
          </label>
          <DatePicker
            name="startDate"
            className="form-control"
            selected={startDate}
            onChange={(date: Date) => onStartDateChanged(date)}
            selectsStart
            showMonthYearPicker
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            dateFormat={SUBMISSION_DATE_FORMAT}
          />
        </div>
        <div className="col-3">
          <label className="form-label" htmlFor="">
            {t("To")}
          </label>
          <DatePicker
            className="form-control"
            selected={endDate}
            onChange={(date: Date) => onEndDateChanged(date)}
            selectsEnd
            showMonthYearPicker
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat={SUBMISSION_DATE_FORMAT}
          />
        </div>
        <div className="col-4">
          <label className="form-label" htmlFor="">
            {t("Company")}
          </label>
          <Select
            name="company"
            options={plantsOptions}
            value={selectedCompany}
            handleOnChange={onSelectChanged}
            wrapperClassName="mb-0"
          />
        </div>
        <div className="col-4 multi-select-wrapper">
          <label className="form-label" htmlFor="polymer">
            Polymer
          </label>
          <MultiSelect
            options={
              materials ? mapToOptions(materials as MaterialGroup[]) : []
            }
            value={polymer as unknown as Option[]}
            onChange={onMaterialsChanged}
            labelledBy="polymer"
            disableSearch
          />
        </div>
        <div className="col-4">
          <label className="form-label" htmlFor="">
            {t("Status")}
          </label>
          <Select
            name="status"
            options={STATUS_OPTIONS}
            value={selectedStatusOption}
            handleOnChange={(s) => onSelectStatusChanged(s)}
          />
        </div>
      </div>

      <SubmissionListTableBody
        submissions={
          polymer.length > 0
            ? submissions.filter(
                (submission) =>
                  polymer.filter(
                    (p) => p.label == submission.materialsGroup?.name,
                  ).length > 0,
              )
            : submissions
        }
        submissionType={selectedSubmissionType}
        onSortChanged={onSortChanged}
        getSubmissions={getSubmissions}
      />

      <div className="d-flex justify-content-between align-items-end">
        <Select
          label={t("Per page limit")}
          name="perPage"
          options={PER_PAGE}
          value={perPage}
          handleOnChange={onPerPageChanged}
        />
        {pageCount > 1 && (
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={onPageChanged}
            containerClassName="pagination"
            previousClassName="page-item"
            nextClassName="page-item"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            activeClassName="active"
            previousLabel={t("Previous")}
            nextLabel={t("Next")}
          />
        )}
      </div>
    </>
  )
}

export default SubmissionListTable
