import { toPercent } from "./numbers"

interface TotalState {
  [key: string]: number
}

class Total {
  private _state: TotalState = {}

  private _maxValue = 100

  private _unit = "kg"

  private _subscribers: unknown[] = []

  public get state() {
    return this._state
  }

  public set state(newState: TotalState) {
    const key = Object.keys(newState)[0]

    this._state = {
      ...this._state,
      ...newState,
    }

    if (this.getTotalStateValue() > this._maxValue) {
      const differenceValue = this.getTotalStateValue() - this._maxValue
      this._state[key] -= differenceValue
    }
  }

  public set maxValue(value: number) {
    this._maxValue = value
  }

  public get unit() {
    return this._unit
  }

  public set unit(unit: string) {
    this._unit = unit
    if (this._unit === "%") {
      this._maxValue = 100
    }
  }

  public set subscribers(subscriber: Function) {
    this._subscribers.push(subscriber)
  }

  public notify() {
    // @ts-ignore
    this._subscribers.forEach((fc) => fc())
  }

  public remove(key: string) {
    delete this._state[key]
  }

  getTotalStateValue = () => {
    return Object.values(this._state).reduce(
      (prev, current) => prev + current,
      0,
    )
  }

  public get capability(): number {
    if (this._unit === "%") {
      const percentage = parseFloat(
        toPercent(this.getTotalStateValue(), this._maxValue),
      )
      return this._maxValue - percentage
    }
    const capability = this._maxValue - this.getTotalStateValue()
    return capability > 0 ? capability : 0
  }

  public getKeyValue(key: string) {
    return this._state[key]
  }

  public resetState() {
    this._state = {}
  }

  public resetMaxValue() {
    this._maxValue = 100
  }

  public resetSubscribers() {
    this._subscribers = []
  }

  public reset() {
    this.resetState()
    this.resetMaxValue()
    this.resetSubscribers()
  }
}

export default new Total()
