import React, { useContext } from "react"

import Checkbox from "components/Checkbox/Checkbox"
import { BranchProps } from "components/Tree/Tree"

import GenericCompositionFormTreeContext from "./GenericCompositionFormTreeContext"

const CompositionFormTreeBranch = ({ node, actionHandler }: BranchProps) => {
  const { checkedTreeBranchInput } = useContext(
    GenericCompositionFormTreeContext,
  )

  const { id, title, selectable } = node
  const branchId = `branch_${id}`
  const isChecked = checkedTreeBranchInput === id

  const handleChange = (checked: boolean) => {
    actionHandler(node, "onProductCheck")

    if (checked === false) {
      actionHandler(node, "onRemoveCheck")
    }
  }

  return (
    <div className="srs-branch w-100">
      <div className="d-flex align-items-center justify-content-between pr-3">
        {selectable ? (
          <div className="d-flex align-items-center justify-content-between pr-3">
            <Checkbox
              name={`${branchId}_checkbox`}
              label={title}
              value={isChecked}
              handleOnChange={() => handleChange(!isChecked)}
            />
          </div>
        ) : (
          <div className="p-2">{title}</div>
        )}
      </div>
    </div>
  )
}

export default CompositionFormTreeBranch
