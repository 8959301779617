import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { history } from "utils/routes"

import useUserFromToken from "hooks/useUserFromToken"

import FinishRegistrationAll from "./FinishRegistrationAll"
import FinishSectorAssociationRepresentative from "./FinishSectorAssociationRepresentative"

const FinishRegistration = () => {
  const { t } = useTranslation()

  const { name, isSectorAssociationRepresentative, registrationFinished } =
    useUserFromToken()

  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (isFirstLoad && registrationFinished !== null) {
      if (registrationFinished === true) {
        history.push("/")
      }
      setIsFirstLoad(false)
    }
  }, [isFirstLoad, registrationFinished])

  const displayFinishUserContent = () => {
    if (isSectorAssociationRepresentative) {
      return <FinishSectorAssociationRepresentative />
    }

    return <FinishRegistrationAll />
  }

  return (
    <div className="container pt-5 pb-5">
      <h2 className="mb-5">
        {t("Hello")} {name}. {t("Please finish registration")}
      </h2>
      {displayFinishUserContent()}
    </div>
  )
}

export default FinishRegistration
