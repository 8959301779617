import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { FormikErrors, useFormikContext } from 'formik'
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Company, CompanyRole } from "modules/companies/types"
import useStateGetter from "hooks/useStateGetter"
import { Country } from "../../../countries/types"
import Select, {
  mapToOptions,
} from "components/Select/Select"
import Checkbox from "components/Checkbox/Checkbox"
import Label from "components/Label/Label"
import { SELECTED_COMPANY_KEY, SUBMISSION_DATE_FORMAT } from "modules/submissions/constants"
import FormError from "components/FormError/FormError"
import { getPrevYear } from "../../utils";
import Input from "components/Input/Input"
import { SubmissionType } from "./SubmissionConverterFormsNewOutput"
import { filterCompanyCompositions } from "modules/genericCompositions/utils"
import { fetchGenericCompositions } from "../../../genericCompositions/actions"
import { CompositionType } from "../../../genericCompositions/enums"
import { GenericComposition } from "../../../genericCompositions/types"
import { windowStorage } from "utils/windowStorage"
import { ACTIVE_COMPANY_KEY } from "../../../../constants"
import { setSelectedCompany } from "modules/submissions/actions"


/* Set date to -1 month in form */
const actualDate = new Date()
actualDate.setMonth(actualDate.getMonth() - 1)

interface Props {
  submission: SubmissionType
  plants: Company[]
  index: number
}
type SubmissionFormsType = {submissions: Array<SubmissionType>}

const SubmissionConverterNewOutputFormFields: React.FC<Props> = ({
  submission,
  plants = [],
  index,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState(false)  
  const [companies, setCompanies] = useState<Company[]>([])
  
  const [currentPlant, setCurrentPlant] = useState<Company>()
  const [isNotPVC, setIsNotPVC] = useState(false)
  const [forFrench, setForFrench] = useState(false)
  // const countries = useStateGetter<Country[]>(["country", "countries"]) ?? []
  const selectedComapny =
    useStateGetter<any>(["submission", "selectedCompany"]) ?? undefined
  
  const { setFieldValue, errors, touched } = useFormikContext<SubmissionFormsType>()

  const compositions = useStateGetter<GenericComposition[]>([
    "genericComposition",
    CompositionType.USED_IN,
    "list",
  ]) || []

  const companyAutoComplete = useCallback(
    (fullCompany: Company) => {
      if (fullCompany.polymers?.length === 1) {
        const selectedOption = {
          label: fullCompany.polymers[0].name,
          value: fullCompany.polymers[0].id,
        }
        setFieldValue(`submissions.${index}.materialGroup`, selectedOption)
        setIsNotPVC(checkCompositionNotPVC(selectedOption.label))
      }
      windowStorage.remove(ACTIVE_COMPANY_KEY, false)
      windowStorage.set(SELECTED_COMPANY_KEY, fullCompany, false)
      dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
    },
    [setFieldValue],
  )

  useEffect(()=>{
    setCompanies(plants.filter((company) =>
      [CompanyRole.CONVERTER, CompanyRole.RECYCLER_AND_CONVERTER].includes(
        company.roleId,
      ),)
    )
    const fullCompany = plants.length === 1 ? plants[0] : selectedComapny ? plants?.find(
      (c) => c.id === selectedComapny.id,
    ) as Company : null
    
    if (fullCompany) {
      setFieldValue(`submissions.${index}.company`, {
        label: fullCompany.name,
        value: fullCompany.id,
      })
      setCurrentPlant(fullCompany)
      companyAutoComplete(fullCompany)
    } else {
      dispatch(setSelectedCompany({ selectedCompany: null }))
    }
  }, [plants])

  // useEffect(() => {
  //   console.log('submission.company')
  //   if (submission.company) {
  //     const selectedCompany = submission.company as unknown as { value: number }
  //     if (selectedCompany) {
  //       const fullCompany = companies.find(
  //         (c) => c.id === selectedCompany.value,
  //       ) as Company

  //       if (fullCompany) {
  //         windowStorage.remove(ACTIVE_COMPANY_KEY, false)
  //         windowStorage.set(SELECTED_COMPANY_KEY, fullCompany, false)
  //         dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
  //       }
  //       dispatch(
  //         fetchGenericCompositions(CompositionType.USED_IN, selectedCompany.value,),
  //       )
  //     }
  //   } else {
  //     windowStorage.remove(ACTIVE_COMPANY_KEY, false)
  //   }
  // }, [submission.company, companies, dispatch])
  
  useEffect(() => {
    dispatch(
      fetchGenericCompositions(CompositionType.USED_IN, submission.company?.value),
    )
  }, [])

  // useEffect(() => {

  // }, [currentPlant])

  const checkCompositionNotPVC = (compositionName: string) => {
    return !compositionName.startsWith('PVC')
  }

  const companyCompositions = useMemo(() => {
    return filterCompanyCompositions<GenericComposition>(compositions, currentPlant, true)
  }, [currentPlant, compositions])

  const currentErrors = errors?.submissions?.[index] as FormikErrors<SubmissionType> || {}
  const currentTouched = touched?.submissions?.[index] || {}

  const handleTotalPercentageOfRecycledPlasticsInFranceChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue(`submissions.${index}.totalPercentageOfRecycledPlasticsInFrance`, value)
    } else {
      setFieldValue(`submissions.${index}.totalPercentageOfRecycledPlasticsInFrance`, 0)
    }
  }
  const handleEstimatedShareExportedOutsideEuChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue(`submissions.${index}.estimatedShareExportedOutsideEu`, value)
    } else {
      setFieldValue(`submissions.${index}.estimatedShareExportedOutsideEu`, 0)
    }
  }

  const handleEstimatedShareExportedInsideEuChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue(`submissions.${index}.estimatedShareExportedInsideEu`, value)
    } else {
      setFieldValue(`submissions.${index}.estimatedShareExportedInsideEu`, 0)
    }
  }

  return (
    <div key={index}>
      <Select
        name={`submissions.${index}.company`}
        label={t("Plant name (*)")}
        options={mapToOptions(companies)}
        value={submission.company}
        handleOnChange={(selectedOption: any) => {
          const plant = companies?.find((a) => a.id == selectedOption?.value)
          if (!plant?.polymers?.find((p) => p.id == submission.materialGroup?.value)){
            setFieldValue(`submissions.${index}.materialGroup`, null)
            submission.materialGroup = null
            setIsNotPVC(false)
          }
          
          setCurrentPlant(plant)
          if (plant){
            companyAutoComplete(plant)
          }
          setFieldValue(`submissions.${index}.company`, selectedOption)
        }}
        inputWrapperClassName="col-sm-8"
        wrapperClassName="no-gutters mb-2 ml-n5"
        labelClassName="col-sm-4 col-form-label"
        error={currentErrors.company && currentTouched.company}
        isDisabled={isDisabled}
      />
      <Select
        name={`submissions.${index}.materialGroup`}
        label={`${t("Polymer")} (*)`}
        options={mapToOptions(currentPlant?.polymers || [])}
        value={submission.materialGroup}
        handleOnChange={(selectedOption: any) => {
          setFieldValue(`submissions.${index}.materialGroup`, selectedOption)
          setIsNotPVC(checkCompositionNotPVC(selectedOption.label))
        }}
        inputWrapperClassName="col-sm-8"
        wrapperClassName="no-gutters mb-2 ml-n5"
        labelClassName="col-sm-4 col-form-label"
        error={currentErrors.materialGroup && currentTouched.materialGroup}
        isDisabled={isDisabled}
      />
      
      <div className="form-group row align-items-center no-gutters mb-2">
        <Label label={t("Date (*)")} name="." labelClassName="col-sm-4" />
        <div className="col-sm-8">
          <DatePicker
            className="form-control"
            selected={submission.date}
            dateFormat={SUBMISSION_DATE_FORMAT}
            showMonthYearPicker
            showPopperArrow={false}
            onChange={(date: Date) => setFieldValue(`submissions.${index}.date`, date)}
            disabled={isDisabled}
          />
          <FormError error={currentTouched.date && (currentErrors.date as string)} />
        </div>
      </div>
      { isNotPVC && <div className="form-group row align-items-center no-gutters mb-2">
        <div className="col-sm-4"></div>
        <div className={"col-sm-8"}>
          <Checkbox
            name={`submissions.${index}.yearlySubmission`}
            label={`Please check this box if the volume consist the total of ${getPrevYear()}`}
            value={submission.yearlySubmission}
            handleOnChange={(val)=>{
              if (val.target.checked) {
                const date = new Date()
                date.setFullYear(getPrevYear())
                date.setMonth(11)
                setFieldValue(`submissions.${index}.date`, date)
              }
              setFieldValue(`submissions.${index}.yearlySubmission`, val.target.checked)
            }}
          />
        </div>
      </div>
      }
      <div className="srs-select form-group row no-gutters mb-2">
        {t("Used In") && (
          <Label
            label={t("Used In" + " (*)")}
            name="product"
            labelClassName="col-sm-4"
          />
        )}          
        <div className={ "col-sm-8" }>
          <Select
            name="product"
            options={mapToOptions(
              companyCompositions,
              "productName",
              "productId",
            )}
            value={submission.product}
            handleOnChange={(selectedOption: any) =>
              setFieldValue(`submissions.${index}.product`, selectedOption)
            }
            isDisabled={isDisabled}
            wrapperClassName="srs-select-sm"
          />
          <FormError error={currentTouched.product && (currentErrors.product as string) } />
        </div>
      </div>
      <Input
        label={
          <span>
            {t(`Total weight of`)}
            <strong>{t(" recycled ")}</strong>
            {t(`plastic used (kg)`)}
          </span>
        }
        name="payment"
        type="number"
        inputWrapperClassName="col-sm-8"
        wrapperClassName="no-gutters mb-2"
        labelClassName="col-sm-4"
        value={submission.total || 0}
        handleOnChange={(e: any) => {
          setFieldValue(`submissions.${index}.total`, e.target.valueAsNumber)
        }}
        placeholder={t("Enter total")}
        min="0"
        isDisabled={isDisabled}
        error={currentTouched.total && currentErrors.total}
      />
      
      {submission?.materialGroup?.label == "PET" ? (
        <>
          <Input
            label={t("Total of PET sold (including recycled PET) (kg)")}
            name="totalPet"
            type="number"
            inputWrapperClassName="col-sm-8"
            wrapperClassName="no-gutters mb-2"
            labelClassName="col-sm-4"
            value={submission.totalPet || 0}
            handleOnChange={(e: any) => {
              setFieldValue(`submissions.${index}.totalPet`, e.target.valueAsNumber)
            }}
            placeholder={t("Enter total")}
            min="0"
            isDisabled={isDisabled}
            error={currentTouched.totalPet && currentErrors.totalPet}
          />
          <Input
            label={t("Total units sold (quantity)")}
            name="totalUnits"
            type="number"
            inputWrapperClassName="col-sm-8"
            wrapperClassName="no-gutters mb-2"
            labelClassName="col-sm-4"
            value={submission.totalUnits || 0}
            handleOnChange={(e: any) => {
              setFieldValue(`submissions.${index}.totalUnits`, e.target.valueAsNumber)
            }}
            placeholder={t("Enter total")}
            min="0"
            isDisabled={isDisabled}
            error={currentTouched.totalUnits && currentErrors.totalUnits}
          />
        </>
      ) : null}
      <div className="form-group row align-items-center no-gutters mb-2">
          <Checkbox
            name={`submissions.${index}.productsInFrance`}
            label={t('Please tick this box if you sell products containing recyclate to the French market')}
            value={forFrench}
            handleOnChange={(val)=>{
              setForFrench(val.target.checked)
              console.log(val.target.checked)
              setFieldValue(`submissions.${index}.productsInFrance`, val.target.checked)
            }}
          />
      </div>
      {forFrench ? (
        <div className="srs-input-extra-content ml-5">
          <Input
            type="number"
            label={t("Estimated share of recycled plastic used in products for the French market (%)")}
            name="totalPercentageOfRecycledPlasticsUsedInProductsInFrance"
            min="0"
            max="100"
            placeholder={t("Add total percentage")}
            handleOnChange={
              handleTotalPercentageOfRecycledPlasticsInFranceChange
            }
            inputWrapperClassName="col-sm-8"
            wrapperClassName="mb-2"
            inputStyle={{ paddingRight: "110px" }}
            labelClassName="col-sm-4"
            tooltip="Please put 0 if you can’t share this data"
            infoTooltip="Please put 0 if you can’t share this data"
            error={
              currentTouched.totalPercentageOfRecycledPlasticsInFrance &&
              currentErrors.totalPercentageOfRecycledPlasticsInFrance
            }
            value={submission.totalPercentageOfRecycledPlasticsInFrance || 0}
            isDisabled={isDisabled}
          />
          <div className="srs-input-extra-content-body">
            <button type="button" className="btn btn-primary" disabled>
              %
            </button>
          </div>
        </div>
      ) : null}

      <div className="srs-input-extra-content">
        <Input
          type="number"
          label={t(
            "Estimated share of recycled plastic used in products exported to outside of EU, UK, Norway and Switzerland (%)",
          )}
          name="estimatedShareExportedOutsideEu"
          min="0"
          max="100"
          placeholder={t("Add expected share")}
          handleOnChange={handleEstimatedShareExportedOutsideEuChange}
          inputWrapperClassName="col-sm-8"
          inputStyle={{ paddingRight: "110px" }}
          labelClassName="col-sm-4"
          error={
            currentTouched.estimatedShareExportedOutsideEu &&
            currentErrors.estimatedShareExportedOutsideEu
          }
          value={submission.estimatedShareExportedOutsideEu || 0}
          isDisabled={isDisabled}
        />
        <div className="srs-input-extra-content-body">
          <button type="button" className="btn btn-primary" disabled>
            %
          </button>
        </div>
      </div>
      <div className="srs-input-extra-content">
        <Input
          type="number"
          label={t(
            "Estimated share recycled plastic used in products exported to Norway and Switzerland (%)",
          )}
          name="estimatedShareExportedInsideEu"
          min="0"
          max="100"
          placeholder={t("Add expected share")}
          handleOnChange={handleEstimatedShareExportedInsideEuChange}
          inputWrapperClassName="col-sm-8"
          inputStyle={{ paddingRight: "110px" }}
          labelClassName="col-sm-4"
          error={
            currentTouched.estimatedShareExportedInsideEu &&
            currentErrors.estimatedShareExportedInsideEu
          }
          value={submission.estimatedShareExportedInsideEu || 0}
          isDisabled={isDisabled}
        />
        <div className="srs-input-extra-content-body">
          <button type="button" className="btn btn-primary" disabled>
            %
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubmissionConverterNewOutputFormFields
