import React, { useEffect, useState } from "react"

import useUserFromToken from "../../../hooks/useUserFromToken"
import CompanyRequestModal from "../../users/components/CompanyRequestModal"
import RegistrationFinishedModal from "./RegistrationFinishedModal"

const FinishRegistration = () => {
  const {
    isRecycler,
    isRrr,
    isAuditor,
    registrationFinished,
    isRecyclerAndConverter,
    isConverter
  } = useUserFromToken()

  const [companyRequestModalVisible, setCompanyRequestModalVisible] =
    useState(false)

  useEffect(() => {
    if (isRrr || isAuditor) {
      setCompanyRequestModalVisible(true)
    }
  }, [isAuditor, isRrr])

  if (!registrationFinished) {
    if (isRrr || isAuditor) {
      if (!registrationFinished && companyRequestModalVisible) {
        return (
          <CompanyRequestModal
            closeModal={() => setCompanyRequestModalVisible(false)}
          />
        )
      }
    }
  }

  if (isRecycler || isRecyclerAndConverter || isConverter) {
    return <RegistrationFinishedModal />
  }

  return null
}

export default FinishRegistration
