import { ProductActions } from "./actions"
import { FETCH_PRODUCTS } from "./constants"
import { Product } from "./types"

export interface ProductState {
  products: Product[]
}

const initialState: ProductState = {
  products: [],
}

const product = (
  state: ProductState = initialState,
  action: ProductActions,
) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default product
