import { BaseSelectedValue } from "../../components/Select/Select"
import { Country } from "../countries/types"

export enum CompanyRole {
  RECYCLER = 2,
  RECOVINYL_REGIONAL_REPRESENTATIVE = 3,
  AUDITOR = 4,
  RECYCLER_AND_CONVERTER = 6,
  CONVERTER = 7,
  POLYMERS_ORGANIZATION = 8,
}

export interface Company {
  id: number
  name: string
  address: string
  country?: Country
  vatNumber: string
  ceoName: string
  ceoEmail: string
  capacities: number
  branches?: Company[]
  parent?: Company
  approved?: boolean
  website?: string
  allowDisplayOnWebsite?: boolean
  archived?: boolean
  roleId: CompanyRole
  polymers?: Array<{
    id: number
    name: string
  }>
  mosa?: Array<{
    id: number
    name: string
  }>
  sharedDataWithMosa?: boolean
  sharedUfmeWithEppa?: boolean
  sharedSnepWithEppa?: boolean
}

export const CompanyType: { [key: number]: string } = {
  0: "Output",
  1: "Input",
}

export interface SectorAssociationCreate {
  nameId: number
  isExternal: boolean
}

export interface CompanyCreate {
  country: number
  vatNumber: string
  name: string
  address: string
  // holding: string;
  ceoName?: string
  ceoEmail?: string
  capacities: number
  website: string
  allowDisplayOnWebsite: boolean
  companyRole: Nullable<BaseSelectedValue<number>>
  companyPolymer: Array<{ value: number; label: string }>
  companyMosa: Array<{ value: number; label: string }>
  sharedDataWithMosa: boolean
  sharedUfmeWithEppa: boolean
  sharedSnepWithEppa: boolean
}

export interface CompanyVatCheck {
  valid: boolean
  countryCode: string
  vatNumber: string
  name: string
  address: string
  issetVat?: boolean
}

export interface Mosa {
  id: number
  name: string
}