import { sum } from "ramda"

import { SectorSubmission } from "./types"

// eslint-disable-next-line import/prefer-default-export
export const computeInitialSectorSubmission = (
  values: SectorSubmission[] | undefined,
) => {
  const initialData: {
    [key: string]: {
      id: number | undefined
      year: number
      data: number[]
      total: number
    }
  } = {
    internal: { id: undefined, year: 0, data: [0, 0, 0, 0], total: 0 },
    external: { id: undefined, year: 0, data: [0, 0, 0, 0], total: 0 },
  }
  if (values) {
    values.forEach(({ id, year, sourceType, q1, q2, q3, q4 }) => {
      const data = [q1, q2, q3, q4]
      initialData[sourceType] = { id, year, data, total: sum(data) }
    })
  }

  return initialData
}

export const getPrevYear = () => {
  const dateNow = new Date()
  return dateNow.getFullYear() - 1
}