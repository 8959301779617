import { FormikErrors, useFormikContext } from "formik"
import React, {
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useCallback,
} from "react"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import Checkbox from "components/Checkbox/Checkbox"
import FormError from "components/FormError/FormError"
import Input from "components/Input/Input"
import Label from "components/Label/Label"
import Select, {
  mapToOptions,
  BaseSelectedValue,
} from "components/Select/Select"
import { handleToast } from "utils/messages"
import { isLikeNumber, fromPercent } from "utils/numbers"
import { cleanStringLikeNumber } from "utils/strings"
import {
  windowStorage,
} from "utils/windowStorage"

import useMaxNumberState from "hooks/useMaxNumberState"
import useStateGetter from "hooks/useStateGetter"

import { ACTIVE_COMPANY_KEY } from "../../../../constants"
import { Company } from "../../../companies/types"
import { compositionGetByCompanyId } from "../../../compositions/service/api"
import { Composition } from "../../../compositions/types"
import { Country } from "../../../countries/types"
import { filterCompanyCompositions } from "../../../genericCompositions/utils"
import { fetchMaterialGroups } from "../../../materials/actions"
import {
  setSelectedCompany,
} from "../../actions"
import {
  SUBMISSION_DATE_FORMAT,
  SELECTED_COMPANY_KEY,
} from "../../constants"
import { ContaminationUnit } from "../../types"
import SubmissionFormInputPartials from "./SubmissionFormInputPartials"
import SubmissionFormOutputPartials from "./SubmissionFormOutputPartials"
import { SubmissionType } from "./SubmissionNewForms"
import { getPrevYear } from "../../utils";

type SubmissionFormsType = {submissions: Array<SubmissionType>}


interface Props {
  submission: SubmissionType
  plants: Company[]
  index: number
  isSubmissionInput: boolean
}

const SubmissionNewForm: React.FC<Props> = ({
  submission,
  plants = [],
  index,
  isSubmissionInput,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { setFieldValue, errors, touched } = useFormikContext<SubmissionFormsType>()
  const [currentPlant, setCurrentPlant] = useState<Company>()  
  const [compositions, setCompositions] = useState<Composition[]>([])
  const [isNotPVC, setIsNotPVC] = useState(false)
  const [countries, setCountries] = useState<Country[]>([])
  const [contaminationState, setContaminationState] = useState<string>("0")
  const [netVolume, setNetVolume] = useState<number>(0)
  const [virginAndAdditives, setVirginAndAdditives] = useMaxNumberState()
  const [contaminationUnit, setContaminationUnit] = useState<ContaminationUnit>(
    ContaminationUnit.PERCENT,
  )

  const countriesEu = useStateGetter<Country[]>(["country", "countries"]) ?? [] 

  const currentErrors = errors?.submissions?.[index] as FormikErrors<SubmissionType> || {}
  const currentTouched = touched?.submissions?.[index] || {}

  const {
    volume,
    grossOutputNonEU,
    grossOutputEU,
    netOutputEU,
    netOutputNonEU,
    administrationNumber,
  } = submission

  const checkCompositionNotPVC = (compositionName: string) => {
    return !compositionName.startsWith('PVC')
  }

  const companyAutoComplete = useCallback(
    (fullCompany: Company) => {
      setFieldValue(`submissions.${index}.country`, {
        label: fullCompany.country?.name,
        value: fullCompany.country?.id,
      })

      if (isSubmissionInput) {
        compositionGetByCompanyId({ companyId: fullCompany.id }).then(
          ({ data, errors }) => {
            if (errors) {
              handleToast(errors, "error")
            }
            if (data) {
              setCompositions(data)
              if (data.length === 1) {
                const selectedOption = {
                  label: data[0].productName,
                  value: data[0].id,
                }
                setIsNotPVC(checkCompositionNotPVC(selectedOption.label))
                setFieldValue(`submissions.${index}.materialGroup`, selectedOption)
                setFieldValue(`submissions.${index}.composition`, selectedOption)
              } else {
                setIsNotPVC(false)
                setFieldValue(`submissions.${index}.materialGroup`, null)
                setFieldValue(`submissions.${index}.composition`, null)
              }
            }
          },
        )
      } else {
        if (fullCompany.polymers?.length === 1) {
          const selectedOption = {
            label: fullCompany.polymers[0].name,
            value: fullCompany.polymers[0].id,
          }
          setFieldValue(`submissions.${index}.materialGroup`, selectedOption)
          setIsNotPVC(checkCompositionNotPVC(selectedOption.label))
        }
      }

    },
    [setFieldValue],
  )

  useEffect(() => {
    countriesEu.push({
      id: 999,
      name: "Other",
      code: "NONEU",
    } as Country)
    setCountries(countriesEu)
  }, [countriesEu])
  
  useEffect(() => {
    dispatch(fetchMaterialGroups())
  }, [dispatch])

  const setDependencyVirginAndAdditives = (numberValue: number) => {
    setVirginAndAdditives(numberValue)
    setFieldValue(`submissions.${index}.virginAdditives`, numberValue)
    const netOutputEU =
    grossOutputEU - grossOutputEU * (numberValue / 100)
    const netOutputNonEU =
    grossOutputNonEU - grossOutputNonEU * (numberValue / 100)
    setFieldValue(`submissions.${index}.netOutputEU`, parseFloat(netOutputEU.toFixed(2)))
    setFieldValue(`submissions.${index}.netOutputNonEU`, parseFloat(netOutputNonEU.toFixed(2)))
    setNetVolume(netOutputEU + netOutputNonEU)
    setFieldValue(`submissions.${index}.netVolume`, netOutputEU + netOutputNonEU)
  }

  const onSetVirginAndAdditives = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const numberValue = +value
    setDependencyVirginAndAdditives(numberValue)
  }

  const onSetVirginAndAdditivesSlider = (value: number) => {
    const numberValue = +value
    setDependencyVirginAndAdditives(numberValue)
  }

  const selectedComapny =
    useStateGetter<Company>(["submission", "selectedCompany"]) ?? undefined

  const compositionsArray = useMemo(
    () => filterCompanyCompositions<Composition>(compositions, selectedComapny, true),    
    [compositions, selectedComapny],
  )

  useEffect(() => {
    const fullCompany = plants.length === 1 ? plants[0] : selectedComapny ? plants?.find(
      (c) => c.id === selectedComapny.id,
    ) as Company : null
    dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
    
    if (fullCompany) {
      setFieldValue(`submissions.${index}.company`, {
        label: fullCompany.name,
        value: fullCompany.id,
      })
      setCurrentPlant(fullCompany)
      companyAutoComplete(fullCompany)
    }
  }, [plants])

  
  const handleContaminationChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setContaminationState(`${value}`)
      setFieldValue(`submissions.${index}.contamination`, value)
      setFieldValue(`submissions.${index}.contaminationState`, value)
    } else {
      setContaminationState("")
      setFieldValue(`submissions.${index}.contamination`, 0)
      setFieldValue(`submissions.${index}.contaminationState`, 0)
    }
  }

  const handleSetGrossOutputEUChange = (value: any) => {
    const parsedValue = parseFloat(value)
    setFieldValue(`submissions.${index}.grossOutputEU`, parsedValue)
    setFieldValue(`submissions.${index}.netOutputEU`, parsedValue)
    setFieldValue("volume", parsedValue + grossOutputNonEU)
  }

  const handleSetGrossOutputNonEUChange = (value: any) => {
    const parsedValue = parseFloat(value)
    setFieldValue(`submissions.${index}.grossOutputNonEU`, parsedValue)
    setFieldValue(`submissions.${index}.netOutputNonEU`, parsedValue)
    setFieldValue("volume", parsedValue + grossOutputEU)
  }

  const handleSetNetOutputEUChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const parsedValue = parseInt(value, 0)
    setFieldValue(`submissions.${index}.netOutputNetEU`, parsedValue)
  }

  const handleSetNetOutputNonEUChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const parsedValue = parseInt(value, 0)
    setFieldValue(`submissions.${index}.netOutputnetNonEU`, parsedValue)
  }

  const handleInputTotalIncomeWasteChange = (value: any) => {
    const parsedValue = parseFloat(value)
    setFieldValue(`submissions.${index}.volume`, parsedValue)
  }

  const handleInputAdministrationNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    setFieldValue(`submissions.${index}.administrationNumber`, value)
  }

  const handleTotalPercentageOfRecycledPlasticsInFranceChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue(`submissions.${index}.totalPercentageOfRecycledPlasticsInFrance`, value)
    } else {
      setFieldValue(`submissions.${index}.totalPercentageOfRecycledPlasticsInFrance`, 0)
    }
  }

  const getNetVolumeEndData = (
    volume: number,
    contamination: number,
    contaminationUnit: ContaminationUnit,
  ): number | undefined => {
    let result
    if (contaminationUnit === ContaminationUnit.PERCENT) {
      if (contamination <= 100) {
        const percent = fromPercent(volume, contamination)
        result = volume - percent
      }
    } else {
      result = volume - contamination
    }
  
    return result !== undefined ? parseFloat(result.toFixed(2)) : result
  }

  useEffect(() => {
    if ( isSubmissionInput ) {
      const compositionValue = submission.composition as BaseSelectedValue | null
      let contaminationPercentage = 0
      if (compositionValue && compositionValue?.value) {
        const composition = compositionsArray.find(
          (v) => compositionValue?.value === v.id,
        )
        if (composition && composition?.contaminationPercentage) {
          contaminationPercentage = composition.contaminationPercentage
        }
      }
      setContaminationState(`${contaminationPercentage}`)
      setFieldValue(`submissions.${index}.contaminationState`, contaminationPercentage)
    }
  }, [
    compositionsArray,
    submission.company,
    submission.composition,
  ])

  useEffect(() => {
    // const stringVolume = volume.toString() as string;
    const cleanVolume = cleanStringLikeNumber(volume)
    const cleanContamination = cleanStringLikeNumber(contaminationState)
    if (isLikeNumber(cleanVolume) && isLikeNumber(cleanContamination)) {
      const volumeNumber = +cleanVolume
      const contaminationNumber = +cleanContamination
      const result = getNetVolumeEndData(
        volumeNumber,
        contaminationNumber,
        contaminationUnit,
      )
      setNetVolume(result ?? 0)
      setFieldValue(`submissions.${index}.netVolume`, result ?? 0)
    }
  }, [volume, contaminationState, contaminationUnit])

  return (
    <>
      <Select
        name={`submissions.${index}.company`}
        label={`${t("Plant name")} (*)`}
        options={mapToOptions(plants)}
        value={currentPlant && {
          label: currentPlant.name,
          value: currentPlant.id,
        }}
        handleOnChange={(selectedOption: any) => {
          setFieldValue(`submissions.${index}.company`, selectedOption)
          const fullCompany = plants?.find(
            (c) => c.id === selectedOption.value,
          ) as Company
          setCurrentPlant(fullCompany)

          if (fullCompany) {
            companyAutoComplete(fullCompany)

            windowStorage.remove(ACTIVE_COMPANY_KEY, false)
            windowStorage.set(SELECTED_COMPANY_KEY, fullCompany, false)
            dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
          }
        }}
        inputWrapperClassName="col-sm-8"
        wrapperClassName="no-gutters mb-2 ml-n5"
        labelClassName="col-sm-4 col-form-label"
        error={currentTouched.company && currentErrors.company}
      />
      {isSubmissionInput &&         
        <Select
          name={`submissions.${index}.composition`}
          label={t("Waste origin")}
          options={mapToOptions(compositionsArray, "productName")}
          value={submission.composition}
          handleOnChange={(selectedOption: any) => {
            setIsNotPVC(checkCompositionNotPVC(selectedOption.label))
            setFieldValue(`submissions.${index}.materialGroup`, selectedOption)
            setFieldValue(`submissions.${index}.composition`, selectedOption)
          }}
          inputWrapperClassName="col-sm-8"
          wrapperClassName="no-gutters mb-2 ml-n5"
          labelClassName="col-sm-4 col-form-label"
          error={currentTouched.composition && currentErrors.composition}
        />
      }
      {!isSubmissionInput && 
        <Select
          name={`submissions.${index}.materialGroup`}
          label={t("Polymer")}
          options={mapToOptions(currentPlant?.polymers || [])}
          value={submission.materialGroup}
          handleOnChange={(selectedOption: any) => {
            setIsNotPVC(checkCompositionNotPVC(selectedOption.label))
            setFieldValue(`submissions.${index}.materialGroup`, selectedOption)
          }}
          inputWrapperClassName="col-sm-8"
          wrapperClassName="no-gutters mb-2 ml-n5"
          labelClassName="col-sm-4"
          error={currentTouched.materialGroup && currentErrors.materialGroup}
        />
      }
      <div className="form-group row align-items-center no-gutters mb-2">
        <div className="col-sm-4">
          <Label
            label={
              <div>
                {t(
                  isSubmissionInput
                    ? "Month Received" + " (*)"
                    : "Date sold (recycler) / Date used in production (converter)",
                )}
                <span
                  className="text-primary"
                  data-tip="If you are reporting for a period longer than a month – select the end date of that period"
                >
                  {" "}
                  (i)
                </span>
              </div>
            }
            name="."
            labelClassName=""
          />
        </div>
        <div className="col-sm-8">
          <DatePicker
            className="form-control"
            selected={submission.date}
            dateFormat={SUBMISSION_DATE_FORMAT}
            showMonthYearPicker
            showPopperArrow={false}
            onChange={(date: Date) => setFieldValue(`submissions.${index}.date`, date)}
          />
          <FormError error={currentTouched.date && (currentErrors.date as string)} />
        </div>
      </div>
      
      { isNotPVC && <div className="form-group row align-items-center no-gutters mb-2">
          <div className="col-sm-4"></div>
          <div className={"col-sm-8"}>
            <Checkbox 
              name={`submissions.${index}.yearlySubmission`}
              label={`Please check this box if the volume consist the total of ${getPrevYear()}`}
              value={submission.yearlySubmission}
              handleOnChange={(val)=>{
                if (val.target.checked) {
                  const date = new Date()
                  date.setFullYear(getPrevYear())
                  date.setMonth(11)
                  setFieldValue(`submissions.${index}.date`, date)
                }
                setFieldValue(`submissions.${index}.yearlySubmission`, val.target.checked)
              }}
            />
          </div>
        </div>
      }
      {isSubmissionInput && (
        <>
          <Select
            name={`submissions.${index}.country`}
            label={`${t("Waste received from")} (*)`}
            options={mapToOptions(countries)}
            value={submission.country}
            handleOnChange={(selectedOption) => {
              setFieldValue(`submissions.${index}.country`, selectedOption)
            }}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            wrapperClassName="no-gutters mb-2"
            error={currentTouched.country && currentErrors.country}
          />
      
        { isNotPVC && 
          <>
          <div className="form-group row align-items-center no-gutters mb-2">
            <div className="col-sm-4"></div>
            <div className={"col-sm-8"}>
              <Checkbox 
                name={`submissions.${index}.originFromEu`}
                label={'Please check box if you are unable to provide origin country, but waste is from EU+UK'}
                handleOnChange={(val)=>{
                  setFieldValue(`submissions.${index}.originFromEu`, val.target.checked)
                }}
              />
            </div>
          </div>
          </>
        }
      
        <SubmissionFormInputPartials
          errors={currentErrors}
          touched={currentTouched}
          values={submission}
          disabled={false}
          contaminationUnit={contaminationUnit}
          setContaminationUnit={setContaminationUnit}
          handleContaminationChange={handleContaminationChange}
          setTotalIncomeWaste={handleInputTotalIncomeWasteChange}
          netVolume={netVolume}
          contamination={contaminationState}
          wrapperClassName="no-gutters mb-2"
        />
        </>
      )}
        {!isSubmissionInput && (
          <>
            <SubmissionFormOutputPartials
              grossOutputEU={grossOutputEU}
              grossOutputNonEU={grossOutputNonEU}
              netOutputEU={netOutputEU}
              netOutputNonEU={netOutputNonEU}
              disabled={false}
              setGrossOutputEU={handleSetGrossOutputEUChange}
              setGrossOutputNonEU={handleSetGrossOutputNonEUChange}
              setNetOutputEU={handleSetNetOutputEUChange}
              setNetOutputNonEU={handleSetNetOutputNonEUChange}
              virginAndAdditives={virginAndAdditives}
              onSetVirginAndAdditives={onSetVirginAndAdditives}
              onSetVirginAndAdditivesSlider={onSetVirginAndAdditivesSlider}
              grossOutputEUError={currentErrors.grossOutputEU}
              grossOutputNonEUError={currentErrors.grossOutputNonEU}
              wrapperClassName="no-gutters mb-2"
            />
            {/* was requeste hide this functionality that why FRI not FR */}
            {currentPlant?.country?.code ===  "FRI" ? (
              <div className="srs-input-extra-content">
                <Input
                  type="number"
                  label={`${t(
                    "Percentage output recycled materials to France (%)",
                  )} (*)`}
                  name={`submissions.${index}.totalPercentageOfRecycledPlasticsUsedInProductsInFrance`}
                  min="0"
                  max="100"
                  placeholder={t("Add total percentage")}
                  handleOnChange={
                    handleTotalPercentageOfRecycledPlasticsInFranceChange
                  }
                  inputWrapperClassName="col-sm-8"
                  inputStyle={{ paddingRight: "110px" }}
                  labelClassName="col-sm-4"
                  wrapperClassName="no-gutters mb-2"
                  error={
                    currentTouched.totalPercentageOfRecycledPlasticsInFrance &&
                    currentErrors.totalPercentageOfRecycledPlasticsInFrance
                  }
                  value={submission.totalPercentageOfRecycledPlasticsInFrance || 0}
                />
                <div className="srs-input-extra-content-body">
                  <button type="button" className="btn btn-primary" disabled>
                    %
                  </button>
                </div>
              </div>
            ) : null}
          </>
        )}
        <Input
          name={`submissions.${index}.administration_number`}
          value={administrationNumber}
          label={t("Extra Information")}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          wrapperClassName="no-gutters mb-2"
          handleOnChange={handleInputAdministrationNumberChange}
          infoTooltip="Optionally add a reference to supporting documents to facilitate the audit"
        />
    </>
  )
}

export default SubmissionNewForm
