import { Dispatch } from "redux"

import { actionHandler } from "../@common/actions"
import { FETCH_COMPANIES, FETCH_PLANTS } from "./constants"
import { getCompanies, getPlants } from "./service/api"
import { Company } from "./types"

interface SetCompaniesPayload {
  companies: Company[]
}

interface SetPlantsPayload {
  plants: Company[]
}

export interface SetCompanies {
  type: string
  payload: SetCompaniesPayload
}

export interface SetPlants {
  type: string
  payload: SetPlantsPayload
}

export const setCompanies = (payload: SetCompaniesPayload): SetCompanies => ({
  type: FETCH_COMPANIES,
  payload,
})

export const setPlants = (payload: SetPlantsPayload): SetPlants => ({
  type: FETCH_PLANTS,
  payload,
})

export const fetchCompanies = () => async (dispatch: Dispatch) => {
  actionHandler(getCompanies(), (companies) => {
    dispatch(setCompanies({ companies }))
  })
}

export const fetchPlants = () => async (dispatch: Dispatch) => {
  actionHandler(getPlants(), (plants) => {
    dispatch(setPlants({ plants }))
  })
}

export type CompanyActions = SetCompanies & SetPlants
