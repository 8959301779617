import React, { useEffect, ReactNode } from "react"

import useStateGetter from "../../hooks/useStateGetter"
import useUserFromToken from "../../hooks/useUserFromToken"
import { UserRoles } from "../../modules/users/types"
import { history } from "../../utils/routes"

const AuthGuard = (props: { children?: ReactNode; roles?: UserRoles[] }) => {
  const { children, roles } = props
  const accessToken = useStateGetter(["auth", "accessToken"])
  const { role } = useUserFromToken()

  useEffect(() => {
    if (!accessToken) {
      history.push("/auth/login")
    } else if (roles && role && !roles.includes(role)) {
      history.push("/")
    }
  }, [accessToken, role, roles])

  return <>{children}</>
}

export default AuthGuard
