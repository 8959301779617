import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import Input from "../../../components/Input/Input"
import Modal from "../../../components/Modal/Modal"
import Tree, {
  BranchProps,
  TreeNode,
  BranchActionHandler,
  polimerTreeUpdateData,
} from "../../../components/Tree/Tree"
import useStateGetter from "../../../hooks/useStateGetter"
import { history } from "../../../utils/routes"
import ConfirmButton from "../../../views/partials/ConfirmButton"
import {
  fetchApplications,
  createApplication,
  updateApplicationTree,
  removeApplication,
} from "../actions"
import { putApplication, showHideApplication } from "../service/api"

const ApplicationBranch = ({ node, actionHandler, level = 1 }: BranchProps) => (
  <div className="w-100 pointer-events-none">
    <div className="srs-branch">
      <div className="d-flex align-items-center justify-content-between pointer-events-none">
        <h6 className="mb-0">{node.title}</h6>
        <div className="srs-branch-actions pointer-events-auto">
          {level < 5 ? (
            <button
              type="button"
              className="btn btn-link p-2"
              onClick={() => actionHandler(node, "add")}
              disabled={level >= 5}
            >
              <i className="fa fa-plus" title="Add children material" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-link p-2"
            onClick={() => actionHandler(node, "edit")}
          >
            <i className="fa fa-pen" title="Edit material" />
          </button>
          <ConfirmButton
            title="Remove"
            text="Are you sure you wish to remove this item?"
            confirmText="Remove"
            icon="trash"
            className="btn-link p-2"
            confirmClassName="btn-outline-danger"
            danger
            onConfirm={() => actionHandler(node, "delete")}
          >
            <i className="fa fa-trash-alt" title="Delete material" />
          </ConfirmButton>
        </div>
      </div>
    </div>
  </div>
)

const ApplicationsManage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [applicationId, setApplicationId] = useState<number | undefined>()
  const [title, setTitle] = useState("")
  const [positionId, setPositionId] = useState(0)
  const [parentId, setParentId] = useState(0)
  const dispatch = useDispatch()
  const applications =
    useStateGetter<TreeNode[]>(["application", "applications"]) ?? []

  const handleAddApplication = async () => {
    if (title.trim().length) {
      await dispatch(createApplication({ title, positionId, parentId }))
      setParentId(0)
      setPositionId(0)
      setIsModalOpen(!isModalOpen)
    }
  }

  const handleUpdateApplication = async () => {
    if (title.trim().length && applicationId) {
      await putApplication({ id: applicationId, title })
      dispatch(fetchApplications())
      setIsModalOpen(!isModalOpen)
    }
  }

  const handleApplicationsBranchAction: BranchActionHandler = (
    { id, title: aTitle },
    actionName,
  ) => {
    switch (actionName) {
      case "visibility":
        showHideApplication({ id })
        dispatch(fetchApplications())
        break
      case "add":
        setParentId(id)
        setIsModalOpen(!isModalOpen)
        break
      case "edit":
        setApplicationId(id)
        setTitle(aTitle)
        setIsModalOpen(!isModalOpen)
        break
      case "delete":
        dispatch(removeApplication({ id }))
        break
      default:
        break
    }
  }

  useEffect(() => {
    dispatch(fetchApplications())
  }, [dispatch])

  const dragEndCallback = (
    dragedElement: number,
    targetElement: number,
    isIndentElement: boolean,
  ) => {
    const childrenIds = polimerTreeUpdateData(dragedElement)
    dispatch(
      updateApplicationTree({
        dragedElement,
        targetElement,
        isIndentElement,
        childrenIds,
      }),
    )
  }

  useEffect(() => {
    if (isModalOpen === false && title !== "" && applicationId !== undefined) {
      setTitle("")
      setApplicationId(undefined)
    }
  }, [applicationId, isModalOpen, title])

  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="mb-5">{t("Manage applications")}</h1>
        <button
          type="button"
          className="btn btn-success rounded-top-right mb-3"
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          {t("Add New Product")}
          <i className="fas fa-plus ml-3" />
        </button>
      </div>

      <div className="srs-tree-container bg-white border border-primary">
        <Tree
          nodes={applications}
          dragEndCallback={dragEndCallback}
          Branch={ApplicationBranch}
          branchActionHandler={handleApplicationsBranchAction}
          isDraggable
        />
      </div>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left mr-2" /> {t("Back")}
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        isOpenHandler={setIsModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <div className="modal-header">
          <h3 className="modal-title">
            <strong>{t("New Application")}</strong>
          </h3>
        </div>
        <div className="modal-body">
          <Input
            label="Name"
            name="title"
            value={title}
            handleOnChange={(e) => setTitle(e.target.value)}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
          />
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary rounded-bottom-left"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            {t("Close")}
          </button>
          <button
            type="submit"
            disabled={!title}
            className="btn btn-success rounded-bottom-right"
            onClick={
              applicationId ? handleUpdateApplication : handleAddApplication
            }
          >
            {applicationId ? "Update" : "Save"}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ApplicationsManage
