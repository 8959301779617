import React from "react"
import Select, { OptionTypeBase, ValueType } from "react-select"

import FormError from "../FormError/FormError"
import Label from "../Label/Label"
import "./SelectAdapter.scss"

export interface BaseSelectedValue<V = string | number> {
  value: V
  label: string
}
// @ts-ignore
export type SelectValue = ValueType<OptionTypeBase, boolean>

export interface Props {
  name: string
  label?: string
  options: BaseSelectedValue[]
  value: SelectValue | null
  handleOnChange: (selectedOption: SelectValue) => void
  isDisabled?: boolean
  wrapperClassName?: string
  inputWrapperClassName?: string
  labelClassName?: string
  error?: string | boolean
  placeholder?: string
  shouldNotDisplayValueWhenOptionsOpen?: boolean
  infoTooltip?: string | boolean
}

const SelectAdapter: React.FC<Props> = ({
  name,
  label,
  options,
  value,
  handleOnChange,
  isDisabled,
  wrapperClassName = "",
  inputWrapperClassName = "col-sm-12",
  labelClassName = "col-sm-12",
  error,
  placeholder = "Select...",
  shouldNotDisplayValueWhenOptionsOpen = false,
  infoTooltip = false,
}) => {
  const styles = {
    singleValue: (base: any, state: any) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? "#fff" : base.color,
    }),
  }

  return (
    <div className={`srs-select form-group row ${wrapperClassName}`}>
      {label && !infoTooltip && (
        <Label label={label} name={name} labelClassName={labelClassName} />
      )}
      {label !== null && infoTooltip && (
        <div className={`${labelClassName} col-form-label`}>
          <div>
            {label}
            <span data-tip={infoTooltip}>{" (i)"}</span>
          </div>
        </div>
      )}
      <div className={inputWrapperClassName}>
        <Select
          styles={shouldNotDisplayValueWhenOptionsOpen ? styles : {}}
          classNamePrefix="srs-select-adapter"
          name={name}
          options={options}
          value={value}
          onChange={handleOnChange}
          isDisabled={isDisabled}
          placeholder={placeholder}
        />
        <FormError error={error} />
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapToOptions = (items: any[], label = "name", value = "id") =>
  items.map((item) => ({
    label: item[label],
    value: item[value],
  }))

export default SelectAdapter
