import React, { ChangeEvent } from "react"

import FormError from "../FormError/FormError"
import Label from "../Label/Label"
import "./Checkbox.scss"

export interface InputProps {
  name: string
  label?: any
  value?: boolean
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  labelClassName?: string
  checkboxWrapperClassName?: string
  error?: string | boolean
  textColor?: string
}

const Checkbox: React.FC<InputProps> = ({
  name,
  label = "",
  value,
  handleOnChange,
  isDisabled = false,
  labelClassName = "col-sm-12",
  checkboxWrapperClassName = "",
  error,
  textColor = "text-black",
}) => (
  <div
    className={`srs-checkbox row ${checkboxWrapperClassName} ${
      isDisabled && "text-muted"
    }`}
  >
    <input
      id={name}
      className="form-control"
      type="checkbox"
      name={name}
      checked={value}
      onChange={handleOnChange}
      disabled={isDisabled}
    />
    <Label
      label={label}
      name={name}
      labelClassName={`cursor-pointer ${labelClassName} ${textColor}`}
    />
    <FormError error={error} />
  </div>
)

export default Checkbox
