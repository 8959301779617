import React, {
  useState,
  useEffect,
} from "react"
import { Formik, Form, FieldArray } from 'formik'
import SubmissionNewForm from "./SubmissionNewForm"
import { useTranslation } from "react-i18next"
import { history } from "utils/routes"
import { Company } from "modules/companies/types"
import { useDispatch } from "react-redux"
import { fetchCountries } from "modules/countries/actions"
import { createSubmissions, fetchSelectedCompany } from "modules/submissions/actions"
import { getPlants } from "modules/companies/service/api"
import { SubmissionArrayFormsValidationShema } from "./SubmissionFormValidationShema"
import { Link, useRouteMatch } from "react-router-dom"
import useStateGetter from "hooks/useStateGetter"
import {SubmissionOutputArrayFormsValidationShema} from "./SubmissionOutputFormValidationShema"
import { BaseSelectedValue } from "components/Select/Select"
import { Country } from "modules/countries/types"
import { ContaminationUnit } from "modules/submissions/types"
import { setUserMetadata, windowStorage } from "utils/windowStorage"
import useUserFromToken from "hooks/useUserFromToken"
import { SELECTED_COMPANY_KEY } from "modules/submissions/constants"
import { getPrevYear } from "../../utils";

/* Set date to -1 month in form */
const actualDate = new Date()
actualDate.setMonth(actualDate.getMonth() - 1)

export type SubmissionType = {
  company: BaseSelectedValue<number> | null
  country: BaseSelectedValue<number> | null
  composition: BaseSelectedValue<number> | null
  materialGroup: BaseSelectedValue<number> | null
  date: Date
  volume: number
  contamination: number
  grossOutputEU: number
  grossOutputNonEU: number
  netOutputEU: number
  netOutputNonEU: number
  totalPercentageOfRecycledPlasticsInFrance: number
  virginAdditives: number
  isInput: boolean
  administrationNumber: string
  yearlySubmission: boolean
  originFromEu: boolean
  contaminationUnit:ContaminationUnit
  contaminationState: number
  netVolume: number
}
const INITIAL_FORM_VALUES: SubmissionType = {
  company: null,
  country: null,
  composition: null,
  materialGroup: null,
  date: actualDate,
  volume: 0,
  contamination: 0,
  grossOutputNonEU: 0,
  grossOutputEU: 0,
  netOutputEU: 0,
  netOutputNonEU: 0,
  totalPercentageOfRecycledPlasticsInFrance: 0,
  virginAdditives: 0,
  isInput: false,
  administrationNumber: "",
  yearlySubmission: false,
  originFromEu: false,
  contaminationUnit: ContaminationUnit.PERCENT,
  contaminationState: 0,
  netVolume: 0
}

interface Props {
  disabled?: boolean
}
const SubmissionNewForms: React.FC<Props> = ({disabled = false}) => {
  const { params }: { params: { type: string | undefined } } = useRouteMatch()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [plants, setPlants] = useState<Company[]>([])
  const [loading, setLoading] = useState(false)  
  const [isSubmissionInput, setIsSubmissionInput] = useState<boolean>(params.type === "input")
  const { id: userId } = useUserFromToken()
  const selectedComapny =
    useStateGetter<Company>(["submission", "selectedCompany"]) ?? undefined

  const countriesEu = useStateGetter<Country[]>(["country", "countries"]) ?? [] 

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchSelectedCompany())
    getPlants().then((d) => {
      const fetchedPlants = d.data
      if (fetchedPlants) {
        setPlants(fetchedPlants)
      }
    })
  }, [dispatch])
  
  const clearSelectedCompany = () => {
    windowStorage.remove(SELECTED_COMPANY_KEY, false)
    dispatch(fetchSelectedCompany())
  }

  useEffect(() => {
    if (params.type === "input") {
      setIsSubmissionInput(true)
    } else {
      setIsSubmissionInput(false)
    }
  }, [params.type])

  return (
    <>
    { isSubmissionInput && <div className="row mb-4">
      <div className="col-sm-8"></div>
      <div className="col-sm-4">
        <Link
          className={
            selectedComapny
              ? "btn btn-outline-primary w-100"
              : "disabled-link btn btn-outline-primary w-100"
          }
          to="/waste-origin"
        >
          {t("Add New Waste")}
        </Link>
      </div>
    </div>
    }
    <Formik
       initialValues={{ submissions: [INITIAL_FORM_VALUES] }}
       validationSchema={isSubmissionInput
        ? SubmissionArrayFormsValidationShema
        : SubmissionOutputArrayFormsValidationShema}
       onSubmit={async (values, actions) => {
          if (!loading) {
            setLoading(true)
            const promises = values.submissions.map((submission, index) => {
              const companyId = submission.company ? submission.company.value : 0
              const countryId = isSubmissionInput ? submission.country?.value : null
              const compositionId = isSubmissionInput ? submission.composition?.value : null
              const materialsGroupId = submission.materialGroup?.value

              const contamination = submission.contamination
              const volumeGross = typeof submission.volume === "string" ? parseInt(submission.volume, 0) : submission.volume

              const selectedCountry = countriesEu.find(
                (countryFromList) => countryFromList.id === submission.country?.value,
              )

              const isMaterialFromOutsideEurope =
                isSubmissionInput && !selectedCountry?.isEu || submission.originFromEu ? true : false

              const date = submission.date
              if (submission.yearlySubmission) {
                date.setFullYear(getPrevYear())
                date.setMonth(11)
              }
              
              if (companyId) {
                
                const credentails = {
                  companyId,
                  countryId,
                  compositionId,
                  materialsGroupId,
                  collectionDate: date,
                  volumeGross,
                  contaminationUnit: submission.contaminationUnit,
                  contaminationPercentage: submission.contaminationState,
                  volumeNet: submission.netVolume,
                  grossOutputEU: submission.grossOutputEU,
                  grossOutputNonEU: submission.grossOutputNonEU,
                  netOutputEU: submission.netOutputEU,
                  netOutputNonEU: submission.netOutputNonEU,
                  totalPercentageOfRecycledPlasticsInFrance: submission.totalPercentageOfRecycledPlasticsInFrance,
                  virginAdditives: submission.virginAdditives,
                  isInput: isSubmissionInput,
                  administrationNumber: submission.administrationNumber,
                  isMaterialFromOutsideEurope,
                  yearlySubmission: submission.yearlySubmission,
                  wasteFromEu: submission.originFromEu,
                  successCallback:() => {}
                }
                if (values.submissions.length -1 === index) {
                  credentails.successCallback= () => {
                    setLoading(false)
                    history.push("/submissions", {
                      title: t("Select Submission type:"),
                      tab: isSubmissionInput ? "input" : "output",
                    })
                  }
                }
                setUserMetadata(userId, { contamination })
                return dispatch(createSubmissions(credentails))
              }
          
            })
            await Promise.all(promises)
            clearSelectedCompany()
            actions.setSubmitting(false)
          }
      }}>
      {({ values }) => (
        <Form>
          <FieldArray
            name="submissions"
            render={arrayHelpers => (
              <div className="clearfix">
                {values.submissions && values.submissions.length > 0 ? (
                  values.submissions.map((submission, index) => (
                    <div key={index}>
                      <SubmissionNewForm submission={submission} plants={plants} index={index} isSubmissionInput={isSubmissionInput}/>
                      <div className="row my-4">                        
                        <div className="col-sm-9 col-md-8 col-lg-7">
                          <button
                            type="button"
                            className="btn btn-success-light rounded-bottom-left"
                            onClick={() => {
                              arrayHelpers.insert(index+1, {...INITIAL_FORM_VALUES, 
                                date: submission.date, 
                                yearlySubmission: !!submission.yearlySubmission
                              })
                            }}
                          >
                            <i className="fas fa-plus mr-3" />
                            {t("Add new Submission")}
                          </button>
                        </div>
                        <div className="col-sm-3 col-md-4 col-lg-1">
                          {index !== 0 && (
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-primary rounded-bottom-left"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              {t("Cancel")}
                            </button>
                            )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <button type="button" onClick={() => arrayHelpers.push(INITIAL_FORM_VALUES)}>
                    {/* show this when user has removed all submissions from the list */}
                    Add a submission
                  </button>
                )}
                <button
                  type="submit"
                  className="btn btn-success float-right rounded-bottom-right ml-2"
                  disabled={loading}
                >
                  {t("Save")}
                  <i className="fas fa-arrow-right ml-3" />
                </button>
              </div>
            )}
          />
        </Form>
       )}
    </Formik>
    </>
  )
}

export default SubmissionNewForms