import { restHelper } from "../../@common/service/api"
import { ID } from "../../@common/types"
import { GenericComposition, GenericCompositionPost } from "../types"

export const queries = {
  genericCompositions: `
    query genericCompositions($type: String, $companyId: Int) {
      genericCompositions(type: $type, companyId: $companyId) {
        id
        type
        productId
        productName
        company {
          id
          name
        }
      }
    }
  `,
  genericComposition: `
    query genericComposition($id: Int!) {
      genericComposition(id: $id) {
        id
        type
        productId
        productName
        company {
          id
          name
        }
      } 
    }
  `,
}

export const mutations = {
  genericCompositionCreate: `
    mutation genericCompositionCreate(
      $productId: Int!
      $productName: String!
      $companyId: Int!
      $type: String!
    ) {
      genericCompositionCreate(
        productId: $productId
        productName: $productName
        companyId: $companyId
        type: $type
      ) { id }
    }
  `,
  genericCompositionArchivization: `
    mutation genericCompositionArchivization(
      $id: Int!
    ) {
      genericCompositionArchivization(
        id: $id
      )
    }
  `,
}

export const getGenericCompositions = restHelper<GenericComposition[], ID>(
  queries,
  "genericCompositions",
)

export const getGenericComposition = restHelper<GenericComposition, ID>(
  queries,
  "genericComposition",
)

export const postGenericComposition = restHelper<
  GenericComposition,
  GenericCompositionPost
>(mutations, "genericCompositionCreate")

export const archiveGenericComposition = restHelper<boolean, ID>(
  mutations,
  "genericCompositionArchivization",
)
