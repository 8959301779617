import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import AuthGuard from "../../components/AuthGuard/AuthGuard"
import { UserRoles } from "../users/types"
import NotificationDetail from "./containers/NotificationDetail"
import NotificationEdit from "./containers/NotificationEdit"
import NotificationList from "./containers/NotificationList"
import NotificationNew from "./containers/NotificationNew"

const Notifications = () => {
  const { path } = useRouteMatch()
  return (
    <>
    <AuthGuard roles={[UserRoles.ADMIN]}>
      <Route exact path={path}>        
          <NotificationList />
      </Route>
      <Route exact path={`${path}/:id`}>
        <NotificationDetail />
      </Route>
      <Route path={`${path}/new`}>
          <NotificationNew />
      </Route>
      <Route path={`${path}/edit/:id`}>
          <NotificationEdit />
      </Route>
    </AuthGuard>
    </>
  )
}

export default Notifications
