import React, { useEffect, useState, FormEvent } from "react"
import { handleToast } from "../../../utils/messages"
import { postNotification } from "../service/api"
import NotificationForm, { NotificationFormData } from "../components/NotificationForm";

const NotificationNew = () => {

  const handleSubmit = async (formData: NotificationFormData) => {
    const polymerIds = formData.polymers.map(o => o.value)
    console.log(formData)
    const { data } = await postNotification({
      subject: formData.subject,
      content: formData.content,
      polymers: polymerIds,
      filePath: formData.filePath,
      status: formData.status
    })
    if (data) {
      console.log(data)
      handleToast(["Notification created"], "info")
    }
  }

  return (
    <>
      <NotificationForm submitHandler={handleSubmit}></NotificationForm>
    </>
  )
}

export default NotificationNew
