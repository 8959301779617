import { AdditionalAudit } from "modules/NewAudit"
import React, { lazy, Suspense } from "react"

import AuthGuard from "./components/AuthGuard/AuthGuard"
import Audit from "./modules/Audit/Audit"
import AuditReports from "./modules/Audit/components/AuditReports"
import PlantsSelection from "./modules/Audit/components/PlantSelection"
import AnnualPercentage from "./modules/Audit/containers/auditOptions/AnnualPercentage"
import EditAudit from "./modules/Audit/containers/auditOptions/EditAudit"
import ExistingAudits from "./modules/Audit/containers/auditOptions/ExistingAudits"
import NewAudit from "./modules/Audit/containers/auditOptions/NewAudit"
import Applications from "./modules/applications"
import Auth from "./modules/auth"
import Plants from "./modules/companies/Plants"
import ManageCompanies from "./modules/companies/components/ManageCompanies/ManageCompanies"
import ManageCompaniesAdmin from "./modules/companies/components/ManageCompanies/ManageCompaniesAdmin"
import Compositions from "./modules/compositions"
import Contract from "./modules/contract"
import Countries from "./modules/countries"
import CountryLanguageEdit from "./modules/countries/CountryLanguageEdit"
import Dashboards from "./modules/dashboards/Dashboards"
import GenericCompositions from "./modules/genericCompositions"
import Materials from "./modules/materials"
import Manual from "./modules/pages/Manual"
import Products from "./modules/products"
import Submissions from "./modules/submissions"
import Users from "./modules/users"
import { UserRoles } from "./modules/users/types"
import Dashboard from "./views/Dashboard"
import DashboardLinks from "./views/DashboardLinks"
import Notifications from "modules/notifications/Notifications"

const Pages = lazy(() => import("./modules/pages/Pages"))

const routes = [
  {
    path: "/auth",
    component: Auth,
  },
  {
    route: "*",
    component: (props: unknown) => (
      <AuthGuard>
        {/* @ts-ignore */}
        <Dashboard {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: "/",
        exact: true,
        component: DashboardLinks,
      },
      {
        path: "/audit-main",
        component: AdditionalAudit,
      },
      {
        path: "/applications",
        component: Applications,
      },
      {
        path: "/waste-origin",
        component: Compositions,
      },
      {
        path: "/used-in",
        component: GenericCompositions,
      },
      {
        path: "/plants",
        component: Plants,
      },
      {
        path: "/companies",
        component: ManageCompanies,
      },
      {
        path: "/dashboards",
        component: Dashboards,
      },
      {
        path: "/manual",
        component: Manual,
      },
      {
        path: "/countries",
        component: () => (
          <AuthGuard roles={[UserRoles.ADMIN]}>
            <Countries />
          </AuthGuard>
        ),
      },
      {
        path: "/companies-admin",
        component: () => (
          <AuthGuard roles={[UserRoles.ADMIN]}>
            <ManageCompaniesAdmin />
          </AuthGuard>
        ),
      },
      {
        path: "/countries-edit/:code",
        component: () => (
          <AuthGuard roles={[UserRoles.ADMIN]}>
            <CountryLanguageEdit />
          </AuthGuard>
        ),
      },
      {
        path: "/materials",
        exact: true,
        component: () => (
          <AuthGuard roles={[UserRoles.ADMIN]}>
            <Materials />
          </AuthGuard>
        ),
      },
      {
        path: "/products",
        component: () => (
          <AuthGuard roles={[UserRoles.ADMIN]}>
            <Products />
          </AuthGuard>
        ),
      },
      {
        path: "/pages",
        component: () => (
          <Suspense fallback={null}>
            <Pages />
          </Suspense>
        ),
      },
      {
        path: "/notifications",
        component: () => (
          <Suspense fallback={null}>
            <Notifications />
          </Suspense>
        ),
      },
      {
        path: "/submissions",
        component: Submissions,
      },
      {
        path: "/users",
        component: Users,
      },
      {
        path: "/plants-selection",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
            ]}
          >
            <PlantsSelection />
          </AuthGuard>
        ),
      },
      {
        path: "/audits",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
            ]}
          >
            <Audit />
          </AuthGuard>
        ),
      },
      {
        path: "/audit-reports/:id",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
              UserRoles.SECTOR_ASSOCIATION_REPRESENTATIVE,
            ]}
          >
            <AuditReports />
          </AuthGuard>
        ),
      },
      {
        path: "/audit-reports/edit/:id",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
              UserRoles.SECTOR_ASSOCIATION_REPRESENTATIVE,
            ]}
          >
            <NewAudit />
          </AuthGuard>
        ),
      },
      {
        path: "/edit-audit/:id",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
              UserRoles.SECTOR_ASSOCIATION_REPRESENTATIVE,
            ]}
          >
            <EditAudit />
          </AuthGuard>
        ),
      },
      {
        path: "/new-audit/:id",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
            ]}
          >
            <NewAudit />
          </AuthGuard>
        ),
      },
      {
        path: "/existing-audit/:id",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
            ]}
          >
            <ExistingAudits />
          </AuthGuard>
        ),
      },
      {
        path: "/annual-percentage/:id",
        component: () => (
          <AuthGuard
            roles={[
              UserRoles.ADMIN,
              UserRoles.AUDITOR,
              UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
            ]}
          >
            <AnnualPercentage />
          </AuthGuard>
        ),
      },
      {
        path: "/contract/:id",
        component: () => (
          <AuthGuard roles={[UserRoles.ADMIN]}>
            <Contract />
          </AuthGuard>
        ),
      },
    ],
  },
]

export default routes
