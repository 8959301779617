import React from "react"
import { useTranslation } from "react-i18next"

import Modal from "components/Modal/Modal"
import Select, { mapToOptions } from "components/Select/Select"

interface AuditorAssignProps {
  showAuditorsModal: boolean
  setShowAuditorsModal: any
  auditors: any
  selectedAuditor: any
  setSelectedAuditor: any
  assignAuditorToAudit: any
}

const AssignAuditor: React.FC<AuditorAssignProps> = ({
  showAuditorsModal,
  setShowAuditorsModal,
  auditors,
  selectedAuditor,
  setSelectedAuditor,
  assignAuditorToAudit,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={showAuditorsModal}
      isOpenHandler={setShowAuditorsModal}
      wrapperClass="srs-modal-lg"
    >
      <div className="modal-header">
        <h3 className="modal-title">
          <strong>{t("Choose auditor")}</strong>
        </h3>
      </div>
      <div className="modal-body small">
        <div className="col-12">
          {auditors && (
            <Select
              name="company"
              label="Choose auditor"
              options={mapToOptions(auditors)}
              value={selectedAuditor}
              handleOnChange={(selectedOption) =>
                setSelectedAuditor(selectedOption)
              }
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
            />
          )}
        </div>
      </div>
      {selectedAuditor && (
        <div className="modal-footer">
          <button
            className="btn btn-success rounded-bottom-right float-right"
            onClick={assignAuditorToAudit}
          >
            {t("Assign auditor to audit")}
          </button>
        </div>
      )}
    </Modal>
  )
}

export default AssignAuditor
