import React from "react"
import { Notification } from "../types"

interface Props {
  notification: Notification
}

const NotificationView: React.FC<Props>= ({notification}) => {  
  return(
  <div>
    <h1>{notification.subject}</h1>
      <div dangerouslySetInnerHTML={{ __html: notification.content }} />      
      <a href={process.env.REACT_APP_API_URL + notification.filePath} target="_blank">Download</a>
  </div>)
}

export default NotificationView