export const activityTypes = [
  {
    label: "All activity",
    value: "all",
  },
  {
    label: "Recycling activity",
    value: "recycle",
  },
  {
    label: "Converting activity",
    value: "convert",
  },
]

export const wasteTypes = [
  {
    label: "All types",
    value: "all",
  },
  {
    label: "Post-consumer",
    value: "post-c",
  },
  {
    label: "Pre-consumer",
    value: "pre-c",
  },
]

export const auditTypes = [
  {
    label: "Recyclass",
    value: "Recyclass",
  },
  {
    label: "EUCertPlast",
    value: "EUCertPlast",
  },
  {
    label: "BQA-CA-CER",
    value: "BQA-CA-CER",
  },
  {
    label: "IPPR Plastica Seconda Vita ",
    value: "IPPR Plastica Seconda Vita",
  },
  {
    label: "Recovinyl",
    value: "Recovinyl",
  },
  {
    label: "AENOR",
    value: "AENOR",
  },
]
export const statuses = [
  {
    label: "All statuses",
    value: "all",
  },
  {
    label: "Pending status",
    value: "SENT",
  },
  {
    label: "Accepted status",
    value: "ACCEPTED",
  },
  {
    label: "Declined status",
    value: "DECLINED",
  },
]