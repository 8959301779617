import React from "react"
import { useTranslation } from "react-i18next"
import Slider from "react-input-slider"
import NumberFormat from "react-number-format"
import ReactTooltip from "react-tooltip"

import Input from "components/Input/Input"
import Label from "components/Label/Label"

import SubmissionFormGrossOutputInput from "./SubmissionFormGrossOutputInput"

interface SubmissionOutputPartials {
  grossOutputEU: any
  grossOutputNonEU: any
  netOutputEU: any
  netOutputNonEU: any
  setGrossOutputEU: any
  setGrossOutputNonEU: any
  setNetOutputEU: any
  setNetOutputNonEU: any
  disabled: boolean
  virginAndAdditives: any
  onSetVirginAndAdditives: any
  onSetVirginAndAdditivesSlider: any
  grossOutputEUError?: any
  grossOutputNonEUError?: any
  wrapperClassName?: string
}

const SubmissionFormOutputPartials: React.FC<SubmissionOutputPartials> = ({
  grossOutputEU,
  grossOutputNonEU,
  netOutputEU,
  netOutputNonEU,
  setGrossOutputEU,
  setGrossOutputNonEU,
  setNetOutputEU,
  setNetOutputNonEU,
  disabled,
  virginAndAdditives,
  onSetVirginAndAdditives,
  onSetVirginAndAdditivesSlider,
  grossOutputEUError,
  grossOutputNonEUError,
  wrapperClassName=""
}) => {
  const { t } = useTranslation()

  return (
    <>
      {/* <h3>
        <b>{t('Gross Output')}</b>
      </h3>

      <div className={`form-group row align-items-center`}>
        <Label
          label={t('Gross output recycled material to EU + UK (kg)')}
          name={grossOutputEU}
          labelClassName="col-sm-4"
        />
        <div className="col-sm-8">
          <SubmissionFormGrossOutputInput
            id="gross-output-eu-eu-input"
            className="form-control"
            grossValue={grossOutputEU}
            setGrossValueFunction={setGrossOutputEU}
            error={grossOutputEUError}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={`form-group row align-items-center`}>
        <Label
          label={t('Gross output recycled material to Non EU (kg)')}
          name={grossOutputNonEU}
          labelClassName="col-sm-4"
        />
        <div className="col-sm-8">
          <SubmissionFormGrossOutputInput
            id="gross-output-non-eu-input"
            className="form-control"
            grossValue={grossOutputNonEU}
            setGrossValueFunction={setGrossOutputNonEU}
            error={grossOutputNonEUError}
            disabled={disabled}
          />
        </div>
      </div>

      <h3>
        <b>{t('Estimated Recycled Content')}</b>
      </h3>
      <div className="d-flex align-items-center mb-3">
        <div className="col">
          <Input
            label={t('Virgin &; additives(%)')}
            name="virginAndAdditives"
            value={virginAndAdditives}
            handleOnChange={onSetVirginAndAdditives}
            wrapperClassName="mb-0"
            labelClassName="col-9"
            inputWrapperClassName="col-3"
            isDisabled={disabled}
          />
        </div>
        <div className="col">
          <Slider
            axis="x"
            x={virginAndAdditives}
            onChange={({ x }) => {
              onSetVirginAndAdditivesSlider(x);
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <h3>
        <b>{t('Net Output')}</b>
      </h3>
      <div className={`form-group row align-items-center`}>
        <Label
          label={t('Net output recycled material to EU + UK (kg)​')}
          name={grossOutputEU}
          labelClassName="col-sm-4"
        />
        <div className="col-sm-8">
          <NumberFormat
            className="form-control"
            type="text"
            value={netOutputEU}
            thousandSeparator={false}
            disabled={true}
            fixedDecimalScale={true}
            isDisabled={disabled}
            decimalSeparator="."
            decimalScale={2}
          />
        </div>
      </div>
      <div className={`form-group row align-items-center`}>
        <Label
          label={t('Net output recycled material exported non-EU (kg)​')}
          name={grossOutputEU}
          labelClassName="col-sm-4"
        />
        <div className="col-sm-8">
          <NumberFormat
            className="form-control"
            type="text"
            value={netOutputNonEU}
            thousandSeparator={false}
            disabled={true}
            fixedDecimalScale={true}
            isDisabled={disabled}
            decimalSeparator="."
            decimalScale={2}
          />
        </div>
      </div> */}

      <div className={`srs-select form-group row align-items-center ${wrapperClassName}`}>
        <SubmissionFormGrossOutputInput
          id="gross-output-eu-eu-input"
          className="form-control"
          grossValue={grossOutputEU}
          setGrossValueFunction={setGrossOutputEU}
          error={grossOutputEUError}
          disabled={disabled}
          labelClassName="col-sm-4"
          label={`${t("Net output recycled material to EU + UK (kg)​")} (*)`}
          infoTooltip={t("Recycled material excludes new additives or fillers")}
        />
      </div>
      <div className={`srs-select form-group row align-items-center ${wrapperClassName}`}>
        <SubmissionFormGrossOutputInput
          id="gross-output-non-eu-input"
          className="form-control"
          grossValue={grossOutputNonEU}
          setGrossValueFunction={setGrossOutputNonEU}
          error={grossOutputNonEUError}
          disabled={disabled}
          labelClassName="col-sm-4"
          label={`${t(
            "Net output recycled material exported non-EU (kg)",
          )} (*)`}
          infoTooltip={t("Recycled material excludes new additives or fillers")}
        />
      </div>
      <ReactTooltip />
    </>
  )
}

export default SubmissionFormOutputPartials
