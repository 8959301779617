import { USER_ROLES_LIST } from "../../users/constants"
import { CreateCompanyInput, PersonalData } from "../types"

class Register {
  private _personalData: PersonalData = {
    role: USER_ROLES_LIST[0],
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    country: null,
    companyName: "",
    vatNumber: "",
  }

  private _companyData: CreateCompanyInput = {
    selectedCountry: null,
    country: 0,
    vatNumber: "",
    name: "",
    address: "",
    // holding: '',
    ceoName: undefined,
    ceoEmail: undefined,
    capacities: 0,
    website: "",
    allowDisplayOnWebsite: false,
    companyRole: null,
    companyPolymer: [],
    companyMosa: [],
    sharedDataWithMosa: false,
    sharedUfmeWithEppa: false,
    sharedSnepWithEppa: false,    
  }

  set personalData(data) {
    this._personalData = data
  }

  get personalData(): PersonalData {
    return this._personalData
  }

  set companyData(data) {
    this._companyData = data
  }

  get companyData(): CreateCompanyInput {
    return this._companyData
  }

  get isPersonalData(): boolean {
    const { role, email, name, password } = this._personalData

    return role !== null && email !== "" && name !== "" && password !== ""
  }
}

export default new Register()
