import React, { useEffect, useState, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch, Link } from "react-router-dom"

import Select, {
  BaseSelectedValue,
  mapToOptions,
  SelectValue,
} from "../../../components/Select/Select"
import { ACTIVE_PLANT_ID_KEY } from "../../../constants"
import useStateGetter from "../../../hooks/useStateGetter"
import { handleToast } from "../../../utils/messages"
import { windowStorage } from "../../../utils/windowStorage"
import { getPlant, getPlants } from "../../companies/service/api"
import { Company } from "../../companies/types"
import { fetchSubmissions } from "../../submissions/actions"
import SubmissionListTableBodyForPlantsSelection from "../../submissions/components/SubmissionListTable/SubmissionListTableBodyForPlantsSelection"
import { Submission } from "../../submissions/types"

const PlantSelection = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const [plant, setPlant] = useState<Company>()
  const [forAuditId, setForAuditId] = useState<number>()
  const [companyId, setCompanyId] = useState<number>(0)
  const [sortByMemo, setSortByMemo] = useState<string[]>([
    "s.createdAt",
    "DESC",
  ])
  const [selectedStatus, setSelectedStatus] = useState<string>("")

  const submissions =
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? []
  const submissionsQty =
    useStateGetter<number>(["submission", "submissionsQty"]) ?? 0

  const onSortChanged = (sortBy: string, sortDirection: string): void => {
    setSortByMemo([sortBy, sortDirection])
  }

  const [plants, setPlants] = useState<Company[]>([])
  const plantsOptions = useMemo(
    () => (plants && plants.length ? mapToOptions(plants) : []),
    [plants],
  )
  const [selectedCompany, setSelectedCompanyState] = useState<SelectValue>(null)
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(false)
  const onSelectChanged = (selectedOption: SelectValue) => {
    const option = selectedOption as { label: string; value: number }
    setSelectedCompanyState(option)
    setForAuditId(option.value)
    setCompanyId(option.value)
    if (option.value) {
      windowStorage.set(ACTIVE_PLANT_ID_KEY, `${option.value}`, false)
    }
  }

  // fetch plants at start
  useEffect(() => {
    getPlants()
      .then((response) => {
        if (response.data) {
          setPlants(response.data as Company[])
        }
      })
      .catch(() => {
        // handleToast([`Error during getting plants list: ${error}`], 'errors');
        handleToast([`Couldn't get plants list`], "errors")
      })
  }, [])

  useEffect(() => {
    if (plants && plants.length) {
      let plant = plants[0]

      const rv_selected_plant_id = windowStorage.get(
        ACTIVE_PLANT_ID_KEY,
        null,
        false,
      )
      if (rv_selected_plant_id) {
        plant =
          plants.find((p) => p.id === parseInt(rv_selected_plant_id, 10)) ??
          plants[0]
      }

      if (plant && plant.id) {
        setCompanyId(plant.id)
        setSelectedCompanyState({
          label: plant.name,
          value: plant.id,
        })
        windowStorage.set(ACTIVE_PLANT_ID_KEY, "${plant.id}", false)
      }
    }
    if (plants.length === 0) {
      setButtonIsDisabled(true)
    } else {
      setButtonIsDisabled(false)
    }
  }, [plants])

  const getSubmissions = useCallback(() => {
    if (companyId) {
      dispatch(
        fetchSubmissions({
          companyId,
          limit: 9999,
          polymers: ['PVC'],
          status: 'SENT',
        }),
      )
    }
  }, [companyId, dispatch])

  useEffect(() => {
    getSubmissions()
    if (forAuditId === undefined) {
      setForAuditId(plantsOptions[0]?.value)
    }
  }, [forAuditId, getSubmissions, plantsOptions])

  return (
    <>
      <h1 className="mb-5">{t("Select a plant to audit")}:</h1>
      <div className="row">
        <div className="col-12">
          <div className="select-plant-area row">
            <div className="col-2 mt-3">{t("Plant")}</div>
            <div className="col-7">
              <Select
                name="company"
                options={plantsOptions}
                value={selectedCompany}
                handleOnChange={onSelectChanged}
              />
            </div>
            <div className="col-3">
              <Link
                to={{
                  pathname: `audit-reports/${forAuditId}`,
                  state: {
                    title: t("Audits"),
                  },
                }}
                style={{ width: "100%" }}
              >
                <button
                  type="submit"
                  className="btn btn-success rounded-bottom-right col-12"
                  disabled={buttonIsDisabled && true}
                >
                  {t("Begin audit")}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <span style={{ color: "#88AFCB" }}>
            {t("Selected plant's submissions")}:
          </span>
        </div>
        <div className="col-12 mt-5">
          <SubmissionListTableBodyForPlantsSelection
            submissions={submissions}
            isInputData
            onSortChanged={onSortChanged}
            getSubmissions={getSubmissions}
          />
        </div>
      </div>
    </>
  )
}

export default PlantSelection
