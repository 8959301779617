import { useFormik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { useDispatch } from "react-redux"
import ReactTooltip from "react-tooltip"

import Checkbox from "components/Checkbox/Checkbox"
import FormError from "components/FormError/FormError"
import Input from "components/Input/Input"
import Modal from "components/Modal/Modal"
import Select, {
  BaseSelectedValue,
  mapToOptions,
  SelectValue,
} from "components/Select/Select"

import { handleToast } from "utils/messages"

import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { EuCountriesArray } from "../../../../constants"
import {
  checkCompanyVatNumber,
  postCompany,
} from "../../../companies/service/api"
import { CompanyVatCheck } from "../../../companies/types"
import { fetchCountries } from "../../../countries/actions"
import { MaterialGroup } from "../../../materials/service/api"
import { fetchUser } from "../../../users/actions"
import { USER_ROLES_LIST } from "../../../users/constants"
import { refreshToken } from "../../actions"
import registerService from "../../service/register"
import { CreateCompanyInput } from "../../types"
import RegisterCompanyFormValidationShema from "../RegisterCompanyFormValidationShema"

const FinishCompanyForm: React.FC<{
  handleStepChange: any
  handleFinishRegistration: any
  materialGroups: MaterialGroup[]
}> = ({ handleStepChange, handleFinishRegistration, materialGroups }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const countries =
    useStateGetter<{ id: string; name: string; code: string }[]>([
      "country",
      "countries",
    ]) ?? []

  const { isRecycler, isConverter, isRecyclerAndConverter } = useUserFromToken()

  const COUNTRIES = countries.map((countrie) => ({
    value: parseInt(countrie.id, 0),
    label: countrie.name,
  }))

  const textButton =
    isRecycler || isConverter || isRecyclerAndConverter
      ? t("Next")
      : t("Finish registration")

  const getSelectedMultiValues = (values: Array<{ value: number }>) =>
    values.map((value) => value.value)

  const submitPostCompany = useCallback(
    (
      fields: {
        companyPolymer: Array<{ value: number }>
        companyRole: Nullable<BaseSelectedValue<number>>
      },
      selectedCountry: string,
    ) => {
      const preaprePolymersToSend =
        fields.companyPolymer.length > 0 ? fields.companyPolymer : []

      postCompany({
        ...fields,
        ...{ country: selectedCountry || 0 },
        ...{ mosa: [] },
        ...{ polymers: getSelectedMultiValues(preaprePolymersToSend) },
        ...{ roleId: fields.companyRole?.value },
      }).then((response) => {
        if (response.errors) {
          handleToast(response.errors, "error")
          setLoading(false)
        } else {
          handleToast(["Company added"])
          dispatch(refreshToken())
          dispatch(fetchUser())
          if (isRecycler || isConverter || isRecyclerAndConverter) {
            handleStepChange(1)
          } else {
            handleFinishRegistration()
          }
        }
      })
    },
    [
      dispatch,
      handleFinishRegistration,
      handleStepChange,
      isRecycler,
      isConverter,
      isRecyclerAndConverter,
    ],
  )

  const onSelectChangeRole = (selectedOption: SelectValue) => {
    setFieldValue("companyRole", selectedOption)
  }

  const onSelectChangePolymer = (selectedOption: SelectValue) => {
    setFieldValue("companyPolymer", selectedOption)
  }

  const [vatErrorModalOpen, setVatErrorModalOpen] = useState(false)
  const vatErrorModal = () => (
    <Modal
      isOpen={vatErrorModalOpen}
      isOpenHandler={setVatErrorModalOpen}
      wrapperClass="srs-modal-lg"
    >
      <div className="px-5 py-3">
        <h2 className="pb-3">{t("Your VAT number couldn't be validated")}</h2>
        <p>
          {t(
            "RecoTrace uses the VIES VAT validation service provided by the European Commission",
          )}
          .
        </p>
        <p>
          {t("Please try again at")}{" "}
          <a
            href="https://ec.europa.eu/taxation_customs/vies/vatRequest.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/taxation_customs/vies/vatRequest.html
          </a>{" "}
          {t("for more information")}.
        </p>
      </div>
    </Modal>
  )

  const handleSubmit = useCallback(
    (credentials: CreateCompanyInput) => {
      if (!loading) {
        console.log({ credentials })
        setLoading(true)
        const { selectedCountry, ...rest } = credentials

        const selectedCountryId = selectedCountry?.value
        if (selectedCountryId) {
          console.log({ selectedCountry })
          const selectedCountryFull = countries.find(
            (c) => parseInt(c.id, 10) === selectedCountryId,
          )
          console.log(selectedCountryFull)

          if (selectedCountryFull) {
            console.log({ EuCountriesArray, selectedCountryFull })
            if (EuCountriesArray.includes(selectedCountryFull.code)) {
              console.log("checking vat")
              checkCompanyVatNumber({
                countryCode: selectedCountryFull.code,
                vatNumber: rest.vatNumber,
              }).then(({ data, errors }) => {
                if (errors) {
                  setVatErrorModalOpen(true)
                  // handleToast(errors, 'error');
                  setLoading(false)
                } else if (data) {
                  const response = data as CompanyVatCheck
                  if (response.valid) {
                    submitPostCompany(rest, selectedCountryFull.id)
                  } else {
                    setVatErrorModalOpen(true)
                    setLoading(false)
                  }
                }
              })
            } else {
              console.log("submit post")
              submitPostCompany(rest, selectedCountryFull.id)
            }
          }
        }
      }
    },
    [countries, loading, submitPostCompany],
  )

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  const formik = useFormik({
    initialValues: {
      ...registerService.companyData,
      agreeTerms: false,
    },
    validationSchema: RegisterCompanyFormValidationShema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors, setFieldValue } = formik

  useEffect(() => {
    registerService.companyData = values
  }, [values])

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCountry", selectedOption)
  }

  const onAllowDisplayChange = (newValue: boolean) => {
    setFieldValue("allowDisplayOnWebsite", newValue)
  }

  const prepareOptionsForCompanyRegister =
    isRecyclerAndConverter || isRecycler || isConverter
      ? [USER_ROLES_LIST[0], USER_ROLES_LIST[1], USER_ROLES_LIST[2]]
      : [
          USER_ROLES_LIST[0],
          USER_ROLES_LIST[1],
          USER_ROLES_LIST[2],
          USER_ROLES_LIST[3],
        ]

  return (
    <>
      <form className="mb-5" onSubmit={formik.handleSubmit}>
        <h2 className="h4 mb-3">{t("Company data")}</h2>
        <Select
          name="selectedCountry"
          label={`${t("Country")} (*)`}
          options={COUNTRIES}
          value={values.selectedCountry}
          handleOnChange={onSelectChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.selectedCountry && errors.selectedCountry}
        />

        <div className="form-group row align-items-center">
          <label htmlFor="name" className="col-sm-4 col-form-label">
            {`${t("VAT without country code and dots")} (*)`}
          </label>
          <div className="col-sm-8">
            <input
              id="vatNumber"
              className="form-control"
              type="text"
              name="vatNumber"
              value={values.vatNumber}
              onChange={formik.handleChange}
            />
            <FormError error={touched.vatNumber && errors.vatNumber} />
          </div>
        </div>

        <div className="form-group row align-items-center">
          <label htmlFor="name" className="col-sm-4 col-form-label">
            {`${t("Company Name")} (*) `}
            <span
              className="text-primary"
              data-tip="A company may include one or more production plants."
            >
              (i)
            </span>
          </label>
          <div className="col-sm-8">
            <input
              id="name"
              className="form-control"
              type="text"
              name="name"
              value={values.name}
              onChange={formik.handleChange}
            />
            <FormError error={touched.name && errors.name} />
            <ReactTooltip />
          </div>
        </div>
        <Select
          name="companyRole"
          label={`${t("Activity")} (*)`}
          options={prepareOptionsForCompanyRegister}
          value={values.companyRole}
          handleOnChange={onSelectChangeRole}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.companyRole && errors.companyRole}
        />

        {values.companyRole?.value === USER_ROLES_LIST[0].value ||
        values.companyRole?.value === USER_ROLES_LIST[1].value ||
        values.companyRole?.value === USER_ROLES_LIST[2].value ? (
          <div className="srs-select form-group row align-items-center">
            <label className="col-sm-4 col-form-label">{`${t(
              "Polymer(s) processed (*)",
            )}`}</label>
            <div className="multi-select-wrapper col-sm-8">
              <MultiSelect
                options={mapToOptions(materialGroups)}
                value={values.companyPolymer}
                onChange={onSelectChangePolymer}
                labelledBy="companyPolymer"
                disableSearch
              />
              {touched.companyPolymer && values.companyPolymer.length === 0 ? (
                <small className="form-text text-danger">
                  Field of Polymer(s) processed is required.
                </small>
              ) : null}
            </div>
          </div>
        ) : null}
        <Input
          label={`${t("Address")} (*)`}
          name="address"
          value={values.address}
          handleOnChange={formik.handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.address && errors.address}
        />
        {(values.companyRole?.value === USER_ROLES_LIST[1].value ||
          values.companyRole?.value === USER_ROLES_LIST[0].value ||
          values.companyRole?.value === USER_ROLES_LIST[2].value) && (
          <Input
            label={t("Name of CEO")}
            name="ceoName"
            value={values.ceoName}
            handleOnChange={formik.handleChange}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.ceoName && errors.ceoName}
          />
        )}
        {(values.companyRole?.value === USER_ROLES_LIST[1].value ||
          values.companyRole?.value === USER_ROLES_LIST[0].value ||
          values.companyRole?.value === USER_ROLES_LIST[2].value) && (
          <Input
            label={t("Email of CEO")}
            type="email"
            name="ceoEmail"
            value={values.ceoEmail}
            handleOnChange={formik.handleChange}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.ceoEmail && errors.ceoEmail}
          />
        )}
        {(values.companyRole?.value === USER_ROLES_LIST[1].value ||
          values.companyRole?.value === USER_ROLES_LIST[0].value ||
          values.companyRole?.value === USER_ROLES_LIST[2].value) && (
          <Input
            label={t("Company Website")}
            name="website"
            value={values.website}
            handleOnChange={formik.handleChange}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.website && errors.website}
          />
        )}
        {(values.companyRole?.value === USER_ROLES_LIST[1].value ||
          values.companyRole?.value === USER_ROLES_LIST[0].value ||
          values.companyRole?.value === USER_ROLES_LIST[2].value) && (
          <Checkbox
            label={t(
              "Company name and website can appear on the PolyREC and Recovinyl websites",
            )}
            name="allowDisplayOnWebsite"
            value={values.allowDisplayOnWebsite}
            handleOnChange={() =>
              onAllowDisplayChange(!values.allowDisplayOnWebsite)
            }
          />
        )}
        <Checkbox
          label={
            <span>
              {t("I have read, fully understood and agree to the PolyREC's")}{" "}
              <a href="https://www.polyrec.eu/terms">
                {t("terms and conditions")}
              </a>
            </span>
          }
          name="agreeTerms"
          error={touched.agreeTerms && errors.agreeTerms}
          handleOnChange={(e) => setFieldValue("agreeTerms", e.target.checked)}
        />
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
            disabled={loading}
          >
            {t(textButton)} <i className="fas fa-arrow-right ml-3" />
          </button>
        </div>
      </form>
      {vatErrorModal()}
    </>
  )
}

export default FinishCompanyForm
