import { useFormik } from "formik"
import React, { useEffect, useState, useCallback } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch, Link } from "react-router-dom"

import FormError from "components/FormError/FormError"
import Label from "components/Label/Label"
import Select, {
  SelectValue,
  mapToOptions,
  BaseSelectedValue,
} from "components/Select/Select"

import { handleToast } from "utils/messages"

import useStateGetter from "hooks/useStateGetter"

import { getPlant } from "../../../companies/service/api"
import { Company } from "../../../companies/types"
import { fetchSubmissions } from "../../../submissions/actions"
import { Submission } from "../../../submissions/types"
import { AUDIT_DATE_FORMAT, auditTypes } from "../../constants"
import { getAudits } from "../../service/api"
import { Audit, AuditStatus } from "../../types"

const AnnualPercentage = () => {
  const { t } = useTranslation()
  const { params }: { params: { id: string } } = useRouteMatch()
  const { id } = params
  const [plant, setPlant] = useState<Company>()
  const [audits, setAudits] = useState<Audit[]>()
  const companyId = parseInt(id)

  const getAuditsList = () => {
    getAudits({ companyId }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }
      if (data) {
        setAudits(data)
      }
    })
  }

  useEffect(() => {
    if (id) {
      getPlant({ id: parseInt(id, 0) }).then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }
        if (data) {
          setPlant(data)
        }
        getAuditsList()
      })
    }
  }, [getAuditsList, id])

  const generateRowToRender = (preparedAuditData: any[]) =>
    preparedAuditData.map((aud) => (
      <tr>
        <td className="align-middle">{aud.createdAt}</td>
        <td className="align-middle table-gray">{aud.allAuditsPerYer}</td>
        <td className="align-middle table-gray">{aud.finished}</td>
        <td className="align-middle table-gray">{`${Math.round(
          (aud.finished / aud.allAuditsPerYer) * 100,
        )} %`}</td>
      </tr>
    ))

  const prepareAuditTableData = () => {
    const data: any[] = []
    let restAudits: any = []

    if (audits) {
      if (audits.length > 0) {
        const firstDate = audits.reduce((r, o) =>
          o.createdAt < r.createdAt ? o : r,
        )

        restAudits = audits.filter((sub) => sub.id !== firstDate.id)

        data.push({
          createdAt: new Date(firstDate.createdAt).getFullYear(),
          finished: firstDate.status === AuditStatus.FINISHED ? 1 : 0,
          allAuditsPerYer: 1,
        })

        for (let i = 0; i < restAudits.length; i++) {
          const checkIfAuditDateExist = data.some(
            (d) =>
              d.createdAt === new Date(restAudits[i].createdAt).getFullYear(),
          )

          if (checkIfAuditDateExist) {
            data.map((d) =>
              d.createdAt === new Date(restAudits[i].createdAt).getFullYear()
                ? {
                    ...d,
                    finished:
                      restAudits[i].status === AuditStatus.FINISHED
                        ? (d.finished += 1)
                        : d.finished,
                    allAuditsPerYer: (d.allAuditsPerYer += 1),
                  }
                : d,
            )
          } else {
            data.push({
              createdAt: new Date(restAudits[i].createdAt).getFullYear(),
              finished: restAudits[i].status === AuditStatus.FINISHED ? 1 : 0,
              allAuditsPerYer: 1,
            })
          }
        }
      }
      return data.sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0,
      )
    }
  }

  const auditTableData = prepareAuditTableData()

  return auditTableData ? (
    <>
      <h1 className="mb-5">
        {t("Annual percentage of")} <strong>{plant?.name}</strong>
      </h1>
      <div className="row">
        {auditTableData.length > 0 ? (
          <div className="col-12 mb-5">
            <div className="row">
              <table className="table table-light table-bordered text-center">
                <thead>
                  <tr>
                    <th className="align-middle col-3">{t("Year")}</th>
                    <th className="align-middle col-3">
                      {t("All audits per year")}
                    </th>
                    <th className="align-middle col-3">
                      {t("Finished audits")}
                    </th>
                    <th className="align-middle col-3">
                      {t("Annual precentage")}
                    </th>
                  </tr>
                </thead>
                <tbody>{generateRowToRender(auditTableData)}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="col-12 mb-5">
            {t("You do not have any finished audits")}
          </div>
        )}
      </div>
    </>
  ) : null
}

export default AnnualPercentage
