import { restHelper } from "../../@common/service/api"
import { DeleteMaterialInput } from "../../materials/service/api"
import { Country } from "../types"

export interface PostCountriesInput {
  id?: number
  code: string
  name: string
  isEu: boolean
  visible?: boolean
}

export type PutCountriesInput = PostCountriesInput

export interface DeleteCountriesInput {
  id: number
}

const queries = {
  countries: `
    query {
      countries {
        id
        code
        name
        isEu
      }
    }
  `,
}

const mutations = {
  countryCreate: `
    mutation countryCreate(
      $code: String!
      $name: String!
      $isEu: Boolean!
      $visible: Boolean
    ) {
      countryCreate(
        code: $code
        name: $name
        isEu: $isEu
        visible: $visible
      ) {
        id
        code
        name
        isEu
      }
    }
  `,
  countryUpdate: `
    mutation countryUpdate(
      $id: Int!
      $code: String!
      $name: String!
      $isEu: Boolean!
      $visible: Boolean
    ) {
      countryUpdate(
        id: $id
        code: $code
        name: $name
        isEu: $isEu
        visible: $visible
      ) {
        id
        code
        name
        isEu
      }
    }
  `,
  countryDelete: `
    mutation countryDelete(
      $id: Int!
    ) {
      countryDelete(
        id: $id
      )
    }
  `,
}

export const getCountries = restHelper<Country[], object>(queries, "countries")

export const postCountries = restHelper<Country, PostCountriesInput>(
  mutations,
  "countryCreate",
)

export const putCountries = restHelper<Country, PutCountriesInput>(
  mutations,
  "countryUpdate",
)

export const deleteCountry = restHelper<boolean, DeleteMaterialInput>(
  mutations,
  "countryDelete",
)
