import * as R from "ramda"

import { Company } from "../types"

export const createMap = (path: string[], data: Array<Company>) => {
  const map = new Map<string, Array<Company>>()
  data.forEach((item) => {
    const mapKey: string = R.path(path, item) || ""
    if (map.has(mapKey)) {
      const array = map.get(mapKey) || []
      array.push(item)
    } else {
      map.set(mapKey, [item])
    }
  })
  return map
}

export const createSortedArray = (
  data: string[],
  sortDirection = "ascending",
) =>
  data.sort((a: string, b: string) => {
    let result = 1

    if (a.toUpperCase() < b.toUpperCase()) {
      result = sortDirection === "ascending" ? -1 : 1
    } else {
      result = sortDirection === "ascending" ? 1 : -1
    }

    return result
  })

export const sortCompanies = (data: Company[], sortDirection = "ascending") =>
  data.sort((a: Company, b: Company) => {
    let result = 1
    const plantNameA = a.name.toUpperCase()
    const plantNameB = b.name.toUpperCase()
    if (plantNameA < plantNameB) {
      result = sortDirection === "ascending" ? -1 : 1
    } else {
      result = sortDirection === "ascending" ? 1 : -1
    }
    return result
  })

export const sortByCompanyName = (
  dataToSort: Company[],
  sortDirection: string,
) => {
  const companiesMap = createMap(["parent", "name"], dataToSort)
  const parentCompanyNames = createSortedArray(
    [...companiesMap.keys()],
    sortDirection,
  )

  let sortedPlants: Company[] = []
  parentCompanyNames.forEach((name) => {
    sortedPlants = [
      ...sortedPlants,
      ...sortCompanies(companiesMap.get(name) || [], "ascending"),
    ]
  })
  return sortedPlants
}

export const sortByCountry = (dataToSort: Company[], sortDirection: string) => {
  const companiesMap = createMap(["country", "name"], dataToSort)
  const countryNames = createSortedArray(
    [...companiesMap.keys()],
    sortDirection,
  )

  let sortedPlants: Company[] = []
  countryNames.forEach((countryName) => {
    sortedPlants = [
      ...sortedPlants,
      ...sortCompanies(companiesMap.get(countryName) || [], "ascending"),
    ]
  })
  return sortedPlants
}
