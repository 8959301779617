import React, { ChangeEvent } from "react"
import ReactTooltip from "react-tooltip"

import FormError from "../FormError/FormError"
import Label from "../Label/Label"
import "./Input.scss"

export interface InputProps {
  type?: string
  name: string
  label?: Nullable<string> | JSX.Element
  value?: string | number | undefined
  placeholder?: string | undefined
  min?: string
  max?: string
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  wrapperClassName?: string
  inputWrapperClassName?: string
  labelClassName?: string
  inputStyle?: { [key: string]: string }
  error?: string | boolean
  right?: boolean
  readOnly?: boolean
  tooltip?: string
  infoTooltip?: string | boolean
  isRequired?: boolean
  maxLength?: number | undefined
  autocomplete?: string
}

const Input: React.FC<InputProps> = ({
  type = "text",
  name,
  label = null,
  value,
  placeholder,
  min,
  max,
  handleOnChange = () => {},
  isDisabled = false,
  wrapperClassName = "",
  inputWrapperClassName = "col-sm-12",
  labelClassName = "col-sm-12",
  inputStyle = {},
  error,
  right = false,
  tooltip,
  readOnly = false,
  infoTooltip = false,
  isRequired = false,
  maxLength,
  autocomplete = "",
}) => {
  return (
    <div className={`form-group row align-items-center ${wrapperClassName}`}>
      {label !== null && !right && !infoTooltip && (
        <Label
          label={!isRequired ? label : `${label} (*)`}
          name={name}
          labelClassName={labelClassName}
        />
      )}
      {label !== null && !right && infoTooltip && (
        <div className={`${labelClassName} col-form-label`}>
          <div>
            {label}
            {isRequired && <span>{" (*)"}</span>}
            <span data-tip={infoTooltip}>{" (i)"}</span>
          </div>
        </div>
      )}
      <div className={inputWrapperClassName}>
        <input
          id={name}
          className="form-control"
          style={inputStyle}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          min={min}
          max={max}
          maxLength={maxLength}
          onChange={handleOnChange}
          onFocus={(event) => {
            const { value: targetValue } = event.target
            if (typeof targetValue === "string" && targetValue === "0") {
              event.target.select()
            }
          }}
          disabled={isDisabled}
          data-tip={tooltip}
          readOnly={readOnly}
          // @ts-ignore
          autoComplete={autocomplete}
        />
        <FormError error={error} />
        {(tooltip || infoTooltip) && <ReactTooltip />}
      </div>
      {label !== null && right && (
        <Label label={label} name={name} labelClassName={labelClassName} />
      )}
    </div>
  )
}

export default Input
