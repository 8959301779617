import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { setTokensAction } from "../../modules/auth/actions"
import { userBackToAdminAccount } from "../../modules/users/service/api"
import { windowStorage } from "../../utils/windowStorage"

const BackToAccountButton = () => {
  const dispatch = useDispatch()
  const token = windowStorage.get("returnToken")

  const handleReturnToAdminAccount = () => {
    userBackToAdminAccount({ returnToken: token }).then((r) => {
      const { data } = r

      if (data) {
        dispatch(
          setTokensAction({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          }),
        )
      }
      windowStorage.clear(false)
      windowStorage.remove("emulate")
      windowStorage.remove("returnToken")
      window.location.reload()
    })
  }
  const { t } = useTranslation()

  return (
    <button
      type="button"
      className="btn btn-warning ml-12 srs-dropdown-btn srs-dropdown-logout"
      onClick={() => {
        handleReturnToAdminAccount()
      }}
    >
      {t("Revert impersonation")}
    </button>
  )
}

export default BackToAccountButton
