import { useFormik } from "formik"
import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import Input from "components/Input/Input"
import Select, {
  SelectValue,
  BaseSelectedValue,
  mapToOptions,
} from "components/Select/Select"
import Tree, { BranchActionHandler } from "components/Tree/Tree"

import { fetchCompanies } from "../../../companies/actions"
import { Company } from "../../../companies/types"
import { Product } from "../../../products/types"
import {
  fetchGenericCompositions,
  createGenericComposition,
} from "../../actions"
import { CompositionType } from "../../enums"
import { GenericComposition } from "../../types"
import { traverseToFindPath } from "../../utils"
import "./GenericCompositionForm.scss"
import GenericCompositionFormTreeBranch from "./GenericCompositionFormTreeBranch"
import GenericCompositionFormTreeContext from "./GenericCompositionFormTreeContext"
import GenericCompositionFormValidationShema from "./GenericCompositionFormValidationShema"

interface Props {
  products: Product[]
  plants: Company[]
  type: CompositionType
  initialComposition?: GenericComposition
  selectedCompany?: Company
}

const GenericCompositionForm: React.FC<Props> = ({
  products,
  plants,
  type,
  initialComposition,
  selectedCompany,
}: Props) => {
  const [checkedTreeBranchInput, setCheckedTreeBranchInput] = useState(0)
  const [isPlantDisable, setIsSetPlantDisable] = useState<boolean>(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const isInitialComposition = !!initialComposition
  const plantsOptions = mapToOptions(plants ?? [])

  const handleOnSubmit = async ({
    selectedCompany,
    productName,
    productId,
  }: {
    selectedCompany: Nullable<BaseSelectedValue<number>>
    productName: string
    productId: number
  }) => {
    if (selectedCompany?.value) {
      await dispatch(
        createGenericComposition({
          productId,
          productName,
          companyId: selectedCompany?.value,
          type: CompositionType.USED_IN,
        }),
      )

      if (history.length > 2) {
        history.goBack()
      } else {
        history.replace(`/${type}`)
      }
    }
  }

  const fetchSelectedCompany = useCallback(() => {
    if (selectedCompany) {
      return {
        label: selectedCompany.name,
        value: selectedCompany.id,
      }
    }

    return null
  }, [])

  useEffect(() => {
    if (fetchSelectedCompany()) {
      setIsSetPlantDisable(true)
    }
  }, [fetchSelectedCompany])

  const formik = useFormik({
    initialValues: {
      selectedCompany: fetchSelectedCompany(),
      productName: "",
      productId: 0,
    },
    validationSchema: GenericCompositionFormValidationShema,
    onSubmit: handleOnSubmit,
  })

  const { values, errors, setFieldValue, touched, handleSubmit } = formik

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCompany", selectedOption)
  }

  const handleCompositionFormTreeBranchAction: BranchActionHandler = (
    { id },
    actionName,
  ) => {
    if (actionName === "onRemoveCheck") {
      setFieldValue("productId", null)
      setFieldValue("productName", ``)
      setCheckedTreeBranchInput(0)
      return
    }

    if (actionName === "onProductCheck") {
      const result = traverseToFindPath<Product>(products, id)
      setFieldValue("productId", id)
      setFieldValue("productName", result.title)
      setCheckedTreeBranchInput(id)
    }
  }

  useEffect(() => {
    if (plants.length === 1) {
      setFieldValue("selectedCompany", {
        label: plants[0].name,
        value: plants[0].id,
      })
    }
  }, [plants, setFieldValue])

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchGenericCompositions(type))
  }, [dispatch, type])

  useEffect(() => {
    if (initialComposition?.id) {
      const { company, productName, productId } = initialComposition
      if (company) {
        setFieldValue("selectedCompany", {
          label: company.name,
          value: company.id,
        })
      }

      setFieldValue("productId", productId)
      setFieldValue("productName", productName)
    }
  }, [initialComposition, setFieldValue])

  return (
    <div className="srs-composition-form">
      <form className="mb-5" onSubmit={handleSubmit}>
        <Select
          name="selectedCompany"
          label={t("Plant")}
          options={plantsOptions}
          value={values.selectedCompany}
          handleOnChange={onSelectChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.selectedCompany && errors.selectedCompany}
          isDisabled={isPlantDisable}
        />
        <Input
          label={t("Used In")}
          name="productName"
          value={values.productName}
          handleOnChange={formik.handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.productName && errors.productName}
          isDisabled
        />
        <hr />
        <h4>
          <strong>{t("Recycled plastic used in")}</strong>
        </h4>
        <div className="srs-tree-line-container mb-5">
          <GenericCompositionFormTreeContext.Provider
            value={{ checkedTreeBranchInput }}
          >
            <Tree
              nodes={products}
              Branch={GenericCompositionFormTreeBranch}
              branchActionHandler={handleCompositionFormTreeBranchAction}
            />
          </GenericCompositionFormTreeContext.Provider>
        </div>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-primary rounded-bottom-left"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left mr-2" /> {t("Back")}
          </button>
          {!isInitialComposition && (
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right"
              disabled={!values.productName}
            >
              {t("Submit")}
              <i className="fas fa-arrow-right ml-3" />
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default GenericCompositionForm
