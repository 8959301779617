import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"

import { TreeNode } from "../../../components/Tree/Tree"
import useStateGetter from "../../../hooks/useStateGetter"
import { fetchPlants } from "../../companies/actions"
import { fetchProducts } from "../../products/actions"
import { fetchGenericComposition } from "../actions"
import GenericCompositionForm from "../components/GenericCompositionForm/GenericCompositionForm"
import { CompositionType } from "../enums"

interface Props {
  type: CompositionType
}

const GenericCompositionDetailContainer: React.FC<Props> = ({ type }) => {
  const dispatch = useDispatch()
  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params

  const currentComposition = useStateGetter<any>([
    "genericComposition",
    String(type),
    "item",
  ])

  const plants = useStateGetter<any>(["company", "plants"])

  const products = useStateGetter<TreeNode[]>(["product", "products"]) ?? []

  const selectedCompany = useStateGetter<any>(["submission", "selectedCompany"])

  const allowedPolymers = selectedCompany.polymers.map(({ name }: any) => name.toLowerCase())
  const filteredProducts = products.filter((product) =>
    allowedPolymers.includes(product.title.toLowerCase()),
  )

  useEffect(() => {
    dispatch(fetchGenericComposition(type, Number(id)))
  }, [dispatch, id, type])

  useEffect(() => {
    dispatch(fetchProducts(true))
    dispatch(fetchPlants())
  }, [dispatch])

  return (
    <div>
      <h1>{currentComposition.productName}</h1>
      <GenericCompositionForm
        products={filteredProducts}
        plants={plants}
        type={type}
        selectedCompany={selectedCompany}
        initialComposition={currentComposition}
      />
    </div>
  )
}

export default GenericCompositionDetailContainer
