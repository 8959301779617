import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import useStateGetter from "../../../hooks/useStateGetter"
import { handleToast } from "../../../utils/messages"
import { Company, CompanyRole } from "../../companies/types"
import { fetchGenericCompositions } from "../actions"
import Select, {
  SelectValue,
  BaseSelectedValue,
  mapToOptions,
} from "components/Select/Select"
import GenericCompositionList from "../components/GenericCompositionList/GenericCompositionList"
import { CompositionType } from "../enums"
import { archiveGenericComposition } from "../service/api"
import { GenericComposition } from "../types"
import { filterCompanyCompositions } from "../utils"
import { getPlants } from "modules/companies/service/api"
import { setSelectedCompany } from "modules/submissions/actions"

interface Props {
  type: CompositionType
}

const GenericCompositionListContainer: React.FC<Props> = ({ type }: any) => {
  const dispatch = useDispatch()
  const [companies, setCompanies] = useState<Company[]>()
  const [currentPlant, setCurrentPlant] = useState<Company>() 

  const compositions =
    useStateGetter<GenericComposition[]>([
      "genericComposition",
      String(type),
      "list",
    ]) || []

  const selectedCompany = useStateGetter<Company>([
    "submission",
    "selectedCompany",
  ])

  const companiesOptions = React.useMemo(
    () => mapToOptions(companies ?? []),
    [companies],
  )

  const companyCompositions = filterCompanyCompositions<GenericComposition>(
    compositions,
    selectedCompany,
  )

  useEffect(() => {
    dispatch(fetchGenericCompositions(type, selectedCompany?.id))
  }, [dispatch, selectedCompany?.id, type])

  const handleArchiveComposition = async (id: number) => {
    const { errors } = await archiveGenericComposition({ id })
    if (errors) {
      handleToast(errors, "error")
    } else {
      handleToast(["Composition archived"])
      dispatch(fetchGenericCompositions(type, selectedCompany?.id))
    }
  }

  const onSelectChange = (selectedOption: any) => {
    const fullCompany = companies?.find(
      (c) => c.id === selectedOption.value,
    )
    dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
    setCurrentPlant(fullCompany)
  }

  useEffect(() => {
    if (selectedCompany && selectedCompany.id !== currentPlant?.id) {
      setCurrentPlant(selectedCompany)
    }
  }, [selectedCompany])

  useEffect(() => {
    getPlants({}).then(({ data, errors }) => {
      if (data) {
        const converterPlants = data.filter((company) =>
          [CompanyRole.CONVERTER, CompanyRole.RECYCLER_AND_CONVERTER].includes(
            company.roleId,
          )
        )
        setCompanies(converterPlants)

        if (data.length === 1) {
          dispatch(setSelectedCompany({ selectedCompany: data[0] }))
          setCurrentPlant(data[0])
        }
      }
    })
  }, [])

  const { t } = useTranslation()

  return (
    <div>
      <Select
          name="selectedCompany"
          label={t("Plant")}
          options={companiesOptions}
          value={currentPlant && {
            label: currentPlant.name,
            value: currentPlant.id,
          }}
          handleOnChange={onSelectChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={false}
          isDisabled={false}
        />
      <GenericCompositionList
        compositions={companyCompositions}
        handleArchiveComposition={handleArchiveComposition}
        type={type}
      />
    </div>
  )
}

export default GenericCompositionListContainer
