export const generateCustomString = (from = 2, to = 9): string =>
  Math.random().toString(36).substr(from, to)

export const slugify = (str: string): string => {
  let sStr = str.replace(/^\s+|\s+$/g, "")

  // Make the string lowercase
  sStr = sStr.toLowerCase()

  // Remove accents, swap ñ for n, etc
  const from =
    "ĄÁÄÂÀÃÅČÇĆĎĘÉĚËÈÊẼĔȆÍÌÎÏŁŃŇÑÓÖÒÔÕØŘŔŚŠŤÚŮÜÙÛÝŸŻŹŽąáäâàãåčçćďęéěëèêẽĕȇíìîïłńňñóöòôõøðřŕśšťúůüùûýÿżźžþÞĐđßÆa·/_,:;"
  const to =
    "AAAAAAACCCDEEEEEEEEEIIIILNNNOOOOOORRSSTUUUUUYYZZZaaaaaaacccdeeeeeeeeeiiiilnnnooooooorrsstuuuuuyyzzzbBDdBAa------"
  for (let i = 0, l = from.length; i < l; i += 1) {
    sStr = sStr.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  // Remove invalid chars
  sStr = sStr
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-")

  return sStr
}

export const truncateString = (str: string, num = 20) => {
  if (str.length <= num) {
    return str
  }
  return `${str.slice(0, num)}...`
}

export const cleanStringLikeNumber = (value: number | string) => {
  const stringVolume = value.toString() as string
  const cleanVolume = stringVolume.replace(",", ".")

  return cleanVolume
}

/**
 * Returns flag classname by country code
 * @param countryCode string
 * @returns
 */
export const getCountryFlagClassName = (countryCode = "") =>
  `flag flag-${countryCode.toLowerCase()}`
