import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { handleToast } from "../../utils/messages"
import { actionHandler } from "../@common/actions"
import { FETCH_COUNTRIES } from "./constants"
import {
  getCountries,
  postCountries,
  putCountries,
  deleteCountry,
  PostCountriesInput,
  PutCountriesInput,
  DeleteCountriesInput,
} from "./service/api"
import { Country } from "./types"

export interface SetCountries {
  type: string
  payload: { countries: Country[] }
}

export const setCountries = (payload: {
  countries: Country[]
}): SetCountries => ({
  type: FETCH_COUNTRIES,
  payload,
})

export const fetchCountries = () => async (dispatch: Dispatch) => {
  actionHandler(getCountries(), (countries) => {
    dispatch(setCountries({ countries }))
  })
}

export const createCountries =
  (credentials: PostCountriesInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postCountries(credentials), () => {
      handleToast(["Country added"], "info")
      dispatch(fetchCountries())
    })
  }

export const updateCountries =
  (credentials: PutCountriesInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(putCountries(credentials), () => {
      handleToast(["Country updated"], "info")
      dispatch(fetchCountries())
    })
  }

export const removeCountry =
  (credentials: DeleteCountriesInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(deleteCountry(credentials), () => {
      handleToast(["Country archived"], "success")
      dispatch(fetchCountries())
    })
  }

export type CountryAction = SetCountries
