import { windowStorage } from "../../utils/windowStorage"
import { Company } from "../companies/types"
import { SubmissionActions } from "./actions"
import {
  FETCH_SUBMISSIONS,
  FETCH_SECTOR_SUBMISSION,
  SET_SELECTED_COMPANY,
  SELECTED_COMPANY_KEY,
  FETCH_SLECECTED_COMPANY,
} from "./constants"
import { Submission, SectorSubmission } from "./types"

export interface SubmissionState {
  submisions: Submission[]
  submissionsQty: number
  sectorSubmisions: SectorSubmission[]
  selectedCompany: Company
}

const initSelectedCompany = (): any => {
  return windowStorage.get(SELECTED_COMPANY_KEY, undefined, false)
}

const initialState: SubmissionState = {
  submisions: [],
  submissionsQty: 0,
  sectorSubmisions: [],
  selectedCompany: initSelectedCompany(),
}

const submission = (
  state: SubmissionState = initialState,
  action: SubmissionActions,
) => {
  switch (action.type) {
    case FETCH_SUBMISSIONS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_SECTOR_SUBMISSION:
      return {
        ...state,
        ...action.payload,
      }
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_SLECECTED_COMPANY:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default submission
