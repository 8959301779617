const dashRE = /-/g
const lodashRE = /_/g

export const jwtDecode = (token: string) => {
  const base64Url = token.split(".")[1]
  if (base64Url === undefined) return null
  const base64 = base64Url.replace(dashRE, "+").replace(lodashRE, "/")
  const jsonStr = Buffer.from(base64, "base64").toString()
  return JSON.parse(jsonStr)
}
