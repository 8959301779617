import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import logo from "../../assets/images/logo.svg"
import useUserFromToken from "../../hooks/useUserFromToken"
import userOnScroll from "../../hooks/userOnScroll"
import { logoutAction } from "../../modules/auth/actions"
// @ts-ignore
import Manual from "../../modules/auth/containers/howto.pdf"
import { history } from "../../utils/routes"
import LangSelect from "./LangSelect"
import ManageAccountDropdown from "./ManageAccountDropdown"
import UserSelect from "./UserSelect"

const Header = () => {
  const dispatch = useDispatch()
  // const accessToken = useStateGetter(['auth', 'accessToken']);
  const { isAdmin, email, id } = useUserFromToken()
  // const isEmulated = !!localStorage.getItem('emulate');

  function onScroll() {
    const logoElement = document.getElementById("logo")
    if (logoElement) {
      if (window.pageYOffset > 60) {
        logoElement.classList.add("small")
      } else {
        logoElement.classList.remove("small")
      }
    }
  }
  userOnScroll(onScroll)

  const logout = () => {
    dispatch(logoutAction())
    history.push("/auth/login")
    window.location.reload()
  }
  const { t } = useTranslation()

  return (
    <header className="srs-app-header bg-white sticky-top d-flex align-items-center">
      {id ? <div style={{ width: "66px" }} /> : null}
      <div className="container d-flex align-items-center justify-content-between">
        <Link
          to={{
            pathname: "/",
            state: {
              title: t("Homepage"),
            },
          }}
          style={{ textDecoration: "none" }}
        >
          <img
            src={logo}
            alt="logo"
            id="logo"
            className="transition"
            style={{ height: "50px", paddingBottom: "0", marginTop: "10px" }}
          />
          <p
            style={{
              textDecoration: "none!important",
              fontSize: "14px",
              marginTop: "5px",
              fontWeight: "500",
            }}
          >
            RECORDING & TRACING POLYMER CIRCULARITY
          </p>
        </Link>
        <div className="d-flex">
          <LangSelect />
          {isAdmin === true && email !== "" && <UserSelect />}
          {email !== "" && <ManageAccountDropdown />}
        </div>
      </div>

      {id ? (
        <Link
          to={Manual}
          target="_blank"
          className="btn btn-link"
          data-tip={t("Need help?")}
          data-for="manual-button"
        >
          <ReactTooltip id="manual-button" place="left" effect="solid" />
          <i className="fa fa-question-circle fa-2x" />
        </Link>
      ) : null}
    </header>
  )
}

export default Header
