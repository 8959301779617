import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { handleToast } from "utils/messages"

import { getRecyclersAttachedToPlant } from "../../../users/service/api"
import { User } from "../../../users/types"

interface CompanyDetailsModalPlantUsersProps {
  companyId: number
}

const CompanyDetailsModalPlantUsers = (
  props: React.PropsWithChildren<CompanyDetailsModalPlantUsersProps>,
) => {
  const { t } = useTranslation()

  const { companyId } = props

  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    getRecyclersAttachedToPlant({ id: companyId }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
        setUsers([])
      }
      if (data) {
        setUsers(data)
      }
    })
  }, [companyId])

  const userRow = (user: User) => {
    return (
      <tr key={user.id}>
        <td className="align-middle">{user.name}</td>
        <td className="align-middle">
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </td>
      </tr>
    )
  }

  return (
    <>
      <h4>Plant users:</h4>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th>{t("User name")}</th>
            <th>{t("User email")}</th>
          </tr>
        </thead>
        <tbody>{users.map((user) => userRow(user))}</tbody>
      </table>
    </>
  )
}

export default CompanyDetailsModalPlantUsers
