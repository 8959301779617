import moment from "moment/moment"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import ReactPaginate from "react-paginate"
import { useDispatch } from "react-redux"

import Select, {
  BaseSelectedValue,
  SelectValue,
} from "components/Select/Select"

import { history } from "utils/routes"
import { windowStorage } from "utils/windowStorage"

import useStateGetter from "hooks/useStateGetter"

import { ACTIVE_COMPANY_KEY } from "../../../../constants"
import { getAcceptedSubmissionsCount } from "../../../companies/service/api"
import { Company } from "../../../companies/types"
import { fetchSubmissions } from "../../actions"
import {
  SUBMISSION_DATE_FORMAT,
  PER_PAGE, // STATUS_OPTIONS,
} from "../../constants"
import { Submission } from "../../types"
import TableBody from "./TableBody"

const addNewSubmission = (
  e: React.MouseEvent<HTMLAnchorElement>,
  tab: string,
) => {
  e.preventDefault()
  history.push(`/submissions/new/${tab}`)
}

const SubmissionListTable = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [acceptedSubmissionsCount, setAcceptedSubmissionsCount] =
    useState<number>(0)

  const [tab, setTab] = useState("input")
  const [isInputData, setIsInputData] = useState(true)
  useEffect(() => {
    setIsInputData(tab === "input")
  }, [tab])

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").date(1).toDate(),
  )
  const [endDate, setEndDate] = useState(new Date())

  const [perPage, setPerPage] = useState<SelectValue>(PER_PAGE[0])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [sortByMemo, setSortByMemo] = useState<string[]>([
    "s.createdAt",
    "DESC",
  ])
  const [selectedStatus, setSelectedStatus] = useState<string>("")

  const STATUS_OPTIONS: BaseSelectedValue[] = [
    { value: "", label: t("ALL STATUSES") },
    // { value: 'CREATED', label: 'CREATED' },
    { value: "SENT", label: t("PENDING") },
    { value: "DECLINED", label: t("DECLINED") },
    { value: "ACCEPTED", label: t("ACCEPTED") },
  ]

  const submissions =
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? []
  const submissionsQty =
    useStateGetter<number>(["submission", "submissionsQty"]) ?? 0
  const selectedCompany =
    useStateGetter<Company | undefined>(["submission", "selectedCompany"]) ??
    undefined

  const selectedStatusOption = useMemo(
    () =>
      STATUS_OPTIONS.filter(
        (s: BaseSelectedValue) => s.value === selectedStatus,
      ),
    [STATUS_OPTIONS, selectedStatus],
  )

  const onSelectStatusChanged = (selectedOption: SelectValue) => {
    const option = selectedOption as BaseSelectedValue
    const value = `${option.value}`
    setCurrentPage(0)
    setSelectedStatus(value)
  }

  useEffect(() => {
    getAcceptedSubmissionsCount().then(({ data }) => {
      if (data) {
        setAcceptedSubmissionsCount(data)
      }
    })
  }, [])

  const onChangeTabClicked = (
    e: React.MouseEvent<HTMLAnchorElement>,
    value: string,
  ): boolean => {
    e.preventDefault()
    setTab(value)
    setCurrentPage(0)
    return false
  }

  const onStartDateChanged = (date: Date) => {
    setStartDate(date)
    setCurrentPage(0)
  }

  const onEndDateChanged = (date: Date) => {
    setEndDate(date)
    setCurrentPage(0)
  }

  const onPerPageChanged = (pp: SelectValue) => {
    const perPageOption = pp as { label: string; value: number }
    setPerPage(perPageOption)
    setCurrentPage(0)
  }

  const onPageChanged = ({ selected }: { selected: number }) => {
    setCurrentPage(selected)
  }

  const onSortChanged = (sortBy: string, sortDirection: string): void => {
    setSortByMemo([sortBy, sortDirection])
  }

  const setCompanyToStorage = () => {
    if (selectedCompany) {
      windowStorage.set(ACTIVE_COMPANY_KEY, selectedCompany, false)
    }
  }

  // update perPage
  useEffect(() => {
    const perPageOption = perPage as { value: number }
    setPageCount(Math.ceil(submissionsQty / perPageOption.value))
  }, [submissionsQty, perPage])

  const getSubmissions = useCallback(() => {
    if (selectedCompany) {
      const perPageOption = perPage as { label: string; value: number }
      dispatch(
        fetchSubmissions({
          companyId: selectedCompany.id,
          status: selectedStatus,
          dateStart: `${startDate.getFullYear()}/${startDate.getMonth() + 1}`,
          dateEnd: `${endDate.getFullYear()}/${endDate.getMonth() + 1}`,
          sortBy: sortByMemo[0],
          sortDirection: sortByMemo[1],
          isInput: tab === "input",
          page: currentPage,
          limit: perPageOption.value,
        }),
      )
    }
  }, [
    selectedStatus,
    dispatch,
    endDate,
    startDate,
    tab,
    currentPage,
    perPage,
    sortByMemo,
    selectedCompany,
  ])

  useEffect(() => {
    getSubmissions()
  }, [getSubmissions])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a
                href="#input"
                className={`nav-link ${tab === "input" ? "active" : ""} `}
                data-toggle="pill"
                role="tab"
                aria-controls="pills-inputData"
                aria-selected="true"
                onClick={(e) => onChangeTabClicked(e, "input")}
              >
                {t("Input")}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#output"
                className={`nav-link ${tab === "output" ? "active" : ""}`}
                data-toggle="pill"
                role="tab"
                aria-controls="pills-outputData"
                aria-selected="false"
                onClick={(e) => onChangeTabClicked(e, "output")}
              >
                {t("Output")}
              </a>
            </li>
            {acceptedSubmissionsCount ? (
              <li className="nav-item ml-auto">
                <a
                  href="#applications"
                  className="nav-link"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-outputData"
                  aria-selected="false"
                  onClick={(e) => {
                    setCompanyToStorage()
                    addNewSubmission(e, tab)
                  }}
                >
                  {t("Add new submission")}
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-2">
          <DatePicker
            name="startDate"
            className="form-control"
            selected={startDate}
            onChange={(date: Date) => onStartDateChanged(date)}
            selectsStart
            showMonthYearPicker
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            dateFormat={SUBMISSION_DATE_FORMAT}
          />
        </div>
        <div className="col-2">
          <DatePicker
            className="form-control"
            selected={endDate}
            onChange={(date: Date) => onEndDateChanged(date)}
            selectsEnd
            showMonthYearPicker
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat={SUBMISSION_DATE_FORMAT}
          />
        </div>
        <div className="row col justify-content-end">
          <div className="col-6">
            <Select
              name="status"
              options={STATUS_OPTIONS}
              value={selectedStatusOption}
              handleOnChange={(s) => onSelectStatusChanged(s)}
            />
          </div>
        </div>
      </div>
      <TableBody
        submissions={submissions}
        isInputData={isInputData}
        onSortChanged={onSortChanged}
        getSubmissions={getSubmissions}
      />

      <div className="d-flex justify-content-between align-items-end">
        <Select
          label={t("Per page limit")}
          name="perPage"
          options={PER_PAGE}
          value={perPage}
          handleOnChange={onPerPageChanged}
        />
        {pageCount > 1 && (
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={onPageChanged}
            containerClassName="pagination"
            previousClassName="page-item"
            nextClassName="page-item"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        )}
      </div>
    </>
  )
}

export default SubmissionListTable
