import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useRouteMatch } from "react-router-dom"

import { windowStorage } from "../../../utils/windowStorage"
import SubmissionConverterFormInput from "../components/SubmissionConverterForm/SubmissionConverterFormInput"
import SubmissionConverterFormOutput from "../components/SubmissionConverterForm/SubmissionConverterFormOutput"
import SubmissionForm from "../components/SubmissionForm/SubmissionForm"
import useSubmission from "../hooks/useSubmission"

const SubmissionEdit = () => {
  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const submission = useSubmission(id ? parseInt(id, 0) : 0)

  useEffect(() => {
    if (id) {
      windowStorage.set("goto", `/submissions/edit/${id}`)
    }
  }, [id])

  const { t } = useTranslation()

  const isConvertingSubmission = submission?.type !== 0
  const submissionIsInput = submission && submission?.isInput

  if (submissionIsInput === undefined) {
    return null
  }

  return (
    <>
      <h1 className="mb-5">{t("Manage submitted data")}</h1>
      {!isConvertingSubmission && (
        <SubmissionForm isEdit initialSubmission={submission} />
      )}
      {isConvertingSubmission && !submissionIsInput && (
        <SubmissionConverterFormOutput isEdit />
      )}
      {isConvertingSubmission && submissionIsInput && (
        <SubmissionConverterFormInput isEdit />
      )}
    </>
  )
}

export default SubmissionEdit
