import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import AuthGuard from "../../components/AuthGuard/AuthGuard"
import AuditorsManage from "./containers/AuditorsManage"
// TODO remove this UserAccount
// import UserAccount from './containers/UserAccount';
import UserAccount2 from "./containers/UserAccount2"
import UsersManage from "./containers/UsersManage"
import { UserRoles } from "./types"
import UserNotifications from "./containers/UserNotifications"

const Users = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Route exact path={`${path}/manage`}>
        <AuthGuard roles={[UserRoles.ADMIN]}>
          <UsersManage />
        </AuthGuard>
      </Route>
      <Route exact path={`${path}/manage/auditors`}>
        <AuthGuard roles={[UserRoles.ADMIN]}>
          <AuditorsManage />
        </AuthGuard>
      </Route>
      <Route exact path={`${path}/account`}>
        <UserAccount2 />
      </Route>
      <Route exact path={`${path}/notifications`}>
        <UserNotifications />
      </Route>
    </>
  )
}

export default Users
