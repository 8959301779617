import { restHelper } from "../../@common/service/api"
import { SectorAssociationCreate, CompanyCreate } from "../../companies/types"
import { RefreshTokenInput } from "../types"

interface History {
  push: (path: string) => void
}

export interface User {
  id: string
}

export interface RegisterInput {
  roleId: number
  email: string
  name: string
  password: string
  confirmPassword?: string
  sectorAssociation?: SectorAssociationCreate
  company?: CompanyCreate
  countryId?: number | null
  vatNumber?: string
  companyName?: string
}

export interface LoginInput {
  email: string
  password: string
}

export interface LoginResponse {
  accessToken: string
  refreshToken?: string
}

export const queries = {
  refreshToken: `
    query refreshToken($refreshToken: String!) {
      refreshToken(refreshToken: $refreshToken)
    }
  `,
}

export const mutations = {
  login: `
    mutation login(
      $email: String!
      $password: String!
    ) {
      login(
        email: $email
        password: $password
      ) {
        accessToken
        refreshToken
      }
    }
  `,
  register: `
    mutation register(
      $name: String!
      $email: String!
      $password: String!
      $roleId: Int!
      $sectorAssociation: SectorAssociationInput
      $countryId: Int
      $companyName: String
      $vatNumber: String
      $polymers: [Int]
      $mosa: [Int]
    ) {
      register(
        name: $name
        email: $email
        password: $password
        roleId: $roleId
        sectorAssociation: $sectorAssociation
        countryId: $countryId
        companyName: $companyName
        vatNumber: $vatNumber
        polymers: $polymers
        mosa: $mosa
      ) {
        id
      }
    }
  `,
  userSendChangePasswordLink: `
    mutation userSendChangePasswordLink(
      $email: String!
    ) {
      userSendChangePasswordLink(email: $email)
    }
  `,
  userConfirmPassword: `
    mutation userConfirmPassword(
      $password: String!,
      $code: String!
    ) {
      userConfirmPassword(password: $password, code: $code)
    }
  `,
  userCheckConfirmationCode: `
    mutation userCheckConfirmationCode(
      $code: String!
    ) {
      userCheckConfirmationCode(code: $code)
    }
  `,
  finishRegistration: `
    mutation finishRegistration(
      $isInput: Boolean
      $fieldsOfActivity: String
    ) {
      finishRegistration(
        isInput: $isInput
        fieldsOfActivity: $fieldsOfActivity
      )
    }
  `,
}

export const getRefreshToken = restHelper<string, RefreshTokenInput>(
  queries,
  "refreshToken",
)

export const registerRequest = restHelper<User, RegisterInput>(
  mutations,
  "register",
)

export const loginRequest = restHelper<LoginResponse, LoginInput>(
  mutations,
  "login",
)

export const sendResetPasswordLink = restHelper<boolean, object>(
  mutations,
  "userSendChangePasswordLink",
)

export const userConfirmPassword = restHelper<boolean, object>(
  mutations,
  "userConfirmPassword",
)

export const checkConfirmationCode = restHelper<boolean, object>(
  mutations,
  "userCheckConfirmationCode",
)

export const postFinishRegistration = restHelper<
  boolean,
  {
    isInput?: boolean
    fieldsOfActivity?: string
    sector?: { [key: string]: number }
    external?: boolean
  }
>(mutations, "finishRegistration")
