import React, { useEffect, useState, FormEvent } from "react"
import { useTranslation } from "react-i18next"
import CKEditor from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import * as Yup from "yup"
import { Notification } from "../types"
import useStateGetter from "hooks/useStateGetter"
import { MaterialGroup } from "modules/materials/service/api"
import { useFormik } from "formik"
import Input from "components/Input/Input"
import UploadFile from "./UploadFile";
import { history } from "utils/routes"
import { MultiSelect } from "react-multi-select-component"
import { mapToOptions } from "components/Select/Select"
import { useDispatch } from "react-redux"
import { fetchMaterialGroups } from "modules/materials/actions"

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      // 'imageUpload',
      // '|',
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
  // ckfinder: {
  //   uploadUrl: '/upload',
  // },
}

export type NotificationFormData = {
  subject: string
  content: string
  polymers: any[],
  filePath: string,
  status: string
}

interface Props {
  notificationInit?: Notification
  submitHandler: (values: any) => void
}

const NotificationForm: React.FC<Props>= ({submitHandler, notificationInit}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [publish, setPublish] = useState(false)
  const [disable, setDisable] = useState(false)
  const [initFile, setInitFile] = useState('')

  const NotificationFormValidationShema = Yup.object().shape({
    subject: Yup.string().required("Subject is required."),
    polymers: Yup.array().min(1, 'For publish any polymer required'),
  })

  const materialGroups =
    useStateGetter<MaterialGroup[]>(["material", "materialGroups"]) ?? []

  const formik = useFormik({
    initialValues: {
      subject: "",
      content: "",
      polymers: [],
      filePath: "",
      status: ""
    },
    validationSchema: NotificationFormValidationShema,
    onSubmit: async (values) => {
      setLoading(true)
      submitHandler(values)
    },
  })
  const { values, handleChange, touched, errors, setFieldValue, handleSubmit } =  formik

  useEffect(() => {
    dispatch(fetchMaterialGroups())
  }, [dispatch])

  useEffect(() => {
    if (notificationInit) {
      setFieldValue('subject', notificationInit.subject)
      setFieldValue('content', notificationInit.content)
      setFieldValue('filePath', notificationInit.filePath)
      const parsedFilePath = notificationInit.filePath.split('/')
      if (parsedFilePath.length > 0) {
        setInitFile(parsedFilePath[parsedFilePath.length-1])
      }
      setFieldValue('status', notificationInit.status)
      if (['REJECT', 'PUBLISH'].includes(notificationInit.status)) {
        setDisable(true)
      }
      if (materialGroups) {
        const selectedPolymers = materialGroups
        .filter(mg => notificationInit.polymers.includes(mg.id))
        .map(mg => ({
          label: mg.name,
          value: mg.id,
        }))
        setFieldValue('polymers', selectedPolymers)
      }
    }
  }, [notificationInit, materialGroups])

  const setFilePath = (path: string) => {
    setFieldValue('filePath', path)
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Input
          label="Subject"
          name="subject"
          value={values.subject}
          handleOnChange={(e) => setFieldValue('subject', e.target.value)}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          isDisabled={disable}
          error={touched.subject && errors.subject}
        />
        <div className="form-group row align-items-center">
          <label className="col-sm-4">Notification content</label>
          <div className="col-sm-8">
            <CKEditor
              editor={ClassicEditor}
              data={values.content}
              isDisabled={disable}
              config={editorConfiguration}
              onChange={(_: any, editor: any) => {
                const data = editor.getData()
                setFieldValue('content', data)
              }}
            />
          </div>
        </div>
        <div className="srs-select form-group row align-items-center">
          <label className="col-sm-4 col-form-label">{`${t(
            "Polymer(s) processed",
          )} (*)`}</label>
          <div className="multi-select-wrapper col-sm-8">
            <MultiSelect
              options={mapToOptions(materialGroups)}
              value={values.polymers}
              onChange={(selectedOptions: any) => setFieldValue("polymers", selectedOptions)}
              labelledBy="companyPolymer"
              disabled={disable}
              disableSearch
            />
            {touched.polymers && values.polymers.length === 0 && publish ? (
              <small className="form-text text-danger">
                Field of Polymer(s) processed is required.
                {errors.polymers}
              </small>
            ) : null}
          </div>
        </div>
        {!disable && (
          <div className="form-group row align-items-center">
            <UploadFile setFilePath={setFilePath} initFile={initFile}></UploadFile>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-primary rounded-bottom-left"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left mr-2" /> Back
          </button>
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
            disabled={loading || disable}
            onClick={() => {
              setFieldValue("status", 'SAVE')
            }}
          >
            Save
            <i className="fas fa-arrow-right ml-3" />
          </button>
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
            disabled={loading || disable}
            onClick={() => {
              setFieldValue("status", 'PUBLISH')
              setPublish(true)
            }}
          >
            Publish
            <i className="fas fa-arrow-right ml-3" />
          </button>
        </div>
      </form>
    </div>
  )
}

export default NotificationForm