import { handleToast } from "../../utils/messages"

// eslint-disable-next-line import/prefer-default-export
export const actionHandler = async (
  service: Promise<any>,
  onSuccess: (data: any) => void,
) => {
  const { data, errors } = await service
  if (errors) {
    handleToast(errors, "error")
    throw errors
  }

  if (data) {
    onSuccess(data)
  }
}
