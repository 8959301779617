import * as Yup from "yup"

const SubmissionFormValidationShema = Yup.object().shape({
  company: Yup.object().typeError("Company is required."),
  country: Yup.object().typeError("Country is required."),
  date: Yup.string()
    .typeError("Need month Received.")
    .required("Month is required."),
  composition: Yup.object()
    .typeError("Waste name is required.")
    .required("Waste name is required."),
  volume: Yup.number()
    .typeError(
      "The volume field must be numeric and may contain decimal points.",
    )
    .min(1)
    .required("Volume is required."),
})

export const SubmissionArrayFormsValidationShema = Yup.object().shape({
  submissions: Yup.array().of(SubmissionFormValidationShema)})

export default SubmissionFormValidationShema
