import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import AuthGuard from "../../components/AuthGuard/AuthGuard"
import { UserRoles } from "../users/types"
import "./Applications.scss"
import ApplicationEdit from "./containers/ApplicationEdit"
import ApplicationList from "./containers/ApplicationList"
import ApplicationsManage from "./containers/ApplicationsManage"
import SectorApplicationList from "./containers/SectorApplicationList"
import useApplicationConfig from "hooks/useApplicationConfig"

const Applications = () => {
  const { path } = useRouteMatch()
  const configApplicationEnd = useApplicationConfig()

  return (
    <>
      { configApplicationEnd?.registrationOpen && (
        <div className="srs-applications">
          <Route exact path={path}>
            <ApplicationList />
          </Route>
          <Route exact path={`${path}/edit/:compositionId/:linkId`}>
            <ApplicationEdit />
          </Route>
          <Route exact path={`${path}/manage`}>
            <AuthGuard roles={[UserRoles.ADMIN]}>
              <ApplicationsManage />
            </AuthGuard>
          </Route>
          <Route exact path={`${path}/sector/:year`}>
            <SectorApplicationList />
          </Route>
        </div>
      )}
      { !configApplicationEnd?.registrationOpen && (
        <div className="srs-applications">The assign end applications functionality is currently closed. You will be notified when available.</div>
      )}
    </>
  )
}

export default Applications
