import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouteMatch, Link } from "react-router-dom"
import ConfirmButton from "views/partials/ConfirmButton"

import { handleToast } from "utils/messages"

import { getPlants } from "../../../companies/service/api"
import { Company } from "../../../companies/types"
import { auditStatuses } from "../../constants"
import { auditDelete, getAudits } from "../../service/api"
import { Audit } from "../../types"

const ExistingAudits = () => {
  const { t } = useTranslation()

  const { params }: { params: { id: string } } = useRouteMatch()
  const { id } = params
  const companyId = parseInt(id)

  const [plant, setPlant] = useState<Company>()
  const [audits, setAudits] = useState<Audit[]>([])

  const [sortKey, setSortKey] =
    React.useState<keyof Pick<Audit, "createdAt" | "draft">>("createdAt")
  const [sortDesc, setSortDesc] = React.useState<{
    createdAt: boolean
    draft: boolean
  }>({
    createdAt: true,
    draft: true,
  })

  const requestSort = (key: keyof Pick<Audit, "createdAt" | "draft">) => {
    if (key !== sortKey) {
      setSortKey(key)
    }

    if (key === sortKey) {
      setSortDesc((prevValue) => ({ ...prevValue, [key]: !prevValue[key] }))
    }
  }

  const filteredAudits = useMemo(() => {
    return audits.filter((audit) => !audit.isDeleted)
  }, [audits])

  const filteredDeletedAudits = useMemo(() => {
    return audits
      .filter((audit) => audit.isDeleted)
      .sort((a, b) =>
        new Date(a.deletedAt).getTime() > new Date(b.deletedAt).getTime()
          ? 1
          : -1,
      )
  }, [audits])

  const sortedAudits = useMemo(() => {
    const sortableItems = [...filteredAudits]

    sortableItems.sort((a: Audit, b: Audit) => {
      let itemA: any = a[sortKey]
      let itemB: any = b[sortKey]

      const sortOrder = sortDesc[sortKey]

      if (itemA < itemB) {
        return sortOrder ? 1 : -1
      }
      if (itemA > itemB) {
        return sortOrder ? -1 : 1
      }

      return 0
    })

    return sortableItems
  }, [filteredAudits, sortDesc, sortKey])

  const getAuditsList = () => {
    getAudits({ companyId }).then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        setAudits(data)
      }
    })
  }

  const handleDeclineAudit = async (id?: number) => {
    if (id) {
      const { errors } = await auditDelete({ id })

      if (errors) {
        handleToast(errors, "error")
      } else {
        getAuditsList()

        handleToast(["Audit deleted"], "success")
      }
    }
  }

  useEffect(() => {
    getAuditsList()
  }, [])

  useEffect(() => {
    if (id) {
      getPlants().then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }

        if (data?.length) {
          const currPlant = data.find((p) => p.id === companyId)

          setPlant(currPlant)
        }
      })
    }
  }, [id])

  const properlyAuditDateFormat = (date: any) => {
    const d = new Date(date)
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
    const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d)
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
    return `${da} ${mo} ${ye}`
  }

  return (
    <>
      <h1 className="mb-5">
        {t("Existing audits of ")}
        <strong>{plant?.parent?.name}</strong>
        {" - "}
        <strong>{plant?.name}</strong>
      </h1>
      <div className="row">
        <div className="col-12 mb-5">
          <div className="row">
            <table className="table table-light table-bordered text-center">
              <thead>
                <tr>
                  <th
                    className="cursor-pointer align-middle px-2"
                    onClick={() => requestSort("createdAt")}
                  >
                    {t("Audit report period")}
                    <span
                      className={`pl-2 fa text-primary ${
                        sortDesc.createdAt ? "fa-angle-down" : "fa-angle-up"
                      }`}
                    />
                  </th>
                  <th
                    className="cursor-pointer align-middle px-2"
                    onClick={() => requestSort("draft")}
                  >
                    {t("Status")}
                    <span
                      className={`pl-2 fa text-primary ${
                        sortDesc.draft ? "fa-angle-down" : "fa-angle-up"
                      }`}
                    />
                  </th>
                  <th className="align-middle px-2">{t("Actions")}</th>
                </tr>
              </thead>

              <tbody>
                {(sortedAudits || []).map((audit: Audit, key) => {
                  return (
                    <tr key={`audit_${key}`}>
                      <td className="align-middle col-8 text-left">
                        <Link
                          to={{
                            pathname: `/edit-audit/${audit.id}`,
                            state: {
                              title: t("Edit Audit"),
                            },
                          }}
                        >
                          {t("Since")}{" "}
                          {properlyAuditDateFormat(audit.createdAt)} -{" "}
                          {t("Until")}{" "}
                          {properlyAuditDateFormat(audit.finishedAt)}
                        </Link>
                      </td>
                      <td
                        className="align-middle"
                        style={{ color: auditStatuses[audit.status].color }}
                      >
                        {audit.draft
                          ? t("Draft")
                          : auditStatuses[audit.status].name}
                      </td>
                      <td
                        className="align-middle"
                        style={{ color: auditStatuses[audit.status].color }}
                      >
                        <ConfirmButton
                          title="Delete"
                          text={t(
                            "Are you sure you wish to delete this audit draft?",
                          )}
                          confirmText={t("Delete")}
                          icon="trash"
                          className="btn-sm text-left w-100 btn-outline-danger"
                          confirmClassName="btn-outline-danger"
                          danger
                          isDisabled={!audit.draft}
                          onConfirm={() => handleDeclineAudit(audit?.id)}
                        >
                          <i className="fas fa-ban btn-icon mr-1" />{" "}
                          {t("Delete")}
                        </ConfirmButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <h1 className="mb-5">
        {t(
          filteredDeletedAudits?.length > 0
            ? "Deleted audits of "
            : "There are no deleted audits for ",
        )}
        <strong>{plant?.parent?.name}</strong>
        {" - "}
        <strong>{plant?.name}</strong>
      </h1>
      {filteredAudits?.length > 0 && (
        <div className="row">
          <div className="col-12 mb-5">
            <div className="row">
              <table className="table table-light table-bordered text-center">
                <thead>
                  <tr>
                    <th className="align-middle px-2">
                      {t("Audit report period")}
                    </th>
                    <th className="align-middle px-2">{t("Status")}</th>
                    <th className="align-middle px-2">{t("Deleted by")}</th>
                    <th className="align-middle px-2">{t("Deleted at")}</th>
                  </tr>
                </thead>

                <tbody>
                  {(filteredDeletedAudits || []).map((audit: Audit, key) => {
                    return (
                      <tr key={`audit_${key}`}>
                        <td className="align-middle text-left">
                          {t("Since")}{" "}
                          {properlyAuditDateFormat(audit.createdAt)} -{" "}
                          {t("Until")}{" "}
                          {properlyAuditDateFormat(audit.finishedAt)}
                        </td>
                        <td
                          className="align-middle"
                          style={{ color: auditStatuses[audit.status].color }}
                        >
                          {audit.draft
                            ? t("Draft")
                            : auditStatuses[audit.status].name}
                        </td>

                        <td className="align-middle">
                          {audit?.deletedBy?.name}
                        </td>
                        <td className="align-middle">
                          {new Date(audit?.deletedAt)?.toDateString()}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ExistingAudits
