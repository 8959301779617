import { useFormik } from "formik"
import { Audit } from "modules/Audit/types"
import { getPlant } from "modules/companies/service/api"
import { Company } from "modules/companies/types"
import React, { useEffect, useState, useCallback } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"

import FormError from "components/FormError/FormError"
import Label from "components/Label/Label"
import Select from "components/Select/Select"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"

import useStateGetter from "hooks/useStateGetter"

import { fetchSubmissions } from "../../../submissions/actions"
import SubmissionListTableBody from "../../../submissions/components/SubmissionListTable/SubmissionListTableBodyForAudits"
import { getSubmission } from "../../../submissions/service/api"
import { Submission } from "../../../submissions/types"
import { AUDIT_DATE_FORMAT, auditTypes } from "../../constants"
import { auditUpdate, getAudit } from "../../service/api"
import UploadEditAudit from "./UploadEditAudit"

const filterSelectedSubmissions = (
  allSubmissions: Submission[] = [],
  selectedSubmissionsIds: number[] = [],
): Submission[] =>
  allSubmissions.filter(({ id }) =>
    selectedSubmissionsIds.includes(id as number),
  )

const EditAudit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { params }: { params: { id: string | undefined } } = useRouteMatch()
  const { id } = params
  const [audit, setAudit] = useState<Audit>()
  const [step, setStep] = useState<string>("input")
  const [sinceDate, setSinceDate] = useState<any>()
  const [untilDate, setUntilDate] = useState<any>()
  const [setDate, setSetDate] = useState<boolean>(false)
  const [auditStatus, setAuditStatus] = useState<boolean>(false)
  const [file, setFile] = useState<any>()

  const [sortByMemo, setSortByMemo] = useState<string[]>([
    "s.createdAt",
    "DESC",
  ])

  const submissions = filterSelectedSubmissions(
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? [],
    audit?.submissions,
  )
  const [toggledSubbmisions, setToggleSubbmision] = useState<Submission[]>([])

  const discardAllAuditChanges = () => {
    values.auditType = null
    setToggleSubbmision([])

    setToggleAllSubmissions({
      inputAllToggle: false,
      outputAllToggle: false,
    })
  }

  const [toggleAllSubmissions, setToggleAllSubmissions] = useState<{
    inputAllToggle: boolean
    outputAllToggle: boolean
  }>({
    inputAllToggle: false,
    outputAllToggle: false,
  })

  const INITIAL_FORM_VALUES = {
    auditType: null,
    sinceDate,
    untilDate,
    companyId: id,
    submissions: [] as Submission[],
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: () => {},
  })
  const { values, errors, setFieldValue, touched } = formik

  useEffect(() => {
    setToggleSubbmision([])
  }, [step])

  const onSortChanged = (sortBy: string, sortDirection: string): void => {
    setSortByMemo([sortBy, sortDirection])
  }

  const getAuditData = useCallback(() => {
    if (id) {
      getAudit({ id: parseInt(id, 0) }).then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }
        if (data) {
          setAudit(data)
        }
      })
    }
  }, [])

  const getSubmissions = useCallback(() => {
    dispatch(
      fetchSubmissions({
        companyId: audit?.company.id,
        sortBy: sortByMemo[0],
        sortDirection: sortByMemo[1],
        limit: 999,
        // dateStart: `${sinceDate.getFullYear()}/${sinceDate.getMonth()}`,
        // dateEnd: `${untilDate.getFullYear()}/${untilDate.getMonth()}`,
      }),
    )
  }, [sortByMemo])

  const setProperlyCheckSubmissions = () => {
    auditTypes.map((single: any) => {
      if (single.value === audit?.auditType) {
        setFieldValue("auditType", single)
      }
    })
    // const tgSubbmisions: any = [];
    // if (audit?.submissions && audit?.submissions != null) {
    //   audit?.submissions.map((submission: any) =>
    //     getSubmission({ id: submission }).then(({ data }) => {
    //       tgSubbmisions.push(data);
    //     })
    //   );
    //   setToggleSubbmision(tgSubbmisions);
    // }
  }

  useEffect(() => {
    setFieldValue("submissions", toggledSubbmisions)
  }, [setFieldValue, toggledSubbmisions])

  useEffect(() => {
    getAuditData()
    getSubmissions()
    setProperlyCheckSubmissions()
    setAuditStatus(audit?.status === 4)
  }, [])

  const updateAudit = (draft: boolean) => {
    const { auditType }: any = values
    const companyId: number | undefined = audit?.company.id
    const auditId = id as string
    let document = { id: 0 }

    if (audit!.document[0] != undefined) {
      document = audit!.document[0]
    }

    const credentials = {
      auditId: parseInt(auditId, 0),
      companyId,
      submissions: values.submissions.map((sub) => sub.id),
      createdAt: new Date(sinceDate),
      finishedAt: new Date(untilDate),
      auditType: auditType?.value && auditType.value,
      draft,
      files: file ? [file] : [],
      documentId: document!.id,
    }

    auditUpdate(credentials)
      .then((res) => {
        handleToast(["Audit Updated."], "success")
        history.push(`/existing-audit/${companyId}`, {
          title: t("Existing audits"),
          id: companyId,
        })
      })
      .catch(() => {
        if (errors) {
          handleToast(["Cannot update audit"], "error")
        }
      })
      .finally(() => {})
  }

  useEffect(() => {
    if (submissions.length > 0 && setDate === false) {
      setSetDate(true)
      const dates: any[] = []
      for (let i = 0; i < submissions.length; i++) {
        dates.push(new Date(submissions[i].collectionDate))
      }
      const maxDate = new Date(Math.max.apply(null, dates))
      const minDate = new Date(Math.min.apply(null, dates))
      if (audit?.createdAt) {
        setSinceDate(audit?.createdAt)
        setUntilDate(audit?.finishedAt)
      } else {
        setSinceDate(minDate)
        setUntilDate(maxDate)
      }
    }
  }, [submissions])

  const onStartDateChanged = (date: Date) => {
    setSinceDate(date)
  }

  const onEndDateChanged = (date: Date) => {
    setUntilDate(date)
  }

  return (
    <>
      <h1 className="mb-5">
        {t("Edit audit of")} <strong>{audit?.company.name}</strong>
      </h1>
      <div className="row">
        <div className="col-12 mb-5">
          {sinceDate && (
            <div className="row">
              <div className="col-6">
                <div className="form-group row align-items-center ">
                  <Label
                    label={`${t("Time period since")}:`}
                    name="since"
                    labelClassName="col-sm-5"
                  />
                  <div className="col-sm-7">
                    <DatePicker
                      className="form-control"
                      selected={new Date(sinceDate)}
                      dateFormat={AUDIT_DATE_FORMAT}
                      showMonthYearPicker
                      showPopperArrow={false}
                      startDate={new Date(sinceDate)}
                      endDate={new Date(untilDate)}
                      maxDate={new Date(untilDate)}
                      onChange={(startDate: Date) =>
                        onStartDateChanged(startDate)
                      }
                      disabled={audit?.status === 4}
                    />
                    <FormError
                      error={touched.sinceDate && (errors.sinceDate as string)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group row align-items-center ">
                  <Label
                    label={`${t("Time period until")}:`}
                    name="until"
                    labelClassName="col-sm-5"
                  />
                  <div className="col-sm-7">
                    <DatePicker
                      className="form-control"
                      selected={new Date(untilDate)}
                      dateFormat={AUDIT_DATE_FORMAT}
                      showMonthYearPicker
                      showPopperArrow={false}
                      startDate={new Date(sinceDate)}
                      endDate={new Date(untilDate)}
                      minDate={new Date(sinceDate)}
                      onChange={(startDate: Date) =>
                        onEndDateChanged(startDate)
                      }
                      disabled={audit?.status === 4}
                    />
                    <FormError
                      error={touched.untilDate && (errors.untilDate as string)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Select
                  name="auditType"
                  label={`${t("Audit type")}:`}
                  options={auditTypes}
                  value={values.auditType}
                  handleOnChange={(selectedOption) =>
                    setFieldValue("auditType", selectedOption)
                  }
                  inputWrapperClassName="col-sm-7"
                  labelClassName="col-sm-5"
                  error={touched.auditType && errors.auditType}
                  isDisabled={audit?.status === 4}
                />
              </div>
            </div>
          )}
        </div>

        <div className="col-12">
          <div className="row">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <button
                  className={`button-as-href nav-link ${
                    step === "input" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="true"
                  onClick={(e) => setStep("input")}
                >
                  {t("Step")} 1: {t("Input")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`button-as-href nav-link ${
                    step === "output" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="true"
                  onClick={(e) => setStep("output")}
                >
                  {t("Step")} 2: {t("Output")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`button-as-href nav-link ${
                    step === "upload" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-inputData"
                  aria-selected="true"
                  onClick={(e) => setStep("upload")}
                >
                  {t("Step")} 3: {t("Upload Audit Report")}
                </button>
              </li>
            </ul>
          </div>
        </div>
        {step === "input" && (
          <SubmissionListTableBody
            submissions={submissions}
            toggledSubbmisions={toggledSubbmisions}
            setToggleSubbmision={setToggleSubbmision}
            toggleAllSubmissions={toggleAllSubmissions}
            setToggleAllSubmissions={setToggleAllSubmissions}
            inputData="input"
            onSortChanged={onSortChanged}
            getSubmissions={getSubmissions}
            isMain={false}
            isDisabled={audit?.status === 4}
          />
        )}
        {step === "output" && (
          <SubmissionListTableBody
            submissions={submissions}
            toggledSubbmisions={toggledSubbmisions}
            setToggleSubbmision={setToggleSubbmision}
            toggleAllSubmissions={toggleAllSubmissions}
            setToggleAllSubmissions={setToggleAllSubmissions}
            inputData="output"
            onSortChanged={onSortChanged}
            getSubmissions={getSubmissions}
            isMain={false}
            isDisabled={audit?.status === 4}
          />
        )}
        {step === "upload" && (
          <UploadEditAudit
            setFile={setFile}
            document={audit?.document[0]}
            isDisabled={audit?.status === 4}
          />
        )}

        <div className="col-12">
          <div className="row my-4">
            <div className="col-sm-5" />
            <div className="col-sm-7">
              <div className="float-right">
                {!auditStatus && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-primary rounded-bottom-left"
                      onClick={() => {
                        discardAllAuditChanges()
                      }}
                    >
                      {t("Discard all changes")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success-light rounded-bottom-left"
                      onClick={() => updateAudit(true)}
                      // disabled={loading}
                    >
                      {t("Save as a draft and exit")}
                    </button>
                  </>
                )}
                {!auditStatus && step === "input" && (
                  <button
                    type="button"
                    className="btn btn-success rounded-bottom-right ml-2"
                    onClick={(e) => setStep("output")}
                  >
                    {t("Continue to next step")}
                  </button>
                )}
                {!auditStatus && step === "output" && (
                  <button
                    type="button"
                    className="btn btn-success rounded-bottom-right ml-2"
                    onClick={(e) => setStep("upload")}
                  >
                    {t("Continue to last step")}
                  </button>
                )}
                {!auditStatus && step === "upload" && (
                  <button
                    type="submit"
                    className="btn btn-success rounded-bottom-right ml-2"
                    onClick={() => updateAudit(false)}
                    disabled={!(audit?.document[0] || file)}
                  >
                    {t("Save and exit")}
                  </button>
                )}
                {auditStatus && (
                  <button
                    type="button"
                    className="btn btn-outline-primary rounded-bottom-left"
                    onClick={() => history.goBack()}
                  >
                    <i className="fas fa-arrow-left mr-2" /> Back
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditAudit
