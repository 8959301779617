import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { DEFAULT_LANGUAGE } from "../constants"

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  debug: false,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
