import { BaseSelectedValue } from "../../components/Select/Select"

export const SUBMISSIONS_PREFIX = "@SUBMISSIONS/"
export const FETCH_SUBMISSIONS = `${SUBMISSIONS_PREFIX}FETCH_SUBMISSIONS`
export const FETCH_SECTOR_SUBMISSION = `${SUBMISSIONS_PREFIX}FETCH_SECTOR_SUBMISSION`
export const SET_SELECTED_COMPANY = `${SUBMISSIONS_PREFIX}SET_SELECTED_COMPANY`
export const FETCH_SLECECTED_COMPANY = `${SUBMISSIONS_PREFIX}FETCH_SELECTED_COMPANY`
export const SELECTED_COMPANY_KEY = `${SUBMISSIONS_PREFIX}SELECTED_COMPANY`
export const SHOW_REGISTER_DONE_MODAL = `@REGISTRATION/SHOW_DONE_MODAL`
export const SHOW_REGISTER_DONE_MODAL_CONVERTER = "true"

export const SUBMISSION_DATE_FORMAT = "MM/yyyy"
export const COLLECTION_DATE_FORMAT = "MMM yyyy"
export const CAN_DELETE_SUBMISSION_TO_DAYS = 38
export const CAN_EDIT_SUBMISSION_TO_DAYS = 38

export const PER_PAGE = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "All", value: 99999 },
]

export const STATUS_OPTIONS: BaseSelectedValue[] = [
  { value: "", label: "ALL STATUSES" },
  // { value: 'CREATED', label: 'CREATED' },
  { value: "SENT", label: "PENDING" },
  { value: "DECLINED", label: "DECLINED" },
  { value: "ACCEPTED", label: "ACCEPTED" },
]
