import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { actionHandler } from "../@common/actions"
import {
  FETCH_GENERIC_COMPOSITION,
  FETCH_GENERIC_COMPOSITIONS,
} from "./constants"
import { CompositionType } from "./enums"
import {
  getGenericComposition,
  getGenericCompositions,
  postGenericComposition,
} from "./service/api"
import { GenericComposition, GenericCompositionPost } from "./types"

interface SetGenericCompositionsPayload {
  type: CompositionType
  genericCompositions: GenericComposition[]
}

interface SetGenericCompositionPayload {
  type: CompositionType
  genericComposition: GenericComposition
}

export interface SetGenericCompositions {
  type: string
  payload: SetGenericCompositionsPayload
}

export interface SetGenericComposition {
  type: string
  payload: SetGenericCompositionPayload
}

export const setGenericCompositions = (
  payload: SetGenericCompositionsPayload,
): SetGenericCompositions => ({
  type: FETCH_GENERIC_COMPOSITIONS,
  payload,
})

export const setGenericComposition = (
  payload: SetGenericCompositionPayload,
): SetGenericComposition => ({
  type: FETCH_GENERIC_COMPOSITION,
  payload,
})

export const fetchGenericCompositions =
  (type: CompositionType, companyId?: number) => async (dispatch: Dispatch) => {
    actionHandler(
      getGenericCompositions({ type, companyId }),
      (genericCompositions) => {
        dispatch(setGenericCompositions({ type, genericCompositions }))
      },
    )
  }

export const fetchGenericComposition =
  (type: CompositionType, id: number) => async (dispatch: Dispatch) => {
    actionHandler(getGenericComposition({ id }), (genericComposition) => {
      dispatch(setGenericComposition({ type, genericComposition }))
    })
  }

export const createGenericComposition =
  (composition: GenericCompositionPost) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postGenericComposition(composition), () => {
      dispatch(
        fetchGenericCompositions(composition.type, composition.companyId),
      )
    })
  }

export type GenericCompositionActions = SetGenericCompositions &
  SetGenericComposition
