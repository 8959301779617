import React, { useEffect, useState } from "react"
import { NotificationAudit } from "../types"
import { getNotificationAudit } from "../service/api"
import { handleToast } from "utils/messages"

interface Props {
  id: number
}

const NotificationAuditView: React.FC<Props>= ({id}) => {
  const [noteAudit, setNoteAudit] = useState<NotificationAudit[]>([])
  useEffect(() => {
    if (id !== 0) {
      console.log(id)
      getNotificationAudit({ id }).then((res) => {
        console.log(res)
        const { data, errors } = res
        if (errors) {
          handleToast(errors, "error")
        }
        if (data) {
          setNoteAudit(data)
        }
      })
    }
  }, [id])

  return(
    <div className="container">

      <div className="row">
        {noteAudit.map((na, index) => (
          <div key={index} className="col-sm-6 float-left list-group-item">
            {na.user?.name} - {na.acceptAt ? 'accepted - ' + na.acceptAt : 'not accepted'}
          </div>
        ))}
      </div>
    </div>
  )
}

export default NotificationAuditView