import React, { useEffect, ReactNode } from "react"

import useStateGetter from "../../hooks/useStateGetter"
import { history } from "../../utils/routes"
import { windowStorage } from "../../utils/windowStorage"

const LoggedInGuard = (props: { children?: ReactNode }) => {
  const { children } = props
  const accessToken = useStateGetter(["auth", "accessToken"])

  useEffect(() => {
    if (accessToken) {
      const goto = windowStorage.get("goto")
      if (goto) {
        history.push(goto)
      } else {
        history.push("/")
      }
    }
  }, [accessToken])

  return <>{children}</>
}

export default LoggedInGuard
