import { useFormik } from "formik"
import React, { useEffect, useState, useCallback } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch, Link } from "react-router-dom"

import FormError from "../../../components/FormError/FormError"
import Label from "../../../components/Label/Label"
import Select, {
  SelectValue,
  mapToOptions,
  BaseSelectedValue,
} from "../../../components/Select/Select"
import useStateGetter from "../../../hooks/useStateGetter"
import { handleToast } from "../../../utils/messages"
import { getPlant } from "../../companies/service/api"
import { Company } from "../../companies/types"
import { fetchSubmissions } from "../../submissions/actions"
import { Submission } from "../../submissions/types"
import { AUDIT_DATE_FORMAT, auditTypes, auditStatuses } from "../constants"
import { getAuditsForCurrentAuditor } from "../service/api"
import { Audit, AuditStatus } from "../types"

const ManageAudtis = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { params }: { params: { id: string } } = useRouteMatch()
  const { id } = params
  const [plant, setPlant] = useState<Company>()
  const [audits, setAudits] = useState<Audit[]>()

  const [sortByMemo, setSortByMemo] = useState<string[]>([
    "s.createdAt",
    "DESC",
  ])

  const submissions =
    useStateGetter<Submission[]>(["submission", "submissions"]) ?? []

  const companyId = parseInt(id)

  const getAuditsList = () => {
    getAuditsForCurrentAuditor().then(({ data, errors }) => {
      if (errors) {
        handleToast(errors, "error")
      }

      if (data) {
        setAudits(data)
      }
    })
  }

  const getSubmissions = useCallback(() => {
    if (id) {
      dispatch(fetchSubmissions({}))
    }
  }, [dispatch, id])

  useEffect(() => {
    getSubmissions()
    getAuditsList()
  }, [getSubmissions])

  const properlyAuditDateFormta = (date: any) => {
    const d = new Date(date)
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
    const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d)
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
    return `${da} ${mo} ${ye}`
  }

  useEffect(() => {
    if (id) {
      getPlant({ id: parseInt(id, 0) }).then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }

        if (data) {
          setPlant(data)
        }
      })
    }
  }, [id])

  return (
    <>
      <div className="row">
        <div className="col-12 mb-5">
          <div className="row">
            <table className="table table-light table-bordered text-center">
              <thead>
                <tr>
                  <th className="align-middle">{t("Audit report period")}</th>
                  <th className="align-middle text-center">{t("Status")}</th>
                </tr>
              </thead>

              <tbody>
                {(audits || []).map((audit: Audit, key) => {
                  return (
                    <tr>
                      <td className="align-middle col-8 text-left">
                        {t("Since")} {properlyAuditDateFormta(audit.createdAt)}{" "}
                        - {t("Until")}{" "}
                        {properlyAuditDateFormta(audit.finishedAt)}
                      </td>
                      <td
                        className="align-middle"
                        style={{ color: auditStatuses[audit.status].color }}
                      >
                        {audit.draft
                          ? t("Draft")
                          : auditStatuses[audit.status].name}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageAudtis
