import React from "react"
import { useTranslation } from "react-i18next"

import { history } from "../../../utils/routes"
import SubmissionListTable from "../components/SubmissionListTable/SubmissionListTable"

const SubmissionList = () => {
  const { t } = useTranslation()
  return (
    <>
      <h1 className="mb-5">{t("Manage submitted data")}</h1>
      <SubmissionListTable />
      <button
        type="button"
        className="btn btn-outline-primary rounded-bottom-left"
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left mr-2" /> {t("Back")}
      </button>
    </>
  )
}

export default SubmissionList
