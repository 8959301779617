import { restHelper } from "../../@common/service/api"

export const queries = {
  userDashboardsUrl: `
    query userDashboardsUrl {
      userDashboardsUrl
    }
  `,
}

export const getDashboardsUrl = restHelper<string, object>(
  queries,
  "userDashboardsUrl",
)
