import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import Modal from "../../../components/Modal/Modal"
import useUserFromToken from "../../../hooks/useUserFromToken"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import toggleElementInArray from "../../../utils/toggleElementInArray"
import { Company } from "../../companies/types"
import SelectableItem from "../components/SelectableItem/SelectableItem"
import UserForm from "../components/UserForm/UserForm"
import {
  attachUserToCompany,
  getCompanyUsers,
  userAttachPlants,
} from "../service/api"
import { User } from "../types"

interface Props {
  user: User
  companyPlants: Company[]
  handleNotify?: (action: string) => void
}

const ParentUserAccount: React.FC<Props> = ({
  user,
  companyPlants,
  handleNotify,
}) => {
  const [selectedChildUser, setSelectedChildUser] = useState<User>()
  const [companyUsers, setCompanyUsers] = useState<User[]>([])
  const [userSelectedCompanies, setUserSelectedCompanies] = useState<number[]>(
    [],
  )

  const [isUserAddModalOpen, setIsUserAddModalOpen] = useState(false)
  const [isUserEditModalOpen, setIsUserEditModalOpen] = useState(false)
  const [isChildUserModalOpen, setIsChildUserModalOpen] = useState(false)

  const { t } = useTranslation()
  const { isRrr } = useUserFromToken()

  const fetchCompanyUsers = async () => {
    const response = await getCompanyUsers()
    setCompanyUsers(response?.data || [])
  }

  useEffect(() => {
    fetchCompanyUsers()
  }, [])

  useEffect(() => {
    if (selectedChildUser) {
      const userPlants = selectedChildUser?.plants || []
      const plantsIds = userPlants.map((plant) => plant.id) as number[]
      setUserSelectedCompanies(plantsIds)
    }
  }, [selectedChildUser])

  const handleOnUserSuccessUpdate = () => {
    setIsUserAddModalOpen(false)
    setIsUserEditModalOpen(false)
    fetchCompanyUsers()
    if (handleNotify) {
      handleNotify("success")
    }
  }

  const handleOnNewUserClick = () => {
    setIsUserAddModalOpen(true)
  }

  const handleOnClickChildUser = (child: User) => {
    setSelectedChildUser(child)
    setIsChildUserModalOpen(true)
  }

  const handleOnClickEditChildUser = (child: User) => {
    setSelectedChildUser(child)
    setIsUserEditModalOpen(true)
  }

  const attachUserToParentCompany = () => setUserSelectedCompanies([])

  const changeSelectedCompanies = (id: number) =>
    setUserSelectedCompanies(toggleElementInArray(id, userSelectedCompanies))

  const submitSelectedPlants = async () => {
    if (selectedChildUser) {
      let response
      if (userSelectedCompanies.length > 0) {
        response = await userAttachPlants({
          userId: Number.parseInt(selectedChildUser.id, 10),
          companyIds: userSelectedCompanies,
        })
      } else {
        response = await attachUserToCompany({
          userId: Number.parseInt(selectedChildUser.id, 10),
        })
      }

      if (response.data) {
        if (userSelectedCompanies.length > 0) {
          const successMessage = t("Plants were attached")
          handleToast([successMessage])
        } else {
          const successMessage = t("User attached to the company")
          handleToast([successMessage])
        }
      } else if (response.errors) {
        handleToast(response.errors, "error")
      }
    }
    fetchCompanyUsers()
    if (handleNotify) {
      handleNotify("success")
    }
  }

  return (
    <>
      <h3 className="mb-3">{t("Users")}</h3>
      {!isRrr && (
        <div className="text-right mb-3">
          <button
            type="button"
            className="btn btn-success rounded-top-right"
            onClick={handleOnNewUserClick}
          >
            {t("Add user")}
            <i className="fas fa-plus ml-3" />
          </button>
        </div>
      )}

      <Modal
        isOpen={isUserAddModalOpen}
        isOpenHandler={setIsUserAddModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <h2 className="pl-5 pt-5">{t("User data")}</h2>
        <div className="pl-5 pr-5 pb-5">
          <UserForm
            parent={user}
            onSuccessHandler={handleOnUserSuccessUpdate}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isUserEditModalOpen}
        isOpenHandler={setIsUserEditModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <h2 className="pl-5 pt-5">{t("User data")}</h2>
        <div className="pl-5 pr-5 pb-5">
          <UserForm
            initialUser={selectedChildUser}
            onSuccessHandler={handleOnUserSuccessUpdate}
          />
        </div>
      </Modal>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th>{t("Email")}</th>
            <th>{t("Name")}</th>
            <th>{t("Plant(s)")}</th>
            <th>{t("Company(ies) address(es)")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {user &&
            companyUsers &&
            companyUsers.map((child) => {
              return (
                <tr key={child.id}>
                  <td className="align-middle">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => handleOnClickEditChildUser(child)}
                    >
                      {child.email}
                    </button>
                  </td>
                  <td className="align-middle">{child.name}</td>
                  <td className="align-middle">
                    {child.plants?.length
                      ? child.plants.map((childCompany) => (
                          <p className="mb-0" key={childCompany.id}>
                            {childCompany.name}
                          </p>
                        ))
                      : t("all plants")}
                  </td>
                  <td className="align-middle">
                    {child.plants?.length
                      ? child.plants.map((childCompany) => (
                          <p className="mb-0" key={childCompany.id}>
                            {childCompany.address}
                          </p>
                        ))
                      : t("all plants")}
                  </td>
                  <td className="align-middle">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm text-left"
                          style={{ width: "100%" }}
                          onClick={() => handleOnClickChildUser(child)}
                        >
                          <i className="fas fa-align-left" />
                          {t("Attach/Unattach plant(s)")}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left mr-2" /> {t("Back")}
        </button>
      </div>

      <Modal
        isOpen={isChildUserModalOpen}
        isOpenHandler={setIsChildUserModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <div className="p-5">
          <h2>{selectedChildUser?.name} plants</h2>

          <SelectableItem
            {...{
              text: t("Attach to main company"),
              isSelected: userSelectedCompanies.length === 0,
              onClickHandler: attachUserToParentCompany,
            }}
          />

          {companyPlants.map((plant) => {
            const isSelected = userSelectedCompanies.includes(
              plant?.id as number,
            )
            return (
              <div key={plant.id}>
                <SelectableItem
                  {...{
                    text: plant.name,
                    isSelected,
                    onClickHandler: () =>
                      plant.id && changeSelectedCompanies(plant.id),
                  }}
                />
              </div>
            )
          })}
          <div className="d-flex justify-content-end mt-5">
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right"
              onClick={submitSelectedPlants}
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ParentUserAccount
