export default (value: number, data: number[]) => {
  const copiedData = [...data]
  const indexOfValue = copiedData.indexOf(value)
  if (indexOfValue === -1) {
    copiedData.push(value)
  } else {
    copiedData.splice(indexOfValue, 1)
  }
  return copiedData
}
