import uploadPDF from "assets/images/auditicons/misc-uploadPDF.svg"
import axios from "axios"
import React, { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { ToastContainer } from "react-toastify"
import { handleToast } from "utils/messages"

const UploadAudit = ({ files = [], setFiles }: any) => {
  const [fileNames, setFileNames] = useState<any[]>(files)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader()

        reader.onabort = () => {
          console.log("file reading was aborted")
        }
        reader.onerror = () => {
          console.log("file reading has failed")
        }

        const formData = new FormData()
        if (file) {
          formData.append("file", file)
        }

        setIsLoading(true)

        const url = `${process.env.REACT_APP_API_URL}audit/uploadfile`
        axios({
          url,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        })
          .then((response) => {
            const array = fileNames
            array.push(file)
            setFileNames(array)
            setFiles(array)
            setIsLoading(false)

            handleToast(["Reports were successfully uploaded!"], "success")
          })
          .catch((error) => {
            setIsLoading(false)

            handleToast(
              ["Only document files are allowed! (PDF, DOC, DOCX)"],
              "error",
            )
          })
      })
    },
    [setFiles],
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div
      className="drop-place col-12 mt-2 mb-4 text-center"
      {...getRootProps()}
    >
      <ToastContainer />

      <input
        {...getInputProps()}
        accept=".doc, application/pdf, .docx"
        multiple
      />
      <div className="col-12 mt-4">
        <img src={uploadPDF} />
      </div>
      <div className="col-12 mt-2">
        <span className="align-middle">
          Drag & drop your audit file here to upload it
        </span>
      </div>
      <div className="col-12 mt-2">
        <span className="align-middle">or</span>
      </div>
      <div className="col-12 mt-2">
        <button type="button" className="btn btn-primary" disabled={isLoading}>
          Browse files on your device
        </button>
      </div>
      {fileNames.map((file, index) => (
        <p key={`file_${index}`} className="mt-2 h-6">
          {file?.path || "file"} <strong className="bold"> uploaded</strong>
        </p>
      ))}
    </div>
  )
}

export default UploadAudit
