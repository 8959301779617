import { useEffect } from "react"

export type EventHandler = (e: Event) => void

const useOnScroll = (eventHandler: EventHandler) => {
  useEffect(() => {
    window.addEventListener("scroll", eventHandler)

    return () => {
      window.removeEventListener("scroll", eventHandler)
    }
  }, [eventHandler])
}

export default useOnScroll
