import { Company } from "../companies/types"
import { GenericComposition } from "./types"

interface BaseComposition { 
  productName: string, 
  isArchived: boolean 
}

type nodeType = {
  id: number
  title: string
  children: nodeType[]
}

type traverseToFindPathResult = {
  title: string
  parentId?: number
}

export function traverseToFindPath<T extends nodeType>(
  nodes: T[],
  selectedId: number,
  parentId?: number,
): traverseToFindPathResult {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]

    if (node.id === selectedId) {
      return {
        title: node.title,
        parentId,
      }
    }

    const matched = traverseToFindPath(node.children || [], selectedId, node.id)

    if (matched && matched.parentId === node.id) {
      return {
        title: `${node.title} - ${matched.title}`,
        parentId,
      }
    }
  }

  return {
    title: "",
  }
}

export function filterCompanyCompositions<T extends BaseComposition>(
  compositions: T[],
  currentCompany?: Company,
  checkArchived = false,
) {
  if (!currentCompany) {
    return compositions
  }

  const allowedPolymers = (currentCompany.polymers || []).map(
    ({ name }: any) => name.toLowerCase(),
  )

  return compositions.filter(
    ({ productName, isArchived }: T) => {
      return (
        allowedPolymers.some((polymer: string) =>
          productName.toLowerCase().startsWith(polymer),
        ) &&
        (!checkArchived || !isArchived)
      )
    },
  )
}
