import React from "react"
import { Route, useRouteMatch } from "react-router-dom"

import LoggedInGuard from "../../components/LoggedInGuard/LoggedInGuard"
import ManualRegister from "../pages/ManualRegister"
import FinishRegistration from "./containers/FinishRegistration/FinishRegistration"
import Login from "./containers/Login"
import Register from "./containers/Register"

const Auth = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Route path={`${path}/finish-registration`}>
        <FinishRegistration />
      </Route>
      <Route path={`${path}/login`}>
        <LoggedInGuard>
          <Login />
        </LoggedInGuard>
      </Route>
      <Route path={`${path}/register`}>
        <LoggedInGuard>
          <Register />
        </LoggedInGuard>
      </Route>
      <Route path={`${path}/manual`}>
        <LoggedInGuard>
          <ManualRegister />
        </LoggedInGuard>
      </Route>
    </>
  )
}

export default Auth
