import { restHelper } from "../../@common/service/api"
import { ID, UpdateTreeDragAndDropInput } from "../../@common/types"
import { Product, CreateProductInput, UpdateProductInput } from "../types"

export const queries = {
  products: `
    query products(
      $onlyVisible: Boolean
    ) {
      products(
        onlyVisible: $onlyVisible
      ) {
        id
        title
        visible
        selectable
        type
        category
        children {
          id
          title
          visible
          selectable
          parentId
          children {
            id
            title
            visible
            selectable
            parentId
            children {
              id
              title
              visible
              selectable
              parentId
            },
            children {
              id
              title
              visible
              selectable
              parentId
              children {
                id
                title
                visible
                selectable
                parentId
              }
            }
          }
        }
      }
    }
  `,
}

export const mutations = {
  productCreate: `
    mutation productCreate(
      $title: String!
      $positionId: Int!
      $parentId: Int
    ) {
      productCreate(
        title: $title
        positionId: $positionId
        parentId: $parentId
      ) {
        id
      }
    } 
  `,
  productUpdate: `
    mutation productUpdate(
      $id: Int!
      $title: String!
    ) {
      productUpdate(
        id: $id
        title: $title
      ) {
        id
      }
    } 
  `,
  productShowHide: `
    mutation productShowHide(
      $id: Int!
    ) {
      productShowHide(
        id: $id
      ) {
        id
      }
    } 
  `,
  productSelectable: `
    mutation productSelectable(
      $id: Int!
    ) {
      productSelectable(
        id: $id
      ) {
        id
      }
    } 
  `,
  productTreeUpdate: `
    mutation productTreeUpdate(
      $dragedElement: Int!
      $targetElement: Int!
      $isIndentElement: Boolean!
      $childrenIds: [Int]!
    ) {
      productTreeUpdate(
        dragedElement: $dragedElement
        targetElement: $targetElement
        isIndentElement: $isIndentElement
        childrenIds: $childrenIds
      ) {
        id
      }
    } 
  `,
  productDelete: `
    mutation productDelete($id: Int!) {
      productDelete(id: $id)
    } 
  `,
}

export const getProducts = restHelper<Product[], object>(queries, "products")

export const postProduct = restHelper<Product, CreateProductInput>(
  mutations,
  "productCreate",
)

export const putProduct = restHelper<Product, UpdateProductInput>(
  mutations,
  "productUpdate",
)

export const showHideProduct = restHelper<boolean, ID>(
  mutations,
  "productShowHide",
)

export const setSelectableProduct = restHelper<boolean, ID>(
  mutations,
  "productSelectable",
)

export const putProductTree = restHelper<Product, UpdateTreeDragAndDropInput>(
  mutations,
  "productTreeUpdate",
)

export const deleteProduct = restHelper<boolean, ID>(mutations, "productDelete")
