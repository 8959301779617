import { Dispatch, AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"

import { actionHandler } from "../@common/actions"
import { FETCH_USERS, FETCH_USER, FETCH_USER_COMPANIES } from "./constants"
import { getUsers, getUser, postUser, putUser } from "./service/api"
import { User, UserCompanies, UserUpdateInput, CreateUserInput } from "./types"

export interface SetUsers {
  type: string
  payload: { users: User[] }
}

export const setUsers = (payload: { users: User[] }): SetUsers => ({
  type: FETCH_USERS,
  payload,
})

export interface SetUser {
  type: string
  payload: { user: User }
}

export const setUser = (payload: { user: User }): SetUser => ({
  type: FETCH_USER,
  payload,
})

export interface SetUserCompanies {
  type: string
  payload: UserCompanies
}

export const setUserCompanies = (payload: UserCompanies): SetUserCompanies => ({
  type: FETCH_USER_COMPANIES,
  payload,
})

export const fetchUsers = () => async (dispatch: Dispatch) => {
  actionHandler(getUsers({}), (users) => {
    dispatch(setUsers({ users }))
  })
}

export const searchUsers = (searchName: string, searchEmail:string) => async (dispatch: Dispatch) => {
  actionHandler(getUsers({ name: searchName, email: searchEmail }), (users) => {
    dispatch(setUsers({ users }))
  })
}

export const fetchUser = () => async (dispatch: Dispatch) => {
  actionHandler(getUser({}), (user) => {
    dispatch(setUser({ user }))
  })
}

export const createUsers =
  (credentials: CreateUserInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(postUser(credentials), () => {
      dispatch(fetchUser())
    })
  }

export const updateUser =
  (credentials: UserUpdateInput) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    actionHandler(putUser(credentials), () => {
      dispatch(fetchUsers())
    })
  }

export type UserActions = SetUsers
