import { CompanyActions } from "./actions"
import { FETCH_COMPANIES, FETCH_PLANTS } from "./constants"
import { Company } from "./types"

export interface CompanyState {
  companies: Company[]
  plants: Company[]
}

const initialState: CompanyState = {
  companies: [],
  plants: [],
}

const company = (
  state: CompanyState = initialState,
  action: CompanyActions,
) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        companies: action.payload.companies,
      }
    case FETCH_PLANTS:
      return {
        ...state,
        plants: action.payload.plants,
      }
    default:
      return state
  }
}

export default company
