import React, { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useRouteMatch } from "react-router-dom"
import Select, {
  SelectValue,
  BaseSelectedValue,
  mapToOptions,
} from "components/Select/Select"
import useStateGetter from "../../../hooks/useStateGetter"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import { filterCompanyCompositions } from "../../genericCompositions/utils"
import { archiveComposition, getCompositions } from "../service/api"
import { Composition } from "../types"
import { Company } from "modules/companies/types"
import { getPlants } from "modules/companies/service/api"
import { setSelectedCompany } from "modules/submissions/actions"

const CompositionList = () => {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const [compositions, setCompositions] = useState<Composition[]>([])
  
  const selectedCompany = useStateGetter<Company>(["submission", "selectedCompany"])

  const [currentPlant, setCurrentPlant] = useState<Company>() 
  const [companies, setCompanies] = useState<Company[]>()

  const fetchCompositions = () => {
    if (currentPlant) {
      const company = currentPlant
      getCompositions({
        companyId: company.id,
      }).then(({ data, errors }) => {
        if (errors) {
          handleToast(errors, "error")
        }
        if (data) {
          const companyCompositions = filterCompanyCompositions<Composition>(
            data,
            company,
          )
          setCompositions(companyCompositions)
        }
      })
    }
  }

  useEffect(fetchCompositions, [currentPlant])

  useEffect(() => {
    if (selectedCompany && selectedCompany.id !== currentPlant?.id) {
      console.log('selectedCompany',selectedCompany)
      setCurrentPlant(selectedCompany)
    }
  }, [selectedCompany])

  useEffect(() => {
    getPlants({}).then(({ data, errors }) => {
      if (data) {
        setCompanies(data)

        if (data.length === 1) {
          dispatch(setSelectedCompany({ selectedCompany: data[0] }))
          setCurrentPlant(data[0])
        }
      }
    })
  }, [])

  const companiesOptions = React.useMemo(
    () => mapToOptions(companies ?? []),
    [companies],
  )

  const onSelectChange = (selectedOption: any) => {
    const fullCompany = companies?.find(
      (c) => c.id === selectedOption.value,
    )
    dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
    setCurrentPlant(fullCompany)
  }

  const handleArchiveComposition = async (id: number) => {
    const { errors } = await archiveComposition({ id })
    if (errors) {
      handleToast(errors, "error")
    } else {
      handleToast(["Composition archived"])
      fetchCompositions()
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <Select
          name="selectedCompany"
          label={t("Plant")}
          options={companiesOptions}
          value={currentPlant && {
            label: currentPlant.name,
            value: currentPlant.id,
          }}
          handleOnChange={onSelectChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={false}
          isDisabled={false}
        />
      <Link
        className="btn btn-success rounded-top-right mb-3 float-right"
        to={`${path}/new`}
      >
        <i className="fas fa-plus-circle fa-lg mr-3" />
        {t("Add")}
      </Link>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="col-sm-11">{t("Name")}</th>
            <th className="col-sm-1">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {compositions.map((composition) => {
            const { id, name, productName } = composition
            return (
              <tr key={id}>
                <td className="align-middle">
                  <Link to={`${path}/show/${id}`}>{productName ?? name}</Link>
                </td>
                <td className="text-center">
                  <div className="row">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        style={{ width: "100%" }}
                        onClick={() => handleArchiveComposition(composition.id)}
                      >
                        <i className="fas fa-archive" /> {t("Archive")}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
          {!compositions.length && (
            <tr>
              <td colSpan={2} className="text-center">
                {t("There is no data to display")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="justify-content-between pb-5">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-right float-right"
          onClick={() => history.goBack()}
        >
          {t("Next")} <i className="fas fa-arrow-right mr-2" />
        </button>
      </div>
    </>
  )
}

export default CompositionList
