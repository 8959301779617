import React from "react"
import { useRouteMatch } from "react-router-dom"

import SectorApplicationListTable from "../components/SectorApplicationListTable/SectorApplicationListTable"

const SectorApplicationList = () => {
  const { params }: { params: { year: string } } = useRouteMatch()
  const year = parseInt(params.year, 0)

  return <SectorApplicationListTable year={year} />
}

export default SectorApplicationList
