import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import * as Yup from "yup"

import Input from "../../../components/Input/Input"
import { handleToast } from "../../../utils/messages"
import { sendResetPasswordLink } from "../service/api"

const ForgottenPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
})

const LoginPasswordReminderForm = () => {
  const history = useHistory()

  const handleSubmit = async ({ email }: { email: string }) => {
    const { errors } = await sendResetPasswordLink({ email })

    if (errors) {
      handleToast(errors, "error")
    } else {
      handleToast(["Link to change password has been sent on email"])
      history.push("/")
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgottenPasswordSchema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors } = formik
  const { t } = useTranslation()

  return (
    <>
      <form className="pb-5" onSubmit={formik.handleSubmit}>
        <Input
          label={t("Email address")}
          type="email"
          name="email"
          value={values.email}
          handleOnChange={formik.handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.email && errors.email}
        />
        <div className="d-flex justify-content-between">
          <Link
            className="btn btn-primary rounded-bottom-left"
            to="/auth/login"
          >
            <i className="fas fa-unlock mr-2" /> {t("Login")}
          </Link>
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
          >
            {t("Submit")}
            <i className="fas fa-arrow-right ml-3" />
          </button>
        </div>
      </form>
    </>
  )
}

export default LoginPasswordReminderForm
