import React from "react"
import { useTranslation } from "react-i18next"

type Props = {
  disabled: boolean
  loading: boolean
  onSave: (addNew: boolean) => void
  onCancel: () => void
}

const SubmissionButtons = ({ disabled, loading, onSave, onCancel }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="col-sm-3 col-md-4 col-lg-1 offset-lg-4">
        <button
          type="button"
          className="btn btn-outline-primary btn-primary rounded-bottom-left"
          onClick={() => onCancel()}
        >
          {t("Cancel")}
        </button>
      </div>
      {!disabled && (
        <div className="col-sm-9 col-md-8 col-lg-7">
          <div className="float-right text-nowrap">
            <button
              type="submit"
              className="btn btn-success-light rounded-bottom-left"
              onClick={() => onSave(false)}
              disabled={loading}
            >
              <i className="fas fa-arrow-down mr-3" />
              {t("Save and Add new Submission")}
            </button>
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right ml-2"
              onClick={() => onSave(true)}
              disabled={loading}
            >
              {t("Save")}
              <i className="fas fa-arrow-right ml-3" />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default SubmissionButtons
