import * as Yup from "yup"

const SubmissionConverterOutputValidationShema = Yup.object().shape({
  company: Yup.object().typeError("Company is required."),
  materialGroup: Yup.object().typeError("Polymer is required."),
  date: Yup.string()
    .typeError("Need month Received.")
    .required("Month is required."),
  isMaterialFromOutsideEurope: Yup.boolean(),

  canMaterialDemonstrateForAudit: Yup.boolean(),
  totalDemonstratedDuringAudit: Yup.number().when(
    "canMaterialDemonstrateForAudit",
    {
      is: true,
      then: Yup.number()
        .required("Field is required")
        .positive("Field must be greater than 0!"),
    },
  ),
})

export const SubmissionConverterArrayOutputValidationShema = Yup.object().shape({
  submissions: Yup.array().of(SubmissionConverterOutputValidationShema)})

export default SubmissionConverterOutputValidationShema
