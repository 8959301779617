import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"

import Modal from "../../../components/Modal/Modal"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import toggleElementInArray from "../../../utils/toggleElementInArray"
import CompanyDetailsModal from "../../companies/components/ManageCompanies/CompanyDetailsModal"
import {
  getAllPlantsList,
  companiesToAttach,
} from "../../companies/service/api"
import { Company } from "../../companies/types"
import SelectableItem from "../components/SelectableItem/SelectableItem"
import {
  getAllAuditors,
  getAllRecovinylRegionalRepresentative,
  getAllSectorAssociationRepresentatives,
  attachApprovedPlants,
  userAttachPlants,
} from "../service/api"
import { User } from "../types"

const AuditorsManage = () => {
  const [loading, setLoading] = useState(false)

  const [isChildUserModalOpen, setIsChildUserModalOpen] = useState(false)
  const [isApprovePlantsModalOpen, setIsApprovePlantsModalOpen] =
    useState(false)
  const [selectedChildUser, setSelectedChildUser] = useState<User>()
  const [tab, setTab] = useState("auditors")
  const [userSelectedCompanies, setUserSelectedCompanies] = useState<number[]>(
    [],
  )
  const [companiesToApprove, setCompaniesToApprove] = useState<Company[]>([])
  const [companiesToApproveIds, setCompaniesToApproveIds] = useState<number[]>(
    [],
  )
  const [plants, setPlants] = useState<Company[]>()
  const [auditors, setAuditors] = useState<User[]>()
  const [
    recovinylRegionalRepresentatives,
    setRecovinylRegionalRepresentatives,
  ] = useState<User[]>()
  const [
    sectorAssociationRepresentatives,
    setSectorAssociationRepresentatives,
  ] = useState<User[]>()
  const { t } = useTranslation()

  const fetchAuditors = async () => {
    const response = await getAllAuditors()
    setAuditors(response?.data || [])
  }

  const fetchRecovinylRegionalRepresentatives = async () => {
    const response = await getAllRecovinylRegionalRepresentative()
    setRecovinylRegionalRepresentatives(response?.data || [])
  }

  const fetchSectorAssociationRepresentatives = async () => {
    const response = await getAllSectorAssociationRepresentatives()
    setSectorAssociationRepresentatives(response?.data || [])
  }

  const fetchCompanies = async () => {
    getAllPlantsList().then(({ data }) => {
      if (data) {
        setPlants(data)
      }
    })
  }

  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0)
  const [isSelectedCompanyPlant, setIsSelectedCompanyPlant] =
    useState<boolean>(false)

  const [isCompanyDetailsOpened, setIsCompanyDetailsOpened] =
    useState<boolean>(false)

  const onCloseCompanyDetailsModal = () => {
    setIsCompanyDetailsOpened(false)
  }

  useEffect(() => {
    fetchAuditors()
    fetchRecovinylRegionalRepresentatives()
    fetchSectorAssociationRepresentatives()
    fetchCompanies()
  }, [])

  const onClickApprovePlants = async (user: User) => {
    const { data, errors } = await companiesToAttach({
      id: Number.parseInt(user.id, 10),
    })
    if (data) {
      if (data.length > 0) {
        setSelectedChildUser(user)
        setCompaniesToApprove(data)
        setCompaniesToApproveIds(data.map((comp) => comp.id || -1))
        setIsApprovePlantsModalOpen(true)
      } else {
        fetchRecovinylRegionalRepresentatives()
      }
    } else if (errors) {
      handleToast(errors, "error")
    }
  }

  const handleOnClickAudit = (child: User) => {
    setSelectedChildUser(child)
    setIsChildUserModalOpen(true)
  }

  const changeSelectedCompanies = (id: number) =>
    setUserSelectedCompanies(toggleElementInArray(id, userSelectedCompanies))

  const submitSelectedPlants = useCallback(async () => {
    if (!loading) {
      setLoading(true)
      if (selectedChildUser) {
        const { data, errors } = await userAttachPlants({
          userId: Number.parseInt(selectedChildUser.id, 10),
          companyIds: userSelectedCompanies,
        })
        if (data) {
          if (userSelectedCompanies.length > 0) {
            const successMessage = t("Plants were attached")
            handleToast([successMessage])
          } else {
            const successMessage = t("All plants were unattached")
            handleToast([successMessage])
          }
        } else if (errors) {
          handleToast(errors, "error")
        }
      }
      fetchAuditors()
      fetchRecovinylRegionalRepresentatives()
      fetchSectorAssociationRepresentatives()
      setLoading(false)
    }
  }, [loading, selectedChildUser, t, userSelectedCompanies])

  const submitPlantsToApprove = useCallback(async () => {
    if (!loading) {
      setLoading(true)
      const { data, errors } = await attachApprovedPlants({
        userId: Number.parseInt(selectedChildUser?.id || "-1", 10),
        companyIds: companiesToApproveIds,
      })

      if (data) {
        if (companiesToApproveIds.length) {
          const successMessage = t("Plants request was accepted successfully")
          handleToast([successMessage])
        } else {
          const successMessage = t("Plants request was rejected sucessfully")
          handleToast([successMessage])
        }
      } else if (errors) {
        handleToast(errors, "error")
      }

      fetchRecovinylRegionalRepresentatives()
      setIsApprovePlantsModalOpen(false)
      setLoading(false)
    }
  }, [companiesToApproveIds, loading, selectedChildUser, t])

  const onClickPlantToApprove = (id: number) =>
    setCompaniesToApproveIds(toggleElementInArray(id, companiesToApproveIds))

  useEffect(() => {
    if (selectedChildUser) {
      const auditorPlants = selectedChildUser?.companies || []
      const plantsIds = auditorPlants.map(
        (company: Company) => company.id,
      ) as number[]
      setUserSelectedCompanies(plantsIds)
    }
  }, [selectedChildUser])

  const onChangeTabClicked = (
    e: React.MouseEvent<HTMLAnchorElement>,
    value: string,
  ): boolean => {
    e.preventDefault()
    setTab(value)
    return false
  }

  const showDetailsModal = (company: Company, isPlant = false) => {
    setIsSelectedCompanyPlant(isPlant)
    setSelectedCompanyId(company.id || 0)
    setIsCompanyDetailsOpened(true)
  }

  const singleRowInTable = (user: User) => {
    const number_of_platns = 1
    return (
      <tr>
        <td className="align-middle">{user.name}</td>
        <td className="align-middle">
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </td>
        <td className="align-middle">
          <ul className="col-12 manage-auditors-list">
            {user &&
              user.companies.map((company: Company) => {
                return (
                  <li onClick={() => showDetailsModal(company, true)}>
                    {`${company.name} ${company.archived ? " (archived)" : ""}`}
                  </li>
                )
              })}
          </ul>
        </td>
        <td className="align-middle">
          <div className="row">
            <div className="col-12">
              {tab === "recovinylRegionalRepresentatives" &&
                user.companiesToAttach &&
                user.companiesToAttach.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm text-left"
                    style={{ width: "100%" }}
                    onClick={() => onClickApprovePlants(user)}
                  >
                    <i className="fas fa-align-left" /> {t("Approve plants")}
                  </button>
                )}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm text-left mt-1"
                style={{ width: "100%" }}
                onClick={() => handleOnClickAudit(user)}
              >
                <i className="fas fa-align-left" />{" "}
                {t("Attach/Unattach Plant(s)")}
              </button>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <>
      <h3 className="mb-3">{t("Manage RRR, Auditors and SAR")}</h3>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a
                href="#auditors"
                className={`nav-link ${tab === "auditors" ? "active" : ""} `}
                data-toggle="pill"
                role="tab"
                aria-controls="pills-inputData"
                aria-selected="true"
                onClick={(e) => onChangeTabClicked(e, "auditors")}
              >
                {t("Auditors")}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#recovinylRegionalRepresentatives"
                className={`nav-link ${
                  tab === "recovinylRegionalRepresentatives" ? "active" : ""
                }`}
                data-toggle="pill"
                role="tab"
                aria-controls="pills-outputData"
                aria-selected="false"
                onClick={(e) =>
                  onChangeTabClicked(e, "recovinylRegionalRepresentatives")
                }
              >
                {t("Recovinyl Regional Representatives")}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#sectorAssociationRepresentatives"
                className={`nav-link ${
                  tab === "sectorAssociationRepresentatives" ? "active" : ""
                }`}
                data-toggle="pill"
                role="tab"
                aria-controls="pills-outputData"
                aria-selected="false"
                onClick={(e) =>
                  onChangeTabClicked(e, "sectorAssociationRepresentatives")
                }
              >
                {t("Sector Associations Representatives")}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th>{t("Name")}</th>
            <th>{t("Email")}</th>
            <th>{t("Plant(s)")}</th>
            <th>{t("Actions")}</th>
          </tr>
          {tab === "auditors" &&
            auditors &&
            auditors.map((auditor) => {
              return singleRowInTable(auditor)
            })}
          {tab === "recovinylRegionalRepresentatives" &&
            recovinylRegionalRepresentatives &&
            recovinylRegionalRepresentatives.map(
              (recovinylRegionalRepresentative) => {
                return singleRowInTable(recovinylRegionalRepresentative)
              },
            )}
          {tab === "sectorAssociationRepresentatives" &&
            sectorAssociationRepresentatives &&
            sectorAssociationRepresentatives.map(
              (sectorAssociationRepresentative) => {
                return singleRowInTable(sectorAssociationRepresentative)
              },
            )}
        </thead>
      </table>

      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left mr-2" /> {t("Back")}
        </button>
      </div>
      <Modal
        isOpen={isApprovePlantsModalOpen}
        isOpenHandler={setIsApprovePlantsModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <div className="p-5">
          <h2>{t("Approve Plants")}</h2>
          <div
            className="mt-5"
            style={{
              height: "50vh",
              overflow: "scroll",
            }}
          >
            {companiesToApprove &&
              companiesToApprove.map((plant) => {
                return (
                  <SelectableItem
                    {...{
                      key: plant.id,
                      text: plant.name,
                      isSelected: companiesToApproveIds.includes(
                        plant.id || -1,
                      ),
                      onClickHandler: () =>
                        plant.id && onClickPlantToApprove(plant.id),
                    }}
                  />
                )
              })}
          </div>
          <div className="d-flex justify-content-end mt-5">
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right"
              onClick={submitPlantsToApprove}
              disabled={loading}
            >
              {t("Approve plants")} <i className="fas fa-plus ml-3" />
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isChildUserModalOpen}
        isOpenHandler={setIsChildUserModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <div
          className="p-5"
          style={{
            height: "70vh",
            overflow: "scroll",
          }}
        >
          <h2>{t("Select Plants")}</h2>
          {plants &&
            plants.map((plant) => {
              const isSelected = userSelectedCompanies.includes(
                plant?.id as number,
              )
              if (!plant.archived) {
                return (
                  <SelectableItem
                    {...{
                      key: plant.id,
                      text: plant.name,
                      isSelected,
                      onClickHandler: () =>
                        plant.id && changeSelectedCompanies(plant.id),
                    }}
                  />
                )
              }
            })}
          <div className="d-flex justify-content-end mt-5">
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right"
              onClick={submitSelectedPlants}
              disabled={loading}
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </Modal>
      <CompanyDetailsModal
        {...{
          companyId: selectedCompanyId,
          isPlant: isSelectedCompanyPlant,
          closeModal: onCloseCompanyDetailsModal,
          isCompanyDetailsOpened,
        }}
      />
    </>
  )
}

export default AuditorsManage
