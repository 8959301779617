import { useFormik } from "formik"
import React, {
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useCallback,
  FormEvent,
} from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRouteMatch, Link } from "react-router-dom"

import FormError from "components/FormError/FormError"
import Input from "components/Input/Input"
import Label from "components/Label/Label"
import Select, {
  mapToOptions,
  BaseSelectedValue,
} from "components/Select/Select"
import { TreeNode } from "components/Tree/Tree"

import { isDaysAfter } from "utils/dates"
import { handleToast } from "utils/messages"
import { isLikeNumber, fromPercent } from "utils/numbers"
import { history } from "utils/routes"
import { cleanStringLikeNumber } from "utils/strings"
import {
  windowStorage,
  setUserMetadata,
  getUserMetadata,
} from "utils/windowStorage"

import useMaxNumberState from "hooks/useMaxNumberState"
import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { ACTIVE_COMPANY_KEY } from "../../../../constants"
import { getPlants } from "../../../companies/service/api"
import { Company } from "../../../companies/types"
import { compositionGetByCompanyId } from "../../../compositions/service/api"
import { Composition } from "../../../compositions/types"
import { fetchCountries } from "../../../countries/actions"
import { Country } from "../../../countries/types"
import { filterCompanyCompositions } from "../../../genericCompositions/utils"
import { fetchMaterialGroups } from "../../../materials/actions"
import {
  createSubmissions,
  setSelectedCompany,
  fetchSelectedCompany,
} from "../../actions"
import {
  SUBMISSION_DATE_FORMAT,
  CAN_EDIT_SUBMISSION_TO_DAYS,
  SELECTED_COMPANY_KEY,
} from "../../constants"
import { putSubmission } from "../../service/api"
import { Submission, ContaminationUnit } from "../../types"
import SubmissionButtons from "./SubmissionButtons"
import SubmissionFormInputPartials from "./SubmissionFormInputPartials"
import SubmissionFormOutputPartials from "./SubmissionFormOutputPartials"
import SubmissionFormValidationShema from "./SubmissionFormValidationShema"
import SubmissionOutputFormValidationShema from "./SubmissionOutputFormValidationShema"

const getNetVolumeEndData = (
  volume: number,
  contamination: number,
  contaminationUnit: ContaminationUnit,
): number | undefined => {
  let result
  if (contaminationUnit === ContaminationUnit.PERCENT) {
    if (contamination <= 100) {
      const percent = fromPercent(volume, contamination)
      result = volume - percent
    }
  } else {
    result = volume - contamination
  }

  return result !== undefined ? parseFloat(result.toFixed(2)) : result
}

/* Set date to -1 month in form */
const actualDate = new Date()
actualDate.setMonth(actualDate.getMonth() - 1)

const INITIAL_FORM_VALUES = {
  company: null,
  country: null,
  composition: null,
  materialGroup: null,
  date: actualDate,
  volume: 0,
  contamination: 0,
  grossOutputNonEU: 0,
  grossOutputEU: 0,
  netOutputEU: 0,
  netOutputNonEU: 0,
  totalPercentageOfRecycledPlasticsInFrance: 0,
  virginAdditives: 0,
  isInput: false,
  administrationNumber: "",
}

interface Props {
  initialSubmission?: Submission
  disabled?: boolean
  isEdit?: boolean
}

const SubmissionForm: React.FC<Props> = ({
  initialSubmission,
  disabled = false,
  isEdit = false,
}) => {
  const dispatch = useDispatch()

  const redirectConfigs = {
    route: isEdit ? "/submissions" : "/submissions/new",
    title: isEdit ? "Select Submission type:" : "Recycling Activity",
  }

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const [isExitOnSubmit, setIsExitOnSubmit] = useState(false)
  const [contaminationState, setContaminationState] = useState<string>("0")
  const [contaminationUnit, setContaminationUnit] = useState<ContaminationUnit>(
    ContaminationUnit.PERCENT,
  )
  const [netVolume, setNetVolume] = useState<number>(0)
  const [companies, setCompanies] = useState<Company[]>([])
  const [isSubmissionInput, setIsSubmissionInput] = useState<boolean>()
  const [compositions, setCompositions] = useState<Composition[]>()
  const [plants, setPlants] = useState<Company[]>()
  const [currentPlant, setCurrentPlant] = useState<Company>()
  const [countries, setCountries] = useState<Country[]>([])
  const { id: userId, isAuditor, isRrr, isAdmin } = useUserFromToken()
  const countriesEu = useStateGetter<Country[]>(["country", "countries"]) ?? []

  const selectedComapny =
    useStateGetter<any>(["submission", "selectedCompany"]) ?? undefined
  const [virginAndAdditives, setVirginAndAdditives] = useMaxNumberState()

  const compositionsArray = useMemo(
    () =>
      compositions
        ? filterCompanyCompositions<Composition>(
            compositions,
            selectedComapny,
            true,
          )
        : [],
    [compositions, selectedComapny],
  )
  const isInitialSubmission = React.useMemo(
    () => !!initialSubmission,
    [initialSubmission],
  )
  const isUpdateMode = isInitialSubmission && disabled === false
  const activeCompany = windowStorage.get(ACTIVE_COMPANY_KEY, null, false)

  const clearSelectedCompany = () => {
    windowStorage.remove(SELECTED_COMPANY_KEY, false)
    dispatch(fetchSelectedCompany())
  }

  useEffect(() => {
    countriesEu.push({
      id: 999,
      name: "Other",
      code: "NONEU",
    } as Country)
    setCountries(countriesEu)
  }, [countriesEu])

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchSelectedCompany())
    getPlants().then((d) => {
      const fetchedPlants = d.data
      const plant = fetchedPlants?.find(
        (a) => a.id == initialSubmission?.company?.id,
      )

      setPlants(fetchedPlants)
      setCurrentPlant(plant)
    })
  }, [dispatch])

  const getPreSelectCompany = () => {
    const formikCompany = values.company as unknown as { value: number }
    let preSelectCompany

    if (companies.length === 0) {
      return preSelectCompany
    }

    if (companies.length === 1) {
      preSelectCompany = companies[0]
    } else if (
      selectedComapny !== undefined &&
      selectedComapny?.id !== formikCompany?.value
    ) {
      preSelectCompany = selectedComapny
    }

    return preSelectCompany
  }

  const handleOnSubmit = async (
    {
      company,
      country,
      composition,
      materialGroup,
      date,
      volume,
      contamination,
      grossOutputEU,
      grossOutputNonEU,
      netOutputEU,
      netOutputNonEU,
      totalPercentageOfRecycledPlasticsInFrance,
      virginAdditives,
      isInput,
      administrationNumber,
    }: {
      company: BaseSelectedValue<number> | null
      country: BaseSelectedValue<number> | null
      composition: BaseSelectedValue<number> | null
      materialGroup: BaseSelectedValue<number> | null
      date: Date
      volume: number
      contamination: number
      grossOutputEU: number
      grossOutputNonEU: number
      netOutputEU: number
      netOutputNonEU: number
      totalPercentageOfRecycledPlasticsInFrance: number
      virginAdditives: number
      isInput: boolean
      administrationNumber: string
    },
    { resetForm }: { resetForm: () => void },
  ) => {
    if (!loading) {
      setLoading(true)
      const companyId = company?.value
      const countryId = isSubmissionInput ? country?.value : null
      const compositionId = isSubmissionInput ? composition?.value : null
      const materialsGroupId = !isSubmissionInput ? materialGroup?.value : undefined

      const selectedCountry = countries.find(
        (countryFromList) => countryFromList.id === country?.value,
      )

      const isMaterialFromOutsideEurope =
        isSubmissionInput && !selectedCountry?.isEu ? true : false

      if (isSubmissionInput) {
        isInput = isSubmissionInput
      }

      let volumeGross = volume

      if (typeof volume === "string") {
        volumeGross = parseInt(volume, 0)
      }

      if (isSubmissionInput) {
        if (!compositionId || !countryId) {
          return
        }
      } else if (!materialsGroupId) {
        return
      }

      const canRemoveSubmission = (createdAt: Date | undefined) => {
        // Nick asked to save this logic for future
        // if (isAdmin) {
        //   return true
        // }
        // if (createdAt && isDaysAfter(CAN_EDIT_SUBMISSION_TO_DAYS, createdAt)) {
        //   return false
        // }

        return true
      }

      if (companyId) {
        const credentails = {
          companyId,
          countryId,
          compositionId,
          materialsGroupId,
          collectionDate: date,
          volumeGross,
          contaminationUnit,
          contaminationPercentage: parseInt(contaminationState, 10),
          volumeNet: netVolume,
          grossOutputEU,
          grossOutputNonEU,
          netOutputEU,
          netOutputNonEU,
          totalPercentageOfRecycledPlasticsInFrance,
          virginAdditives,
          isInput,
          administrationNumber,
          isMaterialFromOutsideEurope,
          successCallback: () => {
            setLoading(false)
            if (isExitOnSubmit === false) {
              resetForm()
            } else {
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: isSubmissionInput ? "input" : "output",
              })
            }
          },
        }

        if (isUpdateMode && initialSubmission?.id) {
          const { createdAt } = initialSubmission
          if (canRemoveSubmission(createdAt)) {
            try {
              const { errors } = await putSubmission({
                ...credentails,
                ...{ id: initialSubmission.id },
              })
              if (errors) {
                handleToast(errors, "error")
              } else {
                handleToast(["Submission updated"])
                if (!isExitOnSubmit) {
                  history.push(redirectConfigs.route)
                } else {
                  history.push(redirectConfigs.route, {
                    title: t(redirectConfigs.title),
                    tab: isSubmissionInput ? "input" : "output",
                  })
                }
              }
              // @ts-ignore
            } catch (error) {
              // @ts-ignore
              handleToast([error.message], "error")
            }
          } else {
            handleToast(
              [
                `Delete action is disabled because this submission was added more than ${CAN_EDIT_SUBMISSION_TO_DAYS} days ago.`,
              ],
              "warning",
            )
          }
        } else {
          await dispatch(createSubmissions(credentails))
          setUserMetadata(userId, { contamination })
        }
        clearSelectedCompany()
      }
    }
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: isSubmissionInput
      ? SubmissionFormValidationShema
      : SubmissionOutputFormValidationShema,
    onSubmit: handleOnSubmit,
  })

  const { values, errors, setFieldValue, touched, handleSubmit } = formik

  useEffect(() => {
    if (!disabled && !isUpdateMode) {
      const company = getPreSelectCompany()
      if (company) {
        setFieldValue("company", mapToOptions([company])[0])
      }
    }
  }, [
    companies,
    disabled,
    getPreSelectCompany,
    isUpdateMode,
    selectedComapny,
    setFieldValue,
  ])

  const {
    volume,
    grossOutputNonEU,
    grossOutputEU,
    netOutputEU,
    netOutputNonEU,
    administrationNumber,
  } = values

  if (activeCompany !== null && values.company === null) {
    setFieldValue("company", {
      label: activeCompany.name,
      value: activeCompany.id,
    })
  }

  useEffect(() => {
    dispatch(fetchMaterialGroups())
  }, [dispatch])

  const materialGroups =
    useStateGetter<TreeNode[]>(["material", "materialGroups"]) ?? []

  const onSetVirginAndAdditives = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const numberValue = +value
    setVirginAndAdditives(numberValue)
    setFieldValue("virginAdditives", numberValue)
    const netOutputEU =
      values.grossOutputEU - values.grossOutputEU * (numberValue / 100)
    const netOutputNonEU =
      values.grossOutputNonEU - values.grossOutputNonEU * (numberValue / 100)
    setFieldValue("netOutputEU", parseFloat(netOutputEU.toFixed(2)))
    setFieldValue("netOutputNonEU", parseFloat(netOutputNonEU.toFixed(2)))
    setNetVolume(netOutputEU + netOutputNonEU)
  }

  const onSetVirginAndAdditivesSlider = (value: number) => {
    const numberValue = +value
    setVirginAndAdditives(numberValue)
    setFieldValue("virginAdditives", numberValue)
    const netOutputEU =
      values.grossOutputEU - values.grossOutputEU * (numberValue / 100)
    const netOutputNonEU =
      values.grossOutputNonEU - values.grossOutputNonEU * (numberValue / 100)
    setFieldValue("netOutputEU", parseFloat(netOutputEU.toFixed(2)))
    setFieldValue("netOutputNonEU", parseFloat(netOutputNonEU.toFixed(2)))
    setNetVolume(netOutputEU + netOutputNonEU)
  }

  const handleContaminationChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setContaminationState(`${value}`)
      setFieldValue("contamination", value)
    } else {
      setContaminationState("")
      setFieldValue("contamination", 0)
    }
  }

  const handleSetGrossOutputEUChange = (value: any) => {
    const parsedValue = parseFloat(value)
    setFieldValue("grossOutputEU", parsedValue)
    setFieldValue("netOutputEU", parsedValue)
    setFieldValue("volume", parsedValue + grossOutputNonEU)
  }

  const handleSetGrossOutputNonEUChange = (value: any) => {
    const parsedValue = parseFloat(value)
    setFieldValue("grossOutputNonEU", parsedValue)
    setFieldValue("netOutputNonEU", parsedValue)
    setFieldValue("volume", parsedValue + grossOutputEU)
  }

  const handleSetNetOutputEUChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const parsedValue = parseInt(value, 0)
    setFieldValue("netOutputNetEU", parsedValue)
  }

  const handleSetNetOutputNonEUChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const parsedValue = parseInt(value, 0)
    setFieldValue("netOutputnetNonEU", parsedValue)
  }

  const handleInputTotalIncomeWasteChange = (value: any) => {
    const parsedValue = parseFloat(value)
    setFieldValue("volume", parsedValue)
  }

  const handleInputAdministrationNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    setFieldValue("administrationNumber", value)
  }

  const handleTotalPercentageOfRecycledPlasticsInFranceChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = 0

    if (event.target && event.target.value) {
      value = parseInt(event.target.value, 10)
      if (value > 100) {
        value = 100
      }
      if (value < 0) {
        value = 0
      }
      setFieldValue("totalPercentageOfRecycledPlasticsInFrance", value)
    } else {
      setFieldValue("totalPercentageOfRecycledPlasticsInFrance", 0)
    }
  }

  useEffect(() => {
    if (initialSubmission) {
      const {
        company,
        country,
        collectionDate,
        composition,
        volumeGross,
        contaminationUnit: initialContaminationUnit,
        volumeNet,
        grossOutputEU,
        grossOutputNonEU,
        netOutputEU,
        netOutputNonEU,
        totalPercentageOfRecycledPlasticsInFrance,
        virginAdditives,
        administrationNumber,
        materialsGroup,
        estimatedShareExportedInsideEu,
        estimatedShareExportedOutsideEu,
      } = initialSubmission
      setFieldValue("company", { value: company.id, label: company.name })
      if (country) {
        setFieldValue("country", { value: country.id, label: country.name })
      }
      setFieldValue("date", new Date(collectionDate))
      if (composition) {
        setFieldValue("composition", {
          value: composition.id,
          label: composition.productName,
        })
      }
      setFieldValue("volume", volumeGross)
      if (initialContaminationUnit) {
        setContaminationUnit(initialContaminationUnit)
        if (initialContaminationUnit === ContaminationUnit.KG) {
          setFieldValue("contamination", volumeGross - volumeNet)
        } else if (initialContaminationUnit === ContaminationUnit.PERCENT) {
          const roundCont = Math.round((1 - volumeNet / volumeGross) * 100)
          setContaminationState(roundCont.toString())
          setFieldValue("contamination", roundCont)
        }
      }
      if (contaminationState) {
        setNetVolume(volumeNet)
      }
      setFieldValue("grossOutputEU", grossOutputEU)
      setFieldValue("grossOutputNonEU", grossOutputNonEU)
      // setFieldValue(
      //   "grossOutputEU",
      //   volumeNet -
      //     ((estimatedShareExportedOutsideEu / 100) * volumeNet +
      //       (estimatedShareExportedInsideEu / 100) * volumeNet),
      // )
      // setFieldValue(
      //   "grossOutputNonEU",
      //   (estimatedShareExportedOutsideEu / 100) * volumeNet +
      //     (estimatedShareExportedInsideEu / 100) * volumeNet,
      // )
      setFieldValue("netOutputEU", netOutputEU)
      setFieldValue("netOutputNonEU", netOutputNonEU)
      setFieldValue(
        "totalPercentageOfRecycledPlasticsInFrance",
        totalPercentageOfRecycledPlasticsInFrance,
      )
      setFieldValue("virginAdditives", virginAdditives)
      setFieldValue("administrationNumber", administrationNumber)

      setVirginAndAdditives(virginAdditives)

      compositionGetByCompanyId({ companyId: company.id }).then(
        ({ data, errors }) => {
          if (errors) {
            handleToast(errors, "error")
          }
          if (data) {
            setCompositions(data)
          }
        },
      )

      if (materialGroups.length > 0 && initialSubmission?.materialsGroup) {
        const findOption = mapToOptions(materialGroups).find(
          (x) => x.value === initialSubmission.materialsGroup?.id,
        )
        setFieldValue("materialGroup", findOption)
      } else {
        setFieldValue("materialGroup", mapToOptions([materialsGroup]))
      }

      if (
        localStorage.getItem("goto") !== null &&
        localStorage.getItem("goto")
      ) {
        localStorage.removeItem("goto")
      }
    }
  }, [initialSubmission])

  const companyAutoComplete = useCallback(
    (fullCompany: Company) => {
      setFieldValue("country", {
        label: fullCompany.country?.name,
        value: fullCompany.country?.id,
      })

      compositionGetByCompanyId({ companyId: fullCompany.id }).then(
        ({ data, errors }) => {
          if (errors) {
            handleToast(errors, "error")
          }
          if (data) {
            setCompositions(data)
          }
        },
      )
    },
    [setFieldValue],
  )

  useEffect(() => {
    // const stringVolume = volume.toString() as string;
    const cleanVolume = cleanStringLikeNumber(volume)
    const cleanContamination = cleanStringLikeNumber(contaminationState)
    if (isLikeNumber(cleanVolume) && isLikeNumber(cleanContamination)) {
      const volumeNumber = +cleanVolume
      const contaminationNumber = +cleanContamination
      const result = getNetVolumeEndData(
        volumeNumber,
        contaminationNumber,
        contaminationUnit,
      )
      setNetVolume(result ?? 0)
    }
  }, [volume, contaminationState, contaminationUnit])

  const userMetadata = getUserMetadata(userId)
  const userMetadataContamination = userMetadata?.contamination

  useEffect(() => {
    if (!disabled && isSubmissionInput && !isUpdateMode) {
      const compositionValue = values.composition as BaseSelectedValue | null
      let contaminationPercentage = 0
      if (compositionValue && compositionValue?.value) {
        const composition = compositionsArray.find(
          (v) => compositionValue?.value === v.id,
        )
        if (composition && composition?.contaminationPercentage) {
          contaminationPercentage = composition.contaminationPercentage
        }
      }
      setContaminationState(`${contaminationPercentage}`)
    }
  }, [
    disabled,
    isUpdateMode,
    compositionsArray,
    values.company,
    values.composition,
  ])

  useEffect(() => {
    if (!disabled && isSubmissionInput && !isUpdateMode) {
      if (compositionsArray.length === 1) {
        setFieldValue("composition", {
          value: compositionsArray[0].id,
          label: compositionsArray[0].productName,
        })
      } else {
        setFieldValue("composition", null)
      }
    }
  }, [disabled, isUpdateMode, compositionsArray, setFieldValue])

  const { params }: { params: { type: string | undefined } } = useRouteMatch()

  useEffect(() => {
    if (params.type === "input") {
      setIsSubmissionInput(true)
    } else {
      setIsSubmissionInput(false)
    }
  }, [params.type])

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isSubmissionInput && !grossOutputEU && !grossOutputNonEU) {
      formik.setFieldError(
        "grossOutputEU",
        "Net output recycled material to EU + UK or to Non EU have to be more than 0",
      )
      formik.setFieldError(
        "grossOutputNonEU",
        "Net output recycled material to EU + UK or to Non EU have to be more than 0",
      )
    } else {
      handleSubmit()
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-5">
        {isAuditor && (
          <Link to="/applications">
            <button
              type="button"
              className="btn btn-outline-info rounded-top-right float-right"
            >
              <i className="fas fa-align-left mr-3" /> {t("Show Applications")}
            </button>
          </Link>
        )}
      </div>
      <form onSubmit={onFormSubmit}>
        {companies && (
          <Select
            name="company"
            label={`${t("Plant name")} (*)`}
            options={mapToOptions(plants ?? [])}
            value={values.company}
            handleOnChange={(selectedOption: any) => {
              setFieldValue("company", selectedOption)
              const fullCompany = plants?.find(
                (c) => c.id === selectedOption.value,
              ) as Company
              setCurrentPlant(fullCompany)

              if (fullCompany) {
                companyAutoComplete(fullCompany)

                windowStorage.remove(ACTIVE_COMPANY_KEY, false)
                windowStorage.set(SELECTED_COMPANY_KEY, fullCompany, false)
                dispatch(setSelectedCompany({ selectedCompany: fullCompany }))
              }
            }}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.company && errors.company}
            isDisabled={disabled}
          />
        )}
        <div className="form-group row align-items-center ">
          <div className="col-sm-4">
            <Label
              label={
                <div>
                  {t(
                    isSubmissionInput
                      ? "Month Received" + " (*)"
                      : "Date sold (recycler) / Date used in production (converter)",
                  )}
                  <span
                    className="text-primary"
                    data-tip="If you are reporting for a period longer than a month – select the end date of that period"
                  >
                    {" "}
                    (i)
                  </span>
                </div>
              }
              name="."
              labelClassName=""
            />
          </div>
          <div className="col-sm-8">
            <DatePicker
              className="form-control"
              selected={values.date}
              dateFormat={SUBMISSION_DATE_FORMAT}
              showMonthYearPicker
              showPopperArrow={false}
              onChange={(date: Date) => setFieldValue("date", date)}
              disabled={disabled}
            />
            <FormError error={touched.date && (errors.date as string)} />
          </div>
        </div>

        {isSubmissionInput && (
          <>
            <div className="srs-select form-group row">
              {t("Waste origin") && (
                <Label
                  label={`${t("Waste origin")} (*)`}
                  name="composition"
                  labelClassName="col-sm-4"
                />
              )}
              {!disabled && (
                <div className="col-sm-4">
                  <Link
                    className={
                      values.company
                        ? "btn btn-outline-primary w-100"
                        : "disabled-link btn btn-outline-primary w-100"
                    }
                    to="/waste-origin"
                  >
                    {t("Add New Waste")}
                  </Link>
                </div>
              )}
              <div className={disabled ? "col-sm-8" : "col-sm-4"}>
                <Select
                  name="composition"
                  options={mapToOptions(compositionsArray, "productName")}
                  value={values.composition}
                  handleOnChange={(selectedOption: any) => {
                    // setFieldValue("materialGroup", selectedOption)
                    setFieldValue("composition", selectedOption)
                  }}
                  isDisabled={disabled}
                />
                <FormError error={touched.composition && errors.composition} />
              </div>
            </div>
            <Select
              name="country"
              label={`${t("Waste received from")} (*)`}
              options={mapToOptions(countries)}
              value={values.country}
              handleOnChange={(selectedOption) => {
                setFieldValue("country", selectedOption)
              }}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              error={touched.country && errors.country}
              isDisabled={disabled}
            />
            <SubmissionFormInputPartials
              errors={errors}
              touched={touched}
              values={values}
              disabled={disabled}
              contaminationUnit={contaminationUnit}
              setContaminationUnit={setContaminationUnit}
              handleContaminationChange={handleContaminationChange}
              setTotalIncomeWaste={handleInputTotalIncomeWasteChange}
              netVolume={netVolume}
              contamination={contaminationState}
            />
          </>
        )}
        {!isSubmissionInput && (
          <>
            {/* {console.log(values.materialGroup)} */}
            <Select
              name="materialGroup"
              label={t("Polymer")}
              options={mapToOptions(currentPlant?.polymers || [])}
              value={values.materialGroup}
              handleOnChange={(selectedOption) => {
                setFieldValue("materialGroup", selectedOption)
              }}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              error={touched.materialGroup && errors.materialGroup}
              isDisabled={disabled}
            />
            <SubmissionFormOutputPartials
              grossOutputEU={grossOutputEU}
              grossOutputNonEU={grossOutputNonEU}
              netOutputEU={netOutputEU}
              netOutputNonEU={netOutputNonEU}
              setGrossOutputEU={handleSetGrossOutputEUChange}
              setGrossOutputNonEU={handleSetGrossOutputNonEUChange}
              setNetOutputEU={handleSetNetOutputEUChange}
              setNetOutputNonEU={handleSetNetOutputNonEUChange}
              disabled={disabled}
              virginAndAdditives={virginAndAdditives}
              onSetVirginAndAdditives={onSetVirginAndAdditives}
              onSetVirginAndAdditivesSlider={onSetVirginAndAdditivesSlider}
              grossOutputEUError={errors.grossOutputEU}
              grossOutputNonEUError={errors.grossOutputNonEU}
            />
            {currentPlant?.country?.code ===
            countries.find((country) => country.code === "FR")?.code ? (
              <div className="srs-input-extra-content">
                <Input
                  type="number"
                  label={`${t(
                    "Percentage output recycled materials to France (%)",
                  )} (*)`}
                  name="totalPercentageOfRecycledPlasticsUsedInProductsInFrance"
                  min="0"
                  max="100"
                  placeholder={t("Add total percentage")}
                  handleOnChange={
                    handleTotalPercentageOfRecycledPlasticsInFranceChange
                  }
                  inputWrapperClassName="col-sm-8"
                  inputStyle={{ paddingRight: "110px" }}
                  labelClassName="col-sm-4"
                  error={
                    touched.totalPercentageOfRecycledPlasticsInFrance &&
                    errors.totalPercentageOfRecycledPlasticsInFrance
                  }
                  value={values.totalPercentageOfRecycledPlasticsInFrance || 0}
                  isDisabled={disabled}
                />
                <div className="srs-input-extra-content-body">
                  <button type="button" className="btn btn-primary" disabled>
                    %
                  </button>
                </div>
              </div>
            ) : null}
          </>
        )}
        <div className="mt-5 mb-3 h5 font-weight-bold">
          {t("Extra Information")}
        </div>
        <div className="form-group row align-items-center">
          <div className="col-12">
            <Input
              name="administration_number"
              value={administrationNumber}
              label={t("Administration Number")}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              handleOnChange={handleInputAdministrationNumberChange}
              isDisabled={disabled}
              infoTooltip="Optionally add a reference to supporting documents to facilitate the audit"
            />
          </div>
        </div>
        <div className="row my-4">
          <SubmissionButtons
            disabled={disabled}
            loading={loading}
            onSave={setIsExitOnSubmit}
            onCancel={() => {
              clearSelectedCompany()
              history.push(redirectConfigs.route, {
                title: t(redirectConfigs.title),
                tab: isSubmissionInput ? "input" : "output",
              })
            }}
          />
        </div>
      </form>
    </>
  )
}

export default SubmissionForm
