import axios from "axios"
import i18next from "i18next"
import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"

import { ACTIVE_LANGUAGE_KEY, DEFAULT_LANGUAGE } from "../../constants"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import { handleToast } from "../../utils/messages"
import "./UserSelect.scss"

const LANGS = [
  ["български", "bg", "BG"], // Bulgarian
  ["Česky", "cz", "CZ"],
  ["Deutsch", "de", "DE"],
  ["English", "uk", "UK"],
  ["Español", "es", "ES"],
  ["Français", "fr", "FR"],
  ["Italiano", "it", "IT"],
  ["Polski", "pl", "PL"],
  ["Português", "pt", "PT"],
  ["Română", "ro", "RO"],
]

const LangSelect = () => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLHeadingElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem(ACTIVE_LANGUAGE_KEY) ?? DEFAULT_LANGUAGE,
  )
  const selectedLangName = useMemo(() => {
    const lang = LANGS.find((l) => l[1] === selectedLang)
    return lang ? lang[0] : "Language"
  }, [selectedLang])

  useOnClickOutside(ref, () => setIsOpen(false))

  interface Translation {
    id: number
    label: string
    translation: string
  }

  const getTranslations = useCallback((lang: string) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}translates/${lang.toUpperCase()}`,
      method: "GET",
    })
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          const tranlsations = response.data

          tranlsations.map((translation: Translation) => {
            i18next.addResource(
              lang.toLowerCase(),
              "translation",
              translation.label,
              translation.translation,
            )
          })
          localStorage.setItem(ACTIVE_LANGUAGE_KEY, lang)
          i18next.changeLanguage(lang)
        } else {
          throw new Error("No translations")
        }
      })
      .catch(() => {
        handleToast(
          ["Could not load translations for the selected language."],
          "errors",
        )
        setSelectedLang(DEFAULT_LANGUAGE)
      })
  }, [])

  useEffect(() => {
    if (LANGS.find((l) => l[1] === selectedLang)) {
      getTranslations(selectedLang)
    } else {
      setSelectedLang(DEFAULT_LANGUAGE)
    }
  }, [dispatch, getTranslations, selectedLang])

  const changeLanguage = (lang: string) => {
    setSelectedLang(lang.toLowerCase())
    setIsOpen(!isOpen)
  }

  return (
    <div className="srs-dropdown srs-dropdown-sm ml-4" ref={ref}>
      <div
        className={`srs-dropdown-inner ${
          isOpen ? "srs-dropdown-inner-open" : ""
        }`}
      >
        <button
          type="button"
          className="btn srs-dropdown-btn"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{selectedLangName}</span>
          <i
            className={`srs-dropdown-icon fas ${
              !isOpen ? "fa-chevron-down" : "fa-chevron-up"
            }`}
          />
        </button>
        {isOpen ? (
          <div className="srs-dropdown-list">
            {LANGS?.map((lang) => {
              return (
                <button
                  key={lang[1]}
                  type="button"
                  className={`btn srs-dropdown-btn srs-dropdown-btn-inner ${
                    selectedLang === lang[1]
                      ? "srs-dropdown-btn-inner-selected"
                      : ""
                  }`}
                  onClick={() => changeLanguage(lang[1])}
                >
                  <div>{lang[0]}</div>
                </button>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default LangSelect
