export const isLikeNumber = (value: number | string): boolean => {
  const n = +value
  if (!Number.isNaN(n)) return true

  return false
}

export const fromPercent = (value: number, percent: number) =>
  (percent / 100) * value

export const toPercent = (obtained: number, total: number) =>
  ((100 * obtained) / total).toFixed(2)
