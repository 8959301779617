import moment from "moment"

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_USER_MANAGEMENT,
} from "../constants"

export const isDaysAfter = (days: number, date: Date) =>
  moment().subtract(days, "days").isAfter(date)

export const dateFormat = (
  date: Date | string,
  format: string = DEFAULT_DATE_FORMAT,
) => moment(date).format(format)

export const dateFormatUserManagement = (
  date: Date | string,
  format: string = DEFAULT_DATE_FORMAT_USER_MANAGEMENT,
) => moment(date).format(format)

export const yearFormat = (date: Date | string, format = "yyyy") =>
  moment(date).format(format)
