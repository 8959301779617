import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import Modal from "../../../components/Modal/Modal"
import useUserFromToken from "../../../hooks/useUserFromToken"
import { history } from "../../../utils/routes"
import { windowStorage } from "../../../utils/windowStorage"
import { SHOW_REGISTER_DONE_MODAL } from "../../submissions/constants"

const RegistrationFinishedModal = () => {
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isRecycler, registrationFinished, isRecyclerAndConverter } =
    useUserFromToken()

  useEffect(() => {
    if (registrationFinished !== null) {
      if (registrationFinished) {
        const showRegistrationModal = windowStorage.get(
          SHOW_REGISTER_DONE_MODAL,
          null,
          false,
        )
        if (showRegistrationModal) {
          windowStorage.remove(SHOW_REGISTER_DONE_MODAL, false)
          if (
            showRegistrationModal === "recycler" &&
            isRecycler &&
            isRecyclerAndConverter
          ) {
            setIsModalOpen(true)
          }
        }
      } else {
        history.push("/auth/finish-registration")
      }
    }
  }, [isRecycler, isRecyclerAndConverter, registrationFinished])

  const goToManageAccount = () => {
    setIsModalOpen(false)
    history.push("/users/account")
  }

  return (
    <Modal isOpen={isModalOpen} isOpenHandler={setIsModalOpen}>
      <h2>{t("Registration finished")}</h2>
      <div className="my-4">
        <p>{t("Thanks for finishing the registration process.")}</p>
        <p>
          {t(
            "If you want to view or edit your company, plants or users details, please go to Manage Account.",
          )}
        </p>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => goToManageAccount()}
        >
          {t("Manage account")}
        </button>
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-right"
          onClick={() => setIsModalOpen(false)}
        >
          {t("Close")}
        </button>
      </div>
    </Modal>
  )
}

export default RegistrationFinishedModal
