import { CompositionActions } from "./actions"
import { FETCH_COMPOSITIONS } from "./constants"
import { Composition } from "./types"

export interface CompositionState {
  compositions: Composition[]
}

const initialState: CompositionState = {
  compositions: [],
}

const composition = (
  state: CompositionState = initialState,
  action: CompositionActions,
) => {
  switch (action.type) {
    case FETCH_COMPOSITIONS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default composition
