import { MaterialActions } from "./actions"
import { FETCH_MATERIALS, FETCH_MATERIAL_GROUPS } from "./constants"
import { Material, MaterialGroup } from "./service/api"

export interface MaterialState {
  materials: Material[]
  materialGroups: MaterialGroup[]
}

const initialState: MaterialState = {
  materials: [],
  materialGroups: [],
}

const material = (
  state: MaterialState = initialState,
  action: MaterialActions,
) => {
  switch (action.type) {
    case FETCH_MATERIALS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_MATERIAL_GROUPS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default material
