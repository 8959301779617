/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"

import "./Modal.scss"

export interface Props {
  isOpen: boolean
  wrapperClass?: string
  isOpenHandler: (isOpen: boolean) => void
}

const Modal: React.FC<Props> = ({
  children,
  isOpen,
  wrapperClass = "",
  isOpenHandler,
}) => {
  const handleModalClose = () => isOpenHandler(!isOpen)

  return (
    <div
      className={`srs-modal modal display-block w-100 p-0 m-0 border-0 ${wrapperClass} fade ${
        isOpen && "d-block show"
      }`}
      style={{ outline: "none" }}
      onClick={handleModalClose}
    >
      <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <button
            type="button"
            className="srs-modal-close position-absolute close"
            onClick={handleModalClose}
          >
            <span>&times;</span>
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
