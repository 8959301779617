import { useFormik } from "formik"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import ReactTooltip from "react-tooltip"
import { debounce } from "lodash";

import Input from "../../../components/Input/Input"
import Modal from "../../../components/Modal/Modal"
import { dateFormatUserManagement } from "../../../utils/dates"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import { getCountryFlagClassName, truncateString } from "../../../utils/strings"
import { windowStorage } from "../../../utils/windowStorage"
import ConfirmButton from "../../../views/partials/ConfirmButton"
import { setTokensAction } from "../../auth/actions"
import { sendResetPasswordLink } from "../../auth/service/api"
import { Company } from "../../companies/types"
import { fetchUsers, searchUsers, updateUser } from "../actions"
import {
  activateUser,
  blockUnblockUser,
  getUser,
  userEmulate,
  deleteUser,
} from "../service/api"
import { User, UserRoles } from "../types"
import UsersManageFormValidationShema from "./UsersManageFormValidationShema"
import useStateGetter from "hooks/useStateGetter"

const defaultSelectedUser: User = {
  id: "",
  name: "",
  email: "",
  password: "",
  role: 0,
  companies: [],
}

const defaultSelectedCompany: Company = {
  id: 0,
  name: "",
  vatNumber: "",
  address: "",
  country: {
    id: 0,
    name: "",
    code: "",
    isEu: true,
    visible: false,
  },
  ceoEmail: "",
  ceoName: "",
  capacities: 0,
  roleId: 0,
}

const UsersManage = () => {
  const { t } = useTranslation()

  const [isUserModalOpen, setIsUserModalOpen] = useState(false)
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(defaultSelectedUser)
  const [selectedCompany, setSelectedCompany] = useState(defaultSelectedCompany)
  const dispatch = useDispatch()
  const [userCompanies, setUserCompanies] = useState<Company[]>([])

  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")

  const userData = useStateGetter<User[]>(['user','users']) || []

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const handleSubmit = (credentials: {
    id: number
    name: string
    email: string
    password: string
  }) => {
    dispatch(updateUser(credentials))
    handleToast(["User updated"])
  }

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      email: "",
      password: "",
    },
    validationSchema: UsersManageFormValidationShema,
    onSubmit: handleSubmit,
  })

  const { values, touched, setFieldValue, errors } = formik

  const handleSearch = () => {
    if(searchName || searchEmail) {
      dispatch(searchUsers(searchName, searchEmail))
    } else {
      dispatch(fetchUsers())
    }
  };

  useEffect(() => {
    handleSearch()
  }, [searchEmail, searchName])

  const handleOnUserClick = (user: User) => {
    setSelectedUser(user)
    setFieldValue("id", parseInt(user.id, 0))
    setFieldValue("name", user.name)
    getUser({ id: parseInt(user.id, 0) }).then(({ data }) => {
      if (data) setUserCompanies(data.plants ?? [])
    })
    setIsUserModalOpen(true)
  }

  const handleOnBlockUnblockClick = async (id?: any) => {
    blockUnblockUser({
      id: id ? parseInt(id, 10) : parseInt(selectedUser.id, 10),
    }).then((response) => {
      if (response.errors) {
        handleToast(response.errors, "error")
      }
      if (response.data) {
        setSelectedUser({
          ...selectedUser,
          ...{ blocked: response.data?.blocked },
        })
        handleToast(["User status changed"])
        dispatch(fetchUsers())
      }
    })
  }

  const handleOnAcceptClick = async () => {
    const data = await activateUser({ id: parseInt(selectedUser.id, 0) })
    if (data?.errors) {
      handleToast(data.errors, "error")
    } else {
      handleToast(["User activated"])
    }

    const today = new Date()
    setSelectedUser({
      ...selectedUser,
      ...{ accepted: today },
    })
    dispatch(fetchUsers())
  }

  const handleOnAcceptButtonClick = async (id: string) => {
    const data = await activateUser({ id: parseInt(id, 10) })
    if (data?.errors) {
      handleToast(data.errors, "error")
    } else {
      handleToast(["User activated"])
    }
    dispatch(fetchUsers())
  }

  const handleOnPasswordReset = async () => {
    const { email } = selectedUser
    const data = await sendResetPasswordLink({ email })

    if (data?.errors) {
      handleToast(data.errors, "error")
    } else {
      const msg = t("Link to change password has been sent on email")
      handleToast([msg])
    }
  }

  const handleOnCompanyClick = (company: Company) => {
    setSelectedCompany(company)
    setIsCountryModalOpen(true)
  }

  const displayUserCompanies = (user: User) => {
    const userId = parseInt(user.id, 0)
    if (userId && userCompanies?.length) {
      return (
        <>
          <h5>
            <strong>{t("Assigned Plants")}:</strong>
          </h5>
          <table className="table table-light table-bordered">
            <thead>
              <tr>
                <th className="col-sm-11">{t("Plant name")}</th>
                <th className="col-sm-11 text-center">{t("Plant country")}</th>
              </tr>
            </thead>
            <tbody>
              {userCompanies.map((userCompany) => {
                return (
                  <tr key={userCompany.id}>
                    <td className="align-middle">
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => handleOnCompanyClick(userCompany)}
                      >
                        {userCompany.name}
                      </button>
                    </td>
                    <td className="align-middle text-center">
                      <span
                        className={getCountryFlagClassName(
                          userCompany.country?.code,
                        )}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )
    }

    return null
  }

  const handleChangeUser = (userId: string) => {
    userEmulate({ id: parseInt(userId, 10) }).then((response) => {
      if (response.errors) {
        handleToast(response.errors, "error")
      }

      if (response.data) {
        dispatch(
          setTokensAction({
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
          }),
        )
        windowStorage.clear(false)
        windowStorage.set("emulate", "true")
        windowStorage.set("returnToken", response.data.returnToken)
        window.location.reload()
      }
    })
  }

  const handleUserRemove = (id: string) => {
    deleteUser({ id: parseInt(id, 10) }).then((response) => {
      if (response.errors) {
        handleToast(response.errors, "error")
      } else {
        setTimeout(() => {
          dispatch(fetchUsers())
          handleToast(["User removed"])
        }, 0)
      }
    })
  }

  return (
    <>
      <h1 className="mb-5">{t("Manage users")}</h1>

      <h4 className="mb-3">{t("Search")}</h4>
      <form className="form-inline mb-3 row">
        <div className="form-group col-md-3">
          <input
            name="name"
            className="form-control w-100"
            type="text"
            placeholder={t("Name")}
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>
        <div className="form-group col-md-3">
          <input
            name="email"
            className="form-control w-100"
            type="text"
            placeholder={t("Email")}
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
        </div>
      </form>

      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="text-center">{t("Name")}</th>
            <th className="text-center">{t("Email")}</th>
            <th className="text-center">{t("Accepted at")}</th>
            <th className="text-center">{t("Last login")}</th>
            <th className="text-center">{t("Last emulate")}</th>
            <th className="text-center">{t("Is active")}</th>
            <th className="text-center actions-col">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {userData.map((user) => {
            const {
              id,
              name,
              email,
              blocked,
              accepted,
              lastActive,
              lastActiveEmulate,
              canBeImpersonated,
              role,
            } = user
            return (
              <tr key={id}>
                <td className="align-middle">
                  <button
                    type="button"
                    className="btn btn-link p-0"
                    onClick={() => handleOnUserClick(user)}
                    data-tip={name}
                  >
                    {truncateString(name, 25)}
                    <ReactTooltip />
                  </button>
                </td>
                <td className="align-middle text-left break-all">{email}</td>
                <td className="align-middle text-center">
                  {accepted ? (
                    dateFormatUserManagement(accepted)
                  ) : (
                    <span className="font-weight-bold text-danger">
                      Not accepted
                    </span>
                  )}
                </td>
                <td className="align-middle text-center">
                  {lastActive ? dateFormatUserManagement(lastActive) : "-"}
                </td>
                <td className="align-middle text-center">
                  {lastActiveEmulate
                    ? dateFormatUserManagement(lastActiveEmulate)
                    : "-"}
                </td>
                <td className="align-middle text-center">
                  {blocked ? (
                    <i className="fa-2x fas fa-ban text-danger" />
                  ) : (
                    <i className="fa-2x fas fa-check-circle text-success" />
                  )}
                  {/* {blocked ? dateFormat(blocked) : '-'} */}
                </td>
                <td className="align-middle text-center">
                  <div className="row">
                    {!accepted && (
                      <div className="col-12 py-1">
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm text-left w-100"
                          onClick={() => handleOnAcceptButtonClick(id)}
                        >
                          <i className="fas fa-check mr-2" />
                          {t("Accept")}
                        </button>
                      </div>
                    )}
                    <div className="col-12 py-1">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm text-left w-100"
                        onClick={() => handleOnUserClick(user)}
                      >
                        <i className="fas fa-eye mr-2" />
                        {t("View profile")}
                      </button>
                    </div>
                    <div className="col-12 py-1">
                      <button
                        type="button"
                        className="btn btn-decline btn-sm text-left w-100"
                        onClick={() => handleOnBlockUnblockClick(id)}
                      >
                        <i
                          className={
                            blocked
                              ? "fas fa-check-circle btn-icon mr-1"
                              : "fas fa-ban btn-icon mr-1"
                          }
                        />
                        {t(blocked ? "Unblock" : "Block")}
                      </button>
                    </div>
                    {role !== UserRoles.ADMIN && (
                      <div className="col-12 py-1">
                        <ConfirmButton
                          title="Remove"
                          text="Are you sure you wish to remove this user?"
                          confirmText="Remove"
                          icon="trash"
                          className="btn-sm text-left w-100 btn-outline-danger"
                          confirmClassName="btn-outline-danger"
                          danger
                          onConfirm={() => handleUserRemove(id)}
                        >
                          <i className="fas fa-trash btn-icon mr-1" />
                          {t("Remove")}
                        </ConfirmButton>
                      </div>
                    )}
                    {canBeImpersonated && (
                      <div className="col-12 py-1">
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm text-left w-100"
                          onClick={() => handleChangeUser(id)}
                        >
                          <i className="fas fa-user btn-icon mr-1" />
                          {t("Emulate")}
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-outline-primary rounded-bottom-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-arrow-left btn-icon mr-1" /> {t("Back")}
        </button>
      </div>
      <Modal
        isOpen={isUserModalOpen}
        wrapperClass="srs-modal-lg"
        isOpenHandler={setIsUserModalOpen}
      >
        <div className="modal-header">
          <h3 className="modal-title">
            <strong>
              {t("User")} {selectedUser.name}
            </strong>
          </h3>
          <span>{selectedUser.email}</span>
        </div>
        <div className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <Input
              label="Username"
              name="name"
              value={values.name}
              handleOnChange={formik.handleChange}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              error={touched.name && errors.name}
            />
            <button type="submit" className="btn btn-sm btn-primary">
              {t("Update")}
            </button>
          </form>
          <hr />
          <button
            type="button"
            onClick={handleOnPasswordReset}
            className="btn btn-sm btn-info w-100"
          >
            {t("Send link to change password")}
          </button>
          <hr />
          {!selectedUser.accepted && (
            <>
              <h5>
                <strong>{t("User not accepted after registration")}:</strong>
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-link"
                onClick={handleOnAcceptClick}
              >
                {t("Accepted")}
              </button>
              <hr />
            </>
          )}
          {displayUserCompanies(selectedUser)}
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary rounded-bottom-left"
            onClick={() => setIsUserModalOpen(!isUserModalOpen)}
          >
            {t("Close")}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isCountryModalOpen}
        isOpenHandler={setIsCountryModalOpen}
        wrapperClass="srs-modal-lg"
      >
        <div className="modal-header">
          <h3 className="modal-title">
            <strong>
              {t("Plant information for")}: {selectedCompany.name}
            </strong>
          </h3>
        </div>
        <div className="modal-body small">
          <h5>{t("General information")}</h5>
          <div className="row">
            <div className="col">{t("Company name")}:</div>
            <div className="col">{selectedCompany.name}</div>
          </div>
          <div className="row">
            <div className="col">{t("Vat number")}:</div>
            <div className="col">{selectedCompany.vatNumber}</div>
          </div>
          <hr />
          <p className="mt-2">{t("Contact information")}</p>
          <div className="row">
            <div className="col">{t("Address")}:</div>
            <div className="col">{selectedCompany.address}</div>
          </div>
          <div className="row">
            <div className="col">{t("Country")}:</div>
            <div className="col">
              <span
                className={getCountryFlagClassName(
                  selectedCompany.country?.code,
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">{t("CEO name")}:</div>
            <div className="col">{selectedCompany.ceoName}</div>
          </div>
          <div className="row">
            <div className="col">{t("CEO email")}:</div>
            <div className="col">{selectedCompany.ceoEmail}</div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UsersManage
