import React from "react"
import { useTranslation } from "react-i18next"
import { sum } from 'lodash'

import { activityTypes } from "../constants"
import { kilogramsToTonnes } from "../helpers"
import styles from "../styles.module.scss"
import { recyclingActivity } from "../types"

export const AuditRecyclingActivityTable: React.FC<{
  tableData: Array<recyclingActivity>
}> = ({ tableData = [] }) => {
  const { t } = useTranslation()

  return (
    <>
      <p className={styles.title}>{activityTypes[1].label}</p>
      <table id="recycling-table" className="table table-light table-bordered">
        <thead>
          <tr>
            <th className="text-center">{t("Polymer")}</th>
            <th className="text-center">{t("Origin")}</th>
            <th className="text-center">{t("Type")}</th>
            {/* <th className="text-center">{t("Sector")}</th> */}
            <th className="text-center">{t("Total waste input, t (gross)")}</th>
            <th className="text-center">
              {t("Total input into recycling, t (net)")}
            </th>
            <th className="text-center">{t("Output, t")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item: recyclingActivity, index: number) => {
            item.eu.postConsumer =
              !item?.eu?.postConsumer || item?.eu?.postConsumer?.length === 0
                ? (null as any)
                : item?.eu?.postConsumer
            item.eu.preConsumer =
              !item?.eu?.preConsumer || item?.eu?.preConsumer?.length === 0
                ? (null as any)
                : item?.eu?.preConsumer
            item.nonEu.postConsumer =
              !item?.nonEu?.postConsumer ||
              item?.nonEu?.postConsumer?.length === 0
                ? (null as any)
                : item?.nonEu?.postConsumer
            item.nonEu.preConsumer =
              !item?.nonEu?.preConsumer ||
              item?.nonEu?.preConsumer?.length === 0
                ? (null as any)
                : item?.nonEu?.preConsumer

            const euPostLength = item?.eu?.postConsumer?.length ?? 1
            const euPreLength = item?.eu?.preConsumer?.length ?? 1
            const nonEuPostLength = item?.nonEu?.postConsumer?.length ?? 1
            const nonEuPreLength = item?.nonEu?.preConsumer?.length ?? 1

            return (
              <tr key={`item_${index}`}>
                <td>{item.material}</td>
                <td className={styles.group}>
                  <div>
                    <tr className={styles.cell}>EU</tr>
                    <tr>&nbsp;</tr>
                    <tr>&nbsp;</tr>
                    {/* {Array.from(Array(euPostLength + euPreLength - 1))?.map(
                      (_, index) => (
                        <tr key={`subEu_${index}`}>&nbsp;</tr>
                      ),
                    )} */}
                    <tr className={styles.cell}>Non-EU</tr>
                    <tr>&nbsp;</tr>
                    <tr>&nbsp;</tr>
                    <tr className={styles.cell}>&nbsp;</tr>
                    {/* {Array.from(
                      Array(nonEuPostLength + nonEuPreLength - 1),
                    )?.map((_, index) => (
                      <tr key={`subEu_${index}`}>&nbsp;</tr>
                    )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                    {/* <tr className={styles.cell}>&nbsp;</tr> */}
                  </div>
                </td>
                <td className={styles.group}>
                  <div>
                    <tr className={styles.cell}>&nbsp;</tr>
                    <tr className={styles.cell}>Post-consumer</tr>
                    {/* {Array.from(Array(euPostLength - 1))?.map((_, index) => (
                      <tr key={`subPostEu_${index}`}>&nbsp;</tr>
                    )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                    <tr className={styles.cell}>Pre-consumer</tr>
                    {/* {Array.from(Array(euPreLength - 1))?.map((_, index) => (
                      <tr key={`subPreEu_${index}`}>&nbsp;</tr>
                    )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                    {/* <tr className={styles.cell}>&nbsp;</tr> */}
                    <tr className={styles.cell}>&nbsp;</tr>
                    <tr className={styles.cell}>Post-consumer</tr>
                    {/* {Array.from(Array(nonEuPostLength - 1))?.map((_, index) => (
                      <tr key={`subNonPostEu_${index}`}>&nbsp;</tr>
                    )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                    <tr className={styles.cell}>Pre-consumer</tr>
                    <tr className={styles.cell}>&nbsp;</tr>
                    {/* {Array.from(Array(nonEuPreLength - 1))?.map((_, index) => (
                      <tr key={`subNonPreEu_${index}`}>&nbsp;</tr>
                    )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                    {/* <tr className={styles.cell}>&nbsp;</tr> */}
                  </div>
                </td>
                {/* <td className={styles.group}>
                  <tr className={styles.cell}>&nbsp;</tr>
                  {item.eu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {a.sector}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  {item.eu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {a.sector}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  <tr className={styles.cell}>&nbsp;</tr>
                  {item.nonEu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {a.sector}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  {item.nonEu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {a.sector}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  <tr className={styles.cell}>Total {item.material}</tr>
                </td> */}
                
                <td className={styles.group}>
                  <tr className={styles.cell}>&nbsp;</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.eu.postConsumer?.map(a => a.totalInputRecycling)))}</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.eu.preConsumer?.map(a => a.totalInputRecycling)))}</tr>
                  <tr className={styles.cell}>&nbsp;</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.nonEu.postConsumer?.map(a => a.totalInputRecycling)))}</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.nonEu.preConsumer?.map(a => a.totalInputRecycling)))}</tr>
                  {/* {item.eu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInputRecycling)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  {item.eu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInputRecycling)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  <tr className={styles.cell}>&nbsp;</tr>
                  {item.nonEu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInputRecycling)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  {item.nonEu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInputRecycling)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                  <tr className={styles.cell}>{kilogramsToTonnes(item.totalInputRecycling)}</tr>
                </td>
                <td className={styles.group}>
                  <tr className={styles.cell}>&nbsp;</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.eu.postConsumer?.map(a => a.totalInput)))}</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.eu.preConsumer?.map(a => a.totalInput)))}</tr>
                  <tr className={styles.cell}>&nbsp;</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.nonEu.postConsumer?.map(a => a.totalInput)))}</tr>
                  <tr className={styles.cell}>{kilogramsToTonnes(sum(item.nonEu.preConsumer?.map(a => a.totalInput)))}</tr>
                  {/* {item.eu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInput)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                  {/* {item.eu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInput)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                  {/* <tr className={styles.cell}>&nbsp;</tr> */}
                  {/* {item.nonEu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInput)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  {item.nonEu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      {kilogramsToTonnes(a.totalInput)}
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                  <tr className={styles.cell}>{kilogramsToTonnes(item.totalInput)}</tr>
                </td>
                <td className={`${styles.group} ${styles.highlightLeft}`}>
                  <tr className={styles.cell}>{kilogramsToTonnes(item.eu.totalOutput)} </tr>
                  <tr className={styles.cell}>&nbsp;</tr>
                  <tr className={styles.cell}>&nbsp;</tr>
                  {/* {item.eu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      &nbsp;
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  {item.eu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      &nbsp;
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                  <tr className={styles.cell}>{kilogramsToTonnes(item.nonEu.totalOutput)} </tr>
                  <tr className={styles.cell}>&nbsp;</tr>
                  <tr className={styles.cell}>&nbsp;</tr>
                  {/* {item.nonEu.postConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      &nbsp;
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>}
                  {item.nonEu.preConsumer?.map((a, i) => (
                    <tr key={`a_${i}`} className={styles.cell}>
                      &nbsp;
                    </tr>
                  )) ?? <tr className={styles.cell}>&nbsp;</tr>} */}
                  <tr className={styles.cell}>{kilogramsToTonnes(item.totalOutput)}</tr>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
