import { sum } from "ramda"
import React, { useState, useEffect, ChangeEvent, FormEvent } from "react"
import { useDispatch } from "react-redux"

import Checkbox from "components/Checkbox/Checkbox"

import { handleToast } from "utils/messages"

import useMaxValueState from "hooks/useMaxValueState"
import useStateGetter from "hooks/useStateGetter"

import { getSectorSubmission } from "../../../submissions/service/api"
import { SectorSubmission } from "../../../submissions/types"
import { computeInitialSectorSubmission } from "../../../submissions/utils"
import { fetchSectorApplications } from "../../actions"
import {
  postSectorApplication,
  deleteSectorApplication,
} from "../../service/api"
import { SectorApplication } from "../../types"

const initialState = {
  "Austria Switzerland": 0,
  Benelux: 0,
  "Eastern Europe": 0,
  France: 0,
  Germany: 0,
  Iberia: 0,
  Italy: 0,
  Poland: 0,
  Scandinavia: 0,
  "United Kingdom": 0,
}

const computeSectorApplications = (sectorApplications: SectorApplication[]) => {
  let totalInternalAdded = 0
  let totalExternalAdded = 0
  const computedSectorApplicationsData = sectorApplications.map(
    (sectorApplication) => {
      const { id } = sectorApplication
      const { year, sourceType } = sectorApplication.sectorAssociationValue
      const jsonValue = JSON.parse(sectorApplication.value)
      const data = Object.entries(jsonValue).filter(([, value]) => value)
      const total = sum(data.map(([, value]) => value as number))

      if (sourceType === "internal") {
        totalInternalAdded += total
      }

      if (sourceType === "external") {
        totalExternalAdded += total
      }

      return {
        id,
        year,
        sourceType,
        data,
        total,
      }
    },
  )

  return {
    computedSectorApplicationsData,
    totalInternalAdded,
    totalExternalAdded,
  }
}

const SectorApplicationListTable = ({ year }: { year: number }) => {
  const [sourceType, setSourceType] = useState("")
  const sectorApplications =
    useStateGetter<SectorApplication[]>([
      "application",
      "sectorApplications",
    ]) ?? []

  const {
    computedSectorApplicationsData,
    totalInternalAdded,
    totalExternalAdded,
  } = computeSectorApplications(sectorApplications)

  const [sectorSubmissions, setSectorSubmissions] = useState<
    SectorSubmission[]
  >([])
  const [state, localDispatch] = useMaxValueState(initialState)
  const dispatch = useDispatch()

  const { internal, external } =
    computeInitialSectorSubmission(sectorSubmissions)
  const internalTotal = internal.total
  const externalTotal = external.total
  const internalRemainingValue = internalTotal - totalInternalAdded
  const externalRemainingValue = externalTotal - totalExternalAdded

  const stateTotal = sum(Object.values(state))

  let selectedSourceTypeValueTotal = 0
  let selectedSectorSubmissionId: number | undefined = 0
  if (sourceType === "internal") {
    selectedSourceTypeValueTotal = internalRemainingValue
    selectedSectorSubmissionId = internal.id
  } else if (sourceType === "external") {
    selectedSourceTypeValueTotal = externalRemainingValue
    selectedSectorSubmissionId = external.id
  } else {
    selectedSourceTypeValueTotal = 0
    selectedSectorSubmissionId = 0
  }

  const handleOnRegionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target
    let baseValue: string = value
    if (!value) {
      baseValue = "0"
      event.target.focus()
    }
    localDispatch({
      type: "set",
      payload: {
        [name]: parseInt(baseValue, 0),
        maxValue: selectedSourceTypeValueTotal,
      },
    })
  }

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const credentials = {
      sectorAssociationValueId: selectedSectorSubmissionId,
      value: JSON.stringify(state),
    }
    const { errors } = await postSectorApplication(credentials)
    if (errors) {
      handleToast(errors, "errors")
    } else {
      handleToast(["Success!"])
      setSourceType("")
      dispatch(fetchSectorApplications({ year }))
      localDispatch({ type: "reset", payload: {} })
    }
  }

  const handleOnClickDelete = async (id: number) => {
    await deleteSectorApplication({ id })
    dispatch(fetchSectorApplications({ year }))
  }

  useEffect(() => {
    getSectorSubmission({ year }).then(({ data }: any) => {
      if (data) {
        setSectorSubmissions(data)
      }
    })
  }, [year])

  useEffect(() => {
    localDispatch({ type: "reset", payload: {} })
  }, [localDispatch, sourceType])

  useEffect(() => {
    dispatch(fetchSectorApplications({ year }))
  }, [dispatch, year])

  return (
    <>
      <table className="table table-light table-bordered">
        <thead>
          <tr>
            <th>Year</th>
            <th>Source type</th>
            <th>Values</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {computedSectorApplicationsData.map((sectorApplication) => {
            const {
              id,
              year: cYear,
              sourceType: cSourceType,
              data,
            } = sectorApplication
            const displayValue = data
              .map(([region, value]) => `${region}: ${value}`)
              .join(", ")

            return (
              <tr key={id}>
                <td className="align-middle">{cYear}</td>
                <td className="align-middle">{cSourceType}</td>
                <td className="align-middle">{displayValue}</td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={() => handleOnClickDelete(id)}
                  >
                    <i className="fas fa-trash-alt" />
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="d-flex">
        <Checkbox
          label={`Internal Pre-consumer waste (T) (${internalRemainingValue})`}
          name="internal"
          value={sourceType === "internal"}
          handleOnChange={() => setSourceType("internal")}
          checkboxWrapperClassName="mb-0 col-6"
          isDisabled={internalRemainingValue === 0}
        />
        <Checkbox
          label={`Volume of recyclate brought from an external source (${externalRemainingValue})`}
          name="extarnal"
          value={sourceType === "external"}
          handleOnChange={() => setSourceType("external")}
          checkboxWrapperClassName="mb-0 col-6"
          isDisabled={externalRemainingValue === 0}
        />
      </div>
      <hr />
      <h2 className="text-center">
        {year} - {stateTotal}/{selectedSourceTypeValueTotal}
      </h2>
      {sourceType !== "" && (
        <form onSubmit={handleOnSubmit}>
          {Object.keys(state).map((key) => (
            <div
              key={key}
              className="d-flex border p-2 mb-2 bg-white text-primary align-items-center justify-content-between"
            >
              {key}
              <div>
                <button
                  type="button"
                  className="btn btn-link"
                  disabled={stateTotal === selectedSourceTypeValueTotal}
                  onClick={() =>
                    localDispatch({
                      type: "increment",
                      payload: {
                        [key]: 10,
                        maxValue: selectedSourceTypeValueTotal,
                      },
                    })
                  }
                >
                  <i className="fas fa-plus-circle fa-lg" />
                </button>
                <input
                  type="text"
                  name={key}
                  value={state[key]}
                  style={{ maxWidth: "75px" }}
                  onFocus={(event) => event.target.select()}
                  onChange={handleOnRegionChange}
                />
                <button
                  type="button"
                  className="btn btn-link"
                  disabled={state[key] === 0}
                  onClick={() =>
                    localDispatch({
                      type: "decrement",
                      payload: {
                        [key]: 10,
                        maxValue: selectedSourceTypeValueTotal,
                      },
                    })
                  }
                >
                  <i className="fas fa-minus-circle fa-lg" />
                </button>
                tonnes
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
          >
            Save
          </button>
        </form>
      )}
    </>
  )
}

export default SectorApplicationListTable
