import React, { useState, useEffect } from "react"
import { useRouteMatch } from "react-router-dom"

import SectorSubmissionForm from "../components/SectorSubmissionForm/SectorSubmissionForm"
import { getSectorSubmission } from "../service/api"
import { SectorSubmission } from "../types"

const SectorSubmissionEdit = () => {
  const [sectorSubmission, setSectorSubmission] = useState<SectorSubmission[]>(
    [],
  )
  const { params }: { params: { year: string } } = useRouteMatch()
  const { year } = params

  useEffect(() => {
    if (year) {
      getSectorSubmission({ year: parseInt(year, 0) ?? 0 }).then(({ data }) => {
        if (data) {
          setSectorSubmission(data)
        }
      })
    }
  }, [year])

  return (
    <>
      <h1 className="mb-5">Edit sector submission</h1>
      <SectorSubmissionForm initialSectorSubmissions={sectorSubmission} />
    </>
  )
}

export default SectorSubmissionEdit
